<template>
<div 
	class='createSetButtonWrapper'
	v-tap='() => $emit("createSet")'
>
	<div class='createSetButton' >
		<div class='border'>
			<svg class='giantIcon'>
				<use xlink:href='#plus' />
			</svg>
		</div>
		<span class='label'>{{$t('elements.buttons.exerciseSet')}}</span>
	</div>
</div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'

export default {
	name: 'CreateSetButton',
	props: ['bussingSetExercises'],
	computed: {
		bussing() { return this.$store.state.bus.bussing && ['browserElements', 'setElements'].includes(this.$store.state.bus.contentType) }
	},
	methods: {
		async onUnloadBus(el) {
			//console.log(el)
			if (!this.$el.contains(el)) return
			const payload = {}
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIds', ids.join(','))
				) return
				const { exercises } = this.$store.getters['elements/getElementsForCopyToSet'](ids)
				payload.exercises = exercises
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { elements } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				payload.elements = elements
			}
			this.$emit('createSet')			
			setTimeout(() => this.$store.dispatch('sets/copyElements', { 
				...payload, 
				setKey: this.$store.state.setBuilder.list[this.$store.state.setBuilder.list.length-1] 
			}).then(elementKeys => {
						//if (elementKeys.length === 1) 
						this.$store.dispatch('setBuilder/setSelected', elementKeys)
					})
			, 0)
		}
	},
	mounted() {
		eventBus.$on('unloadBus', this.onUnloadBus)
	},
	beforeDestroy() {
		eventBus.$off('unloadBus', this.onUnloadBus)		
	}	
}
</script>

<style lang='scss'>
.createSetButtonWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: relative;

	.createSetButton {
		color: $color-black;
		font-size: $size-font-large;
		text-align: center;
		max-width: 96px;
		width: 100%;
		cursor: pointer;
		padding: 1px;

		.border {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 96px;
			width: 100%;
			padding-top: 100%;
			border-radius: 50%;
			margin-bottom: $size-gutter * 2;
			background: $button-gradient-primary;
			color: $color-white;

			svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: scale(0.5);
			}
		}

		&:hover .border {
			background: $color-primary-accent;
		}
	}
}
</style>