<template>
<div class='saveTemplatesList'>
	<div class='content'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			class='recycleScroller'
		>
			<template slot='before'>
				<div class='fullPath bubble'>
					<div class="content">
						<span v-html="pathTo(folderId, true)" />
					</div>
				</div>				
				<ResultInfo :total='total' />
			</template>		
			<template slot='sticky'>
				<TableColumns v-if='items.length'
					module='saveTemplates'
					:columns='[{
						label: $t("elements.cols.exerciseSet"),
						selectable: false,
						flex: 1
					},{
						label: $t("elements.cols.lastUpdated"),
						selectable: false,
						flexBasis: "179px",
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem 
					:item='item' 
					:itemKey='item.id' 
					:path='pathTo(item.folderId)' 
				/>
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
	</div>
	<div class='saveAsWrapper'>
		<SaveAsInput v-model='savenameData' />
	</div>
	<div class='footerPanel modalControls'>
		<CancelButton @click='$emit("close")' />
		<div class='saveButton iconTextButton success' @click='$emit("save", savenameData)'>
			<svg class='smallIcon'>
				<use xlink:href='#save' />
			</svg>
			<span class='label'>{{$t('elements.buttons.save')}} {{savenameData || $t('elements.labels.untitled')}}</span>
		</div>
	</div>	
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { setsApi } from '@/services/api/modules/clinician/sets'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import TableColumns from '@/components/common/TableColumns'
import AtBottom from '@/components/common/AtBottom'
import Loading from '@/components/common/Loading'
import CancelButton from '@/components/common/buttons/Cancel'
import SaveAsInput from '@/components/common/SaveAsInput'
import ListItem from './ListItem'

export default {
	name: 'SaveTemplatesList',
	components: { Loading, RecycleScroller, TableColumns, AtBottom, ListItem, ResultInfo, CancelButton, SaveAsInput },
	props: ['folders', 'savename'],
	data() { return {
		working: false,
		total: 0,
		items: [],
		savenameData: this.savename
	}},
	computed: { 
		folderId() { return this.$store.state.saveTemplates.folderId },
		filter() {
			return {
				folderId: this.folderId
			}
		}
	},
	methods: {
		fetchTop() {
			this.working = true
			return setsApi.touch('fetchTemplates', this.filter).then(([err, result]) => {
				if (!err) {
					this.items = result.items
					this.total = result.total
				}
			})
			.finally(() => this.working = false)
		},
		fetchNext() {
			if (this.items.length < this.total) {
				this.working = true
				return setsApi.touch('fetchTemplates', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
					if (!err) this.items.push(...result.items)
				})
				.finally(() => this.working = false)
			}
		},
		pathTo(folderId, fullPath) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (
					node.parentId && 
					(fullPath || node.id !== this.$store.state.saveTemplates.folderId)
				) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return nodes.length ? "/ " + nodes.join("&nbsp;/ ")	: ""
		}
	},
	watch: {
		folderId(a,b) { if (a && a!==b) this.fetchTop() }
	},
	mounted() {
		if (this.folderId) this.fetchTop()
	}
}
</script>

<style lang='scss'>
.saveTemplatesList {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	background: $color-neutral-accent;


	.fullPath { 
		font-weight: bold; background: none; 
		color: $color-primary-accent; 
		margin: $size-gutter * 2;
		margin-bottom: 0; 
	}

	.content { 
		position: relative; 
		.tableColumns {
			margin: $size-gutter * 2;
			margin-bottom: 0;
		}
		.resultInfo {
			margin-top: $size-gutter * 2;			
		}
	}

	.saveAsWrapper { padding: $size-gutter * 2; }

}
</style>