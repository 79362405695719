<template>
<li class='category'>
	<div 
		class='node' 
		@click='toggleSelect'		
	>
		<div 
			@click.stop='toggleOpen'		
			class='switch' 
			:class='{ open, selected }'
		>
			<svg>
				<use xlink:href='#triangle' />
			</svg>	
		</div>
		<div class='title' :class='{ selected }'>{{ node.title }}</div>
	</div>
	<ul v-if='open && node.subordinates'>
		<Category
			v-for='sub in node.subordinates'
			:key='sub.id'
			:node='sub'
			:class='{ animateOpen }'
		/>
	</ul>
</li>
</template>

<script>
import Category from './Category'

let animateTimer

export default {
	name: 'Category',
	props: ['node'],
	components: {
		Category
	},
	data: () => ({
		animateOpen: false
	}),
	computed: {
		open() {
			return this.$store.getters['categories/open'](this.node.id)
		},
		selected() {
			return this.$store.state.categories.selected === this.node.id
		}
	},
	methods: {
		toggleOpen() {
			if (!this.open) {
				clearTimeout(animateTimer)
				this.animateOpen = true
				animateTimer = setTimeout(() => this.animateOpen = false, 1000)
			}
			this.$store.dispatch('categories/toggleOpen', this.node.id)
		},
		toggleSelect() {
			if (!this.selected && !this.open && this.node.subordinates) this.toggleOpen()
			this.$store.dispatch('categories/setSelected', this.selected ? null : this.node.id)
		}
	}
}
</script>

<style lang='scss'>
.desktop .categoriesTree .category .node:hover .title {
	background: $color-neutral-accent;
}

.categoriesTree .category {
	position: relative;

	&.animateOpen {
		animation: treeNode 600ms;
	}

	.node {
		position: relative;
		padding: 0;
		cursor: pointer;

		.title {
			display: inline-block;
			padding-top: $size-gutter / 2;
			padding-bottom: $size-gutter / 2;
			padding-left: $size-gutter;
			padding-right: $size-gutter;
			border-radius: 2px;
			margin-left: 1px;

			&.selected {
				position: relative;
				z-index: 1;
				/*
				background: $button-gradient-primary !important;
				color: $color-white;
				*/
				background: $color-neutral;
				color: $color-primary-accent;
				font-weight: bold;
			}
		}

		.switch {
			position: absolute;
			z-index: 1;
			top: 5px;
			left: -16px;
			width: 12px;
			height: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			&.selected > svg { color: $color-primary-accent; }

			> svg { 
				width: 100%;
				height: 100%;
				color: $color-neutral-dark;
				transform-origin: center;
				transform: rotate(-30deg) scale(0.8);

			}
			/*
			border-radius: 6px;
			border: 1px solid $color-neutral-shadow;
			background: $color-neutral-shadow;
			*/
			&.open, &.selected {
				border: 0;
				top: 4px;
				left: -13px;
				> svg {
					color: $button-gradient-primary;
					transform: rotate(60deg) scale(0.8);
				}

			}

		}

		&.tick:after {
			content: '';
			position: absolute;
			top: 12px;
			left: -12px;
			height: 1px;
			width: 7px;
			background: $color-neutral-dark;			
		}
	}
/*
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: -9px;
		height: 100%;
		width: 1px;
		background: $color-neutral-shadow;
	}

	&:last-child:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 13px;
		left: -9px;
		width: 3px;
		height: calc(100% - 12px);
		background: $color-neutral-panel;
	}
*/
	ul {
		padding-left: $size-gutter * 3;
		list-style: none;
	}
}

@keyframes treeNode {
	from {
		opacity: 0;
		transform: translateX($size-gutter);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
</style>


