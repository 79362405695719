<template>
<div class='resourceInspectorCaption' >
	<PlainText 
		ref='caption'
		v-model='caption' 
		class='caption' 
		maxlength='200' 
		:htmlEntities='true'
		:placeholder = 'placeholder'
		@focus='$emit("focus")'
	/>
</div>
</template>

<script>
import PlainText from '@/components/common/PlainText'

export default {
	name: 'ResourceInspectorCaption',
	props: ['resource', 'setResourceCaption'],
	components: {
		PlainText
	},
	data: () => ({
		placeholder: null
	}),
	computed: {
		caption: {
			get() {
				return this.resource.caption
			},
			set(value) {
				this.setResourceCaption({ key: this.resource.key || this.resource.cid, value })
			}
		}
	},
	methods: {
		setPlaceholder() {
			this.placeholder = this.$t('views.designer.phResourceCaption')
		},
		unsetPlaceholder() {
			this.placeholder = ''
		}
	},
	mounted() {
		this.$refs.caption.$el.addEventListener('focus', this.unsetPlaceholder)
		this.$refs.caption.$el.addEventListener('blur', this.setPlaceholder)
		this.setPlaceholder()
	},
	beforeDestroy() {
		this.$refs.caption.$el.removeEventListener('focus', this.unsetPlaceholder)
		this.$refs.caption.$el.removeEventListener('blur', this.setPlaceholder)
	}
}
</script>

<style lang='scss'>
.resourceInspectorCaption {
	.caption textarea {
		width: 100%;
		height: 100px;
		text-align: center;
		font-size: $size-font-large;
		line-height: $size-lineHeight-large;
		padding-left: 100px;
		padding-right: 100px;
	}
}
</style>

