<template>
<div class='exerciseBrowser' @toggleDetail='onToggleDetail'>
	<div>
		<div class='tabsPanel'>
			<Tabs @copyTo='copyElements' />
		</div>
		<div class='elementsPanel'>
		
			<div class='mainWrapper'>

				<div v-show='showLeftPanel' class='categoriesWrapper'>
					<!--
					<CloseButton v-if='showCategories' @click='$emit("toggleCategories")' />
					<CloseButton v-else-if='showFolders' @click='$emit("toggleFolders")' />
					-->
					<div v-if='showCategories && $store.state.exerciseBrowser.tab==="stock"' @click='$emit("toggleCategories")' class='blockButton'>
						<svg class='smallIcon'>
							<use xlink:href='#angleLeft' />
						</svg>						
					</div>
					<div v-else-if='showFolders && $store.state.exerciseBrowser.tab==="custom"' @click='$emit("toggleFolders")' class='blockButton'>
						<svg class='smallIcon'>
							<use xlink:href='#angleLeft' />
						</svg>						
					</div>					
					<Scroll>
						<Categories v-show='$store.state.exerciseBrowser.tab==="stock"' />
						<KeepAlive>
							<Folders v-show='$store.state.exerciseBrowser.tab==="custom"' @update='updateFolderData' @drop='moveElements' :canMoveSelectionToFolder='canMoveSelectionToFolder' />
						</KeepAlive>
					</Scroll>
				</div>

				<div class='elementsWrapper'>

					<div 
						ref='menu'
						class='scrollMenu' 
						:style='`transform: translateY(${menuTop}px)`'
					>
						<div class='searchWrapper'><div><div>
							<div class='search'>
								<div 
									v-if='!showCategories && $store.state.exerciseBrowser.tab==="stock"'
									class='categoriesButton iconTextButton neutral' 
									@click='$emit("toggleCategories")'
								>
									<svg class='smallIcon'>
										<use xlink:href='#tree' />
									</svg>
									<span class='label'>{{$t("elements.buttons.categories")}}</span>
								</div>
								<div 
									v-else-if='!showFolders && $store.state.exerciseBrowser.tab==="custom"'
									class='categoriesButton iconTextButton neutral' 
									@click='$emit("toggleFolders")'
								>
									<svg class='smallIcon'>
										<use xlink:href='#folders' />
									</svg>
									<span class='label'>Folders</span>
								</div>								
								<SearchInput 
									v-model='search'
									@submit='fetchTop'
									:placeholder='$t("views.designer.phSearchExercises")'
									ref='searchInput'
								/>
							</div>
							<!--<SelectionControls v-if='showSelectionControls' @copy='onCopy' :canMoveSelectionToFolder='canMoveSelectionToFolder' />-->
						</div></div></div>
					</div>
					<transition name='scroll'>
						<div v-show='!$store.state.main.mobile && showMenuTab' class='blockButton neutral menuTrigger' @click='onClickMenuTab'>
							<svg class='smallIcon'>
								<use xlink:href='#search' />
							</svg>
						</div>
					</transition>


					<Scroll 
						ref='scroll'
						:fetchNext='fetchNext'
						:loading='loading'
						:freeze='bussing'
						@diff='onScrollDiff'
					>				
						<Lasso 
							:selectableKeys='elementKeys'
							:getSelected='getSelected'
							:setSelected='setSelected'
							:setSelecting='setSelecting'
							:max='50'
							selectableClass='mainElement'
						>
							<ElementsHeader :path='path' :folders='folderData' :showSubfolders='true' />
							<Elements 
								v-if='Object.values(folderData).length'
								:elementKeys='elementKeys' 
								@copy='onCopy' 
								:folderData='folderData' 
								:canMoveSelectionToStack='canMoveSelectionToStack' 
								:canMoveSelectionOutOfStack='canMoveSelectionOutOfStack' 
								@moveElementsIntoStack='moveElementsIntoStack' 
								@moveElementsOutOfStack='moveElementsOutOfStack'
								@showContextMenu='showContextMenu'
							/>
							<div class='elementsFooter'>
								<Pagination 
									v-if='$store.getters["exerciseBrowser/lastPage"]'
									:totalPages='$store.getters["exerciseBrowser/totalPages"]' 
									:page='$store.state.exerciseBrowser.page'
									@click='page => { showMenu(); $store.dispatch("exerciseBrowser/setPage", page) }' 
								/>		
							</div>
						</Lasso>
					</Scroll>
					<transition name='fade'><Loading v-show='loading' /></transition>
				</div>

			</div>
		</div>
	</div>
		<ContextMenu 
			v-if='contextMenu' 
			:e='contextMenu'
			@close='contextMenu=false'
		>
				<div 
					class='iconTextButton neutral'
					@click='$store.dispatch("exerciseBrowser/clearSelected")'
				>
					<svg class='smallIcon'>
						<use xlink:href='#cancel' />
					</svg>
					<span class='label'>{{$t("elements.buttons.unselect")}}</span>
				</div>
				<div 
					class='iconTextButton neutral' 
					@click='contextMenu.target.click()'
					:class='{ disabled: selected.length>1 }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#edit' />
					</svg>
					<span class='label'>Open / Close</span>
				</div>					
				<div 
					class='iconTextButton neutral' 
					@click='copyElements()'
					:class='{ disabled: selected.length > 10 }'
				>
					<svg class='smallIcon'>
						<use xlink:href='#copy' />
					</svg>
					<span class='label'>{{$t("elements.buttons.copy")}}: {{selected.length}}</span>
				</div>	
				<div 
					class='iconTextButton neutral' 
					@click='convertToStack'
					:class='{ disabled: !(canMoveSelectionToStack && selected.length>=2) }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#stack' />
					</svg>
					<span class='label'>Convert to Stack: {{selected.length}}</span>
				</div>			
				<!--						
				<div 
					class='iconTextButton neutral' 
					@click='confirmDelete'
					:class='{ disabled: !canModifySelection }'										
				>
					<svg class='smallIcon'>
						<use xlink:href='#delete' />
					</svg>
					<span class='label'>{{$t("elements.buttons.delete")}}: {{selected.length}}</span>
				</div>	
			-->
		</ContextMenu>
</div>
</template>

<script>
import { debounce, uniq } from 'lodash'
import { onResizeMixin } from '@/components/common/mixins/onResize'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import Tabs from './Tabs'
import Elements from './Elements'
import ElementsHeader from './ElementsHeader'
import SelectionControls from './SelectionControls'
import ContextMenu from '@/components/common/ContextMenu'
import Loading from '@/components/common/Loading'
import Lasso from '@/components/common/Lasso'
import Scroll from '@/components/common/Scroll'
import SearchInput from '@/components/common/SearchInput'
import Pagination from '@/components/common/Pagination'
import CloseButton from '@/components/common/buttons/Close'
import Categories from '../categories/Categories'
import Folders from './Folders'

const getLimit = (width, height) => {
	const aspectRatio = 370 / 400
	const titleHeight = 50
	const iconBarHeight = 40
	const elGutter = 1
	const elMinWidth = 110 + elGutter
	const padding = 14
	const containerWidth = width + elGutter - padding
	const containerHeight = height
	const elsPerRow = Math.floor(containerWidth / elMinWidth)
	const elWidth = containerWidth / elsPerRow
	const elHeight = aspectRatio * elWidth + titleHeight + iconBarHeight
	const elsPerCol = Math.floor(containerHeight / elHeight)
	const limit = elsPerRow * (elsPerCol + 1) * 2
	return limit
}

export default {
	name: 'ExerciseBrowser',
	mixins: [onResizeMixin,smallModalMixin],
	components: {
		Tabs,
		Elements,
		ElementsHeader,
		Lasso,
		Loading,
		Scroll,
		ContextMenu,
		SearchInput,
		SelectionControls,
		Pagination,
		CloseButton,
		Categories,
		Folders
	},
	props: ['columns', 'showCategories', 'showFolders'],
	data: () => ({
		w: null,
		unsubscribeStore: false,
		menuTop: 0,
		showMenuTab: false,
		ignoreScrollEvents: false,
		scrollTop: 0,
		folderData: {},
		contextMenu: false
	}),
	computed: {
		showLeftPanel() {
			return (
				//(this.w === null || this.w > 600) &&
				(this.showCategories && this.$store.state.exerciseBrowser.tab==="stock") || 
				(this.showFolders && this.$store.state.exerciseBrowser.tab==="custom") 
			)
		},	
		err() {
			return this.$store.state.elements.apiError || this.$store.state.exerciseBrowser.apiError 
		},
		loading() {
			return this.$store.state.elements.apiPending || this.$store.state.exerciseBrowser.apiPending
		},
		bussing() {
			return (
				this.$store.state.bus.bussing &&
				['browserElements', 'setElements'].includes(this.$store.state.bus.contentType)
			)
		},
		selecting() {
			return this.$store.state.exerciseBrowser.selected.length
		},
		selected() { return this.$store.state.exerciseBrowser.selected },
		/*
		showCategories() {
			return (
				['custom','stock'].includes(this.$store.state.exerciseBrowser.tab) &&
				this.$store.state.categories.on
			)
		},
		*/
		/*
		showSelectionControls() {
			return (
				this.selecting 
			)
		},
		*/
		search: {
			get() {
				return this.$store.state.exerciseBrowser.search
			},
			set(value) {
				this.$store.dispatch('exerciseBrowser/setSearch', value)
			}
		},
		elementKeys() {
			return this.$store.state.exerciseBrowser.list
		},
		path() {
			if (this.$store.state.exerciseBrowser.tab==='stock' && this.$store.state.categories.selected && this.$store.state.exerciseBrowser.meta.id && this.$store.getters['categories/path']) {
				return this.$store.getters['categories/path']
			} else if (this.$store.state.exerciseBrowser.tab==='custom' && this.$store.state.exerciseBrowser.folderId && this.$store.state.exerciseBrowser.meta.folderId) {
				const folderId = this.$store.state.exerciseBrowser.meta.folderId
				/*
				const nodes = []
				const fn = (node) => {
					nodes.unshift(node.title)
					if (node.parentId) fn(this.folderData[node.parentId])
				}
				if (this.folderData[folderId]) fn(this.folderData[folderId])
				return "/&nbsp;&nbsp;" + (nodes.join("&nbsp;&nbsp;/&nbsp;&nbsp;"))		
			*/
				const path = this.getPath(folderId)
				return path.length ? path : null
				//console.log(path)
				//return path.length ? "/&nbsp;" + path.join("&nbsp;/&nbsp;")	: null
			} else {
				return null
			}
		},
		/*
		children() {
			const folderId = this.$store.state.exerciseBrowser.folderId
			if (folderId) {
				return Object.keys(this.folderData).filter(k=>this.folderData[k].parentId===folderId).map(k=>this.folderData[k])
			} else {
				return []
			}
		},
		*/
		canModifySelection() {
			return this.canMoveSelectionToFolder || this.canMoveSelectionToStack || this.canMoveSelectionOutOfStack
		},
		/* these should probably be named a bit different but i think they are in the right place here */
		canMoveSelectionToFolder() {
			const keys = this.$store.state.bus.bussing && this.$store.state.bus.contentType==='browserElements' ? this.$store.state.bus.bus : this.$store.state.exerciseBrowser.selected
			if (!keys.length) return false
			return keys.every(k => {
				if (!this.$store.state.elements.root[k]) return false
				const type = this.$store.state.elements.root[k].type
				const folderId = this.$store.state.elements.root[k].folderId
				if (type === 'stockExercise' || type === 'linkedExercise' || type === 'linkedStack') return false
				else if (
					(type === 'teamExercise' || type === 'teamStack') &&
					this.$store.state.profile.teams[this.folderData[folderId].teamId].teamExercises !== "readWrite"
				) return false
				else if (
					(type === 'orgExercise' || type === 'orgStack') &&
					this.$store.state.profile.user.orgExercises !== 'readWrite'
				) return false					
				else if (
					type === 'stackExercise'
				) {
					const stackKey = this.$store.state.elements.root[k].stackKey
					const stackType = this.$store.state.elements.root[stackKey].type
					const stackFolderId = this.$store.state.elements.root[stackKey].folderId
					if (stackType === 'teamStack' && this.$store.state.profile.teams[this.folderData[stackFolderId].teamId].teamExercises !== "readWrite") return false
					else if (stackType === 'orgStack' && this.$store.state.profile.user.orgExercises !== 'readWrite') return false
					else return true
				} else return true
			})
		},
		canMoveSelectionToStack() {
			if (
				this.$store.state.exerciseBrowser.tab!=='custom' || 
				(this.$store.state.bus.bussing && this.$store.state.bus.contentType!=='browserElements')
			) return false
			const keys = this.$store.state.bus.bussing ? this.$store.state.bus.bus : this.$store.state.exerciseBrowser.selected
			if (!keys.length) return false
			return keys.every(k => {
				if (!this.$store.state.elements.root[k]) return false
				const type = this.$store.state.elements.root[k].type
				const folderId = this.$store.state.elements.root[k].folderId
				if (type === 'stockExercise' || type === 'userStack' || type==='teamStack' || type === 'orgStack' || type === 'stackExercise' || type === 'linkedExercise' || type === 'linkedStack') return false
				else if (
					type === 'teamExercise' &&
					this.$store.state.profile.teams[this.folderData[folderId].teamId].teamExercises !== "readWrite"
				) return false
				else if (
					type === 'orgExercise' &&
					this.$store.state.profile.user.orgExercises !== 'readWrite'
				) return false					
				else return true
			})
		},
		canMoveSelectionOutOfStack() {
			if (
				this.$store.state.exerciseBrowser.tab!=='custom' || 
				(this.$store.state.bus.bussing && this.$store.state.bus.contentType!=='browserElements')
			) return false			
			const keys = this.$store.state.bus.bussing ? this.$store.state.bus.bus : this.$store.state.exerciseBrowser.selected
			if (!keys.length) return false		
			return keys.every(k => {
				const type = this.$store.state.elements.root[k].type	
				if (
					type === 'stackExercise'
				) {
					const stackKey = this.$store.state.elements.root[k].stackKey
					const stackType = this.$store.state.elements.root[stackKey].type
					const stackFolderId = this.$store.state.elements.root[stackKey].folderId
					if (stackType === 'linkedStack') return false 
					else if (stackType === 'teamStack' && this.$store.state.profile.teams[this.folderData[stackFolderId].teamId].teamExercises !== "readWrite") return false
					else if (stackType === 'orgStack' && this.$store.state.profile.user.orgExercises !== 'readWrite') return false
					else return true
				} else return false
			})				
		},
	},
	methods: {
		onScrollDiff({ diff, el }) {
			this.scrollTop = el.scrollTop			
			if (this.ignoreScrollEvents) return
			// return if bouncing
			if (
				el.scrollTop < 0 ||
				el.scrollTop > el.scrollHeight - el.offsetHeight
			) return // bounce
			// cap limits
			const h = this.$refs.menu.offsetHeight + 14
			if (this.menuTop > 0) return this.menuTop = 0
			else if (this.menuTop < -h) return this.menuTop = -h 
			// calc tab
			if (!this.showMenuTab && this.menuTop === -h) this.showMenuTab = true
			else if (this.showMenuTab && this.menuTop > -h) this.showMenuTab = false
			// calc pos
			if (diff < 0 && Math.abs(this.menuTop) < h) {
				this.menuTop+=diff
			} else if (diff > 0 && this.menuTop < 0) {
				this.menuTop = Math.min(0, this.menuTop + diff)
			}
		},
		onClickMenuTab() {
			this.showMenuTab=false
			this.menuTop = 0
			this.$refs.searchInput.focus()
		},
		onToggleDetail() {
			this.ignoreScrollEvents = true
			setTimeout(() => this.ignoreScrollEvents = false, 500)
			setTimeout(() => this.hideMenu(), 0)
		},
		hideMenu() {
			const h = this.$refs.menu.offsetHeight	
			this.menuTop = -h - 14
			this.showMenuTab = true		
		},	
		showMenu() {
			this.menuTop = 0
			this.showMenuTab = false	
			//if (this.$refs.scroll.$refs.scrollContent.scrollTop === 0) this.menuShadow = false
		},
		getPath(folderId) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift({ id: node.id, title: node.title })
				if (node.parentId) fn(this.folderData[node.parentId])
			}
			if (this.folderData[folderId]) fn(this.folderData[folderId])
			return nodes
		},
		onCopy() {
			this.copySetElements()
		},
		setLimit: debounce(function() {
			if (!this.active || !this.$refs.scroll) return
			const width = this.$refs.scroll.$el.clientWidth
			const height = this.$refs.scroll.$el.clientHeight
			this.$store.dispatch('exerciseBrowser/setLimit', getLimit(width, height))
		}, 500, { 'leading': false, 'trailing': true }),
		onResize() {
			this.setLimit()
			this.showMenu()
			//this.w = this.$el.offsetWidth
		},
		fetchTop() {
			this.$store.dispatch('exerciseBrowser/fetchTop')
					.then(() => this.showMenu())	
/*
			if (!this.$store.state.exerciseBrowser.search && this.$store.state.categories.selected) 
				this.$store.dispatch('categories/clearSearch')
					.then(() => this.$store.dispatch('exerciseBrowser/fetchTop'))
					.then(() => this.showMenu())
			else this.$store.dispatch('exerciseBrowser/fetchTop')
					.then(() => this.showMenu())	
*/			
		},
		fetchNext() {
			this.$store.dispatch('exerciseBrowser/fetchNext')
		},
		getSelected() {
			return this.$store.state.exerciseBrowser.selected
		},
		setSelected(keys) {
			this.$store.dispatch('exerciseBrowser/setSelectedMain', keys)
		},
		setSelecting(keys) {
			this.$store.dispatch('exerciseBrowser/setSelecting', keys)
		},
		focusSearchInput() {
			this.$refs.searchInput.focus()
		},
		refresh() {
			this.$store.dispatch('exerciseBrowser/reset').then(this.fetchTop)
		},
		moveElements({folderId, data}) {
			const movingOutOfStack = this.$store.state.elements.root[data[0]].type==='stackExercise'
			this.$store.dispatch('exerciseBrowser/moveElements', { folderId, ids: data.join(',') })
				.then(([err])=>{
					if (!err) {
						this.$store.dispatch('flash/showAction', { code: 'movedTo', vars: { folder: "/&nbsp;" + this.getPath(folderId).map(n=>n.title).join("&nbsp;/&nbsp;") }})
						if (movingOutOfStack) { // dragging from inside a strack directly to another folder
							//this.$store.dispatch('exerciseBrowser/fetchTop')
							//	.then(()=>{ this.$store.dispatch('exerciseBrowser/setSelectedMain', data)})
							const stackId = this.$store.state.elements.root[data[0]].stackKey
							this.$store.commit('elements/popStackExercisesList', { stackId, exerciseIds: data })
						} else if (this.$store.state.exerciseBrowser.folderId) {
							const path = this.getPath(folderId)
							/*
							if (path.some(n=>n.id===this.$store.state.exerciseBrowser.folderId)) { // a child folder
								this.$store.commit('elements/moveToFolder', { ids: data, folder: this.folderData[folderId] })
								this.$store.dispatch('exerciseBrowser/setSelectedMain', data)
							} else { // outside the scope of selected folder
								this.$store.dispatch('exerciseBrowser/removeElements', data)
							}
							*/
							this.$store.dispatch('exerciseBrowser/removeElements', data)
							this.$store.dispatch('exerciseBrowser/clearSelected')							
						} else {
							this.$store.commit('elements/moveToFolder', { ids: data, folder: this.folderData[folderId] })
							this.$store.dispatch('exerciseBrowser/setSelectedMain', data)
						}
					}
				})
		},
		moveElementsIntoStack(stack, ids = this.$store.state.bus.bus) {
			this.$store.dispatch('exerciseBrowser/moveElementsIntoStack', { stackId: stack.id, ids })
				.then(([err])=>{
					if (!err) {
						this.$store.dispatch('flash/showAction', { code: 'movedTo', vars: { folder: stack.title || "Untitled Stack" }})
						this.$store.dispatch('exerciseBrowser/removeElements', ids)
						this.$store.dispatch('exerciseBrowser/fetchByIds', stack.id)
							.then(()=>{ this.$store.dispatch('exerciseBrowser/setSelectedSub', ids)})
					}
				})
		},
		moveElementsOutOfStack(stack) {
			//this.moveElements({ folderId: stack.folderId, data: this.$store.state.bus.bus })
			//console.log('ahoy')
			const exerciseIds = [...this.$store.state.bus.bus]
			this.$store.dispatch('exerciseBrowser/moveElements', { folderId: stack.folderId, ids: exerciseIds })
				.then(([err])=>{
					this.$store.commit('elements/moveToFolder', { ids: exerciseIds, folder: this.folderData[stack.folderId] })
					this.$store.commit('elements/popStackExercisesList', { stackId: stack.id, exerciseIds })
					this.$store.commit('exerciseBrowser/prependList', exerciseIds)
					this.$store.commit('exerciseBrowser/incrementTotal', exerciseIds.length)
					this.$store.dispatch('exerciseBrowser/setSelectedMain', exerciseIds)	
					this.$store.dispatch('flash/showAction', { code: 'movedTo', vars: { folder: "/&nbsp;" + this.getPath(stack.folderId).map(n=>n.title).join("&nbsp;/&nbsp;") }})

				})
		},
		updateFolderData(v) {
			this.folderData = v 
			this.$store.commit('exerciseBrowser/setFolderData', v)
		},
		async copyElements(ids = this.selected) {
			if (
				!this.$store.getters['elements/complete'](ids) &&
				!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
			) return
			if (this.canMoveSelectionOutOfStack) {
				const { exercises } = this.$store.getters['elements/getElementsForCopy'](ids)
				this.$store.dispatch('exerciseBrowser/copyToStack', { key: this.$store.state.exerciseBrowser.detail, exercises })
			} else {
				const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy'](ids)		
				const keys = await this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks, folders: this.folderData })
				this.$refs.scroll.scrollToTop()
				this.showMenu()
				if (!this.$store.getters['exerciseBrowser/showCreate']) {
					if (this.$route.params.tab!=='custom') this.$router.replace({ params: { tab: 'custom' }})
					await this.$store.dispatch('exerciseBrowser/fetchWorkaround', { folderId: this.$store.state.exerciseBrowser.userRootId, tab: 'custom' })
				}						
				await this.$store.dispatch('exerciseBrowser/setSelectedMain', keys)				
			}
		},
		confirmDelete() {
			const ids = this.selected
			const fn = () => this.$store.dispatch('exerciseBrowser/destroy', ids)
			/*
			const items = ids.map(id => {
				let str = ''
				let x = this.$store.state.elements.root[id]
				if (x.type.includes('Exercise')) str+='Exercise: '
				else str+='Stack: '
				str+=x.title 
				str+=' ('+this.getPath(x.folderId).map(f=>f.title).join(' / ')+')'
				return str
			})
			*/
			const items = ids.map(id => this.$store.state.elements.root[id].title || 'Untitled '+(this.$store.state.elements.root[id].type.includes('Exercise') ? 'Exercise' : 'Stack') )
			const folderIds = uniq(ids.map(id => this.$store.state.elements.root[id].folderId).filter(v=>v))
			let p2 = ''
			if (folderIds.length === 1) {
				p2+='...from the folder:<br /><br /><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;'+this.getPath(folderIds[0]).map(f=>f.title).join('&nbsp;/&nbsp;') + '</b><br /><br />'
			} else if (folderIds.length) {
				p2+='...from mulitple folders.<br /><br />'
			}
			p2+='Are you sure?  <span>(This cannot be undone.)</span>'
			this.showSmallModal(ConfirmDeleteModal, { items, fn, p1: 'You are about to delete the following exercise(s)...', p2 })
		},
		async convertToStack() {
			const ids = [...this.selected]			
			const [stackId] = await this.$store.dispatch('exerciseBrowser/createStack')
			this.$store.dispatch('exerciseBrowser/moveElementsIntoStack', { stackId, ids })
				.then(([err])=>{
					if (!err) {
					//	this.$store.dispatch('flash/showAction', 'converted')
						this.$store.dispatch('exerciseBrowser/removeElements', ids)
						this.$store.dispatch('exerciseBrowser/fetchByIds', stackId)
						this.$refs.scroll.scrollToTop()
						this.showMenu()
					}
				})
		},
		copySetElements() {
			const { exercises } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
			exercises.forEach(x=>{
				delete x.customId
				delete x.stockId
				delete x.orig
			})
			this.$store.dispatch('exerciseBrowser/copy', { exercises })
		},
		showContextMenu(e) {
			if (this.$store.state.exerciseBrowser.tab!=='starred') this.contextMenu=e
		}
		/*
		warnUnsaved(fn) {
			this.warningUnsaved = true
			this.showSmallModal(ConfirmModal, {
				title: this.$t('views.resetPortal.title'),
				message: this.$t('views.resetPortal.p1'),
				ok: this.$t('elements.buttons.reset'),
				fn
			}, undefined, undefined, {
				'before-close': () => this.warningUnsaved = false
			})
		}
		*/
	},
	watch: {
		err(err) {
			if (err && err.code === 'ERRPERMISSION') this.refresh()
		},
		scrollTop(v) {
			if (v === 0) {
				this.showMenu()
			} 
			//if (!this.menuShadow && v > 54) this.menuShadow = true
			//else if (this.menuShadow && v <= 54) this.menuShadow = false
		},
		'$store.state.categories.selected': function(a,b) {
			if (this.$store.state.exerciseBrowser.tab==='stock') {
				this.$store.dispatch('exerciseBrowser/fetchTop')
					.then(() => this.showMenu())			
				const folderId = +this.$route.params.folderId || null
				if (folderId !== a) {
					this.$router.push({ params: { folderId: a, tab: 'stock' }})			
				}		
			}
		},
		'$store.state.exerciseBrowser.folderId': function(a,b) {
			if (this.$store.state.exerciseBrowser.tab==='custom') {
				// this is terrible, god help us
				if (!this.$store.state.exerciseBrowser.fetchWorkaround) this.$store.dispatch('exerciseBrowser/fetchTop')
					.then(() => this.showMenu())
				const folderId = +this.$route.params.folderId || null
				if (folderId !== a) {
					this.$router.push({ params: { folderId: a, tab: 'custom' }})		
				}
			}
		},
		'$route.params.folderId': function(a, b) {
			if (a!==b) {
				const id = +a || null
				if (this.$route.params.tab==='stock' && this.$store.state.categories.selected !== id) {
					this.$store.commit('categories/setSelected', id)
				} else if (this.$route.params.tab==='custom' && this.$store.state.exerciseBrowser.folderId !== id) {
					this.$store.commit('exerciseBrowser/setFolderId', id)
				}
			}
		},
		'$route.params.tab': function(a,b) {
			if (a!==b) {
				let folderId
				if (a === 'stock') folderId = this.$store.state.categories.selected 
				else if (a === 'custom') folderId = this.$store.state.exerciseBrowser.folderId 
				else folderId = null
				if (this.$route.params.tab !== a || (+this.$route.params.folderId || null) !== folderId) {
					this.$nextTick(()=>this.$router.push({ params: { tab: a, folderId } }))
				}
				this.$store.dispatch('exerciseBrowser/setTab', a)
					.then(() => {
						this.showMenu()
					})				
			}
		},
		'$store.state.exerciseBrowser.fetchWorkaround': function(a,b) {
			if (!a && b) this.scrollTop = 0
		},
		showLeftPanel(v) {
			if (!v) {
				if (this.$store.state.exerciseBrowser.tab==="stock") {
					//this.$store.dispatch('categories/setSelected', null)
				} else if (this.$store.state.exerciseBrowser.tab==="custom") {
					//console.log('todo')
				}
			}
		}
/*
			} else if (
				[
					'categories/setSelected'
				].includes(type) &&
				['all','stock'].includes(store.state.exerciseBrowser.tab)
			){
				store.dispatch('exerciseBrowser/fetchTop')

*/
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$store.dispatch('exerciseBrowser/setTab', to.params.tab)
				.then(() => {
					const folderId = +to.params.folderId || null			
					if (folderId && to.params.tab==='stock') {
						vm.$store.commit('categories/setSelected', folderId)
						vm.$store.commit('exerciseBrowser/setFolderId', null)							
					} else if (folderId && to.params.tab==='custom') {
						vm.$store.commit('exerciseBrowser/setFolderId', folderId)	
						vm.$store.commit('categories/setSelected', null)							
					} else if (to.params.tab === 'stock' && vm.$store.state.categories.selected) {
						vm.$router.replace({ params: { folderId: vm.$store.state.categories.selected }})	
					} else if (to.params.tab === 'custom' && vm.$store.state.exerciseBrowser.folderId) {
						vm.$router.replace({ params: { folderId: vm.$store.state.exerciseBrowser.folderId }})	
					}			
					next()
				})
				.catch(() => next({ name: 'getLanding', replace: true }))
		})
	},
	/*
	beforeRouteUpdate(to, from, next) {
		const folderId = +to.params.folderId || null
		this.$store.dispatch('exerciseBrowser/setTab', { tab: to.params.tab, folderId  })
			.then(() => {
				this.showMenu()
				next()
			})
			.catch(() => next(false))
	},
	*/
	mounted() {
		this.active = true
		this.unsubscribeStore = this.$store.subscribe(({ type }) => {
			if (type === 'exerciseBrowser/resetList') this.$refs.scroll.scrollToTop()
		//	if (type === 'exerciseBrowser/resetList') setTimeout(() => this.$refs.elementsWrapper.update(), 0)
			// RECALC STICKIES elementsWrapper
		})
		this.$nextTick().then(() => {
			this.onResize()
			if (!this.$store.state.exerciseBrowser.loaded) this.fetchTop()
		})
	},
	beforeDestroy() {
		this.active = false
		this.unsubscribeStore()
	}
}
</script>

<style lang='scss'>
.exerciseBrowser {
	@include fill;
	@include panel;
	overflow: visible;
	position: relative;
	background: none;

	> div { 
		container-type: inline-size;
		container-name: exerciseBrowser;
		display: grid;
		@include fill;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;

		.scrollMenu {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 100;
			display: flex;

			.searchWrapper { 
				flex: 1;	
				> div { margin: 0 $size-gutter * 2; background: $color-neutral-silver; padding-top: $size-gutter * 2;}
				> div > div {		
					position: relative;
					border: 1px solid $color-neutral-shadow;
					transition: box-shadow .5s;
					&:empty { display: none; }
					&:first-child { }
				}
			}
	/*
			&.menuShadow > div {
				box-shadow: 0 3px 5px rgba(0,0,0,0.2);			
			}
	*/		
			.searchInput { flex: 1; }

			.categoriesButton {
				border-right: 1px solid $color-neutral-shadow;
			}
			
			.search {
				display: flex; 
			}
		}

		.mainWrapper {

			display: flex;
			height: 100%;

			.categoriesWrapper {
				flex: 1.25;
				max-width: 250px;
				min-width: 220px;
				height: 100%;
				position: relative;
				padding-left: $size-gutter * 2;
				background: linear-gradient(to top, $color-neutral-panel, $color-neutral-silver);
				margin-right: 1px; 
				.scrollContainer { width: calc(100% - 14px); }
				.scrollContent { min-height: 100%; }

				/*
				&:not([style*="display: none"])+.elementsWrapper { margin-left: 0; }
				*/
				>.closeButton {
					position: absolute;
					top: 15px;
					right: 0;
					z-index: 20;
					opacity: 0;
					transition: opacity 200ms linear;
				}
				.blockButton {
					position: absolute;
					top: 15px;
					right: 0;
					z-index: 20;		
					opacity: 0;
					transition: opacity 200ms linear, transform 200ms linear;
					&:hover { transform: scale(1.4); color: $color-primary-accent; }								
				}
				&:hover .blockButton { opacity: 1; }
				.scrollContent{ border-bottom: 1px solid transparent; } /* weird css bug with scroll */
				.dragging { transform: translateX(-40px); } /* i have no idea */
			}


			.elementsWrapper {
				flex: 3;
				position: relative;
				padding-left: $size-gutter * 2;
				padding-right: $size-gutter * 2;
				.scrollContainer { width: calc(100% - 14px); }
				background: linear-gradient(to top, $color-neutral-panel, $color-neutral-silver);

				/*
				&:only-child {
					margin: 0;
					.exerciseBrowserElements {
						margin-left: 0; margin-right: 0;
						.exerciseBrowserElementDetail { 
							padding-left: $size-gutter * 4;
							padding-right: $size-gutter * 4;
							margin-bottom: $size-gutter * 2;
						}
					}
				}
				*/
			}

		}



		.menuTrigger {
			position: absolute;
			top: 0;
			z-index: 200;
			right: $size-gutter * 2;
			border-bottom-left-radius: 7px;
			border: 1px solid $color-neutral-shadow;
			border-top: none;
			box-shadow: 0 4px 6px  rgba(0,0,0,.08);
		}	

		>.tabsPanel {
			display: flex;
			box-shadow: 1px 0 0 0 $color-neutral-shadow;
			> div:first-child { flex: 1; }
		}


		>.elementsPanel {
			position: relative;
			overflow: hidden;
		}

		.elementsFooter {
			display: flex;
			align-items: center;
			justify-content: center;
			height: $size-control-height * 2;
			margin-bottom: 120px;
		}

		.loading {
			background: rgba($color-neutral-panel, 0.3);
		}
	}
}
/*
@container exerciseBrowser (max-width: 725px) {
	.exerciseBrowser { 

		.categoriesWrapper { padding-left: 0 !important; }
		.mainWrapper {padding-left: 0; }

		.categoriesTree {
			padding-right: 0; 
			.header { border: none; border-top: 1px solid transparent; background: none; }
			.tree { padding-right: 0; margin-left: 0; }
		}
		.root { border-color: transparent !important; background: none !important; }

	}
}
*/
</style>