//import { throttle } from 'lodash'
import ResizeObserver from 'resize-observer-polyfill'

export const onResizeMixin = {
	data: () => ({
		resizeObserver: null
	}),
	methods: {
		createObserver() {
			this.resizeObserver = new ResizeObserver(this.onResize)
			this.resizeObserver.observe(this.$el)
		},
		destroyObserver() {
			if (this.resizeObserver) {
				this.resizeObserver.unobserve(this.$el)
				this.resizeObserver.disconnect()
				this.resizeObserver = null
			}			
		}
	},
	mounted: function () {
	//	this.onResize = throttle(this.onResize, 200)
		this.createObserver()
	},
	beforeDestroy() {
		this.destroyObserver()
	}
}