<template>
<div class='setControls'>
<!--
		<SaveButton @click='save' :disabled='set.id && !fresh' />
-->
		<div v-if='saved' class='iconTextButton neutral green saved'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t("elements.labels.saved")}}</span>
		</div>

		<div
			v-else 
			class='iconTextButton neutral' 
			v-tap='save'
		>
			<svg class='smallIcon'>
				<use xlink:href='#save' />
			</svg>
			<span class='label'>{{$t("elements.buttons.save")}}</span>
		</div>	

		<EnabledButton @click='saveAs' :disabled='!set.id' :svg='"#save"' :label='$t("elements.buttons.saveAs")+"..."' class='neutral' />

		<div v-if='sent' class='iconTextButton neutral green' v-tap='email'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t("elements.labels.sent")}}</span>
		</div>

		<div
			v-else 
			class='iconTextButton neutral'
			:class='{ disabled: !$store.state.profile.user.allowPHI }' 
			v-tap='email'
		>
			<svg class='smallIcon'>
				<use xlink:href='#email' />
			</svg>
			<span class='label'>{{$t("elements.buttons.send")}}</span>
		</div>

		<div 
			class='iconTextButton neutral' 
			v-tap='printPdf'
		>
			<svg class='smallIcon'>
				<use xlink:href='#print' />
			</svg>
			<span class='label'>{{$t("elements.buttons.pdf")}}</span>
		</div>	


		<template v-if='synced && $store.state.profile.emr && $store.state.profile.emr.enabled'>
			<div class='iconTextButton neutral green syncEmr'>
				<svg class='smallIcon'>
					<use xlink:href='#check' />
				</svg>
				<span class='label'>Added to {{$store.state.profile.emr.title}}</span>
			</div>
			<CopyToClipboard ref='copyToClipboard' :setKey='setKey' v-show='false' />
		</template>

		<template v-else-if='$store.state.profile.emr && $store.state.profile.emr.enabled && $store.state.profile.emr.title !== "juvonno" && $store.state.sets.root[this.setKey].type === "clientSet" && $store.state.clients[$store.state.sets.root[this.setKey].clientId].emr'>
			<EnabledButton 
				@click='sync' 
				:disabled='!saved' 
				:svg='"#clipboard"' 
				:label='`Add To ${$store.state.profile.emr.title}`' 
				class='neutral syncEmr' 
				:class='{ alertSync: set.alertSync }'
			/>
			<CopyToClipboard ref='copyToClipboard' :setKey='setKey' v-show='false' />			
		</template>

		<CopyToClipboard v-else ref='copyToClipboard' :setKey='setKey'>{{$t("elements.buttons.copyToClipboard")}}</CopyToClipboard>

		<div 
			class='iconTextButton neutral' 
			v-tap='duplicate'
		>
			<svg class='smallIcon'>
				<use xlink:href='#copy' />
			</svg>
			<span class='label'>{{$t("elements.buttons.copy")}}</span>
		</div>	
<!--
		<HistoryButton @click='history' />	
-->
		<div 
			class='iconTextButton neutral' 
			v-tap='education'			
		>
			<svg class='miniIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("elements.buttons.education")}}</span>
		</div>

		<div 
			class='iconTextButton neutral addExerciseButton' 
			v-tap='createExercise'
		>
			<svg class='miniIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("elements.buttons.exercise")}}</span>
		</div>

		<div 
			class='iconTextButton neutral addDividerButton' 
			v-tap='createDivider'
		>
			<svg class='miniIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("elements.buttons.divider")}}</span>
		</div>

		<div 
			class='iconTextButton neutral' 
			v-tap='addCover'
		>
			<svg class='miniIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("elements.buttons.coversheet")}}</span>
		</div>		


</div>
</template>

<script>
import { throttle } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { largeModalMixin } from '@/components/common/mixins/modal'
import { fullModalMixin } from '@/components/common/mixins/modal'

import AlertModal from '@/components/common/modals/Alert'
// import SaveButton from '@/components/common/buttons/Save'
import EnabledButton from '@/components/common/buttons/Enabled'

//import HistoryButton from '@/components/common/buttons/History'
import Client from '@/components/clinician/clients/modals/Client'
import SaveNewSet from './modals/SaveNewSet'
import SignClientSet from './modals/SignClientSet'
import EmailSet from './modals/emailSet/EmailSet'
import SaveAndEmailSet from './modals/saveAndEmailSet/Save'
import SetHistory from './modals/setHistory/SetHistory'
import SetPdf from './modals/setPdf/SetPdf'
import AttachEducation from './modals/education/Attach'
import CopyToClipboard from './CopyToClipboard'
import ClientModal from '@/components/clinician/clients/modals/Client'



export default {
	name: 'SetControls',
	mixins: [smallModalMixin, largeModalMixin, fullModalMixin],
	components: { /* SaveButton, HistoryButton,*/ CopyToClipboard, EnabledButton },
	props: ['setKey'],
	data() {
		return { menuOpen: false }
	},
	computed: {
		set() {
			return this.$store.state.sets.root[this.setKey]
		},
		saved() {
			return this.set.id && !this.fresh
		},
		fresh() {
			return !!this.$store.state.sets.root[this.setKey].fresh && this.$store.state.sets.root[this.setKey].type !== 'draftSet' && this.$store.state.sets.root[this.setKey].type//|| this.$store.state.sets.root[this.setKey].type === 'folderSet'
		},
		sent() {
			return !!this.$store.state.sets.root[this.setKey].sent
		},
		synced() {
			return !!this.$store.state.sets.root[this.setKey].synced
		},		
		canUndo() {
			return this.$store.state.sets.undos[this.setKey] && this.$store.state.sets.undos[this.setKey].length
		},
		canRedo() {
			return this.$store.state.sets.redos[this.setKey] && this.$store.state.sets.redos[this.setKey].length
		},
		undoCount() {
			return this.$store.state.sets.undos[this.setKey] ? this.$store.state.sets.undos[this.setKey].length : 0
		},
		redoCount() {
			return this.$store.state.sets.redos[this.setKey] ? this.$store.state.sets.redos[this.setKey].length : 0          
		},
		client() {
			const clientKey = this.$store.state.sets.root[this.setKey].clientId
			return clientKey ? this.$store.state.clients[clientKey] : false
		}		
	},
	methods: {
		onKeyDown(e) {
			const modals = () => !!document.getElementById('modals-container').childNodes.length
			if (this.$store.state.setBuilder.focus !== this.setKey) return
			if (e.keyCode == 90 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				e.preventDefault()
				e.stopImmediatePropagation()
				this.undo()
			} else if (e.keyCode == 89 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				e.preventDefault()
				e.stopImmediatePropagation()
				this.redo()
			} else if (e.keyCode == 80 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				e.preventDefault()
				e.stopImmediatePropagation()
				if (!modals()) this.printPdf()
			} else if (e.keyCode == 83 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				//console.log()
				e.preventDefault()
				e.stopImmediatePropagation()
				if (!modals()) this.save()
			} else if (e.keyCode == 69 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				//console.log()
				e.preventDefault()
				e.stopImmediatePropagation()
				if (!modals()) this.email()
			} else if (e.keyCode == 67 && (e.ctrlKey || e.metaKey) && e.target.classList.contains('simplebar-content-wrapper')) {
				e.preventDefault()
				e.stopImmediatePropagation()
				this.$refs.copyToClipboard.copyToClipboard()
			}
		},
		/*
		closeMenu() {
			this.menuOpen = false
			document.removeEventListener('pointerdown', this.closeMenu)		
		},
		deferCloseMenu() {
			this.timer = setTimeout(() => this.closeMenu(), 500)
		},
		cancelDeferCloseMenu() {
			clearTimeout(this.timer)
		},
		openMenu() {
			this.menuOpen = true
			document.addEventListener('pointerdown', this.closeMenu)
		},
		*/
		save: throttle(function() {
			if (!this.set.id) {
				this.showSmallModal(SaveNewSet, { setKey: this.setKey }) 
			} else if (this.$store.state.sets.root[this.setKey].type === 'folderSet') {
				if (this.$store.state.sets.root[this.setKey].elements.length > 100) {
					this.showSmallModal(AlertModal, {
						title: this.$t('views.warnCannotSaveToFolder.title'),
						message: this.$t('views.warnCannotSaveToFolder.p1')
					})
				} else {
					this.$store.dispatch('sets/saveOldSet', this.setKey)
				}
			} else if (this.$store.state.sets.root[this.setKey].type === 'clientSet') {
				if (this.$store.state.sets.root[this.setKey].elements.length > 50) {
					this.showSmallModal(AlertModal, {
						title: this.$t('views.warnCannotSendToClient.title'),
						message: this.$t('views.warnCannotSendToClient.p1')
					})
				} else {
					const fn = () => this.$store.dispatch('sets/saveOldSet', this.setKey)
					this.showSmallModal(SignClientSet, { fn, client: this.$store.state.clients[this.set.clientId], clientSet: this.set })
				}
			}
		}, 200, { leading: true, trailing: false }),

		saveAs: throttle(function() {
			this.showSmallModal(SaveNewSet, { setKey: this.setKey }) 
		}, 200, { leading: true, trailing: false }),

		sync: throttle(function() {
			if (!this.$store.state.profile.emr || !this.$store.state.profile.emr.enabled) {
				this.$store.dispatch('flash/showAlert', 'badIntegration')
			} else {
				this.$store.dispatch('sets/copyToEmr', this.setKey)
					.then(([err]) => {
						if (!err) {
							this.$store.commit('sets/setSynced', { key: this.setKey, value: true }) 
							this.$store.commit('sets/setAlertSync', { key: this.setKey, value: false })	
							this.$store.dispatch('flash/showAction', 'copied')
						} else {
							this.$store.dispatch('flash/showAlert', 'badIntegration')
						}
					})
			}
		}, 200, { leading: true, trailing: false }),

		email: throttle(function() {
			const cb = () => this.$store.commit('sets/setSent', { key: this.setKey, value: true }) //this.sent = true
			if (this.$store.state.sets.root[this.setKey].elements.length > 50) {
				this.showSmallModal(AlertModal, {
					title: this.$t('views.warnCannotSendToClient.title'),
					message: this.$t('views.warnCannotSendToClient.p1')
				})
			} else if (
				this.set.type === 'clientSet' && 
				(
					!this.$store.state.clients[this.set.clientId].email || 
					!this.$store.state.clients[this.set.clientId].portalKey ||
					this.$store.state.clients[this.set.clientId].emailErr 
				)
			) {
				// no email
				this.showSmallModal(Client, {
					client: this.$store.state.clients[this.set.clientId], 
					emailRequired: true,
					onSave: this.email
				})
			} else if (this.set.type === 'clientSet' && this.fresh) {
				// save existing clientset and then email
				/*
				const fn = () => this.$store.dispatch('sets/saveOldSet', this.setKey)
					.then(() => this.showSmallModal(EmailSet, { setKey: this.setKey }) )
				this.showSmallModal(SignClientSet, { fn, client: this.$store.state.clients[this.set.clientId] })
				*/
				this.showSmallModal(EmailSet, { setKey: this.setKey, saveFirst: true, cb }) 
			} else if (this.set.type === 'clientSet') {
				// no need to save, just email
				this.showSmallModal(EmailSet, { setKey: this.setKey, cb }) 
			} else {
				// new set, save to client and then email
				//const onSave = () => this.showSmallModal(EmailSet, { setKey: this.setKey }) 
				//this.showLargeModal(SaveAndEmailSet, { setKey: this.setKey, emailRequired: true, cb }) 
				this.$store.state.main.mobile ? this.showLargeModal(SaveAndEmailSet, { setKey: this.setKey, emailRequired: true, cb }) : this.showMediumModal(SaveAndEmailSet, { setKey: this.setKey, emailRequired: true, cb }) 

			}
		}, 200, { leading: true, trailing: false }),
		printPdf: throttle(function() {
			if (this.$store.state.sets.root[this.setKey].elements.length > 50) {
				this.showSmallModal(AlertModal, {
					title: this.$t('views.warnCannotExport.title'),
					message: this.$t('views.warnCannotExport.p1')
				})
			} else {
				this.$store.state.main.mobile ? this.showLargeModal(SetPdf, { setKey: this.setKey }) : this.showMediumModal(SetPdf, { setKey: this.setKey }) 

				//this.showLargeModal(SetPdf, { setKey: this.setKey }) 
			}
		}, 200, { leading: true, trailing: false }),		
		education: throttle(function() {
			const max = this.$store.state.sets.root[this.setKey].education ? 10 - this.$store.state.sets.root[this.setKey].education.length : 10
			this.$store.state.main.mobile ? this.showLargeModal(AttachEducation, { setKey: this.setKey, max }) : this.showMediumModal(AttachEducation, { setKey: this.setKey, max }) 
		}, 200, { leading: true, trailing: false }),
		history() {
			this.showLargeModal(SetHistory, { setKey: this.setKey }) 
		},
		duplicate() {
			this.$store.dispatch('setBuilder/duplicateSet', this.setKey)
				.then(() => {
					this.$store.dispatch('flash/showAction', 'duplicated')
					this.$emit('duplicated')
				})
		},
		addCover() {
			this.$emit('addCover')
		},
		createExercise() {
			this.$store.dispatch('sets/createExercise', this.setKey)
				.then(([exerciseKey]) => this.$store.dispatch('sets/toggleDetail', exerciseKey))
				.then(() => this.$emit('scrollToBottom', '.setExerciseDetail'))
		},
		createDivider() {
			this.$store.dispatch('sets/createDivider', this.setKey)
				.then(([dividerKey]) => this.$store.dispatch('sets/toggleDetail', dividerKey))			
				.then(() => this.$emit('scrollToBottom', '.setDividerDetail'))
		},
		undo() {
			this.$store.dispatch('setBuilder/clearSelected')
			this.$store.dispatch('sets/undo', this.setKey)
		},
		redo() {
			this.$store.dispatch('setBuilder/clearSelected')
			this.$store.dispatch('sets/redo', this.setKey)
		},
		editClient() {
			this.showSmallModal(ClientModal, { client: this.client })
		}		
	},
	mounted() {
		window.addEventListener('keydown', this.onKeyDown)
		console.log(this.$store.state.sets.root[this.setKey])
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onKeyDown)
	},
	watch: {
		saved(a, b) {
			if (b && !a)  {
				this.$store.commit('sets/setSent', { key: this.setKey, value: false }) // this.sent = false
			}
			if (!a) {
				this.$store.commit('sets/setSynced', { key: this.setKey, value: false }) // this.sent = false	
				this.$store.commit('sets/setAlertSync', { key: this.setKey, value: true })			
			}
		}
	}
}
</script>

<style lang='scss'>
.setControls {

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	border: 1px solid transparent;

	>div.iconTextButton {
		position: relative;
		grid-column: span 4;
		height: $size-control-height;
		background: $color-neutral-silver;
		&.disabled { background: $color-neutral-silver !important; }
	}

	>div {
		&:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
			grid-column: span 3;
			.label { font-size: $size-font-small; font-weight: bold; }
			svg { margin-right: $size-gutter; }
		} 
	}

/*	
	.addExerciseButton, .addDividerButton {
		display: none;
	}

	.addExerciseDividerButton { cursor: auto; span { display: flex; align-items: center; height: $size-control-height; cursor: pointer; }}
*/
	.green {
		svg {
			color: $color-primary-accent;
		}
		color: $color-primary-accent;
	}
	.saved { pointer-events: none; }
	.disabled {
		pointer-events: none;
	}

	.syncEmr {
		.label { 
			text-transform: capitalize; 
		}
		&.alertSync {
			color: $color-alert;
		}
	}
}

.wideMenu .setControls {
	grid-template-columns: repeat(5, 1fr);
/*
	.addExerciseButton, .addDividerButton {
		display: flex;
	}
	.addExerciseDividerButton {
		display: none;
	}
*/
	>div {
		grid-column: span 1;	
	}
}
</style>