var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({directives:[{name:"touchHold",rawName:"v-touchHold",value:(_vm.onPull),expression:"onPull"},{name:"tap",rawName:"v-tap",value:(_vm.onClick),expression:"onClick"}],staticClass:"exerciseBrowserElement exerciseBrowserExercise ctrlLasso",class:{ 
		detail: _vm.detail, 
		selected: _vm.selected, 
		selecting: _vm.selecting, 
		mainElement: _vm.mainElement, 
		subElement: !_vm.mainElement,
		bussing: _vm.$store.state.bus.bussing
	}},!_vm.$store.state.main.mobile ? { mousedown: _vm.onMouseDown } : {}),[_c('div',{staticClass:"icons"},[(_vm.showSortable)?_c('SortButton',{attrs:{"axes":"xy"}}):_vm._e(),_c(_vm.iconComponent,{tag:"component",staticClass:"elementType",attrs:{"elementKey":_vm.element.key}}),(_vm.showFavourite)?_c('Favourite',{attrs:{"elementKey":_vm.element.key},nativeOn:{"mousedown":function($event){$event.stopPropagation();}}}):_vm._e()],1),_c('div',{staticClass:"aspectRatio",on:{"mouseenter":_vm.startSlideShow,"mouseleave":_vm.stopSlideShow,"mousedown":_vm.stopSlideShow}},[_c('ExerciseThumbnail',{attrs:{"elementKey":_vm.element.key}}),(!_vm.$store.state.main.mobile && _vm.showSlideShow)?_c('SlideShow',{attrs:{"exerciseKey":_vm.element.key}}):_vm._e()],1),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.element.title))]),(!_vm.$store.state.main.mobile)?_c('svg',{staticClass:"smallIcon",on:{"click":function($event){$event.stopPropagation();return _vm.addElementToSet.apply(null, arguments)}}},[_c('use',{attrs:{"xlink:href":"#plus"}})]):_vm._e()]),(_vm.loading)?_c('Loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }