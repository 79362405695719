<template>
	<div class='exerciseThumbnail' :class='{ flipImages: exercise.reflect }'>
		<LazyImage v-if='thumbnailUrl' :src='thumbnailUrl' :contain='!exercise.thumbnailId' />
	</div>
</template>

<script>
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'ExerciseThumbnail',
	props: ['elementKey'],
	components: {
		LazyImage
	},
	computed: {
		exercise() {
			return this.$store.state.elements.root[this.elementKey]
		},
		thumbnailUrl() {
			return this.$store.getters['elements/getThumbnailUrl'](this.exercise.key)
		}
	}
}
</script>

<style lang='scss'>
.exerciseThumbnail {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 7px;
	background: $color-white;
}
</style>


