<template>
<div class='clientPanel'>

	<div class='headerPanel'>
		<ClientInfo :client='client' />
	</div>

	<div class='contentPanel'>
		<ScrollForever
			:itemHeight='41'
			:itemKeys='clientSetKeys'
			:itemComponent='itemComponent'
			:fetchNext='fetchNext'
			:loading='loading'
		>
			<template slot='header'>
				<div class='emailPanel formContent'>
					<div class='field'>
						<div class='label'>{{$t("elements.labels.subject")}}:</div>
						<TextInput 
							ref='emailSubject'
							v-model='subject' 
							maxlength='100' 
							:placeholder='$t("views.emailSet.phEmailSubject")' 
						/>
					</div>
					<div class='field'>
						<div class='label'>
							{{$t("elements.labels.cc")}}:
							<svg class='detail' @click='detailCC'>
								<use xlink:href='#detail' />
							</svg>
						</div>
						<EmailsInput 
							v-model='cc' 
							:placeholder='$t("views.emailSet.phEmailCC")' 
							:maxlength='500'
							:maxtags='5'
						/>			
					</div>
					<div class='field'>
						<div class='label'>{{$t("elements.labels.message")}}:</div>
						<RichText 
							v-model='body' 
							:placeholder='$t("views.emailSet.phEmailBody")' 
							:maxlength='1000'
						/>
					</div>
					<div class='setLink'>{{$t("views.emailSet.link")}}</div>
					<div class='field'>
						<div class='label'>{{$t("elements.labels.signature")}}:</div>
						<RichText 
							v-model='signature' 
							:placeholder='$t("views.emailSet.phEmailSignature")' 
							:maxlength='500'
						/>
					</div>	
				</div>				
				<ResultInfo v-if='totalSets' :total='totalSets' :foundPhrase='$t("elements.results.clientsSets")' />
			</template>
			<template slot='sticky'>
				<ClientSetsHeader v-if='totalSets' module='saveClientSets' />
			</template>
		</ScrollForever>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</div>

	<div class='formContent saveAs'>
		<div class='field'>
			<div class='label'>
				{{$t("elements.buttons.saveAs")}}:
				<svg class='detail' @click='detailSaveAs'>
					<use xlink:href='#detail' />
				</svg>
			</div>
			<TextInput 
				ref='saveAsInput'
				v-model='savenameData' 
				:placeholder='$t("elements.labels.untitled")' 
				:maxlength='100'
			/>
		</div>
	</div>
	<div class='signMessage' v-html='signMessage'></div>	

	<div class='footerPanel modalControls'>
		<CancelButton @click='$emit("close")' />
		<div class='saveButton iconTextButton success' @click='save'>
			<svg class='smallIcon'>
				<use xlink:href='#email' />
			</svg>
			<span class='label' v-html='$t("elements.buttons.signsend") + " " + (savenameData || $t("elements.labels.untitled"))' />
		</div>
	</div>

</div>
</template>

<script>
import { fill } from '@/utilities'
import { defaultModalAction } from '@/components/common/mixins/modal'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import EmailsInput from '@/components/common/EmailsInput'
import AlertModal from '@/components/common/modals/Alert'
import RichText from '@/components/common/RichText'


import ResultInfo from '@/components/common/ResultInfo'
import ScrollForever from '@/components/common/ScrollForever'
import CancelButton from '@/components/common/buttons/Cancel'
//import SaveAsInput from '@/components/common/SaveAsInput'
import ClientInfo from '@/components/clinician/clients/ClientInfo'
import ClientSetsHeader from './ClientSetsHeader'
import ClientSetRow from './ClientSetRow'

export default {
	name: 'clientPanel',
	mixins: [defaultModalAction, smallModalMixin, dateTime],
	components: {
		Loading,
		ResultInfo,
		ScrollForever,
		ClientSetsHeader,
		ClientInfo,
		CancelButton,
		TextInput,
		EmailsInput,
		RichText
	},
	props: ['savename'],
	data() {
		return {
			itemComponent: ClientSetRow,
			savenameData: this.savename || this.date(new Date()),

			subject: '', 
			cc: this.$store.state.profile.user.clientEmailCC,
			body: this.$store.state.profile.user.clientEmailBody,
			signature: this.$store.state.profile.user.clientEmailSig,
		}
	},
	computed: {
		loading() { return this.$store.state.saveClientSets.apiPending },
		clientSetKeys() { return this.$store.state.saveClientSets.list },
		totalSets() { return this.$store.state.saveClientSets.meta.total },
		client() { return this.$store.getters['saveClients/detail'] },
		user() { return this.$store.state.profile.user },		
		signMessage() {
			return fill(this.$t('views.updateClientSet.p1'), {
				user: this.user.firstname +' '+ this.user.lastname,
				client: this.client.firstname +' '+ this.client.lastname   
			})
		}
	},
	methods: {
	//	doDefault() { this.save() },
		fetchTop() { this.$store.dispatch('saveClientSets/fetchTop') },
		fetchNext() { this.$store.dispatch('saveClientSets/fetchNext') },
		save() { 
			this.$emit('save', {
				savename: this.savenameData,
				email: {
					subject: this.subject || undefined,
					cc: this.cc.length ? this.cc : undefined,
					body: this.body,
					signature: this.signature
				}
			})
		},
		detailCC() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.detailCCReceipt.title'),
				message: this.$t('views.detailCCReceipt.p1')
			})
		},		
		detailSaveAs() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.detailSaveAs.title'),
				message: this.$t('views.detailSaveAs.p1')
			})
		}		
	},
	watch: {
		client(newClient, oldClient) {
			if (newClient.key && newClient.key !== oldClient.key) {
				this.$store.dispatch('saveClientSets/setClient', newClient.key)
				this.$refs.emailSubject.focus()
			}
		}
	},
	mounted() {
		this.$store.dispatch('saveClientSets/setClient', this.client.key)
	}
}
</script>

<style lang='scss'>
.saveAndEmailClientSet .clientPanel {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.headerPanel {
		padding-bottom: 0;
	}

	.clientInfo {
		padding-left: $size-gutter * 4;
		padding-right: $size-gutter * 2;		
		header {  height: 42px; margin-top: $size-gutter * 2; padding: 0; }
		height: 156px;
	}

	.contentPanel {
		position: relative;

		.emailPanel { 
			margin-left: $size-gutter * 2;
			padding-left: $size-gutter * 2;
			margin-right: $size-gutter * 2;
			padding-bottom: $size-gutter * 4; 
			border-bottom: 1px dashed $color-neutral-shadow;
		}

		.setLink {
			height: $size-control-height;
			margin-left: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $size-font-standard;
		}

		.resultInfo {
			margin-top: $size-gutter * 2;
		}
	}

	.signMessage {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		margin: 0 $size-gutter * 4;
		margin-top: $size-gutter * 2;
		margin-bottom: $size-gutter * 2; 
	}

	.saveAs {
		margin-left: $size-gutter * 4;
		margin-right: $size-gutter * 2;
		margin-top: $size-gutter * 2;
	}

	.footerPanel {
		margin-top: $size-gutter * 2;
	}
}
</style>
