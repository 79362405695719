<template>
<div 
	:class='{ 
		detail, 
		selected, 
		selecting, 
		mainElement, 
		subElement: !mainElement,
		bussing: $store.state.bus.bussing
	}' 
	v-touchHold='onPull'
	v-tap='onClick'		
	v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'		
	class='exerciseBrowserElement exerciseBrowserExercise ctrlLasso' 
>
	<div class='icons'>
		<SortButton v-if='showSortable' axes='xy' />
		<component :is='iconComponent' :elementKey='element.key' class='elementType'></component>
		<Favourite v-if='showFavourite' :elementKey='element.key' @mousedown.native.stop />
	</div>
	<div 
		@mouseenter='startSlideShow'
		@mouseleave='stopSlideShow'
		@mousedown='stopSlideShow'
		class='aspectRatio'
	>
		<ExerciseThumbnail :elementKey='element.key' />
		<SlideShow v-if='!$store.state.main.mobile && showSlideShow' :exerciseKey='element.key' />
	</div>
	<div class='title'>
		<span>{{element.title}}</span>
		<svg v-if='!$store.state.main.mobile' class='smallIcon' @click.stop='addElementToSet'>
			<use xlink:href='#plus' />
		</svg>
	</div>
	<Loading v-if='loading' />
</div>
</template>

<script>
import ElementBase from './ElementBase'
import ExerciseThumbnail from '@/components/clinician/designer/exerciseBrowser/ExerciseThumbnail'
import SlideShow from '@/components/clinician/designer/exerciseBrowser/SlideShow'
import UserExerciseIcons from './UserExerciseIcons'
import TeamExerciseIcons from './TeamExerciseIcons'
import OrgExerciseIcons from './OrgExerciseIcons'
import LinkedExerciseIcons from './LinkedExerciseIcons'
import StockExerciseIcons from './StockExerciseIcons'
import Favourite from './Favourite'

export default {
	name: 'Exercise',
	extends: ElementBase,
	components: {
		ExerciseThumbnail,
		SlideShow,
		UserExerciseIcons,
		LinkedExerciseIcons,
		TeamExerciseIcons,
		OrgExerciseIcons,
		StockExerciseIcons,
		Favourite
	},
	data: () => ({
		showSlideShow: false,
		slideShowTimer: null,
		slideShowPending: false
	}),
	computed: {
		mainElement() {
			return this.element.type !== 'stackExercise'
		},
		showSortable() {
			if (this.$store.state.main.mobile) return false			
			if (this.element.type === 'stackExercise') {
				const stackType = this.$store.state.elements.root[this.element.stackKey].type
				const stackFolderId = this.$store.state.elements.root[this.element.stackKey].folderId
				const folderData = this.$store.state.exerciseBrowser.folderData[stackFolderId]
				if (stackType === 'userStack') return true
				else if (stackType === 'teamStack' && this.$store.state.profile.teams[folderData.teamId].teamExercises === "readWrite") return true
				else if (stackType === 'orgStack' && this.$store.state.profile.user.orgExercises === 'readWrite') return true
				else return false

			} else {
				const folderData = this.$store.state.exerciseBrowser.folderData[this.$store.state.exerciseBrowser.folderId]
				if (this.$store.state.exerciseBrowser.tab === 'starred') return true
				else if (
					this.$store.state.exerciseBrowser.tab === 'custom' && 
					this.$store.state.exerciseBrowser.folderId && 
					(
						folderData && folderData.type === 'user' || 
						(folderData && folderData.type === 'team' && this.$store.state.profile.teams[folderData.teamId].teamExercises === "readWrite") ||
						(folderData && folderData.type === 'org' && this.$store.state.profile.user.orgExercises === 'readWrite')
					)
				) return true

				else return false
			}
		},
		/*
		showIcons() {
			return this.showSortable || this.mainElement;
		},
		*/
		showFavourite() {
			return this.mainElement
		},
		iconComponent() {
			if (this.element.type === 'userExercise') return 'UserExerciseIcons'
			else if (this.element.type === 'teamExercise') return 'TeamExerciseIcons'
			else if (this.element.type === 'orgExercise') return 'OrgExerciseIcons'
			else if (this.element.type === 'linkedExercise') return 'LinkedExerciseIcons'
			//else if (this.element.type === 'stockExercise') return 'StockExerciseIcons'
			else return null
		}
	},
	methods: {
		startSlideShow() {
			if (!this.$store.state.bus.bussing) {
				this.slideShowPending = true
				this.slideShowTimer = setTimeout(() => {
					if (!this.slideShowPending || !this.$store.state.elements.root[this.element.key]) return
					if (!this.$store.state.elements.root[this.element.key].complete) {
						this.$store.dispatch('exerciseBrowser/fetchByIds', this.element.key)
							.then(() => {
								if (this.element.resources.length > 1) this.showSlideShow = true
							})
					} else {
						if (this.element.resources.length > 1) this.showSlideShow = true
					}
				}, 1500)
			}
		},
		stopSlideShow() {
			clearTimeout(this.slideShowTimer)
			this.showSlideShow = false
			this.slideShowPending = false
		},
		toggleSelected() {
			if (this.mainElement) this.$store.dispatch('exerciseBrowser/toggleSelectedMain', this.element.key)
			else this.$store.dispatch('exerciseBrowser/toggleSelectedSub', this.element.key)
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserExercise {
	&.subElement .icons {
		@include absTopLeft;
		width: 100%;
	}
	.spinner { transform: scale(0.8); }

/*
	.aspectRatio {
		margin: 0 1px;
	}
*/
}
</style>