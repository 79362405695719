<template>
<div 
	class='setBuilder' 
	:class='{ bussingSetExercises }' 
>
	<div 
		class='setBuilderTabs'
		:class='{ showTabs: list.length }'
	>
		<template v-if='list.length'>
			<div 
				v-tap='() => createSet(true)'
				class='createSetTab iconTextButton tagPrimary'
			>
				<svg class='smallIcon'>
					<use xlink:href='#plus' />
				</svg>
				<span class='label'>{{$t('elements.buttons.new')}}</span>
			</div>
			<SlickList 
				v-model='list' 
				:lockToContainerEdges='true' 
				:helperClass='"sortHelper"' 
				:helperSettlingClass='"settling"' 
				:axis='"x"' 
				:lockAxis='"x"'
				:draggedSettlingDuration='0'
				:distance='10'					
				class='setTabs'
				:class='{ columns, sorting }'
				@sort-start='onSortStart'
				@sort-end='onSortEnd'
				ref='tabScroll'
				@mouseenter.native='onMouseEnterTabs'
				@mousemove.native='onMouseMoveTabs'
				@mouseleave.native='onMouseLeaveTabs'
			><div ref='tabScrollContent'>
				<SetTab 
					v-for='(setKey, index) in list' 
					:key='setKey' 
					:index='index'
					:setKey='setKey'
					:columns='columns' 
					@tabSelected='index => scrollToTab(index)'
					@tabClosed='onTabClosed'
					@showSpinner='showSpinner=true'
					@hideSpinner='showSpinner=false'
				/>
				<div class='spacer' />
			</div></SlickList>
			<div 
				v-if='showLayoutButton'
				class='layoutButton blockButton' 
				v-tap='() => $store.dispatch("setBuilder/toggleColumns")'
			>
				<svg v-if='!columns' class='smallIcon'>
					<use xlink:href='#column' />
				</svg>
				<svg v-else class='smallIcon'>
					<use xlink:href='#columns' />
				</svg>
			</div>			
			<div 
				v-if='!$store.state.bulkParameters.on' 
				v-tap='() => $store.dispatch("bulkParameters/toggleOn")'
				class='bulkParametersTab iconTextButton'
			>
				<svg class='smallIcon'>
					<use xlink:href='#bulkParameters' />
				</svg>
				<span class='label'>{{$t('elements.buttons.parameters')}}</span>
			</div>
		</template>
	</div>
	<SetScroll ref='scroll' @scroll='onScroll' :class='{ multiple }' :snap='snap'>
		<div class='setsWrapper'>
			<div class='sets' :class='{ maximized, tabs: list.length }' ref='sets'>
				<div 
					v-for='setKey in list' 
					:key='setKey' 
					class='setWrapper'
					:style='`min-width: ${maximized}px;`'
				>
					<Set :setKey='setKey' @duplicated='scrollToTab(list.length - 1)' />
				</div>
				<CreateSetButton v-if='!list.length' @createSet='() => createSet(true)' :bussingSetExercises='bussingSetExercises' />
				<div v-else class='spacer' :style='spacerStyle'>
					<CreateSetButton v-if='columns' @createSet='() => createSet(true)' :bussingSetExercises='bussingSetExercises' />
				</div>
			</div>
		</div>
	</SetScroll>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import { throttle, without } from 'lodash'
import { SlickList } from '@/libs/slickSort'
import { onResizeMixin } from '@/components/common/mixins/onResize'
import Loading from '@/components/common/Loading'
import CreateSetButton from './CreateSetButton'
import Set from './Set'
import SetScroll from './SetScroll'
import SetTab from './SetTab'

export default {
	name: 'SetBuilder',
	mixins: [onResizeMixin],
	components: {
		SlickList,
		Loading,
		CreateSetButton,
		Set,
		SetScroll,
		SetTab
	},
	props: ['columns','showLayoutButton'],
	data: () => ({
		maximized: false,
		clientWidth: null,
		offsetWidth: 0,
		snap: true,
		showSpinner: false,
		sorting: false
	}),
	computed: {
		bussingSetExercises() { 
			//return false
			
			return (
				(
					(!this.columns && this.list.length > 1) || 
					(this.columns && this.list.length > 2)
				) &&
				this.$store.state.bus.bussing && 
				this.$store.state.bus.contentType === 'setElements' &&
				!this.pin
			)
			
		},
		tabIndex() { return this.$store.state.setBuilder.tabIndex },
		loading() { return this.$store.state.sets.apiPending || this.showSpinner },
		multiple() { return this.list.length > 1 },
		setIds() { return this.$store.getters['setBuilder/router'] },
		spacerStyle() {
			return `min-width: ${Math.max(this.clientWidth - this.maximized, 0)}px`
		},
		list: {
			get() {
				return this.$store.state.setBuilder.pin ? without(this.$store.state.setBuilder.list, this.$store.state.setBuilder.pin) : this.$store.state.setBuilder.list
			},
			set(list) {
				if (this.$store.state.setBuilder.pin) {
					this.$store.dispatch('setBuilder/setList', list.concat([this.$store.state.setBuilder.pin]))
				} else {
					this.$store.dispatch('setBuilder/setList', list)					
				}
			}
		},
		pin() {
			return this.$store.state.setBuilder.pin
		}
	},
	methods: {
		onRoute(vm, to, from, next) {
			if (vm.setIds && vm.setIds !== to.params.setIds) return next({ name: 'getLanding', replace: true })
			else if (!vm.setIds && vm.$store.state.setBuilder.list.length && to.params.setIds) return next({ name: 'getLanding', replace: true })
			else if (!vm.$store.state.setBuilder.list.length && to.params.setIds) vm.$store.dispatch('setBuilder/fetch', to.params.setIds).catch(() => vm.$router.replace({ params: Object.assign(vm.$route.params, { setIds: null }) })) 
			return next()
		},
		onSortStart() {
			this.sorting = true
			this.onMouseLeaveTabs()
		},
		onSortEnd({ newIndex }) {
			this.sorting = false
			this.toggleTab(newIndex)
		},
		onKeyDown(e) {
			if ((e.ctrlKey || e.metaKey) && e.which === 65) {
				const focus = this.$store.state.setBuilder.focus
				if (focus) this.$store.dispatch('setBuilder/setSelected', this.$store.state.sets.root[focus].elements)
			} else if (e.which === 27) {
				this.$store.dispatch('setBuilder/clearSelected')
			}else if (
				(e.which === 8 || e.which === 46) && 
				this.$store.state.setBuilder.selected.length &&
				e.target === document.body
			) {
				e.preventDefault()
				e.stopPropagation()
				this.$store.dispatch('setBuilder/removeElements', { elementKeys: this.$store.state.setBuilder.selected })
				return false
			}
		},
		onResize: throttle(function() {
			if (!this.active) return
			this.offsetWidth = this.$el.offsetWidth				
			const w = (this.columns ? this.$el.offsetWidth / 2 : this.$el.offsetWidth)
		//	this.maximized = Math.max(Math.min(w, 720), 415)
			this.maximized = Math.max(w, 415)
			this.clientWidth = this.$el.clientWidth
			this.scrollToTab(this.tabIndex, false)
			//this.$refs.scroll.$el.children[0].scrollLeft = this.tabIndex * this.maximized
		}, 100, { leading: true, trailing: true }),
		onScroll: throttle(function(left) {
			if (!this.active || this.tabIndex === null) return
			for (let i=0; i<this.$refs.sets.children.length; i++) {
				const el = this.$refs.sets.children[i]
				if (el.offsetLeft - (el.offsetWidth/2) >= left) {
					const index = Math.max(0, i-1)
					this.$store.dispatch('setBuilder/setTabIndex', index)
					break
				}
			}
			//eventBus.$emit('horizontalScroll')
		}, 20, { leading: true, trailing: false }),
		onMouseMove: function(e) {
			const clientX = e.clientX || e.touches[0].clientX
			const rect = this.rect
			if (clientX - rect.left < rect.width * .25) {
				this.scrollInertia = (25 - ((clientX - rect.left) / rect.width * 100)) * 2
			} else if (clientX - rect.left > rect.width * .75) {
				this.scrollInertia = (75 - ((clientX - rect.left) / rect.width * 100)) * 2
			} else {
				this.scrollInertia = 0
			}
		},
		onBussing(el) {
			if (!this.$el.contains(el)) this.scrollInertia = 0
		},
	/*
		onMouseLeave() {
			
		},
	*/
		onMouseMoveTabs(e) {
			if (this.$store.state.main.mobile || this.sorting) return
			const el = this.$refs.tabScroll.$el
			const rect = el.getBoundingClientRect()
			const percent = (e.clientX - rect.left) / rect.width
			if (percent < 0.05) this.tabScrollInertia = -2
			else if (percent > .95) this.tabScrollInertia = 2
			else this.tabScrollInertia = 0;
		},
		onMouseEnterTabs() {
			if (this.$store.state.main.mobile) return
			this.onMouseLeaveTabs()
			const el = this.$refs.tabScroll.$el
			const repeat = () => {
				el.scrollLeft += this.tabScrollInertia
				this.tabScroll = requestAnimationFrame(repeat)
			}
			this.tabScroll = requestAnimationFrame(repeat)			
		},
		onMouseLeaveTabs() {
			if (this.$store.state.main.mobile) return
			cancelAnimationFrame(this.tabScroll)
		},
		onClickTab() {
			//this.toggleTab(index)
			eventBus.$emit('showSetMenu')
		},
		onTabClosed(index) {
			if (this.list.length) {
				const i = index ? index - 1 : 0
				this.$store.dispatch('setBuilder/setTabIndex', i)
				this.$store.dispatch('setBuilder/setFocus', this.list[i])
				setTimeout(() => this.scrollToTab(i), 0)
			}
		},
		createSet(toRight) {
			this.$store.dispatch('setBuilder/createSet', toRight)
				.then(() => {
					if (toRight) {
						if (this.columns && this.list.length >= 2) {
							this.scrollToTab(this.list.length - 1, true)	
							const key = this.list[this.list.length - 1]
							setTimeout(() => this.$store.dispatch('setBuilder/setFocus', key), 0)											
						} else {
							this.scrollToTab(this.list.length - 1)
						}
					} else {
						this.$store.dispatch('setBuilder/setTabIndex', 0)
						this.scrollToTab(0)
					}
				})
		},
		toggleTab(index) {
			this.$store.dispatch('setBuilder/setTabIndex', index)
			setTimeout(() => this.scrollToTab(index), 0)
			//this.$store.dispatch('setBuilder/setFocus', this.$store.state.setBuilder.list[index])
		},
		scrollToTab(index, end) {
			if (!this.$refs.sets.children[index]) return
			this.$refs.sets.children[index].scrollIntoView({ inline: end ? 'end' : 'start' })	
			//setTimeout(() => this.$refs.sets.children[index].scrollIntoView(true), 0)
		},
		startAutoScroll() {
			this.snap = false
			const scale = .9
			const scrollLeft = this.$refs.scroll.$el.children[0].scrollLeft * scale
			const columnOffset = this.columns ? 0 : 1
			const scrollRight = (this.$refs.scroll.$el.children[0].scrollLeft - (this.maximized * (this.list.length - columnOffset))) * scale
			this.translateX = 0
			this.scrollInertia = 0
			let origin 
			if (this.tabIndex === 0) origin = 0
			else if (this.tabIndex < this.list.length - 1) origin = `${this.tabIndex}50%`
			else if (this.tabIndex === this.list.length - 1) origin = `${this.tabIndex + 1}00%`
			this.$refs.sets.style.transition = `transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1)`
			this.$refs.sets.style.transformOrigin = `${origin} 0`
			const repeat = () => {
				this.translateX += (this.scrollInertia / 2)
				if (this.translateX > scrollLeft) this.translateX = scrollLeft
				else if (this.translateX < scrollRight) this.translateX = scrollRight
				this.$refs.sets.style.transform = `translateX(${this.translateX}px) scale(0${scale})`
				this.autoScroll = requestAnimationFrame(repeat)
			}
			this.autoScroll = requestAnimationFrame(repeat)
		},
		stopAutoScroll() {
			//clearInterval(this.autoScroll)
			cancelAnimationFrame(this.autoScroll)
			this.$refs.sets.style.transition = `unset`
			this.$refs.sets.style.transform = `none`
			const index = this.$store.state.setBuilder.list.indexOf(this.$store.state.setBuilder.focus)
			//this.scrollToTab(index)
			this.snap = true
			this.$nextTick(() => this.scrollToTab(index)) // safari
		}
	},
	watch: {
		setIds(setIds) {
			if (setIds !== this.$route.params.setIds) this.$router.replace({ params: Object.assign(this.$route.params, { setIds }) })
		},
		list(sets) {
			if (this.tabIndex >= sets.length - 1) {
				this.toggleTab(Math.max(0, sets.length - 1)) // avoid the flicker when closing a set
				setTimeout(() => this.toggleTab(Math.max(0, sets.length - 1)), 0) // need to allow update when pinning
			}
		},
		bussingSetExercises(value) {
			if (value) {
				this.rect = this.$el.getBoundingClientRect()
				if (this.$store.state.main.mobile) this.$el.addEventListener('touchmove', this.onMouseMove, { passive: true })
				else this.$el.addEventListener('mousemove', this.onMouseMove)
				this.startAutoScroll()
			} else {
				if (this.$store.state.main.mobile) this.$el.removeEventListener('touchmove', this.onMouseMove)
				else this.$el.removeEventListener('mousemove', this.onMouseMove)				
				this.stopAutoScroll()				
			}
		},
		tabIndex(value) {
			const key = this.list[value]
			this.$store.dispatch('setBuilder/setFocus', key)
		},
		pin(newValue, oldValue) {
			if (!newValue) this.toggleTab(Math.max(0, this.$store.state.setBuilder.list.indexOf(oldValue)))
		}
	},
	mounted() {
		this.active = true
		eventBus.$on('bussing', this.onBussing)
		window.addEventListener('keydown', this.onKeyDown)
		this.unsubscribe = this.$store.subscribe(({ type }) => {
			if (type === 'setBuilder/prependList') this.$refs.scroll.scrollToLeft()
		})
		this.clientWidth = this.$el.clientWidth
		const index = this.$store.state.setBuilder.list.indexOf(this.$store.state.setBuilder.focus)
		setTimeout(() => this.scrollToTab(index), 0) // safari is picky
	//	this.toggleTab(this.$store.state.setBuilder.list.indexOf(this.$store.state.setBuilder.focus)) // make sure we are on the latest tab
		//this.onResize()
	},
	beforeDestroy() {
		this.active = false
		eventBus.$off('bussing', this.onBussing)
		window.removeEventListener('keydown', this.onKeyDown)
		this.unsubscribe()
	},
	beforeRouteEnter(to, from, next) {
		next(vm => vm.onRoute(vm, to, from, next))
	},
	beforeRouteUpdate(to, from, next) {
		return this.onRoute(this, to, from, next)
	}	
}
</script>
<style lang='scss'>
.setBuilder {
	@include fill;
	position: relative;
	display: grid;
	grid-template-rows: auto 1fr;


	.setBuilderTabs {
		box-shadow: -1px 0 0 0 $color-neutral-shadow;
		&:not(:empty) { border-bottom: 1px solid $color-neutral-shadow; }
	}

	.setBuilderTabs.showTabs {
		display: flex;
		align-items: center;
		transition: opacity 200ms linear;
		background: $background-panel-inset;
		height: $size-control-height + 1;
		min-width: 0;

		.setTabs {
			position: relative;
			flex: 1;
			overflow-x: scroll;
			overflow-y: hidden;
			scrollbar-width: none;
			padding-left: 1px;
			&::-webkit-scrollbar { display: none; } 
/*	
			&.columns { 
				padding-left: $size-gutter * 2;
			}
*/
			> div {
				display: flex;
				align-items: center;			
			}

/*
			&.sorting { 
				cursor: move !important;
				.setTab * { pointer-events: none !important; }
			}
*/
			.spacer { 
				min-width: 75%;
				height: $size-control-height;
				background: $background-panel-inset;
			}			

		}

		.createSetTab {
			height: $size-control-height + 1;
			.label { font-weight: bold; }
			flex: 0;
		}

		.layoutButton { 
			box-sizing: content-box;
			flex: 0; 
		}		

		.bulkParametersTab { 
			height: $size-control-height + 1;			
			margin-left: auto; 
		}
	}       

	.setsWrapper {
		height: 100%;
	}

	.multiple {
		.setsWrapper {

/*
			padding-bottom: $size-gutter * 3 + 1; 

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: $size-gutter * 3 + 1;
				background: $color-neutral-shadow;
				content: '';
			}
*/
		}
	}
	& :not(.multiple) {
		.vb-dragger-x { display: none; }
	}

	.sets {
		display: flex;
		min-width: 100%;
		height: 100%;
		>div {
		/*	max-width: 720px; */
			flex-basis: 100%;
			margin-right: 1px;
			background: $color-neutral-panel;
			background: linear-gradient(to top, $color-neutral-panel, $color-neutral-silver);

		}

		.createSetButtonWrapper {
			margin: 0;
			flex-basis: 100%;
			max-width: 100%;
		}

		&.tabs {
			&.maximized > div:not(:last-child) {
	/*			min-width: 720px; */
			}
		}
	}

	.setWrapper {
		scroll-snap-align: start;
		/* border-right: 1px solid $color-neutral-shadow; */
	}

	.spacer {
		height: 100%;
		.createSetButtonWrapper { padding: $size-gutter * 2; background: none; }
	}

	> .loading {
		background: rgba($color-neutral-panel, 0.2);

		> div {
			position: relative;
			top: -17px;
		}
	}

	&.bussingSetExercises {            

		background: linear-gradient(to top, $color-neutral-panel, $color-neutral-silver);

		.sets {
		}

		.setWrapper:not(:last-child) { height: 112%; }
		/*
		.setTabs, .vb-dragger-x {
			opacity: 0;
		}
		*/
		.scrollContent {
			overflow: hidden !important;
			/*
			scroll-snap-type: none !important;
			*/
		}

		.setFooter { display: none; }

		.vb-dragger {
			display: none;
		}
	}
}
</style>