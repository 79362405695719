<template>
<SmallModal class='emailSetModal' @close='$emit("close")' @doDefault='emailIfNotFocussed'>
	<template v-slot:title>{{$t("views.emailSet.titleEmail")}} {{client.email}}</template>
	<template v-slot:message>
		<div class='field'>
			<div class='label'>{{$t("elements.labels.subject")}}:</div>
			<TextInput 
				v-model='subject' 
				maxlength='100' 
				:placeholder='$t("views.emailSet.phEmailSubject")' 
			/>
		</div>
		<div class='field'>
			<div class='label'>
				{{$t("elements.labels.cc")}}:
				<svg class='detail' @click='detailCC'>
					<use xlink:href='#detail' />
				</svg>
			</div>
			<EmailsInput 
				v-model='cc' 
				:placeholder='$t("views.emailSet.phEmailCC")' 
				:maxlength='500'
				:maxtags='5'
			/>
		</div>
		<!--
		<div class='field'>
			<div class='label'>{{$t("elements.labels.header")}}:</div>
			<HeaderFooterPreview />
		</div>
	-->
		<div class='field'>
			<div class='label'>{{$t("elements.labels.message")}}:</div>
			<RichText 
				v-model='body' 
				:placeholder='$t("views.emailSet.phEmailBody")' 
				:maxlength='1000'
			/>
		</div>
		<div class='setLink'>{{$t("views.emailSet.link")}}</div>
		<div class='field'>
			<div class='label'>{{$t("elements.labels.signature")}}:</div>
			<RichText 
				v-model='signature' 
				:placeholder='$t("views.emailSet.phEmailSignature")' 
				:maxlength='500'
			/>
		</div>
		<div class='signMessage' v-html='signMessage'></div>	
		<transition name='fade'><Loading v-show='loading' /></transition>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='email'>
			<svg class='smallIcon'>
				<use xlink:href='#email' />
			</svg>
			<span class='label' v-html='$t("elements.buttons.signsend")' />
		</div>
	</template>
</SmallModal>
</template>

<script>
import { fill } from '@/utilities'	
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'
import SmallModal from '@/components/common/modals/SmallModal'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import EmailsInput from '@/components/common/EmailsInput'
import RichText from '@/components/common/RichText'
//import HeaderFooterPreview from './HeaderFooterPreview'

export default {
	name: 'EmailSet',
	mixins: [smallModalMixin],
	components: {
		SmallModal,
		RichText,
		EmailsInput,
		Loading,
		TextInput
	},
	props: ['setKey','saveFirst','cb'],
	data() {
		return {
			subject: '', 
			cc: this.$store.state.profile.user.clientEmailCC,
			body: this.$store.state.profile.user.clientEmailBody,
			signature: this.$store.state.profile.user.clientEmailSig,
			loading: false
		}
	},
	computed: {
		client() { return this.$store.state.clients[this.$store.state.sets.root[this.setKey].clientId] },
		user() { return this.$store.state.profile },		
		signMessage() {
			return fill(this.$t('views.updateClientSet.p1'), {
				user: this.user.firstname +' '+ this.user.lastname,
				client: this.client.firstname +' '+ this.client.lastname   
			})
		}		
	},
	methods: {
		async email() {
			if (this.$store.state.profile.user.clinicianType === 'demo') {
				this.$emit('close')
				return this.$store.dispatch('flash/showAlert', 'notAvailableInDemo')
			}
			if (this.loading) return
			this.loading = true
			setTimeout(async () => { // to give cc a chance to update
				if (this.saveFirst) await this.$store.dispatch('sets/saveOldSet', this.setKey)
				const email = {}
				if (this.subject) email.subject = this.subject
				if (this.cc.length) email.cc = this.cc
				if (this.body) email.body = this.body
				if (this.signature) email.signature = this.signature					
				await this.$store.dispatch('sets/email', {
					setKey: this.setKey,
					email
				})
				this.cb()
				this.$emit('close')
			}, 0)
		},
		detailCC() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.detailCCReceipt.title'),
				message: this.$t('views.detailCCReceipt.p1')
			})
		},
		emailIfNotFocussed() {
			const elements = document.querySelectorAll( '.emailSetModal :focus' )
			if (!elements.length) this.email()
		}
	},
	mounted() {
		this.loading = true
		this.$store.dispatch('headerFooters/fetch')
			.finally(() => this.loading = false)
	}
}
</script>

<style lang='scss'>
.emailSetModal {
	.loading {
		background: rgba($color-white, .3);
	}

	.setLink {
		height: $size-control-height;
		margin-left: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
	}

	.signMessage {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		margin-top: $size-gutter * 4;
	}	

	.formContent {
	}
}
</style>