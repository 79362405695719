<template>
<div class='exerciseBrowserSlideShow'>
	<div 
		v-for='(url, index) in resourceUrls' 
		v-show='active(index)'
		:key='index' 
		:class='{ visible: isVisible(index) }'
		class='imgWrapper'
	>
		<img 
			@load='loaded.push(index)'
			:src='url' 
		/>
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { compact } from 'lodash'
import { Repeat } from '@/utilities'
import Loading from '@/components/common/Loading'

export default {
	name: 'SlideShow',
	props: ['exerciseKey'],
	components: {
		Loading
	},
	data: () => ({
		counter: 0,
		loading: true,
		loaded: [],
		visible: [],
		repeat: null,
		looping: false
	}),
	computed: {
		resourceUrls() {
			const resources = this.$store.getters['elements/getResourcesByExercise'](this.exerciseKey)
			return compact(resources.map(resource => this.$store.getters['resources/isImage'](resource.resourceId) ? this.$store.getters['resources/getResource'](resource.resourceId) : null ))
		}
	},
	methods: {
		isVisible(index) {
			return this.visible.includes(index)
		},
		active(index) {
			if (this.looping) {
				return index === 0 || index === this.resourceUrls.length - 1
			} else {
				return this.loaded.includes(index)
			}
		},
		cycleVisibility() {
			if (this.visible.length === this.resourceUrls.length) {
				this.visible = []
				this.counter = 0
				this.looping = true
				setTimeout(() => this.looping = false, 200)
			}
			this.visible.push(this.counter)
			this.counter++;
		}
	},
	watch: {
		loaded: function(loaded) {
			if (loaded.length === this.resourceUrls.length) {
				this.loading = false
				this.repeat = new Repeat(this.cycleVisibility, 1200)
				this.$nextTick(() => this.repeat.start())
			}
		}
	},
	beforeDestroy() {
		if (this.repeat) {
			this.repeat.stop()
			this.repeat = null
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserSlideShow {
	@include absTopLeft;
	width: 100%;
	height: 100%;
	animation: fadeIn 200ms linear;
	pointer-events: none;

	.imgWrapper {
		@include absTopLeft;
		width: 100%;
		height: 100%;		
		background: $color-white;
		opacity: 0;
		transition: opacity .2s linear;

		&.visible {
			opacity: 1;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			pointer-events: none;
		}
	}
}

body .exerciseBrowserSlideShow .loading {
	background: none;
}
</style>


