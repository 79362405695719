<template>
	<div class='exerciseBrowserTabs tabButtons'>
		<router-link 
			tag='div'
			:to='{ params: { tab: "stock", folderId: $store.state.categories.selected || null }}' 
		>
			<svg class='smallIcon typeIcon'>
				<use xlink:href='#stock' />
			</svg>			
			<span class='label'>SimpleSet</span>
		</router-link>

		<!--
		<router-link
			tag='div' 
			ref='stock'			
			:to='{ params: { tab: "stock" }}' 
		>
			<svg v-if='tab === "stock"' class='smallIcon typeIcon'>
				<use xlink:href='#stock' />
			</svg>			
			<span class='label'>{{ $t('elements.tabs.stock') }}</span>
		</router-link>
		-->		

		<template v-if='showDroppable'>

			<div style='flex: 2;' class='dropStock' @mouseenter='hovering=true' @mouseleave='hovering=false' @mouseup='$emit("copyTo", $store.state.bus.bus)'>
				<svg class='smallIcon typeIcon'>
					<use xlink:href='#plus' />
				</svg>			
				<span class='label'>{{ $t('elements.tabs.custom') }}</span>
			</div>

		</template>
		<template v-else>

			<router-link
				tag='div' 
				:to='{ params: { tab: "custom", folderId: $store.state.exerciseBrowser.folderId || null }}' 
				:class='{ copyStock: showCopied }'
			>
				<!--
					<transition name='fade'>
						<div v-if='$store.state.profile.showUpdateAlert && !$store.state.profile.tips && buffer' id='updateAlert' @click.stop>
							<div>
								<div>Heads Up!  SimpleSet has changed.  View your personalized exercises, and create new exercises in the "Custom" tab.</div>
								<div class='textButton success' @click.stop='$store.commit("profile/hideUpdateAlert")'>Got it!</div>
							</div>
						</div>
					</transition>
				-->
				<svg class='smallIcon typeIcon'>
					<use xlink:href='#personal' />
				</svg>			
				<span id='customExercisesTab' class='label'>{{ $t('elements.tabs.custom') }}</span>
			</router-link>

			<router-link
				tag='div' 
				:to='{ params: { tab: "starred", folderId: null }}' 
			>
				<svg class='smallIcon typeIcon'>
					<use xlink:href='#star' />
				</svg>	
				<span class='label'>{{ $t('elements.tabs.starred') }}</span>
			</router-link>

		</template>		
		<!--

							@dropOn.native='() => copyTo("custom")'

			<router-link 
				v-if='teamExercises' 
				tag='div' 
				ref='team'
				:to='{ params: { tab: "team" }}' 
				:class='{
					bussing: bussing && teamExercises === "readWrite"
				}'	
				v-dropOn='bussing && teamExercises === "readWrite"'  
				@dropOn.native='() => copyTo("team")'
			>
				<svg v-if='bussing && teamExercises === "readWrite" && tab !== "team"' class='smallIcon typeIcon'>
					<use xlink:href='#plus' />
				</svg>			
				<svg v-else class='smallIcon typeIcon'>
					<use xlink:href='#team' />
				</svg>
				<span class='label'>{{ $t('elements.tabs.team') }}</span>
			</router-link>
		-->
	</div>
</template>

<script>
// import { dropOn } from '@/components/common/directives/dropOn'
import { eventBus } from '@/utilities/eventBus'

export default {
	name: 'Tabs',
//	directives: {
//		dropOn
//	},
	data() { return {
		showCopied: false,
		hovering: false,
		buffer: false
	}},
	computed: {
		/*
		teamExercises() {
			return this.$store.getters['profile/teamExercises']
		},
		*/
		tab() {
			return this.$store.state.exerciseBrowser.tab
		},
		bussing() { 
			return (
				this.$store.state.bus.bussing //&&
				//['browserElements', 'setElements'].includes(this.$store.state.bus.contentType)
			)
		},
		showDroppable() {
			return this.bussing && this.$store.state.exerciseBrowser.tab==="stock" && this.$store.state.bus.contentType==="browserElements"
		}
	},
	methods: {
		copyToCustomHandler() {
			this.showCopied = true
			setTimeout(()=>this.showCopied=false, 2000)
		},
//		clickTab(tab) {
		//	this.$router.push({ params: Object.assign(this.$route.params, { tab }) })
//		},
/*
		async copyTo(tab) {
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
				) return
				const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy'](ids)
				this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks, tab })
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { exercises } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				this.$store.dispatch('exerciseBrowser/copy', { exercises, tab })
			}
		}
*/
	},
	watch: {
		/*
		tab(tab) {
			//this.$nextTick()
			//	.then(() => {
					if (tab !== this.$route.params.tab) {
						let folderId 
						if (tab === 'stock') folderId = this.$store.state.categories.selected || null
						else if (tab === 'custom') folderId = this.$store.state.exerciseBrowser.folderId || null
						else folderId = null
						this.$router.push({ params: { tab, folderId } })
					}
			//	})
		}
		*/
		showDroppable(v) {
			if (!v) this.hovering = false
		},
		hovering(v) {
			if (v) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}			
		}
	},
	
	mounted() {
		setTimeout(()=>this.buffer=true, 1000)
		eventBus.$on('copyToCustom', this.copyToCustomHandler)
	},
	beforeDestroy() {
		eventBus.$off('copyToCustom', this.copyToCustomHandler)
	}
}
</script>

<style lang='scss'>
/*
#updateAlert {
	position: absolute;
	top: -50%;
	left: -50%;
	width: 100%;
	height: 100%;
	z-index: 99999;
	border-radius: 7px;
	margin-top: -2000px + 20px;
	margin-left: calc(50% - 2000px);
	border: 2000px solid rgba(0,0,0,0.8);
	box-sizing: content-box;

	> div {
		position: absolute;
		top: 150%; left: 0;
		width: 100%;
		font-size: $size-font-large;
		line-height: $size-lineHeight-large;
		color: #fff;
		text-align: center;
		z-index: 10;
		.textButton { margin-top: $size-gutter * 3; height: $size-control-height * 1.5; font-size: $size-font-large; }
	}

	&:before {
		position: fixed;
		top: 40px;
		left: 0;
		width: 300%;
		height: calc(100% - 40px);
		content: '';
	}
}
*/

.exerciseBrowserTabs {

	.copyIcon {
		display: none;
	}

	.dropStock {
		position: relative;
		&:hover {

			&:before {
				position: absolute;
				top: -1px;
				left: -1px;
				width: calc(100% + 2px);
				height: calc(100% + 2px);
				content: '';
				background: #fff;
				border: 1px solid saturate(lighten($color-primary, 20%), 20%);
				box-shadow: inset 0 0 7px saturate(lighten($color-primary, 20%), 20%);
				animation: fadeIn 200ms linear;
			}				
		}
		.label, svg { position: relative; z-index: 10; }
	}

	.copyStock {

			&:before {
				position: absolute;
				top: -1px;
				left: -1px;
				width: calc(100% + 2px);
				height: calc(100% + 2px);
				content: '';
				background: #fff;
				border: 1px solid saturate(lighten($color-primary, 20%), 20%);
				box-shadow: inset 0 0 7px saturate(lighten($color-primary, 20%), 20%);
				animation: fadeIn 200ms linear;
			}			
		.label, svg { position: relative; z-index: 10; }

	}

	.copiedNotice {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		/* background: linear-gradient(to bottom, $color-neutral-silver, $color-neutral-panel); */
/*		background: $background-panel-inset; */
		z-index: 10000;
	}

	.bussing {
		&:not(.selected) {
			/*
			outline: 2px dashed rgba($color-black, 0.2);
			outline-offset: -4px;		

			&:after { display: none; }
*/
			cursor: copy;

			&.dropOn:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 0;
				background: rgba($color-focus, .2);
				pointer-events: none;				
			}
		}
	}

/*
	.bussing {
		height: $size-control-height * 2 + $size-gutter * 2 + 3;
		opacity: 1 !important;

		.label, svg {
			position: relative;
			z-index: 10;
		}

		svg {
			display: inherit !important;
		}

		&:not(.selected) {
			outline: 2px dashed rgba($color-black, 0.2);
			outline-offset: -$size-gutter * 2;		
			cursor: copy;

			&.dropOn:before {
				content: '';
				position: absolute;
				top: $size-gutter * 2;
				left: $size-gutter * 2;
				width: calc(100% - #{$size-gutter * 4});
				height: calc(100% - #{$size-gutter * 4} + 1px);
				z-index: 0;
				background: rgba($color-focus, .2);
				pointer-events: none;				
			}
		}
	}


	.expanded {
		height: $size-control-height * 2 + 3px;		
	}
*/
}
</style>


