<template>
<div class='exerciseBrowserStackControls'>
	<div class='controlBar'>
		<template v-if='disabled'>
			<div class='locked iconTextButton'>
				<svg class='smallIcon'>
					<use xlink:href='#lock' />
				</svg>
				<span class='label'>{{$t("views.designer.msgReadOnlyStack")}}</span>
			</div>
		</template>
		<template v-else>
			<TextInput 
				ref='titleInput'
				v-model='title' 
				:placeholder='$t("views.designer.phStackTitle")' 
				@input='save'
				class='editStackTitle' 
				maxlength='100' 
			/>
			<!--
			<div 
				class='iconTextButton neutral' 
				v-tap='copy'
			>
				<svg class='smallIcon'>
					<use xlink:href='#copy' />
				</svg>
				<span class='label'>{{$t("elements.buttons.copy")}}</span>
			</div>				
		-->
			<DeleteButton v-if='!childrenSelected' @click='confirmDelete' />
		</template>
	</div>
</div>
</template>

<script>
import { debounce } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import TextInput from '@/components/common/TextInput'
import DeleteButton from '@/components/common/buttons/DeleteBlock'

export default {
	name: 'StackControls',
	props: ['stackKey'],
	components: {
		TextInput,
		DeleteButton
	},
	mixins: [smallModalMixin],
	computed: {
		stack() {
			return this.$store.state.elements.root[this.stackKey]
		},
		childrenSelected() {
			return this.$store.state.exerciseBrowser.selected.length && this.$store.state.exerciseBrowser.selected.every(id=>this.$store.state.elements.root[id].stackKey===this.stackKey)
		},
		title: {
			get() {
				return this.stack.title
			},
			set(value) {
				this.$store.dispatch('elements/setTitle', {
					key: this.stack.key,
					value
				})
			}
		},
		disabled() {
			return this.$store.getters['elements/disabled'](this.stack.key)
		}
	},
	methods: {
		save: debounce(function() {
			if (this.active && this.stack.fresh) this.$store.dispatch('exerciseBrowser/save', this.stack.key)
		}, 1000, { leading: false, trailing: true }),
		copy() {
			const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy']([this.stack.key])
			this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks }).then(() => this.$emit('copy'))
		},
		confirmDelete() {
			const fn = () => this.$store.dispatch('exerciseBrowser/destroy', this.stack.key)
			this.showSmallModal(ConfirmDeleteModal, { items: this.stack.title, fn })
		}
	},
	mounted() { 
		this.active = true 
		if (!this.title && !this.disabled) this.$refs.titleInput.focus()
	},
	beforeDestroy() { this.active = false },
}
</script>

<style lang='scss'>
body .exerciseBrowserStackControls {
	.controlBar { margin-bottom: -1px; }

	.editStackTitle {
		flex: 3;
	}

	.locked { flex: 3; }

}
</style>