<template>
<div 
	v-if='exercise.thumbnailId || canFlip'
	class='setExerciseControls'
>
	<div class='controlBar'>
		<UnsetThumbnailButton 
			v-if='showUnsetThumbnail' 
			:thumbnailUrl="$store.getters['resources/getResource'](exercise.thumbnailId)" 
			:flip='exercise.reflect' 
			@click='unsetThumbnail'
		/>
		<FlipImagesButton 
			v-if='canFlip' 
			@click='flipImages' 
			:flip='exercise.reflect' 
		/>
	</div>
</div>
</template>

<script>
import UnsetThumbnailButton from '@/components/clinician/designer/common/UnsetThumbnailButton'
import FlipImagesButton from '@/components/clinician/designer/common/FlipImagesButton'

export default {
	name: 'SetExerciseControls',
	props: ['exerciseKey'],
	components: {
		UnsetThumbnailButton,
		FlipImagesButton
	},
	computed: {
		exercise() {
			return this.$store.state.sets.elements[this.exerciseKey]
		},
		canFlip() {
			return !!this.exercise.resources.length || this.exercise.thumbnailId
		},
		thumbnailUrl() {
			const r = this.exercise.resources[0]
			const rId = r ? this.$store.state.sets.resources[r].resourceId : null
			return rId ? this.$store.getters['resources/getResource'](rId) : null
			//return this.$store.getters['resources/getResource'](this.exercise.thumbnailId)
		},
		showUnsetThumbnail() {
			return (
				this.exercise.thumbnailId &&
				this.exercise.resources.length 
				/*
				this.exercise.resources
					.map(key => this.$store.state.sets.resources[key].resourceId)
					.find(key => this.$store.state.resources.resources[key] && this.$store.state.resources.resources[key].type === 'userImage')
				*/
			)
		}
	},
	methods: {
		unsetThumbnail() {
			this.$store.dispatch('sets/unsetThumbnail', this.exercise.key)
		},
		flipImages() {
			this.$store.dispatch('sets/toggleReflect', this.exercise.key)
		}
	}
}
</script>

<style lang='scss'>
.setExerciseControls {
	margin-bottom: 1px;
}
</style>