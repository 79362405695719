<template>
<div class='setPdfPreview'>
	<Scroll>

		<div v-if='selected.includes("cvr") && ($store.state.sets.root[setKey].title || $store.state.sets.root[setKey].instructions)' class='preview' ref='coverWrapper'>
			<Cover :setKey='setKey' ref='cover' @resize='scaleCover' />
		</div>

		<div v-if='selected.includes("edu") && $store.state.sets.root[setKey].education && $store.state.sets.root[setKey].education.length' class='preview' ref='eduWrapper'>
			<Education :setKey='setKey' ref='edu' @resize='scaleEducation' />
		</div>

		<div v-if='selected.includes("sum")' class='preview' ref='summaryWrapper'>
			<Summary :setKey='setKey' ref='summary' @resize='scaleSummary'  :selectedExs='selectedExs' />
		</div>

		<div v-if='selected.includes("cmp")' class='preview' ref='comprehensiveWrapper'>
			<Comprehensive :setKey='setKey' ref='comprehensive' @resize='scaleComprehensive'  :selectedExs='selectedExs'/>
		</div>

		<div v-if='selected.includes("imgs")' class='preview' ref='imagesWrapper'>
			<Images :setKey='setKey' ref='images' @resize='scaleImages' :selectedExs='selectedExs' />
		</div>

		<div v-if='selected.includes("trck")' class='preview' ref='trackingWrapper'>
			<Tracking :setKey='setKey' ref='tracking' @resize='scaleTracking' :selectedExs='selectedExs' />
		</div>

		<div v-if='selected.includes("txt")' class='preview' ref='txtWrapper'>
			<TextSummary :setKey='setKey' ref='txt' @resize='scaleText' :selectedExs='selectedExs' />
		</div>

	</Scroll>
</div>
</template>

<script>
import { onResizeMixin } from '@/components/common/mixins/onResize'
import Scroll from '@/components/common/Scroll'
import Cover from './PreviewCover'
import Education from './PreviewEducation'
import Summary from './PreviewSummary'
import Comprehensive from './PreviewComp'
import Images from './PreviewImages'
import Tracking from './PreviewTracking'
import TextSummary from './PreviewText'

const padding = 54

export default {
	name: 'SetPdfPreview',
	mixins: [onResizeMixin],
	components: { Scroll, Cover, Education, Summary, Comprehensive, Images, Tracking, TextSummary },
	props: ['setKey', 'selectedExs'],
	computed: {
		selected() { return this.$store.state.setPdf.selected }
	},
	methods: {
		onResize() {
			if (this.selected.includes('cvr')) this.scaleCover()
			if (this.selected.includes('edu')) this.scaleEducation()
			if (this.selected.includes('sum')) this.scaleSummary()
			if (this.selected.includes('cmp')) this.scaleComprehensive()
			if (this.selected.includes('imgs')) this.scaleImages()
			if (this.selected.includes('trck')) this.scaleTracking()
			if (this.selected.includes('txt')) this.scaleText()
		},
		scaleCover() { this.scale(this.$refs.cover?.$el, this.$refs.coverWrapper) },
		scaleEducation() { this.scale(this.$refs.edu?.$el, this.$refs.eduWrapper) },
		scaleSummary() { this.scale(this.$refs.summary?.$el, this.$refs.summaryWrapper) },
		scaleComprehensive() { this.scale(this.$refs.comprehensive?.$el, this.$refs.comprehensiveWrapper) },
		scaleImages() { this.scale(this.$refs.images?.$el, this.$refs.imagesWrapper) },
		scaleTracking() { this.scale(this.$refs.tracking?.$el, this.$refs.trackingWrapper) },
		scaleText() { this.scale(this.$refs.txt?.$el, this.$refs.txtWrapper) },
		scale(childEl, parentEl) {
			if (childEl && parentEl) {
				const childWidth = childEl.offsetWidth
				const childHeight = childEl.offsetHeight
				const parentWidth = parentEl.offsetWidth - padding
				const scale = parentWidth / childWidth
				parentEl.style.height = (childHeight * scale + padding) + 'px'
				childEl.style.transform = 'scale('+ scale +')' 
				window.dispatchEvent(new Event('resize'))
			}
		}
	},
	/*
	watch: {
		selected() {
			setTimeout(() => this.onResize(), 500)
		}
	},
	*/
	mounted() {
		this.onResize()
		//setTimeout(() => this.onResize(), 0)
	}
}
</script>

<style lang='scss'>
.setPdfPreview {
	@include fill;
	position: relative;
	background: $color-white;

	.preview {
		position: relative;
		margin-top: $size-gutter * 3;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 1;
		border: $size-gutter * 3 solid $color-white;
		background: $color-white;
		margin-bottom: 50px;

		> div {
			@include absTopLeft;
			transform-origin: 0% 0%;
		}

		& + .preview {
			&:after {
				z-index: 1;				
				position: absolute;
				top: -50px;
				left: 0;
				width: calc(100% - 14px);
				border-top: 1px dashed $color-neutral-shadow;
				content: '';
			}
			&:before {
				z-index: 2;
				position: absolute;
				top: -63px;
				left: 50%;
				padding: 7px 0;
				margin-left: -25px;
				width: 50px;
				text-align: center;
				background: #fff;
				content: 'break';
			}			
		}
	}
}
</style>
