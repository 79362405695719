<template>
<SlickList 
	v-model='list' 
	:axis='"xy"' 
	:gap='1' 
	:helperClass='"sortHelper"' 
	:helperSettlingClass='"settling"' 
	:parent='".scrollContainer"'
	:useDragHandle='true'
	:shouldCancelStart='cancelSort'
	:lockToContainerEdges='true'
	@sort-end='onSortEnd'
>
	<div class='exerciseBrowserElements'>
		<CreateButton 
			v-if='showCreate' 
			@copy='onCopy' 
			@create='onCreate' 
			@move='$emit("move")'
			:showCreateStack='showCreateStackButton'
			:canMoveSelectionToStack='canMoveSelectionToStack'
		/>
		<template v-for='(elementKey, index) in elementKeys'>
			<SlickItem 
				:key='elementKey' 
				:index='index'
			>
				<Exercise 
					v-if='$store.getters["elements/isExercise"](elementKey)'
					:element='$store.state.elements.root[elementKey]'
					:selected='$store.getters["exerciseBrowser/selected"](elementKey)'
					:selecting='$store.getters["exerciseBrowser/selecting"](elementKey)'
					:detail='$store.getters["exerciseBrowser/detail"](elementKey)'
					@showContextMenu='e=>$emit("showContextMenu",e)'
				/>
				<Stack 
					v-else-if='$store.getters["elements/isStack"](elementKey)'
					:element='$store.state.elements.root[elementKey]'
					:selected='$store.getters["exerciseBrowser/selected"](elementKey)'
					:selecting='$store.getters["exerciseBrowser/selecting"](elementKey)'
					:detail='$store.getters["exerciseBrowser/detail"](elementKey)'
					@showContextMenu='e=>$emit("showContextMenu",e)'					
				/>
			</SlickItem>
			<ElementDetail 
				@copy='$emit("copy")'			
				v-if='detail(elementKey)' 
				:key='`detail-${elementKey}`' 
				:elementKey='elementKey' 
				:folderData='folderData'
				:canMoveSelectionToStack='canMoveSelectionToStack' 
				:canMoveSelectionOutOfStack='canMoveSelectionOutOfStack'
				@moveElementsIntoStack='v=>$emit("moveElementsIntoStack", v)'	
				@moveElementsOutOfStack='v=>$emit("moveElementsOutOfStack", v)'				
				@showContextMenu='e=>$emit("showContextMenu",e)'																		
			/>
		</template>
	</div>
</SlickList>
</template>

<script>
import { SlickList, SlickItem } from '@/libs/slickSort'
import Exercise from './Exercise'
import Stack from './Stack'
import ElementDetail from './ElementDetail'
import CreateButton from './CreateButton'

export default {
	name: 'ElementsBase',
	props: ['elementKeys','folderData','canMoveSelectionToStack','canMoveSelectionOutOfStack'],
	components: {
		Exercise,
		Stack,
		ElementDetail,
		SlickList,
		SlickItem,
		CreateButton
	},
	data: () => ({
		isSorting: false
	}),
	methods: {
		cancelSort(e) {
			return e.ctrlKey || e.metaKey
		},
		detail(elementKey) {
			return this.$store.getters['exerciseBrowser/detail'](elementKey)
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserElements {
	display: grid;
	grid-gap: 1px; 
	grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	grid-auto-flow: row dense;
	align-items: stretch;
	margin-left: 1px;
	margin-right: $size-gutter * 2 + 1;
	padding: 1px 0;
	position: relative;

	&:empty { border: none; }

	.exerciseBrowserElements {
		/* grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); */
		margin: 0;
		padding: 1px !important;
	}

	>div:not(.exerciseBrowserElementDetail) {
		z-index: 1;
	}
}
</style>