var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saveTemplatesList"},[_c('div',{staticClass:"content"},[_c('RecycleScroller',{staticClass:"recycleScroller",attrs:{"items":_vm.items,"item-size":40,"buffer":500},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ListItem',{attrs:{"item":item,"itemKey":item.id,"path":_vm.pathTo(item.folderId)}})]}}])},[_c('template',{slot:"before"},[_c('div',{staticClass:"fullPath bubble"},[_c('div',{staticClass:"content"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pathTo(_vm.folderId, true))}})])]),_c('ResultInfo',{attrs:{"total":_vm.total}})],1),_c('template',{slot:"sticky"},[(_vm.items.length)?_c('TableColumns',{attrs:{"module":"saveTemplates","columns":[{
						label: _vm.$t("elements.cols.exerciseSet"),
						selectable: false,
						flex: 1
					},{
						label: _vm.$t("elements.cols.lastUpdated"),
						selectable: false,
						flexBasis: "179px",
					}]}}):_vm._e()],1),_c('template',{slot:"after"},[_c('AtBottom',{attrs:{"action":_vm.fetchNext}})],1)],2)],1),_c('div',{staticClass:"saveAsWrapper"},[_c('SaveAsInput',{model:{value:(_vm.savenameData),callback:function ($$v) {_vm.savenameData=$$v},expression:"savenameData"}})],1),_c('div',{staticClass:"footerPanel modalControls"},[_c('CancelButton',{on:{"click":function($event){return _vm.$emit("close")}}}),_c('div',{staticClass:"saveButton iconTextButton success",on:{"click":function($event){return _vm.$emit("save", _vm.savenameData)}}},[_c('svg',{staticClass:"smallIcon"},[_c('use',{attrs:{"xlink:href":"#save"}})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('elements.buttons.save'))+" "+_vm._s(_vm.savenameData || _vm.$t('elements.labels.untitled')))])])],1),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.working),expression:"working"}]})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }