<template>
<SmallModal class='saveExerciseSetModal' @close='$emit("close")'>
	<template v-slot:title>{{$t("views.saveNewSet.title")}}</template>
	<template v-slot:message>
		<p>{{$t("views.saveNewSet.p1")}}</p>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='saveToFolder'>
			<svg class='smallIcon'>
				<use xlink:href='#templates' />
			</svg>
			<span class='label'>{{$t("views.saveNewSet.toFolder")}}</span>
		</div>
		<div class='iconTextButton primary' @click='saveToClient'>
			<svg class='smallIcon'>
				<use xlink:href='#clients' />
			</svg>
			<span class='label'>{{$t("views.saveNewSet.toClient")}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import SmallModal from '@/components/common/modals/SmallModal'
import AlertModal from '@/components/common/modals/Alert'
import SaveTemplate from './saveTemplate/Save'
import SaveClientSet from './saveClientSet/Save'

export default {
	name: 'SaveNewSet',
	mixins: [smallModalMixin, largeModalMixin],
	components: {
		SmallModal
	},
	props: ['setKey'],
	computed: {
	},
	methods: {
		saveToFolder() {
			if (this.$store.state.sets.root[this.setKey].elements.length > 100) {
				this.$emit('close')
				this.showSmallModal(AlertModal, {
					title: this.$t('views.warnCannotSaveToFolder.title'),
					message: this.$t('views.warnCannotSaveToFolder.p1')
				})
			} else {
				this.$emit('close')
				this.$store.state.main.mobile ? this.showLargeModal(SaveTemplate, { setKey: this.setKey }) : this.showMediumModal(SaveTemplate, { setKey: this.setKey }) 
				/*
				this.$store.dispatch('saveFolderSets/clearList')
					.then(() => this.$store.dispatch('folders/clear'))
					.then(() => this.$store.dispatch('folders/fetchFolders', 'save'))
					.then(([err, result]) => {
						if (!err) {
							const { userTree, teamTree, orgTree } = result
							this.$emit('close')
							this.showLargeModal(SaveFolderSet, { setKey: this.setKey, userTree, teamTree, orgTree }) 
						}
					})
				*/
			}
		},
		saveToClient() {
			this.$emit('close')
			if (this.$store.state.sets.root[this.setKey].elements.length > 50) {
				this.showSmallModal(AlertModal, {
					title: this.$t('views.warnCannotSendToClient.title'),
					message: this.$t('views.warnCannotSendToClient.p1')
				})
			} else {
				this.$store.state.main.mobile ? this.showLargeModal(SaveClientSet, { setKey: this.setKey }) : this.showMediumModal(SaveClientSet, { setKey: this.setKey }) 

				//this.showLargeModal(SaveClientSet, { setKey: this.setKey }) 
			}
		}
	},
	mounted() {
		if (!this.$store.state.profile.user.allowPHI) {
			setTimeout(()=>this.saveToFolder(), 0)
		}
	}
}
</script>

<style lang='scss'>
.saveExerciseSetModal {
}
</style>