<template>
	<div class='stackThumbnails' :class='{ multiple: exerciseKeys.length > 1 }'>
		<template v-for='exerciseKey in exerciseKeys'>
			<LazyImage
				v-if='thumbnailUrl(exerciseKey)' 
				:src='thumbnailUrl(exerciseKey)'
				:key='exerciseKey' 
				:class='{ flipImages: flip(exerciseKey) }'
				class='stackThumbnail'
			/>
		</template>
	</div>
</template>

<script>
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'StackThumbnail',
	props: ['elementKey'],
	components: {
		LazyImage
	},
	computed: {
		exerciseKeys() {
			const exerciseKeys = this.$store.state.elements.root[this.elementKey].exercises
			return exerciseKeys.length <= 4 ? exerciseKeys : exerciseKeys.slice(0, 4)
		}
	},
	methods: {
		thumbnailUrl(exerciseKey) {
			return this.$store.getters['elements/getThumbnailUrl'](exerciseKey)
		},
		flip(exerciseKey) {
			return this.$store.state.elements.root[exerciseKey].reflect
		}
	}
}
</script>

<style lang='scss'>
.stackThumbnails {
	pointer-events: none;
	@include absTopLeft;
	width: 100%;
	height: 100%;
	background: $color-white;

	&.multiple {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		width: 100%;		
		height: 100%;
		grid-gap: $size-gutter;
		padding: $size-gutter;
	}

	.stackThumbnail {
		position: relative;
		width: 100%;
		padding-top: $size-aspectRatio-thumbnail;

		img {
			@include absTopLeft;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}		
	}
}
</style>


