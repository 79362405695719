<template>
<div v-handle class='sortHandle' v-on='$listeners'>
	<svg class='smallIcon'>
		<use :xlink:href='icon' />
	</svg>
</div>
</template>

<script>
import { HandleDirective } from '@/libs/slickSort'

export default {
	name: 'SortHandle',
	props: ['axes'],
	computed: {
		icon() {
			if (this.axes === 'x') return '#dragX'
			else if (this.axes === 'y') return '#dragY'
			else if (this.axes === 'xy') return '#dragXY'
			else return '#drag'
		}
	},
	directives: {
		handle: HandleDirective
	}
}
</script>

<style lang='scss'>
.sortHandle {
	position: relative;
	color: $color-neutral-dark;
	transition: color .2s linear;
	cursor: grab;

	&:hover {
		color: $color-accent !important;
	}
}
</style>
