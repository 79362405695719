<template>
	<div 
		class='favourite' 
		@touchstart.stop='toggle'
		v-on='!$store.state.main.mobile ? { click: toggle } : {}'					
	>
		<svg 
			class='miniIcon' 
			:class='{ favourited }'
		>
			<use xlink:href='#star' />
		</svg>
	</div>
</template>

<script>
export default {
	name: 'Favourite',
	props: ['elementKey'],
	computed: {
		favourited() {
			return !!this.$store.state.elements.root[this.elementKey].favourite
		}
	},
	methods: {
		toggle(e) {
			e.stopPropagation()
			this.$store.dispatch('exerciseBrowser/setFavourite', {
				key: this.elementKey,
				value: !this.favourited
			})
		}
	}
}
</script>

<style lang='scss'>
.favourite {
	cursor: pointer;
	position: relative;
	color: $color-neutral-dark;
	&:before {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		content: ''
	}


	> svg {
		transition: color .2s linear;
		cursor: pointer;
	}

	> .favourited {
		color: $color-accent;
	}

	&:only-child {
		margin-left: auto;
	}
}
</style>


