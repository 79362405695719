<template>
<div class='resourceInspectorSwatches'>
	<div 
		v-for='(swatch, index) in swatches' 
		:style='`background: ${swatch}`' 
		:key='index'
		:class='{ selected: selected === index }'
		@pointerdown='e => pickSwatch(index, e)'
		class='swatch' 
	>
		<div class='dot' :style='` width: ${r*2}px; height: ${r*2}px;`' />
	</div>
</div>
</template>

<script>
export default {
	name: 'ResourceInspectorSwatches',
	components: {},
	data() { 
		return {
			swatches: ['#000000','#FFFFFF','#CC0001','#E36101','#FFCC00','#009900','#0066CB'],
			selected: this.$store.state.main.mobile ? null : 0,
			r: 6,
			startX: 0,
			offsetR: 0
		}
	},
	computed: {
	},
	methods: {
		addListeners: function() {
			document.addEventListener('pointermove', this.setBrushSize)
			document.addEventListener('pointerup', this.removeListeners)
		},
		removeListeners: function() {
			document.removeEventListener('pointermove', this.setBrushSize)
			document.removeEventListener('pointerup', this.removeListeners)
		},
		setBrushSize: function(e) {
			const r = Math.floor(this.offsetR + (e.clientX - this.startX)/10)
			if (r < 2) this.r = 2
			else if (r > 20) this.r = 20
			else this.r = r
			this.$emit('setRadius', this.r)
		},
		pickSwatch(index, e) {
			const pageX = e.pageX ? e.pageX : e.touches[0].pageX
			this.startX = pageX
			this.offsetR = this.r
			this.addListeners()
			this.$emit('setSwatch', this.swatches[index])
			this.selected = index
		}
	}
}
</script>

<style lang='scss'>
.resourceInspectorSwatches {
	overflow: hidden;
	
	border: 1px solid black;

	.swatch {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 42px;
		height: 40px;
		cursor: pointer;

		.dot {
			display: none;
			background: #ffffff;
			border-radius: 50%;
		}

		&:nth-child(2) {
			.dot { background: #000000; }
		}

		&.selected .dot {
			display: block;
		}
	}
}
</style>

