<template>
<div class='emailsInput'>
	<VueTagsInput
		v-model='tag'
		:tags='tags'
		:separators='[";", ",", " "]'
		:add-on-key='[";", ",", " ", 13]'
		:placeholder='placeholder'
		:maxlength='maxlength'
		:max-tags='maxtags'
		@tags-changed='onChange'
		@before-adding-tag='checkTag'
	/>
</div>
</template>

<script>
import { isEmail } from '@/utilities'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
	name: 'EmailsInput',
	components: {
		VueTagsInput
	},
	props: ['value', 'placeholder', 'maxlength', 'maxtags'],
	data() {
		return {
			tag: '',
			tags: this.parseValue(this.value)
		}
	},
	methods: {
		onChange(newTags) {
			this.tags = newTags
			const condensed = this.tags.map(tag => tag.text)
			this.$emit('input', condensed)
		},
		checkTag(obj) {
			obj.tag.text = obj.tag.text.trim()
			if (isEmail(obj.tag.text)) obj.addTag()
			else return false
		},
		parseValue(value) {
			return value ? value.map(text => ({ text })) : []
		},
		reset() { 
			this.tag = ''
			this.tags = []
		}
	},
	watch: {
		value(value) {
			this.tags = this.parseValue(value)
		}
	}
}
</script>

<style lang='scss'>
body .emailsInput {

	.vue-tags-input {
		max-width: 100%;
	}

	.ti-new-tag-input-wrapper {
		padding: 0;
		margin: 0;
		flex: 0 0 100%;

		input {
			height: $size-control-height;
			padding-left: $size-gutter * 2;
			padding-right: $size-gutter * 2;
		}
	}

	.ti-tags {
		padding-bottom: 0;
	}

	.ti-tag {
		padding: 0;
		margin: 0;
		height: 23px;
		border-radius: 12px;
		padding-left: $size-gutter * 2;
		padding-right: 3px;
		background: $button-gradient-primary;
		font-size: $size-font-small;
		font-weight: bold;
		margin-top: 3px;
		margin-left: 3px;

		&.ti-deletion-mark, &.ti-deletion-mark i {
			background: $button-gradient-alert;
		}

		&:nth-last-child(2) {
			margin-bottom: 3px;
		}
	}

	.ti-content { 
		line-height: 23px;
	}

	.ti-actions {
		margin: 0;
		padding-left: $size-gutter;

		i {
			width: 17px;
			height: 17px;
			border-radius: 9px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $color-primary;
			font-weight: bold;
			margin: 0;
			padding: 0;
		}
	}


	.ti-input {
		border: none;
		padding: 0;
		margin: 0;
	}
}
</style>

