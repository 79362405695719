<template>
<div class='clientPanel'>

	<div class='headerPanel'>
		<ClientInfo :client='client' />
	</div>

	<div class='contentPanel'>
		<ScrollForever
			:itemHeight='41'
			:itemKeys='clientSetKeys'
			:itemComponent='itemComponent'
			:fetchNext='fetchNext'
			:loading='loading'
		>
			<template slot='header'>
				<!--<ResultInfo v-if='totalSets' :total='totalSets' :foundPhrase='$t("elements.results.clientsSets")' />-->
			</template>
			<template slot='sticky'>
				<ClientSetsHeader v-if='totalSets' module='saveClientSets' />
			</template>
		</ScrollForever>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</div>

	<SaveAsInput ref='saveAsInput' v-model='savenameData' />
	<div class='signMessage' v-html='signMessage'></div>	

	<div class='footerPanel modalControls'>
		<CancelButton @click='$emit("close")' />
		<div class='saveButton iconTextButton success' @click='save'>
			<svg class='smallIcon'>
				<use xlink:href='#save' />
			</svg>
			<span class='label' v-html='$t("elements.buttons.signsave") + " " + (savenameData || $t("elements.labels.untitled"))' />
		</div>
	</div>

</div>
</template>

<script>
import { fill } from '@/utilities'
import { defaultModalAction } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import ResultInfo from '@/components/common/ResultInfo'
import ScrollForever from '@/components/common/ScrollForever'
import CancelButton from '@/components/common/buttons/Cancel'
import SaveAsInput from '@/components/common/SaveAsInput'
import ClientInfo from '@/components/clinician/clients/ClientInfo'
import ClientSetsHeader from './ClientSetsHeader'
import ClientSetRow from './ClientSetRow'

export default {
	name: 'clientPanel',
	mixins: [defaultModalAction],
	components: {
		Loading,
		ResultInfo,
		ScrollForever,
		ClientSetsHeader,
		ClientInfo,
		SaveAsInput,
		CancelButton
	},
	props: ['savename'],
	data() {
		return {
			itemComponent: ClientSetRow,
			savenameData: this.savename
		}
	},
	computed: {
		loading() { return this.$store.state.saveClientSets.apiPending },
		clientSetKeys() { return this.$store.state.saveClientSets.list },
		totalSets() { return this.$store.state.saveClientSets.meta.total },
		client() { return this.$store.getters['saveClients/detail'] },
		user() { return this.$store.state.profile },		
		signMessage() {
			return fill(this.$t('views.updateClientSet.p1'), {
				user: this.user.firstname +' '+ this.user.lastname,
				client: this.client.firstname +' '+ this.client.lastname   
			})
		}		
	},
	methods: {
	//	doDefault() { this.save() },
		fetchTop() { this.$store.dispatch('saveClientSets/fetchTop') },
		fetchNext() { this.$store.dispatch('saveClientSets/fetchNext') },
		save() { this.$emit('save', this.savenameData) }
	},
	watch: {
		client(newClient, oldClient) {
			if (newClient.key && newClient.key !== oldClient.key) {
				this.$store.dispatch('saveClientSets/setClient', newClient.key)
				this.$refs.saveAsInput.focus()
			}
		}
	},
	mounted() {
		this.$store.dispatch('saveClientSets/setClient', this.client.key)
	}
}
</script>

<style lang='scss'>
.saveClientSet .clientPanel {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.headerPanel {
		padding-bottom: 0;
	}

	.clientInfo {
		padding-left: $size-gutter * 4;
		padding-right: $size-gutter * 2;		
		header {  height: 42px; margin-top: $size-gutter * 2; padding: 0; }
		height: 156px;
	}
	.contentPanel {
		position: relative;

		.resultInfo {
		}
	}

	.saveAsInput {
		margin-top: $size-gutter * 2;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 2;
	}

	.signMessage {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		margin: 0 $size-gutter * 4;
		margin-top: $size-gutter * 2;
		margin-bottom: $size-gutter * 4;
	}


	.footerPanel {
		margin-top: $size-gutter * 2;
	}
}
</style>
