<template>
<div class='pdfImages' :class='[$store.state.setPdf.imgs]'>
	<div
		v-for='(element, index) in set.elements'
		:key='index'
		:class='["element",element.type]'
	>
		<div class='header'>
			<div class='title'>{{element.title}}</div>
			<div v-if='element.type === "setExercise"' class='parameters'>
				<div 
					v-for='(parameter, index) in element.parameters.filter(v => v.value)' 
					:key='index'
				>
					<b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' />
				</div>
			</div>
		</div>

		<div 
			v-if='element.type === "setExercise"' 
			class='resources'
			:class='{ flipImages: element.reflect }'
		>
			<div 
				v-for='(resource, index) in element.resources.filter(r => r.resourceId && $store.state.resources.resources[r.resourceId].type.includes("Image"))' 
				:key='index'
				class='resource'
			>
				<div class='thumbWrapper'>
					<div class='thumb'>
						<LazyImage 
							v-if='$store.getters["resources/getResource"](resource.resourceId)' 
							:src='$store.getters["resources/getResource"](resource.resourceId)' 
							:contain='true'
						/>
					</div>
				</div>
				<div class='caption' v-html='resource.caption' />
			</div>
		</div>

	</div>
</div>
</template>

<script>
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'PreviewComprehensive',
	components: { LazyImage },
	props: ['setKey', 'selectedExs'],
	computed: {
		set() { 
			return this.$store.getters['sets/getSetForExport'](this.setKey, this.selectedExs) 
		},
		imgs() { return this.$store.state.setPdf.imgs }
	},
	watch: {
		imgs() { this.$nextTick(() => this.$emit('resize')) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfImages {
	width: 1000px;

	.title { font-weight: bold; }

	.setDivider {

		.title {
			border-bottom: 8px solid $color-primary-accent;	
		}

		&:not(:first-child) {
			padding-top: 40px;
			border-top: 1px dashed $color-neutral-shadow;
		}
	}

	.setExercise {

		.title {
			padding-right: 16px;
		}

		.thumbWrapper {
			position: relative;
			padding-bottom: 75%;

			.thumb {
				@include absTopLeft;
				@include fill;
			}
		}

		.parameters {
			display: flex;
			flex-wrap: wrap;

			> div {
				white-space: nowrap;
				border-right: 1px solid $color-neutral-shadow;
				padding-right: 16px;
				margin-right: 16px;

				&:last-child {
					border-right: none !important;
					padding-right: 0 !important;
					margin-right: 0 !important;
				}
			}
		}

		.resources {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			.caption {
				text-align: center;
			}
		}
	}

	&.std {

		.setExercise {
			border: 1px solid $color-neutral-shadow;
			margin-bottom: 16px;

			.header {
				display: flex;
				align-items: center;
				padding: 20px 20px 21px 20px;
				background: $color-neutral-silver;
				
				.title { font-size: 20px; }

				.parameters {
					font-size: 18px;
					margin-left: auto;
				}
			}

			.resources {
				padding: 6px 8px 6px 8px;

				.resource {
					padding: 10px 8px;
					width: 20%;
					
					&:first-child:nth-last-child(1) { width: 33%; }

					&:first-child:nth-last-child(2),
					&:first-child:nth-last-child(2) ~ div { width: 33%; }

					&:first-child:nth-last-child(3),
					&:first-child:nth-last-child(3) ~ div { width: 33%; }

					&:first-child:nth-last-child(4),
					&:first-child:nth-last-child(4) ~ div { width: 25%; }
				}

				.caption {
					font-size: 18px;
					line-height: 22px;
					padding: 10px 0;
				}
			}
		}

		.setDivider {

			.title {
				font-size: 26px;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 16px;
			}
		}
	}

	&.lrg {

		.setExercise {
			border: 1px solid $color-neutral-shadow;
			margin-bottom: 16px;

			.header {
				display: flex;
				align-items: center;
				padding: 20px 30px 21px 30px;
				background: $color-neutral-silver;
				
				.title { font-size: 30px; }

				.parameters {
					font-size: 26px;
					margin-left: auto;
				}
			}

			.resources {
				padding: 10px 12px 10px 12px;

				.resource {
					padding: 10px 8px;
					width: 25%;
					
					&:first-child:nth-last-child(1) { width: 33%; }

					&:first-child:nth-last-child(2),
					&:first-child:nth-last-child(2) ~ div { width: 33%; }

					&:first-child:nth-last-child(3),
					&:first-child:nth-last-child(3) ~ div { width: 33%; }

				}

				.caption {
					font-size: 26px;
					padding: 10px 0;
				}
			}
		}

		.setDivider {
			margin-bottom: 10px;

			.title {
				font-size: 30px;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 16px;
			}

		}
	}

	&.max {

		.setExercise {
			border: 1px solid $color-neutral-shadow;
			margin-bottom: 16px;

			.header {
				padding: 20px 30px 21px 30px;
				background: $color-neutral-silver;
				
				.title { font-size: 40px; }

				.parameters {
					padding-top: 10px;
					font-size: 32px;
				}
			}

			.resources {
				padding: 10px 12px 10px 12px;

				.resource {
					padding: 10px 8px;
					width: 33%;
				}

				.caption {
					font-size: 32px;
					padding: 10px 0;
				}
			}
		}

		.setDivider {
			margin-bottom: 10px;

			.title {
				font-size: 40px;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 16px;
			}
		}
	}
}
</style>
