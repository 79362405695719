<template>
<div class='designer' :class='{ setColumns: columns && $store.state.setBuilder.list.length }'>

<!--
		<Categories ref='categories' />
	</div>
-->

	<div ref='main' class='main' :class='{ wide: (showCategories && $store.state.exerciseBrowser.tab==="stock") || (showFolders && $store.state.exerciseBrowser.tab==="custom") }'>
		<div class='leftPanel'>
			<transition name='rtltr'>
				<div v-if='$store.state.setBuilder.pin' class='setWrapper'>
					<SetTab :setKey='$store.state.setBuilder.pin' :selected='true' />
					<Set :setKey='$store.state.setBuilder.pin' />
				</div>
			</transition>
			<router-view 
				name='exerciseBrowser' 
				:columns='columns' 
				:showCategories='showCategories' 
				:showFolders='showFolders' 
				@toggleCategories='showCategories=!showCategories'
				@toggleFolders='showFolders=!showFolders'
			/>
		</div>

		<div class='rightPanel'>
			<router-view name='setBuilder' :columns='columns' :showLayoutButton='mainElWidth > 1260 && $store.state.setBuilder.list.length && !$store.state.bus.bussing' />
		</div>
	</div>

	<div class='rightPullOut' :class='{ pullOutOff: !bulkParameters }'>
		<BulkParameters v-if='bulkParameters' ref='bulkParameters' />
	</div>

</div>
</template>

<script>
import Cookies from 'js-cookie'	
import { onResizeMixin } from '@/components/common/mixins/onResize'
import Categories from './categories/Categories'
import BulkParameters from './bulkParameters/BulkParameters'
import Set from './setBuilder/Set'
import SetTab from './setBuilder/SetTab'

export default {
	name: 'Designer',
	mixins: [onResizeMixin],
	components: {
		BulkParameters,
		Categories,
		Set,
		SetTab
	},
	data: () => ({ 
		mainElWidth: null,
		showCategories: true,
		showFolders: true
	}),
	computed: {
		bulkParameters() {
			return this.$store.state.bulkParameters.on && this.$store.state.setBuilder.list.length
		},
		categories() {
			return this.$store.state.categories.on
		},
		columns() {
			return (
				this.$store.state.setBuilder.list.length && 
				this.$store.state.setBuilder.columns && (
					(!this.$store.state.categories.on && (this.mainElWidth || 0) > 1260) ||
					(this.$store.state.categories.on && (this.mainElWidth || 0) > 1400)
				)
			)
		}
		/*
		mainStyle() {
			if (!(this.$store.state.setBuilder.list.length - (this.$store.state.setBuilder.pin ? 1 : 0))) return 'max-width: 100%'
			else if (this.$store.state.setBuilder.columns) return 'max-width: 2160px'
			else return 'max-width: 1440px'
		}
	*/
	},
	methods: {
		onResize() {
			this.$nextTick(() => this.mainElWidth = this.$refs.main.offsetWidth)
		}
	},
	watch: {
		bulkParameters(newValue, oldValue) {
			if (newValue !== oldValue) this.onResize()
			if (newValue) setTimeout(() => this.$refs.bulkParameters.$el.scrollIntoView(), 0)
		},
		categories(newValue, oldValue) {
			if (newValue !== oldValue) this.onResize()
		//	if (newValue) setTimeout(() => this.$refs.categories.$el.scrollIntoView(), 0)				
		},
		showCategories(a,b) {
			if (a !== b) {
				this.onResize()
				const o = { categories: !!this.showCategories, folders: !!this.showFolders }
				//console.log('boop')
				Cookies.set(`${this.$store.state.profile.user.id}-designer-ui`, o)
			}
		},
		showFolders(a,b) {
			if (a !== b) {
				this.onResize()
				const o = { categories: !!this.showCategories, folders: !!this.showFolders }		
				//console.log(o)		
				Cookies.set(`${this.$store.state.profile.user.id}-designer-ui`, o)				
			}
		}
	/*
		mainElWidth(value) {
			if (
				value < 1260 && 
				this.$store.state.setBuilder.list.length &&
				this.$store.state.setBuilder.columns
			) this.$store.dispatch('setBuilder/toggleColumns')
		}
	*/
	},
	mounted() {
		const o = Cookies.getJSON(`${this.$store.state.profile.user.id}-designer-ui`)
		console.log(o)
		if (o) {
			this.showCategories=o.categories
			this.showFolders = o.folders
		}
	}
}
</script>

<style lang='scss'>
.designer {
	@include absTopLeft;
	@include fill;
	display: flex;
	background: $color-white;
	overflow: scroll;
	&::-webkit-scrollbar { display: none; }
	scrollbar-width: none;

		background: repeating-linear-gradient(
			180deg,
			darken($color-neutral, 5%),
			darken($color-neutral, 5%) 14px,
			$color-neutral-silver 14px,
			$color-neutral-silver 28px
		);
		background-position: 0 -1px;



	> .main {
		flex: 1;
		display: grid;
		grid-gap: 1px;
		grid-template-rows: 1fr;
		min-width: 940px;
/*		grid-template-columns: auto minmax(calc(100% - 720px), calc(50%)); */


		grid-template-columns: auto minmax(calc(100% - 805px), calc(50%));
		&.wide { 
			grid-template-columns: auto minmax(calc(100% - 945px), calc(42.25%)); 
		}
		
		>.leftPanel {
			position: relative;
			z-index: 600;

			.setWrapper {
				@include absTopLeft;
				@include fill;
				z-index: 600;
				display: grid;
				grid-template-rows: auto 1fr;

				.setTab {
					border-right: none;
				}
			}
		}

		>.rightPanel {
			position: relative;			
			min-width: 0;
		}
	}

	&.setColumns > .main {
	/*	grid-template-columns: auto minmax(calc(100% - 720px), calc(66.66%)); */
			grid-template-columns: 1fr 2fr;
			&.showCategories { 
				grid-template-columns: 1.32fr 2fr;
			}			

	}

	> .leftPullOut, > .rightPullOut {
		flex: 0;
		flex-basis: 13%;
		min-width: 240px;
		max-width: 320px;
		position: relative;

		&.pullOutOff {
			flex-basis: 0;
			max-width: 0;
			min-width: 0;			
		}
	}
}

.designer, .sortHelper {
	img {
		transition: transform .2s linear !important;
	}
}
</style>