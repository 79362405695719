<template>
<div class='clientRow' :class='{ selected }' @click.stop='onClick' @dblclick.stop='onDblClick'>
	<div class='lastnameCol'>
		<span>
			<span><span>{{client.lastname}}, {{client.firstname}}</span><br /><span class='email'>{{client.email}}</span></span>
			<span v-if='client.emr' class='tag success'>{{$store.state.profile.emr.title.charAt(0).toUpperCase() + $store.state.profile.emr.title.slice(1)}}</span>
		</span>
	</div>
	<!--
	<div class='emailCol'>
		<span>{{client.email}}</span>		
	</div>
-->
	<div class='clinicianCol'>
		<span>{{client.clinicianName}}</span>		
	</div>
	<!--<div class='setCountCol'>{{client.setCount}}</div> -->
<!--	<div class='createdCol'>{{created}}</div> -->
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ClientModal from '@/components/clinician/clients/modals/Client'

export default {
	name: 'SaveClientRow',
	mixins: [dateTime, smallModalMixin],
	props: ['itemKey'],
	computed: {
		client() { return this.$store.state.clients[this.itemKey] },
		created() { return this.date(this.client.created) },
		selected() { return this.$store.getters['saveClients/selected'](this.client.key) }
	},
	methods: {
		onClick() {
			setTimeout(() => this.$store.dispatch('saveClients/setSelected', this.client.key), 0) // setTimeout to avoid ios keyboard
		},
		onDblClick(e) {
			if (e.ctrlKey || e.metaKey) return
			this.showSmallModal(ClientModal, { client: this.client })
		}
	}
}
</script>

<style lang='scss'>
@import '@/components/clinician/clients/scss/clientRow.scss';
</style>