<template>
<div class='exerciseBrowserControls'>
	<div class='controlBar'>
		<template v-if='disabled'>
			<div class='locked iconTextButton'>
				<svg class='smallIcon'>
					<use xlink:href='#lock' />
				</svg>
				<span class='label'>{{$t("views.designer.msgReadOnlyExercise")}}</span>
			</div>
		</template>
		<template v-else>
			<HistoryButton v-if="exercise.type !== 'stockExercise'" @click='showHistory' />
			<FlipImagesButton v-if='canFlip' @click='flipImages' :flip='exercise.reflect' />	
			<EnabledButton @click='save' :disabled='!exercise.fresh' :svg='"#save"' :label='$t("elements.buttons.save")' class='neutral tagSuccess' />
			<!--
			<div 
				class='iconTextButton neutral' 
				v-tap='copy'
			>
				<svg class='smallIcon'>
					<use xlink:href='#copy' />
				</svg>
				<span class='label'>{{$t("elements.buttons.copy")}}</span>
			</div>					
		-->
			<DeleteButton v-if='canDelete' @click='confirmDelete' />
		</template>
	</div>
</div>
</template>

<script>
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import FlipImagesButton from '@/components/clinician/designer/common/FlipImagesButton'
import EnabledButton from '@/components/common/buttons/Enabled'
import DeleteButton from '@/components/common/buttons/DeleteBlock'
import HistoryButton from '@/components/common/buttons/History'
import CustomExerciseHistory from './modals/CustomExerciseHistory'

export default {
	name: 'ExerciseControls',
	props: ['exerciseKey', 'disabled', 'onRestored'],
	components: {
		FlipImagesButton,
		EnabledButton,
		DeleteButton,
		HistoryButton
	},
	mixins: [smallModalMixin, largeModalMixin],
	computed: {
		exercise() {
			return this.$store.state.elements.root[this.exerciseKey]
		},
		canDelete() {
			return this.exercise.type !== 'stockExercise'
		},
		canFlip() {
			return !!this.exercise.resources.length
		},
		thumbnailUrl() {
			const r = this.exercise.resources[0]
			const rId = r ? this.$store.state.elements.resources[r].resourceId : null
			return rId ? this.$store.getters['resources/getResource'](rId) : null			
			//return this.$store.getters['resources/getResource'](this.exercise.thumbnailId)
		},
		showUnsetThumbnail() {
			return (
				this.exercise.thumbnailId &&
				this.exercise.resources.length 
				/*
				this.exercise.resources
					.map(key => this.$store.state.elements.resources[key].resourceId)
					.find(key => this.$store.state.resources.resources[key] && this.$store.state.resources.resources[key].type === 'userImage')
				*/
			)
		}
	},
	methods: {
		unsetThumbnail() {
			this.$store.dispatch('elements/unsetThumbnail', this.exercise.key)
		},
		flipImages() {
			this.$store.dispatch('elements/toggleReflect', this.exercise.key)
		},
		copy() {
			const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy']([this.exerciseKey])
			this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks }).then(() => this.$emit('copy'))
		},		
		save() {
//			this.$emit('saved')

			if (this.exercise.fresh) this.$store.dispatch('exerciseBrowser/save', this.exercise.key).then(() => {
				// careful here, if you replace the element too soon the event wont propagate
				this.$emit('saved')
				if (this.$store.state.exerciseBrowser.tab!=='stock') this.$store.dispatch('flash/showAction', 'saved')

			})


		},
		confirmDelete() {
			const fn = () => this.$store.dispatch('exerciseBrowser/destroy', this.exercise.key)
			this.showSmallModal(ConfirmDeleteModal, { items: this.exercise.title, fn })
		},
		showHistory() {
			this.showMediumModal(CustomExerciseHistory, { exerciseId: this.exercise.id, onRestored: this.onRestored }) 
		}
	}
}
</script>

<style lang='scss'>
body .exerciseBrowserControls {
	.locked {
		flex: 3;
	}
}
</style>