<script>
import { uniq } from 'lodash'
import { Distance } from '@/utilities'
import SortButton from '@/components/common/buttons/Sort'
import CheckButton from '@/components/common/buttons/Check'
import CloseButton from '@/components/common/buttons/Close'

export default {
	name: 'ElementBase',
	props: ['element', 'selected', 'selecting', 'detail', 'index'],
	components: {
		SortButton,
		CheckButton,
		CloseButton
	},
	data: () => ({
		newElement: false,
		animateDetail: false,
		distance: false
	}),
	computed: {
		title() {
			return this.element.title
		}
	},
	methods: {
		onMouseDown(e) {
			//if (e.target.closest('.sortHandle')) return
			if (
				!e.target.closest('.noSelect') && 
				(e.ctrlKey || e.metaKey || e.shiftKey)
			) {
				this.toggleSelected(e)
			} else if (e.which & e.which === 3) {
				if (!this.selected) {
					this.$store.dispatch('setBuilder/clearSelected').then(()=>this.toggleSelected())
				}
				this.$emit('showContextMenu', e)
				// this.addElementToSet()		
			} else {
				new Distance({
					onPull: () => this.onPull(e),
					onStart: () => this.distance = true,
					onStop: () => this.distance = false,
					e,
				})
			}
		},
		onPull(e) {
			const getIndex = key => this.$store.state.sets.root[this.$store.state.sets.elements[key].setKey].elements.indexOf(key)
			const getKeys = () => uniq(this.$store.state.setBuilder.selected.concat([this.element.key])).sort((a, b) => getIndex(a) - getIndex(b))			
			const rect = this.$el.getBoundingClientRect()
			const offset = this.$el.getClientRects()[0]			
			const pageX = e.pageX ? e.pageX : e.touches[0].pageX
			const pageY = e.pageY ? e.pageY : e.touches[0].pageY			
			if (!this.selected) this.$store.dispatch('setBuilder/clearSelected')
			const keys = getKeys()
			const options = Object.assign({
				data: keys,
				contentType: 'setElements',
				el: this.getClone()
			}, keys.length === 1 ? {
				startX: rect.x,
				startY: rect.y,
				screenX: pageX,
				screenY: pageY,
				originX: (e.clientX - offset.left) / rect.width * 100,
				originY: (e.clientY - offset.top) / rect.height * 100						
			} : null)
			this.$store.dispatch('bus/loadBus', options)
		},
		onPointerdown(e) {
			e.target.releasePointerCapture(e.pointerId)
		},		
		/*
		onSortStart() {
			if (this.detail) {
				const setKey = this.$store.state.sets.elements[this.element.key].setKey
				this.$store.dispatch('sets/setDetail', { key: setKey, value: null })
			}
		},
		*/
		toggleSelected: function(e) {
			if (e && e.shiftKey) this.$store.dispatch('setBuilder/selectUpTo', { key: this.element.key, list: this.$store.state.sets.root[this.element.setKey].elements })
			else this.$store.dispatch('setBuilder/toggleSelected', this.element.key)
		},
		toggleDetail(e) {
			if (
				!e.metaKey && !e.shiftKey && !e.ctrlKey &&
				this.$store.state.setBuilder.selected.length && 
				this.$store.state.sets.elements[this.$store.state.setBuilder.selected[0]].setKey === this.element.setKey
			) {
				return this.$store.dispatch('setBuilder/clearSelected')
			}
			setTimeout(() => {
				if (e.ctrlKey || e.metaKey || e.shiftKey || this.$store.state.bus.bussing) return 
				if (this.doubleClick) return
				this.doubleClick = true
				setTimeout(() => this.doubleClick = false, 500)
				setTimeout(() => {
					this.$store.dispatch('setBuilder/setSelected', [])
					this.$store.dispatch('sets/toggleDetail', this.element.key)
				}, 0)
				if (this.$store.state.sets.root[this.element.setKey].detail !== this.element.key) {
					this.animateDetail = true
					setTimeout(() => this.animateDetail = false, 1000)
					setTimeout(() => {
						this.$el.scrollIntoView()
					}, 0)
					this.$el.dispatchEvent(new Event('toggleDetail', { bubbles: true }))
				}
			}, 0)
		},
		getClone() {
			const box = this.$el.getBoundingClientRect()
			const clone = this.$el.cloneNode(true)
			clone.classList.remove('detail', 'selected', 'selecting', 'distance')
			clone.style.width = box.width + 'px'
			return clone
		},
        close() {
            this.$store.dispatch('setBuilder/removeElements', { elementKeys: this.selected ? this.$store.state.setBuilder.selected : [this.element.key] })
        },
	},
	beforeMount() {
		if (Date.now() - this.element.created < 1000) {
			this.newElement = true
			setTimeout(() => this.newElement = false, 500)
		}
	}
}
</script>

<style lang='scss'>
.mobile .setElement .checkButton {
	position: relative;
	z-index: 20;
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 150%;
		height: 100%;
		content: '';
	}
}


.setElement {
/*
	> div > div {
		border-bottom: 1px dashed $color-neutral;
	}
*/
	> div > div {
		display: flex;
		position: relative;
		min-height: $size-control-height * 1.5;

		.controlsPanel {
			display: flex;
			align-items: center;

			> div { display: flex; }

			padding-left: 16px + $size-gutter * 3; /* placeholder for sorthandle */
/*
			.sortHandle { padding: $size-gutter*2 $size-gutter $size-gutter*2 $size-gutter*2; }
*/
			.checkButton { 
				padding: $size-gutter*3 $size-gutter*2 $size-gutter*3 $size-gutter; 
				margin-top: -2px;
			}
		}

		.contentPanel {
			flex: 1;
			overflow: hidden;
		}
	}

	&.selected, &.selecting {
		> div > div { background: $color-focus !important; }
		> div > div > div { background: $color-focus !important; }
		.checkButton { color: $color-black !important; }
		.sortHandle { color: $color-neutral-shadow !important; transition: none; }
	}
/*
	&.detail { 
		z-index: 100; 
		transform: translate3d(0,0,20px); 
	}

	&.detail > div > div {
		z-index: 1;
		overflow: visible;
		&:after {
			content: '';
			position: absolute;
			width: 100px;
			left: calc(50% - 50px);
			bottom: -$size-gutter * 3 + .5px;
			height: $size-gutter * 3;
			background: $color-white;
			clip-path: polygon(50% 100%, 0 0, 100% 0);
		}
		filter: drop-shadow(0 1px 0 $color-neutral-shadow);
	}
*/
/*
	&.distance > div > div {
		&:before {
			@include absTopLeft;
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			border: 2px solid $color-focus;         
			pointer-events: none;
			animation: fadeIn 600ms linear;
		}

		&:after {
			transition: background 600ms linear; 
			background: $color-focus;
		}
	}
*/
/*
	&.selected.detail > div > div:after,
	&.selecting.detail > div > div:after {
		background: $color-focus !important;
	}
*/
	&.animateDetail + .setElementDetail > div {
		animation: slideDown 600ms $ease-out-quint;
	}
}

.bus .setElement {
	box-sizing: content-box; 
	overflow: hidden;
}

.bus .setElement > div {
	padding-bottom: 0;
	> div { margin: 0; }
}

.sortHelper .setElement {
	padding-top: 1px;
}
</style>