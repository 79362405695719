<template>
<div class='exerciseBrowserExerciseDetail' @mousedown.stop :key="renderKey"><div>

	<div @click="$store.dispatch('exerciseBrowser/setFolderId', exercise.folderId)" class='path' v-html='path' />

	<ExerciseControls :exerciseKey='exercise.key' :disabled='disabled' @copy='$emit("copy")' @saved='onSaved' :onRestored='onRestored' />

	<div class='grid'>

		<div class='a'>
			<TextInput 
				ref='titleInput'
				v-model='title' 
				:placeholder='$t("views.designer.phExerciseTitle")' 
				maxlength='100' 
				:disabled='disabled'
			/>
			<UnsetThumbnailButton v-if="exercise.thumbnailId" :thumbnailUrl="$store.getters['resources/getResource'](exercise.thumbnailId)" @click="$store.dispatch('elements/unsetThumbnail', exercise.key)" />
		</div>

		<div class='c'>
			<div ref='parameters'>
				<EditParameters 
					:parameters='parameters' 
					:getList='getParametersList' 
					:setList='setParametersList' 
					:disabled='disabled'
					@create='createParameter'
					@delete='deleteParameter'
					@setTitle='setParameterTitle'
					@setValue='setParameterValue'
				/>
			</div>
		</div>

		<div class='b'>
			<div ref='instructions'>
				<RichText 
					v-model='instructions' 
					:placeholder='$t("views.designer.phExerciseInstructions")' 
					:disabled='disabled'
					:maxlength='5000'
				/>
			</div>
		</div>

		<div class='d'>
			<div ref='resources'>
				<EditResources
					:resources='resources'
					:getList='getResourcesList' 
					:setList='setResourcesList' 
					:disabled='disabled'
					:flip='exercise.reflect'
					@sketch='sketchResource'
					@create='createResource'
					@delete='deleteResource'
					@setCaption='setResourceCaption'
					@inspectResource='showResourceInspector'
				/>
				<transition name='fade'><Loading v-if='loadingResources' /></transition>
			</div>
		</div>

	</div>

</div></div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'
import EditParameters from '@/components/clinician/designer/common/EditParameters'
import EditResources from '@/components/clinician/designer/common/EditResources'
import ExerciseControls from '@/components/clinician/designer/exerciseBrowser/ExerciseControls'
import { resourceInspectorMixin } from '@/components/clinician/designer/resourceInspector/mixin'
import UnsetThumbnailButton from '@/components/clinician/designer/common/UnsetThumbnailButton'

class Bloop {
	constructor(initialX, initialY, w, h) {
		const target = document.getElementById('customExercisesTab')
		const rTarget = target.getBoundingClientRect()
		const targetX = rTarget.x+rTarget.width+7
		const targetY = rTarget.y-16
		const bloop = document.createElement('div')
		bloop.classList.add('bloop')
		bloop.style.width=w+'px'
		bloop.style.height=h+'px'
		document.body.appendChild(bloop)
		const done = () => {
			cancelAnimationFrame(this.bloopAnimation)
			bloop.classList.add('fadeOut')
			setTimeout(()=>bloop.remove(), 200)
		}
		let i = 0
		const repeat = () => {
			let j = i/100
			const x = ((targetX - initialX) * j ) + initialX
			const y = ((targetY - initialY) * j * j) + initialY
			//bloop.style.left = x+'px'
			//bloop.style.top = y+'px'
			//bloop.style.transform = `scale(${1-i/133})`
			bloop.style.transform = `translate(${x}px, ${y}px) scale(${1-i/133})`
			if (i>=100) return done()
			else i+=2
			this.bloopAnimation = requestAnimationFrame(repeat)
		}
		this.bloopAnimation = requestAnimationFrame(repeat)	
	}
}


/*
const lastRow = el => {
	const offsets = []
	let sibling = el.nextElementSibling
	while (sibling) {
		offsets.push(sibling.offsetTop)
		sibling = sibling.nextElementSibling
	}
	return !offsets.length || offsets.every(offset => offset < el.offsetTop)
}
*/
export default {
	name: 'ExerciseDetail',
	props: ['elementKey','path'],
	mixins: [resourceInspectorMixin],
	components: {
		TextInput,
		RichText,
		EditParameters,
		EditResources,
		ExerciseControls,
		Loading,
		UnsetThumbnailButton
	},
	data: () => ({
	//	lastRow: false,
		instructionsHeight: null,
		parametersHeight: null,
		resourcesHeight: null,
		renderKey: 0
	}),
	computed: {
		exercise() {
			return this.$store.state.elements.root[this.elementKey]
		},
		disabled() {
			return this.$store.getters['elements/disabled'](this.exercise.key)
		},
		title: {
			get() {
				return this.exercise.title
			},
			set(value) {
				this.$store.dispatch('elements/setTitle', {
					key: this.exercise.key,
					value
				})
			}
		},
		instructions: {
			get() {
				return this.exercise.instructions
			},
			set(value) {
				this.$store.dispatch('elements/setInstructions', {
					key: this.exercise.key,
					value
				})
			}
		},
		parameters() {
			return this.$store.getters['elements/getParametersByExercise'](this.exercise.key, true)
		},
		resources() {
			return this.$store.getters['elements/getResourcesByExercise'](this.exercise.key, true)
		}
	},
	methods: {
		onRestored() {
			this.renderKey++
		},
		async onSaved() {
			this.renderKey++
			if (this.exercise.type==='stockExercise' && this.$store.state.exerciseBrowser.tab==='stock') {
				const el = this.$el.parentNode.previousSibling
				const box = el.getBoundingClientRect()
				//document.body.appendChild(clone)

				let buffer
				let i=0
				const repeat = () => {
					new Bloop(box.x, box.y, box.width, box.height)
					i++
					if (i<=25) buffer = requestAnimationFrame(repeat)
					else cancelAnimationFrame(repeat)
				}
				buffer = requestAnimationFrame(repeat)	
				eventBus.$emit("copyToCustom")


/*
				const init = () => new Promise(resolve=>{
					new Bloop(box.x, box.y, box.width, box.height)
					setTimeout(resolve, 20)
				})
				for (let i of Array.from(Array(20).keys())) {
					await init()
				}
*/
				//clone.remove()
			}
		},
	/*
		onResize: function() {
			//this.wide = this.$el.offsetWidth > 550
			//if (this.exercise.type === 'stackExercise') this.lastRow = lastRow(this.$el)
		},
	*/
		// parameters
		getParametersList() {
			return this.exercise.parameters
		},
		setParametersList(list) {
			this.$store.dispatch('elements/setExerciseParametersList', {
				key: this.exercise.key,
				value: list
			})			
		},
		createParameter() {
			this.$store.dispatch('elements/createParameter', { exerciseKey: this.exercise.key })
		},
		deleteParameter(parameterKey) {
			this.$store.dispatch('elements/deleteParameter', parameterKey)
		},
		setParameterTitle(payload) {
			this.$store.dispatch('elements/setParameterTitle', payload)
		}, 
		setParameterValue(payload) {
			this.$store.dispatch('elements/setParameterValue', payload)
		},

		// resources
		getResourcesList() {
			return this.exercise.resources
		},
		setResourcesList(list) {
			this.$store.dispatch('elements/setExerciseResourcesList', {
				key: this.exercise.key,
				value: list
			})			
		},
		createResource(payload) {
			return this.$store.dispatch('elements/createResource', { exerciseKey: this.exercise.key, ...payload })
		},
		sketchResource(payload) {
			this.createResource(payload).then(resource => this.showResourceInspector({ resourceKey: resource.key }))
		},
		deleteResource(resourceKey) {
			this.$store.dispatch('elements/deleteResource', resourceKey)
		},
		setResourceCaption(payload) {
			this.$store.dispatch('elements/setResourceCaption', payload)
		}, 		
		setResourceId(payload) {
			this.$store.dispatch('elements/setResourceId', payload)
		},
		showResourceInspector(payload) {
			const index = payload.resourceKey ? this.resources.findIndex(resource => resource.key === payload.resourceKey) : this.resources.length - 1
			this.showResourceInpsectorModal({
				resources: this.resources, 
				index, 
				setResourceId: this.setResourceId, 
				setResourceCaption: this.setResourceCaption,
				disabled: payload.disabled,
				flip: this.exercise.reflect
			})
		}
	},
	mounted() {
		if (!this.title) this.$refs.titleInput.focus()		
	},
	beforeDestroy() {
	//	if (this.$store.getters['elements/disabled'](this.elementKey) || this.$store.state.exerciseBrowser.tab==='stock') return
	//	if (this.exercise.fresh) this.$store.dispatch('exerciseBrowser/save', this.elementKey)
	}
}
</script>

<style lang='scss'>
.exerciseBrowserExerciseDetail {
	
	.a { 
		display: flex; 
		> * { flex: 1; }
	}
	
	.controlBar { border-bottom: none; }

	.path:hover { color: $color-primary-accent; text-decoration: underline; cursor: pointer; }

	.grid {
		display: grid;
		grid-gap: 1px;
		grid-template-columns: 1fr;
		background: $color-neutral-shadow;
		border: 1px solid $color-neutral-shadow;

	}
/*
	&.lastRow {
		margin-bottom: -$size-gutter * 2;
	}
*/
}

.bloop {
	position: absolute;
	top: 0;
	left: 0;
	background: radial-gradient(circle, rgba($color-focus,1) 0%, rgba($color-focus,1) 10%, rgba($color-focus,0) 50%);
	z-index: 10000;
	transform-origin: top left;
	animation: fadeIn 500ms linear;
	transition: opacity 200ms linear;
	&.fadeOut { opacity: 0; }
	/*
	> div { box-shadow: 0 4px 6px -2px rgba(0,0,0,.08); }
	*/
}
</style>


