<template>
<div class='attachEducationList'>
	<div class='controls'><div>
		<SearchInput 
			v-model='localSearch'
			@input='fetchSearch'
			@submit='fetchSearchImmediate'
			:placeholder='$t("views.educationManager.phSearchEducation")'
		/></div>
		<div v-if="folderId" class='fullPath bubble'>
			<div class="content">
				<span v-html="pathTo(folderId, true)" />
			</div>
			<CloseButton @click='$store.dispatch("attachEducation/setFolderId", null)' />
		</div>
	</div>
	<div class='content'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			class='recycleScroller'
		>
			<template slot='before'>
				<ResultInfo :total='total' :searched="searched" />
			</template>		
			<template slot='sticky'>
				<TableColumns v-if='items.length'
					module='attachEducation'
					:columns='[{
						label: $t("elements.cols.title"),
						selectable: true,
						sort: "title",
						flex: 1
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem 
					:item='item' 
					:itemKey='item.id' 
					:path='pathTo(item.folderId)' 
					@toggleSelect='toggleSelect'
					@click.native='select(item.id)' 
					:selected='selectedIds.includes(item.id)' 
				/>
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { debounce, isEqual } from 'lodash'
import { educationApi } from '@/services/api/modules/clinician/education'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import TableColumns from '@/components/common/TableColumns'
import AtBottom from '@/components/common/AtBottom'
import SearchInput from '@/components/common/SearchInput'
import CloseButton from '@/components/common/buttons/Close'
import Loading from '@/components/common/Loading'
import ListItem from './ListItem'

export default {
	name: 'EducationList',
	components: { Loading, SearchInput,CloseButton, RecycleScroller, TableColumns, AtBottom, ListItem, ResultInfo },
	props: ['folders','max'],
	data() { return {
		localSearch: '',
		working: false,
		total: 0,
		items: [],
		selectedIds: [],
		fetchSearch: debounce(function(search) {
			this.$store.dispatch('attachEducation/setSearch', search)
				.then(() => this.fetchTop())
		}.bind(this), 1000, { leading: false, trailing: true })
	}},
	computed: {
		folderId() { return this.$store.state.attachEducation.folderId },
		search() { return this.$store.state.attachEducation.search },
		searched() { return this.$store.state.attachEducation.searched },
		sort() { 
			return {
				col: this.$store.state.attachEducation.sortColumn,
				dir: this.$store.state.attachEducation.sortDirection
			}
		},
		filter() {
			return {
				folderId: this.folderId,
				search: this.search,
				sort: this.sort.col,
				sortDir: this.sort.dir,
			}
		}
	},
	methods: {	
		fetchSearchImmediate() {
			this.fetchSearch.flush()
		},
		fetchTop() {
			this.working = true
			return educationApi.touch('fetchList', this.filter).then(([err, result]) => {
				if (!err) {
					this.items = result.items
					this.total = result.total
					this.$store.dispatch('attachEducation/setSearched', this.filter.search)
				}
			})
			.finally(() => this.working = false)
		},
		fetchNext() {
			if (this.items.length < this.total) {
				this.working = true
				return educationApi.touch('fetchList', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
					if (!err) this.items.push(...result.items)
				})
				.finally(() => this.working = false)
			}
		},
		pathTo(folderId, fullPath) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (
					node.parentId && 
					(fullPath || node.id !== this.$store.state.attachEducation.folderId)
				) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return "/ " + nodes.join("&nbsp;/ ")			
		},
		toggleSelect(educationId) {
			if (this.selectedIds.includes(educationId)) {
				this.selectedIds = this.selectedIds.filter(id => id !== educationId)
			} else if (this.selectedIds.length < this.max) {
				this.selectedIds.push(educationId)
			}
		},
		select(educationId) {
			if (this.max) this.selectedIds = [educationId]
		}
	},
	watch: {
		folderId(a,b) { if (a!==b) this.fetchTop() },
		sort(a,b) { if (!isEqual(a, b)) this.fetchTop() },
		selectedIds(val) { this.$emit('selected', val) }
	},
	mounted() {
		this.localSearch = this.search
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.attachEducationList {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.controls {
		margin: $size-gutter * 2;
		margin-bottom: 0;

		> div {
			border: 1px solid $color-neutral-shadow;
			background: $color-neutral-shadow;
		}
		.fullPath { 
			font-weight: bold; color: $color-primary-accent; background: none; border: none; 
			.closeButton { margin-right: auto; margin-left: 0; }
		}
	}

	.content { 
		position: relative; 
		.tableColumns {
			margin: $size-gutter * 2;
			margin-bottom: 0;
		}
		.resultInfo {
			margin-top: $size-gutter * 2;	
		}
	}
}
</style>