import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchList: '/',
		fetch: '/${id}',
		fetchHistory: '/history/${id}'
	},
	post: {
		create: '/',
		createHistory: '/history'
	},
	put: {
		update: '/${id}',
		move: '/${educationId}/folder/${folderId}'
	},
	delete: {
		destroy: '/${id}'
	}
}

export const educationApi = buildApi('education', routes, '/clinician/education')

