<template>
<div class='pdfTracking' :class='{ dividers }'>

	<div
		class='dates'
		:style='`grid-template-columns: 136px repeat(${days}, 1fr);`'
	>
		<div v-for='day in (days + 1)' :key='"date_"+day' />
	</div>

	<div
		v-for='(elementKey, index) in elementKeys'
		:key='index'
		class='element'
	>

		<div 
			v-if='getEl(elementKey).type === "setExercise"' 
			class='setExercise'
			:style='`grid-template-columns: 100px repeat(${days}, 1fr);`'
		>
			<div class='title'>
				<span>{{getEl(elementKey).title}}{{initSetCount()}}</span>
				<span>
					<span 
						v-for='(parameter, index) in getParameters(elementKey)' 
						:key='index'
						class='parameter'
					>{{parameter.title}}:&nbsp; <span v-html='parameter.value' />{{countSets(parameter.title, parameter.value)}}</span>
				</span>
			</div>
			<div class='thumb' :class='{ flipImages: getEl(elementKey).reflect }'><div>
				<LazyImage 
					v-if='$store.getters["sets/getThumbnailUrl"](elementKey)' 
					:src='$store.getters["sets/getThumbnailUrl"](elementKey)' 
					:contain='true'
				/>
			</div></div>
			<div v-for='day in days' :key='"day_"+day' class='day'>
				<div v-for='count in setCount' :key='"set_"+count' />
			</div>
		</div>

		<div v-else class='setDivider'>
			<div class='title'>{{getEl(elementKey).title}}</div>
		</div>

	</div>
</div>
</template>

<script>
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'PreviewTracking',
	components: { LazyImage },
	props: ['setKey', 'selectedExs'],
	data: () => ({ defaultSets: 4 }),
	computed: {
		days() { return this.$store.state.setPdf.trck },
		elementKeys() { 
			const keys = this.$store.state.sets.root[this.setKey].elements 
			return this.selectedExs ? keys.filter(k=>this.selectedExs.includes(k)) : keys
		},
		dividers() { return !!this.elementKeys.filter(key => this.getEl(key).type === 'setDivider' ).length }
	},
	methods: {
		getEl(elementKey) { return this.$store.state.sets.elements[elementKey] },
		getParameters(elementKey) { 
			return this.$store.getters["sets/getParametersByExercise"](elementKey)
				.filter(parameter => parameter.title && parameter.value) 
		},
		initSetCount() {
			this.setCount = this.defaultSets
		},
		countSets(title, value) {
			if (title && ['sets'].includes(title.toLowerCase())) this.setCount = Math.min( Math.abs( parseInt( value.trim() )), 8 ) || this.defaultSets
		}
	},
	watch: {
		days() { this.$nextTick(() => this.$emit('resize')) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfTracking {

	width: 1000px;

	&.dividers {
		padding-left: 32px;
	}

	.dates {
		display: grid;
		grid-gap: 1px;

		> div:not(:first-child) {
			height: 64px;
			background: 
				linear-gradient(to top left,
					rgba(0,0,0,0) 0%,
					rgba(0,0,0,0) calc(50% - 0.8px),
					$color-neutral-shadow 50%,
					rgba(0,0,0,0) calc(50% + 0.8px),
					rgba(0,0,0,0) 100%);
		}
	}

	.element {
		position: relative;
	}

	.setExercise {
		display: grid;
		grid-gap: 1px;
		background: $color-neutral-shadow;
		min-height: 32px * 5;

		.title {
			display: flex;
			grid-column: 1 / -1;
			background: $color-neutral-silver;
			border-top: 1px solid $color-neutral-shadow;
			font-size: 17px;
			padding: 16px 0;
			padding-left: 8px;

			> span:first-child {
				flex: 2;
			}

			> span:last-child {
				flex: 3;
				text-align: right;
			}

			.parameter {
				display: inline-block;
				padding-right: 16px;
			}
		}
		.thumb { 
			position: relative; 
			background: $color-white;
			padding-top: $size-aspectRatio-thumbnail;

			> div {
				@include absTopLeft;
				@include fill;				
			}
		}
		.day {
			background: $color-white;
			> div {
				height: 32px;

				&:not(:last-child) { border-bottom: 1px solid $color-neutral-shadow; }

				&:first-child:nth-last-child(1) { height: 100%; }

				&:first-child:nth-last-child(2),
				&:first-child:nth-last-child(2) ~ div { height: 50%; }

				&:first-child:nth-last-child(3),
				&:first-child:nth-last-child(3) ~ div { height: 33%; }
			}
		}		
	}

	.setDivider {
		position: absolute;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: rotate(90deg);
		line-height: 46px;
		text-transform: uppercase;

		.title {
			font-size: 17px;
			font-weight: bold;
		}
	}
}
</style>
