<template>
<div class='clientSetRow'>
	<div class='titleCol'>
		<span class='title'>{{$store.getters['saveClientSets/title'](set.key)}}</span>
	</div>
	<div class='updatedCol'><div>
		<div class='updated'>{{updated}}</div>
		<div v-if='set.savedBy' class='savedBy'>{{$t('elements.parts.by')}} {{set.savedBy}}</div>
	</div></div>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'

export default {
	name: 'SaveClientSetRow',
	mixins: [dateTime],
	props: ['itemKey'],
	computed: {
		set() { return this.$store.state.saveClientSets.sets[this.itemKey] || {} },
		updated() { return this.dateTime(this.set.updated) }
	}
}
</script>

<style lang='scss'>
@import '@/components/clinician/clients/scss/clientSetRow.scss';

.saveAndEmailClientSet .clientSetRow {
	color: $color-neutral-dark;
	margin: 0 $size-gutter * 2;
	.updatedCol { border-right: 1px solid $color-neutral-shadow; }
}
</style>