<template>
<div class='setContent'>
	<Scroll 
		ref='scroll' 
		v-on='$listeners'
		:freeze='$store.state.bus.bussing && $store.state.bus.contentType === "setElements" && $store.state.setBuilder.focus === setKey'
	>
		<Lasso 
			:selectableKeys='elementKeys'
			:getSelected='getSelected'
			:setSelected='setSelected'
			:setSelecting='setSelecting'
			selectableClass='setElement'
		>
			<div class='topPadding' />
			<div v-if='title || instructions || coversheet' class='coversheet' :class='{ bussing: $store.state.bus.bussing }' @mousedown.stop>
				<template v-if='title || instructions || coversheet'>
					<TextInput 
						v-model='title' 
						ref='title'
						class='title' 
						:placeholder='$t("views.designer.phCoversheetTitle")' 
						maxlength='100' 
					/>
					<CloseButton 
						v-tap='removeCover'
					/>
					<div class='instructions'>
						<RichText 
							v-model='instructions' 
							:placeholder='$t("views.designer.phCoversheetInstructions")' 
							:collapseOnInactive='true'
						/>
					</div>
				</template>
			</div>
			<div v-if='education' class='education' :class='{ bussing: $store.state.bus.bussing }'>
				<div v-for='(item) in education' :key='item.id' @click='editEducation(item)'>
					<svg class='smallIcon'>
						<use xlink:href='#attach' />
					</svg>
					<span>{{item.title}}</span>
					<CloseButton @click.stop='detachEducation(item)' />
				</div>
			</div>
			<div v-if='false' class='clientSetNotice'><div>
				{{$t('views.designer.clientSetNotice.p1')}} {{client.firstname}} {{client.lastname}}.<br />
				{{$t('views.designer.clientSetNotice.p2')}}
			</div></div>
			<SetElements ref='setElements' :setKey='setKey' :padding='padding' v-on='$listeners' />
		</Lasso>
		<div 
			v-if='!$store.state.sets.root[this.setKey].elements.length' 
			class='initialDrop iconTextButton' 
			:class='{ bussing: $store.state.bus.bussing && ["browserElements","setElements"].includes($store.state.bus.contentType) }'
			:style='`margin-bottom: ${padding-150}px`' 
		>
			<div class='dropExercisesButton' >
				<div class='border'>
					<svg class='giantIcon'>
						<use xlink:href='#plus' />
					</svg>
				</div>
				<span class='label'>Drop Exercises</span>
			</div>
		</div>		
	</Scroll>
</div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import { onResizeMixin } from '@/components/common/mixins/onResize'
import { largeModalMixin, smallModalMixin } from '@/components/common/mixins/modal'
import Lasso from '@/components/common/Lasso'
import Scroll from '@/components/common/Scroll'
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'
import CloseButton from '@/components/common/buttons/Close'
import ConfirmModal from '@/components/common/modals/Confirm'
import EducationItem from './modals/education/Item'
import SetElements from './SetElements'

export default {
	name: 'SetContent',
	props: ['setKey', 'mousein'],
	mixins: [onResizeMixin, largeModalMixin, smallModalMixin],
	components: {
		Scroll,
		Lasso,
		TextInput,
		RichText,
		SetElements,
		CloseButton
	},
	data: () => ({
		padding: 0,
		coversheet: false
	}),
	computed: {
		title: {
			get() {
				return this.$store.state.sets.root[this.setKey].title
			},
			set(value) {
				const key = this.setKey
				this.$store.dispatch('sets/setTitle', { key, value })
			}
		},
		instructions: {
			get() {
				return this.$store.state.sets.root[this.setKey].instructions
			},
			set(value) {
				const key = this.setKey
				this.$store.dispatch('sets/setInstructions', { key, value })
			}
		},
		education() {
			const list = this.$store.state.sets.root[this.setKey].education
			if (list && list.length) {
				return list.map(id => this.$store.state.sets.education[id])
			} else {
				return null
			}
		},		
		elementKeys() {
			return this.$store.state.sets.root[this.setKey].elements
		},
		autoScroll() {
			return (
				this.mousein &&
				this.$store.state.bus.bussing && (
					this.$store.state.bus.contentType === 'browserElements' || 
					this.$store.state.bus.contentType === 'bulkParameters' || 
					(this.$store.state.bus.contentType === 'setElements' && this.$store.state.setBuilder.focus !== this.setKey)
				)
			)
		},
		client() {
			const clientKey = this.$store.state.sets.root[this.setKey].clientId
			return clientKey ? this.$store.state.clients[clientKey] : false
		}			
	},
	methods: {
		/*
		onMouseupInitialDrop() {
			this.$refs.setElements.copy(this.$refs.setElements.hovering)
		},
		*/
		async onUnloadBus(el) {
			//console.log(el)
			if (!this.$el.contains(el) || this.elementKeys.length) return				
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIds', ids.join(','))
				) return
				const { exercises } = this.$store.getters['elements/getElementsForCopyToSet'](ids)
				this.$store.dispatch('sets/copyElements', { 
					exercises, 
					setKey: this.setKey
				}).then(elementKeys => {
					this.$store.dispatch('setBuilder/setSelected', elementKeys)
				})	
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { elements } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				this.$store.dispatch('sets/copyElements', { elements, setKey: this.setKey })
					.then(elementKeys => {
						this.$store.dispatch('setBuilder/setSelected', elementKeys)
					})				
			}

		},
		onResize: function() {
			this.padding = (this.$el.offsetHeight - 67)
			this.$emit('resize')
		},
		onMouseMove: function(e) {
			this.endY = e.clientY || e.touches[0].clientY
		},
		getSelected() {
			return this.$store.state.setBuilder.selected
		},
		setSelected(keys) {
			this.$store.dispatch('setBuilder/setSelected', keys)
		},
		setSelecting(keys) {
			this.$store.dispatch('setBuilder/setSelecting', keys)
		},
		addCover() {
			this.coversheet = true
			this.$nextTick(function() { this.$refs.title.focus() })
		},
		removeCover() {
			this.$store.dispatch('sets/setTitle', { key: this.setKey, value: '' })
			this.$store.dispatch('sets/setInstructions', { key: this.setKey, value: '' })
			this.coversheet = false
		},
		detachEducation(item) {
			let educationId
			if (item.type === 'stockEducation') {
				educationId = `stock-${item.id}`
			} else if (item.type === 'customEducationHistory') {
				educationId = `cust-${item.id}`
			}
			const value = this.$store.state.sets.root[this.setKey].education.filter(id => id !== educationId)
			this.$store.commit('sets/setEducation', { key: this.setKey, value })
		},
		editEducation(item) {
			this.showMediumModal(
				EducationItem, 
				{ setKey: this.setKey, educationId: item.id, type: item.type, folders: {} }, 
				"800px", 
				{ 'before-close': e => {
					if(document.querySelector('.attachedEducationItem .saveButton')) {
						this.showSmallModal(ConfirmModal, {
							title: this.$t('views.confirmExitEducation.title'),
							message: this.$t('views.confirmExitEducation.p1'),
							ok: this.$t('elements.buttons.continue'),
							fn: () => this.$modal.hideAll()
						})
						e.cancel()
					}
				}}
			) 
		},
		startAutoScroll: function() {
			const gutter = 150
			const accel = 8
			const scrollEl = this.$refs.scroll.scrollEl
			const box = scrollEl.parentNode.getBoundingClientRect()
			const getOffset = () => {
				if (this.endY > box.bottom - gutter) return (this.endY - box.bottom + gutter) / accel
				else if (this.endY < box.top + gutter) return -(box.top + gutter - this.endY) / accel
				else return 0
			}
			const repeat = () => {
				const offset = getOffset()
				scrollEl.scrollTop += offset
				this.scrollInterval = requestAnimationFrame(repeat)
			}
			this.scrollInterval = requestAnimationFrame(repeat)
			/*
			this.scrollInterval = setInterval(() => {
				const offset = getOffset()
				scrollEl.scrollTop += offset
			}, 5)
			*/
		}
	},
	watch: {
		autoScroll(value) {
			if (value) {
				if (this.$store.state.main.mobile) document.addEventListener('touchmove', this.onMouseMove, { passive: true })
				else document.addEventListener('mousemove', this.onMouseMove)
				this.startAutoScroll()
			} else {
				//clearInterval(this.scrollInterval)
				cancelAnimationFrame(this.scrollInterval)
				if (this.$store.state.main.mobile) document.removeEventListener('touchmove', this.onMouseMove)
				else document.removeEventListener('mousemove', this.onMouseMove)	
			}
		}
	},
	mounted() {
		eventBus.$on('unloadBus', this.onUnloadBus)
	},
	beforeDestroy() {
		eventBus.$off('unloadBus', this.onUnloadBus)		
	}	
}
</script>
<style lang='scss'>
/*
.wideMenu .setContent .topPadding {
	height: $size-control-height * 2 + $size-gutter * 4 + 2;
}
*/
.setContent {
	position: relative;

	.topPadding {
		height: $size-control-height * 3 + $size-gutter * 4 + 3;
	}

	.coversheet {
		position: relative;
		z-index: 20;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 2;
		border: 1px solid $color-neutral-shadow;
		background: $color-neutral-shadow;
		display: grid;
		grid-template-columns: 1fr auto;
		margin-bottom: $size-gutter * 2;
		&.bussing { 
			pointer-events: none;
			z-index: 500; 
		}

		.title {
		}

		.closeButton {
			background: $color-white;
		}

		.instructions {
			min-width: 0;
			grid-column: 1 / -1;
		}
	}

	.education {
		position: relative;
		z-index: 20;		
		margin: 0 $size-gutter * 2;
		padding: 1px;
		padding-left: 0; 
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
		margin-bottom: $size-gutter * 2;

		&.bussing { 
			pointer-events: none;
			z-index: 500; 
		}
		
		> div {
			min-height: $size-control-height;
			padding-left: $size-gutter * 2;
			display: flex;
			align-items: center;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			cursor: pointer;
			> span { 
				flex: 1; 
				display: block; 
				padding: $size-gutter 0; 
			}
			> svg { margin-right: $size-gutter * 2; flex: 0; flex-basis: 16px; }
			&:hover { 
				background: $color-neutral-accent; 
				.closeButton { opacity: 1; }
			}
			.closeButton { opacity: 0; transition: opacity 200ms linear; }
			&:only-child { grid-column: 1/-1; }
		}
	}

	.clientSetNotice {
		height: $size-control-height * 2;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		color: $color-primary-accent;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;

		> div { 
			text-align: center;
			border-radius: 3px;
			border: 1px dashed $color-neutral-shadow;
			padding: $size-gutter $size-gutter * 2;
			background: $color-white;
			box-shadow: 0 0 0 1px #fff;
		}
	}

	.setSelectionControls {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 200;
	}

}

#app:not(.mobile) .setContent .lassoContainer:not(.lassoSelecting) .setElements:not(.sorting):not(.dropElements):not(.dropParameters) .setExercise:not(.detail):hover > div > div {
		background: $color-neutral-silver;
	}
.mobile .setContent .education .closeButton { opacity: 1; }

</style>