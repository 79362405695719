import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmModal from '@/components/common/modals/Confirm'
import ResourceInspector from './ResourceInspector'

export const resourceInspectorMixin = {
	mixins: [smallModalMixin],
	data: () => ({
		loadingResources: false
	}),
	methods: {
		showResourceInpsectorModal(params) {
			const keys = this.resources.map(resource => resource.resourceId)
			this.loadingResources = true
			this.$store.dispatch('resources/fetch', keys)
				.then(() => {
					this.loadingResources = false
					setTimeout(() => {
						this.$modal.show(
							ResourceInspector, 
							params, 
							{ width: '800px', height: '100%', classes: 'resourceInspectorModal', transition: 'largeModalFade' },
							{ 'before-close': this.beforeCloseResources }
						)
					}, 0)
				})
		},
		beforeCloseResources(e) {
			if(document.querySelector('.resourceInspector .modalControls')) {
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmExitResourceInspector.title'),
					message: this.$t('views.confirmExitResourceInspector.p1'),
					ok: this.$t('elements.buttons.continue'),
					fn: () => this.$modal.hideAll()
				})
				e.cancel()
			}
		}
	}
}