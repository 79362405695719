<template>
<div class='editResource'>
	<div class='thumbnail'>
		<LazyImage 
			:src='url' 
			v-on='!$store.state.main.mobile ? { click: inpsectResource, mousedown: onMouseDown } : {}'	
			:contain='!isVideo'
		/>
		<svg v-if='isVideo' class='video smallIcon'>
			<use xlink:href='#video' />
		</svg>
	</div>
	<!--
		<span style='color: blue'>{{resource.id}} / {{resource.draftId}} / {{resource.key}}</span>&nbsp;
	-->
	<PlainText 
		v-model='caption' 
		ref='caption'
		:placeholder='$t("views.designer.phResourceCaption")' 
		:disabled='disabled'
		:htmlEntities='true'
		:class='{ focussed: focus }'				
		@focus='() => { focus = true; $emit("focus") }'
		@blur='() => { focus = false; $emit("blur") }'		
		class='caption' 
		maxlength='200' 
	/>
	<template v-if='!disabled'>
		<SortButton v-if='!$store.state.main.mobile' axes='xy' />
		<div v-handle v-else-if='!editing && $store.state.main.mobile' class='mobileSortHandle'>
			<div class='focusResource' v-tap='inpsectResource' />			
			<div class='focusCaption' v-tap='() => { $refs.caption.focus() }'></div>
		</div>	
		<CloseButton v-if='!$store.state.main.mobile || !editing' @click='deleteResource' v-tap='deleteResource' />
	</template>
</div>
</template>

<script>
import { HandleDirective } from '@/libs/slickSort'	
import { Distance } from '@/utilities'
import SortButton from '@/components/common/buttons/Sort'
import CloseButton from '@/components/common/buttons/Close'
import LazyImage from '@/components/common/LazyImage'
import PlainText from '@/components/common/PlainText'

export default {
	name: 'EditResource',
	props: ['resource', 'disabled', 'editing'],
	directives: {
		handle: HandleDirective
	},		
	components: {
		SortButton,
		CloseButton,
		LazyImage,
		PlainText
	},
	data: () => ({
		distance: false,
		focus: false
	}),
	computed: {
		caption: {
			get() {
				return this.resource.caption
			},
			set(value) {
				this.$emit('setCaption', { key: this.resource.key, value })
			}
		},
		url() {
			return this.$store.getters['resources/getResource'](this.resource.resourceId)
		},
		isVideo() {
			return this.$store.getters['resources/isVideo'](this.resource.resourceId)
		}
	},
	methods: {
		onMouseDown(e) {
			if (e.target.closest('.sortHandle')) return
			const pageX = e.pageX ? e.pageX : e.touches[0].pageX
			const pageY = e.pageY ? e.pageY : e.touches[0].pageY
			new Distance({
				onPull: () => {
					const rect = this.$el.getBoundingClientRect()
					this.$store.dispatch('bus/loadBus', {
						data: this.resource,
						contentType: 'exerciseResource',
						el: this.getClone(),
						startX: rect.x,
						startY: rect.y,
						screenX: pageX,
						screenY: pageY
					})
				},
				onStart: () => this.distance = true,
				onStop: () => this.distance = false,
				e
			})
		},
		deleteResource() {
			this.$emit('delete', this.resource.key)
		},
		inpsectResource() {
			if (this.$store.state.bus.bussing) return
			this.$emit('inspectResource', { resourceKey: this.resource.key, disabled: this.disabled })			
		},
		getClone() {
			const box = this.$el.getBoundingClientRect()
			const clone = this.$el.cloneNode(true)
			clone.classList.remove('distance')
			clone.style.width = box.width + 'px'
			return clone
		}
	}
}
</script>

<style lang='scss'>
.editResource {
	position: relative;
	background: $color-white;
	overflow: hidden;
	display: flex;

	> .sortHandle {
		position: absolute;
		top: $size-gutter;
		left:  $size-gutter;
		transition: opacity .2s linear;
		opacity: 0;
		background: rgba(255,255,255,0.9);
		padding: 3px;
		padding-bottom: 0;
		border-bottom-right-radius: 50%;

		.smallIcon {
			background: $color-white;
		}
	}

	.caption {
		flex: 1;
		height: $size-control-height * 2;
		line-height: $size-lineHeight-standard;
		&.focussed { background: $color-focus !important; }
	}

	.thumbnail {
		position: relative;
		width: $size-control-height * 2 * (4/3);
		height: $size-control-height * 2;
		cursor: pointer;
		padding: 7px;

		.video {
			position: absolute;
			bottom: $size-gutter;
			right: $size-gutter;
			z-index: 10;
			color: $color-primary-accent;
			pointer-events: none;
			height: 24px;
			width: 24px;
			padding: 4px;
			border-top-left-radius: 3px;
			background: rgba(255,255,255,0.9);
		}
	}

	> .closeButton {
		z-index: 21;
		@include absTopRight;
		transition: opacity .2s linear;
		opacity: 0;
	}

	&:hover {
		.closeButton, .sortHandle {
			opacity: 1;
		}
	}
/*
	&.distance {
		&:before {
			@include absTopLeft;
			@include fill;
			content: '';
			z-index: 10;
			border: 2px solid $color-focus;			
			pointer-events: none;
			animation: fadeIn 600ms linear;
		}
	}
*/
}

.mobile .editResource {

	.mobileSortHandle {
		z-index: 20;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.focusResource {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			width: $size-control-height * 2 * (4/3);
			height: 100%;
		}

		.focusCaption {
			position: relative;
			margin-left: $size-control-height * 2 * (4/3);
			width: 100%;
			height: 100%;
		}
	}

/*
	.closeButton { opacity: 1; }
*/

}
</style>

