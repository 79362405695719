<template>
<div class='attachEducation'>
	<div class='header'>	
		<span>{{$t('views.educationManager.attach')}} {{max}} {{ max === 1 ? $t('elements.labels.document') : $t('elements.labels.documents')}}</span>
	</div>
	<Folders @update='onFolderUpdate' />
	<div class='content' :style='style'>
		<List :max='max' :folders='folders' @selected='onSelected' />
		<Preview ref='preview' v-if='selectedIds.length' :selectedIds='selectedIds' />
		<div class='footer modalControls'>
			<CancelButton @click='$emit("close")' />
			<div v-if='selectedIds.length' class='attachButton iconTextButton success' @click='attach' :class='{ disable: $refs.preview && $refs.preview.working }'>
				<svg class='smallIcon'>
					<use xlink:href='#attach' />
				</svg>
				<span class='label'>{{$t('elements.buttons.attach')}}: {{selectedIds.length}}</span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { uniq } from 'lodash'
import CancelButton from '@/components/common/buttons/Cancel'
import Folders from './Folders'
import List from './List'
import Preview from './Preview'

export default {
	name: 'AttachEducation',
	components: { CancelButton, Folders, List, Preview },
	props: ['setKey','max'],
	data() { return {
		folders: {},
		selectedIds: []
	}},	
	computed: {
		style() {
			return this.selectedIds.length ? 'grid-template-columns: 1fr minmax(170px, 20%);' : 'grid-template-columns: 1fr;'
		}
	},
	methods: {
		onFolderUpdate(val) {
			this.folders = val
		},
		onSelected(val) {
			this.selectedIds = val
		},
		attach() {
			const items = Object.values(this.$refs.preview.items)
			const education = {}
			items.forEach(item => {
				const o = { title: item.title }
				if (item.type === 'stockEducation') {
					o.type = item.type
					o.id = item.id
					education[`stock-${o.id}`] = o
				} else if (item.type === 'customEducation') {
					o.type = 'customEducationHistory'
					o.id = item.historyId
					education[`cust-${o.id}`] = o
				}
			})
			this.$store.commit('sets/spreadInEducation', education)
			const educationIds = this.$store.state.sets.root[this.setKey].education ? 
				uniq([...this.$store.state.sets.root[this.setKey].education, ...Object.keys(education) ]) : Object.keys(education)
			this.$store.commit('sets/setEducation', { key: this.setKey, value: educationIds })
			this.$emit('close')
		}
	},
	watch: {},
	mounted() {}
}
</script>

<style lang='scss'>
.attachEducation {
	@include fill;
	display: grid;
	grid-template-columns: minmax(250px, 20%) 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;
	> .header {
		grid-column: -1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-large;
		color: $color-primary-accent;
		font-weight: bold;
		background: #fff;
		height: $size-control-height * 1.5;
		> svg { margin-right: $size-gutter * 2; position: relative; top: -2px; }
	}
	> .content {
		display: grid;
		grid-template-rows: 1fr auto;
		> .footer {
			grid-column: -1/1;		
		}
	}
	.attachButton {
		&.disable {
			pointer-events: none;
			opacity: .7;
		}
	}
	.readOnly { display: none; }
}
</style>
