<template>
<div class='setScrollContainer' style='position: relative; overflow: hidden;'>
	<div 
		ref='scrollContent' 
		class='scrollContent' 
		:class='{ snap, hidden: $store.state.setBuilder.list.length <= 1 }' 
		@scroll='onScroll' 
		@mousedown='onMousedown'
	>
		<slot />
	</div>
</div>
</template>

<script>
export default {
	name: 'SetScroll',
	props: ['snap'],
	methods: {
		scrollToLeft: function() {
			this.$refs.scrollContent.scrollLeft = 0
		},
		scrollToRight: function() {
			this.$refs.scrollContent.scrollLeft = this.$refs.scrollContent.scrollWidth
		},
		onScroll() {
			this.$emit('scroll', this.$refs.scrollContent.scrollLeft)
		},
		onMousedown(e) {
			if (
				e.target.tagName === 'INPUT' ||
				e.target.tagName === 'TEXTAREA' ||
				e.target.closest('.ProseMirror')

			) {
				this.$refs.scrollContent.classList.add('freeze')
				document.addEventListener('mouseup', this.onMouseup)
			}		
		},
		onMouseup() {
			this.$refs.scrollContent.classList.remove('freeze')			
			document.removeEventListener('mouseup', this.onMouseup)			
		}
	}
}
</script>

<style lang='scss'>
.setScrollContainer {
	height: 100%;

	> .scrollContent {
		height: 100%;
		overflow-y: hidden;	
		overflow-x: overlay;
		-webkit-overflow-scrolling: touch;
		&.snap { scroll-snap-type: x mandatory; }
		&.hidden { overflow: hidden !important; }

		&.freeze {
			overflow: hidden !important;
		}
	}

	&.multiple > .scrollContent {
		padding: 0 !important;
	}

}

.desktop .setScrollContainer.multiple > .scrollContent {

	color: transparent;
	transition: color linear 200ms;
	&:hover { color: darken($color-neutral, 12%); }

	&::-webkit-scrollbar {
		display: none;
		height: $size-gutter * 2;
		cursor: move;
	}

	&::-webkit-scrollbar-track {}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px;
		border: solid 3px transparent;
		border-radius: $size-gutter;
		color: inherit;		
	}	

	scrollbar-width: thin;	
}
</style>

