<template>
<div class='setElementDetail setExerciseDetail ctrlLasso' @pointerdown='onMouseDown'><div>

	<ExerciseControls :exerciseKey='exercise.key' />

	<div class='grid' :class='[ gridLayout ]'>

		<div class='a'>
			<TextInput 
				ref='titleInput'
				v-model='title' 
				:placeholder='$t("views.designer.phExerciseTitle")' 
				maxlength='100' 
			/>
		</div>

		<div class='c'>
			<div ref='parameters'>
				<EditParameters 
					:parameters='parameters' 
					:getList='getParametersList' 
					:setList='setParametersList' 
					:wide='wide'
					@create='createParameter'
					@delete='deleteParameter'
					@setTitle='setParameterTitle'
					@setValue='setParameterValue'
				/>
			</div>
		</div>

		<div class='b'>
			<div ref='instructions'>
				<RichText 
					v-model='instructions' 
					:placeholder='$t("views.designer.phExerciseInstructions")' 
					:maxlength='5000'
				/>
			</div>
		</div>

		<div class='d'>
			<div ref='resources'>
				<EditResources
					:resources='resources'
					:getList='getResourcesList' 
					:setList='setResourcesList' 
					:flip='exercise.reflect'
					:wide='wide'
					@sketch='sketchResource'
					@create='createResource'
					@delete='deleteResource'
					@setCaption='setResourceCaption'
					@inspectResource='showResourceInspector'
				/>
				<transition name='fade'><Loading v-if='loadingResources' /></transition>
			</div>
		</div>

	</div>

</div></div>
</template>

<script>
import Loading from '@/components/common/Loading'
import { resourceInspectorMixin } from '@/components/clinician/designer/resourceInspector/mixin'
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'
import EditParameters from '@/components/clinician/designer/common/EditParameters'
import EditResources from '@/components/clinician/designer/common/EditResources'
import ExerciseControls from './ExerciseControls'

export default {
	name: 'ExerciseDetail',
	components: {
		TextInput,
		RichText,
		EditParameters,
		EditResources,
		ExerciseControls,
		Loading
	},
	mixins: [resourceInspectorMixin],
	props: ['elementKey'],
	data: () => ({
		instructionsHeight: null,
		parametersHeight: null,
		resourcesHeight: null,
		wide: false
	}),
	computed: {
		exercise() {
			return this.$store.state.sets.elements[this.elementKey]
		},
		title: {
			get() {
				return this.exercise.title
			},
			set(value) {
				this.$store.dispatch('sets/setElementTitle', {
					key: this.exercise.key,
					value
				})
			}
		},
		instructions: {
			get() {
				return this.exercise.instructions
			},
			set(value) {
				this.$store.dispatch('sets/setElementInstructions', {
					key: this.exercise.key,
					value
				})
			}
		},
		parameters() {
			return this.$store.getters['sets/getParametersByExercise'](this.exercise.key, true)
		},
		resources() {
			return this.$store.getters['sets/getResourcesByExercise'](this.exercise.key, true)
		},
		gridLayout() {
			if (this.parametersHeight && this.resourcesHeight && this.instructionsHeight) {
				if (this.parametersHeight + this.resourcesHeight < this.instructionsHeight) return 'longInstructions'
				else if (this.parametersHeight > this.resourcesHeight) return 'longParameters'
				else return 'longResources'
			}
			else return null
		}
	},
	methods: {
		onResize: function() {
			this.wide = this.$el.offsetWidth > (550 + 28) // padding
		},
		onMouseDown() {
			this.$store.dispatch('setBuilder/setFocus', this.exercise.setKey)
		},
		calcHeights: function() {
			this.parametersHeight = this.$refs.parameters.clientHeight
			this.resourcesHeight = this.$refs.resources.clientHeight
			this.instructionsHeight = this.$refs.instructions.clientHeight
		},

		// parameters
		getParametersList() {
			return this.exercise.parameters
		},
		setParametersList(list) {
			this.$store.dispatch('sets/setExerciseParametersList', {
				key: this.exercise.key,
				value: list
			})			
		},
		createParameter() {
			this.$store.dispatch('sets/createParameter', { exerciseKey: this.exercise.key })
		},
		deleteParameter(parameterKey) {
			this.$store.dispatch('sets/deleteParameter', parameterKey)
		},
		setParameterTitle(payload) {
			this.$store.dispatch('sets/setParameterTitle', payload)
		}, 
		setParameterValue(payload) {
			this.$store.dispatch('sets/setParameterValue', payload)
		},

		// resources
		getResourcesList() {
			return this.exercise.resources
		},
		setResourcesList(list) {
			this.$store.dispatch('sets/setExerciseResourcesList', {
				key: this.exercise.key,
				value: list
			})			
		},
		createResource(payload) {
			return this.$store.dispatch('sets/createResource', { exerciseKey: this.exercise.key, ...payload })
		},
		sketchResource(payload) {
			this.createResource(payload).then(resource => this.showResourceInspector({ resourceKey: resource.key }))
		},
		deleteResource(resourceKey) {
			this.$store.dispatch('sets/deleteResource', resourceKey)
		},
		setResourceCaption(payload) {
			this.$store.dispatch('sets/setResourceCaption', payload)
		}, 		
		setResourceId(payload) {
			this.$store.dispatch('sets/setResourceId', payload)
		},
		showResourceInspector(payload) {
			const index = payload.resourceKey ? this.resources.findIndex(resource => resource.key === payload.resourceKey) : this.resources.length - 1
			this.showResourceInpsectorModal({
				resources: this.resources, 
				index, 
				setResourceId: this.setResourceId, 
				setResourceCaption: this.setResourceCaption,
				flip: this.exercise.reflect
			})
		},
	},
	mounted: function () {
		this.$nextTick().then(this.calcHeights)
		if (!this.title) this.$refs.titleInput.focus()
	}
}
</script>

<style lang='scss'>

.setElementWrapper:last-child .setExerciseDetail {
	border-bottom: none;
}

.setExerciseDetail {
	position: relative;
	top: 21px;
	left: 14px;
	z-index: 10;
	transform: translate3d(0,0,10px); /* safari */
	width: calc(100% - 28px);
	padding-bottom: 42px;
	/*
	padding-top: $size-gutter * 5 - 1;
	padding-bottom: $size-gutter * 4;
	border-top: 1px solid $color-neutral-shadow;
	border-bottom: 1px solid $color-neutral-shadow;
	margin-bottom: -2px;
	*/

	> div {
		background: $color-neutral-shadow;
		border: 1px solid $color-neutral-shadow;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);

	}

	.controlBar {
		border: none;
	}

	.grid {
		display: grid;
		grid-gap: 1px;
		grid-template-columns: 1fr;
	}
}
/*
.setExerciseDetail + div .setElement { border-top: 1px solid $color-neutral-shadow; }
*/
</style>