<template>
<div class='setPdf'>
	<div class='leftCol'>
		<Scroll>
			<Templates :setKey='setKey' />
			<HeaderFooters />
		</Scroll>
	</div>
	<Preview :setKey='setKey' :selectedExs='selectedExs' />
	<Footer 
		@close='$emit("close")' 
		@printPdf='printPdf'
		@exportPdf='exportPdf'
	/>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import printJS from 'print-js-updated'
import { isMobile } from '@/utilities'
import { pollDownload } from '@/components/common/mixins/pollDownload'
import { apiPath } from '@/configuration/settings'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import Templates from './Templates'
import HeaderFooters from './HeaderFooters'
import Preview from './Preview'
import Footer from './Footer'

export default {
	name: 'SetPdf',
	mixins: [pollDownload],
	components: { Scroll, Loading, Templates, HeaderFooters, Preview, Footer },
	props: ['setKey', 'selectedExs'],
	data() {
		return {
			loading: false,
			polling: false
		}
	},
	methods: {
		async printPdf() {
			this.loading = true
			const payload = this.$store.getters['sets/getSetForExport'](this.setKey, this.selectedExs)
			const params = this.$store.getters['setPdf/templates']
			const hf = this.$store.getters["headerFooters/list"].find(r=>r.id===this.$store.state.headerFooters.selected)
			params.headerFooterId = hf ? hf.id : null
			const options = {
				params,
				withCredentials: true
			}
			try {
				if (/apple/i.test(navigator.vendor) /*&& isMobile() */) {
					const windowRef = window.open()
					windowRef.document.body.innerHTML = 'Loading, please wait...'
					const response = await axios.post(apiPath + '/clinician/sets/pdf', payload, options)
					const url = response.data.url
					const readyId = response.data.readyId
					await this.poll(readyId)
					const pdf = await axios.get(url, { responseType: 'blob' })
					const href = window.URL.createObjectURL(new Blob([pdf.data], { type: 'application/pdf' }))
					windowRef.location = href
					if (isMobile()) setTimeout(() => windowRef.print(), 500) // breaks safari 14.1
				} else {
					const response = await axios.post(apiPath + '/clinician/sets/pdf', payload, options)
					const url = response.data.url
					const readyId = response.data.readyId
					await this.poll(readyId)
					printJS({
						printable: url,
						type: 'pdf'
					})
				}
				
			} catch (error) {
				const err = error.response.data.error
				if (err && err.code === 'ERRPERMISSION' && err.details === 'Daily max') this.$store.dispatch('flash/showAlert', 'dailyMax')
			} finally {
				this.loading = false
				this.$emit('close')
			}
		},
		async exportPdf() {
			this.loading = true
			const payload = this.$store.getters['sets/getSetForExport'](this.setKey)
			const params = this.$store.getters['setPdf/templates']
			params.headerFooterId = this.$store.state.headerFooters.selected
			const options = {
				params,
				withCredentials: true
			}
			try {
				let response
				response = await axios.post(apiPath + '/clinician/sets/pdf', payload, options)
				//console.log(response)
				const url = response.data.url
				const readyId = response.data.readyId
				await this.poll(readyId)
				const link = document.createElement('a')
				link.setAttribute('href', url)
				link.setAttribute('download', this.getFilename())
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				/*
				response = await axios.get(url, { responseType: 'blob' })
				const href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
				const link = document.createElement('a')
				link.setAttribute('download', this.getFilename())
				link.href = href
				link.click()
				*/
			} catch (error) {
				const err = error.response.data.error
				if (err && err.code === 'ERRPERMISSION' && err.details === 'Daily max') this.$store.dispatch('flash/showAlert', 'dailyMax')
			} finally {
				this.loading = false
				this.$emit('close')
			}
		},
		getFilename() {
			if (this.$store.state.sets.root[this.setKey].type === 'clientSet') {
				const client = this.$store.state.clients[this.$store.state.sets.root[this.setKey].clientId]
				return 'exercises_'+ client.firstname + client.lastname + '_' + dayjs().format('MMM-D') + '.pdf'
			} else {
				return 'exercises_'+ dayjs().format('MMM-D') +'.pdf'
			}
		}
	},
	mounted() {
		this.loading = true
		this.$store.dispatch('headerFooters/fetch')
			.finally(() => this.loading = false)	
	},
	beforeDestroy() {
		this.polling = false
	}
}
</script>

<style lang='scss'>
// body iframe { display: none; } /* used by printJS */

.setPdf {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 2.5fr 1fr;
	grid-template-rows: 1fr auto;
	background: $color-neutral-shadow;


	header { text-align: center; margin-top: $size-gutter * 2; margin-bottom: 0;}

	.leftCol {
		position: relative;
		background: $color-neutral-accent;
		grid-row: 1;
	}

	.setPdfPreview {
		grid-row: 1 / span 2;
	}
/*
	.setPdfFooter {
		grid-column: 1/-1;
	}
*/
	.loading {
		background: rgba($color-white, 0.3);
	}
}
</style>
