<template>
<div class='clientsHeader' @click.stop>
	<TableColumns
		:module='module'
		:columns='[{
			label: $t("elements.cols.client"),
			selectable: true,
			sort: "lastname,firstname,email",
			flex: 3
		},{
			label: "Last Seen by",
			flex: 1
		}]'
	/>
</div>
</template>

<script>
import TableColumns from '@/components/common/TableColumns'

export default {
	name: 'ClientsHeader',
	props: ['module'],
	components: { TableColumns }
}
</script>

<style lang='scss'>
.clientsHeader {
	position: relative;
	margin-left: $size-gutter * 2;
	margin-right: $size-gutter * 2;
	background: $color-neutral;
/*
	.tableColumns > div:nth-last-child(2) {
		flex-basis: 70px;
		border-left: 1px solid $color-neutral-shadow;

		@media screen and (max-width: 1820px) {
			display: none;
		}
	}
*/

	.tableColumns > div:nth-last-child(1) {
		min-width: 150px;
	}

}
</style>