<template>
<div class='attachedEducationItem' :key='renderKey'>
	<div>
		<Scroll>
			<div class='text'>
				<TextInput v-model='attrs.title' :placeholder='$t("views.educationManager.phTitle")' :maxlength='100' />
				<RichText v-model='attrs.instructions' :placeholder='$t("views.educationManager.phInstructions")' :maxlength='8000' />
			</div>
			<Resources :resources='attrs.resources' @update='v => attrs.resources = v' />
		</Scroll>
	</div>
	<div class='footer modalControls'>
		<CancelButton @click='$emit("close")' />
		<div v-if='unsaved' class='saveButton iconTextButton success' @click='save'>
			<svg class='smallIcon'>
				<use xlink:href='#save' />
			</svg>
			<span class='label'>{{$t('elements.buttons.save')}}</span>
		</div>
	</div>	
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { isEqual, pick } from 'lodash'
import uniqid from 'uniqid'
import { educationApi } from '@/services/api/modules/clinician/education'
import CancelButton from '@/components/common/buttons/Cancel'
import Loading from '@/components/common/Loading'
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'
import Scroll from '@/components/common/Scroll'
import Resources from '@/components/clinician/education/ItemResources'

export default {
	name: 'EducationItem',
	components: { Loading, CancelButton, TextInput, RichText, Scroll, Resources },
	props: ['setKey','educationId','type'],
	data() { return {
		renderKey: 0,
		working: false,
		saved: '',
		attrs: {
			id: null,
			created: null,
			updated: null,
			title: '',
			instructions: '',
			resources: []
		}
	}},
	computed: {
		unsaved() {
			return !isEqual(this.saved, JSON.stringify(this.attrs)) && !this.working
		}
	},
	methods: {
		parseResult(education) {
			const { id, created, updated, title, instructions, resources } = education
			resources.forEach(r => r.cid = uniqid())
			this.attrs = { id, created, updated, title, instructions, resources }
			this.saved = JSON.stringify(this.attrs)
		},
		fetch() {
			this.working = true
			educationApi.touch(this.type === 'stockEducation' ? 'fetch' : 'fetchHistory', { id: this.educationId })
				.then(([err, result]) => {
					if (!err) {
						this.parseResult(result.education)
						this.$store.dispatch('resources/parseResourcePreviews', result.resources)
						this.renderKey++
					}
				})
				.finally(() => this.working = false)
		},
		save() {
			this.working = true
			const { title, instructions, resources } = this.attrs
			const payload = { 
				title, instructions, 
				resources: resources.map(r => pick(r, ['resourceId','caption'])) 
			}
			educationApi.touch('createHistory', payload)
				.then(([err, result]) => {
					if (!err) {
						const o = {}
						o[`cust-${result.id}`] = { id: result.id, type: 'customEducationHistory', title: result.title }
						this.$store.commit('sets/spreadInEducation', o)
						const originalId = this.type === 'stockEducation' ? `stock-${this.educationId}` : `cust-${this.educationId}`
						const educationIds = this.$store.state.sets.root[this.setKey].education.filter(v => v !== originalId)
						educationIds.push(`cust-${result.id}`)
						this.$store.commit('sets/setEducation', { key: this.setKey, value: educationIds })
						this.$store.dispatch('flash/showAction', 'educationUpdated')
						this.$emit('close')
					}
				})
				.finally(() => this.working = false)	
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.attachedEducationItem {
	@include fill;
	position: relative;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	background: $color-neutral-panel;

	> div { position: relative; }

	.text { 
		margin: 0 $size-gutter * 4;
		margin-bottom: $size-gutter * 4;
		display: grid;
		grid-template-rows: auto auto;
		grid-gap: 1px;
		background: $color-neutral-shadow;
		border: 1px solid $color-neutral-shadow;
	}

	.educationItemResources {
		margin: 0 $size-gutter * 4;
	}

	.scrollContent {
		padding: $size-gutter * 4 0;
	}

	.footer { position: relative; z-index: 20; }

	.ProseMirror { padding: $size-gutter * 3; }
}
</style>