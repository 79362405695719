<template>
<div 
	@click='$emit("click")' 
	class='unsetThumbnailButton iconTextButton neutral'
	:class='{ flipImages: flip}'
>
	<img v-if='thumbnailUrl' :src='thumbnailUrl' />
	<div v-else class='blankThumb' />
	<span class="label">Unset Default Img</span>
	<CloseButton />
</div>
</template>

<script>
import CloseButton from '@/components/common/buttons/Close'

export default {
	name: 'UnsetThumbnailButton',
	props: ['thumbnailUrl', 'flip'],
	components: { CloseButton }
}
</script>

<style lang='scss'>
.unsetThumbnailButton  {
	overflow: hidden;
	position: relative;
	img, .blankThumb {
		width: $size-control-height - 4;
		height: $size-control-height - 4;
		position: relative;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 2;
		border-radius: 2px;
		overflow: hidden;
		flex-shrink: 0;
		object-fit: cover;
	}
	.blankThumb {
		background: $color-white;
	}
	.label {
		white-space: nowrap;
	}
	.closeButton:after {
		position: absolute;
		top: 0;
		left: -1000%;
		width: 3000%;
		height: 100%;
		content: '';
	}
}
</style>
