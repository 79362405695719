const flipImage = data => 
	new Promise(resolve => {
		const canvas = window.document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.src = data
		img.onload = () => {
			canvas.width = img.width
			canvas.height = img.height
			ctx.save()
			ctx.scale(-1, 1)
			ctx.drawImage(img, -img.width, 0, img.width, img.height)
			ctx.restore()
			const flipped = canvas.toDataURL()
			return resolve(flipped)
		}
	})

export default flipImage 
