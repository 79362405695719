<template>
<div class='pdfEducation' :class='[$store.state.setPdf.edu]'>
	<div v-for='(id, i) in $store.state.sets.root[this.setKey].education' :key='i' class='title'>
		{{$store.state.sets.education[id].title}}
	</div>
	<div class='details'>(Details inserted here...)</div>
</div>
</template>

<script>
export default {
	name: 'PreviewEducation',
	props: ['setKey'],
	computed: {
		edu() { return this.$store.state.setPdf.edu }
	},
	watch: {
		edu() { this.$nextTick(() => this.$emit('resize')) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfEducation {

	width: 1000px;

	.title {
		&:first-child { padding-top: 40px; }
		text-align: center;
	}

	.details {
		font-size: 30px;
		text-align: center;
		color: $color-primary-accent;
		padding-top: 50px;
	}

	&.std {
		.title { font-size: 30px; }
	}

	&.lrg {
		.title { font-size: 40px; }
	}

	&.max {
		.title { font-size: 50px; }
	}
}
</style>
