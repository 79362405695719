<template>
<div 
	class='bulkParameterPreset' 
	:class='{ canApply }'
    v-tap='applyParameters'
    v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'  	
	v-touchHold='onPull'		
>
	<SortButton class='sortButton blockButton' axes='y' />
	<div class='content'>
		<div 
			v-for='parameterKey in parameterKeys' 
			:key='parameterKey'
			class='parameter'
		><div>
			<span class='title'><b>{{getTitle(parameterKey)}}: </b></span>
			<span class='value' v-html='getValue(parameterKey)' />
		</div></div>
	</div>
	<CloseButton @click='destroy' class='closeButton' />	
</div>
</template>

<script>
import { Distance } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import CloseButton from '@/components/common/buttons/Close'
import SortButton from '@/components/common/buttons/Sort'

export default {
	name: 'BulkParameterPreset',
	props: ['presetKey'],
	mixins: [smallModalMixin],	
	components: { CloseButton, SortButton },
	data: () => ({
		distance: false
	}),
	computed: {
		parameterKeys() {
			return this.$store.state.bulkParameterPresets.presets[this.presetKey].parameters
		},
        canApply() {
            return !!this.$store.state.setBuilder.selected.length
        }
	},
	methods: {
		onMouseDown(e) {
			if (e.target.closest('.sortHandle')) return
			new Distance({
				onPull: () => this.onPull(e),
				onStart: () => this.distance = true,
				onStop: () => this.distance = false,
				e
			})
		},
		onPull(e) {
			const rect = this.$el.getBoundingClientRect()
			const pageX = e.pageX ? e.pageX : e.touches[0].pageX
			const pageY = e.pageY ? e.pageY : e.touches[0].pageY			
			this.$store.dispatch('bus/loadBus', {
				data: this.$store.getters['bulkParameterPresets/getParametersForCopy'](this.presetKey),
				contentType: 'bulkParameters',
				el: this.getClone(),
				startX: rect.x,
				startY: rect.y,
				screenX: pageX,
				screenY: pageY			
			})
		},		
		getTitle(parameterKey) {
			return this.$store.state.bulkParameterPresets.parameters[parameterKey].title
		},
		getValue(parameterKey) {
			return this.$store.state.bulkParameterPresets.parameters[parameterKey].value
		},
        applyParameters() {
            if (this.canApply) {
                const exerciseKeys = this.$store.state.setBuilder.selected
                const parameters = this.$store.getters['bulkParameterPresets/getParametersForCopy'](this.presetKey)
                this.$store.dispatch('sets/applyParameters', { exerciseKeys, parameters })
            }
        },
		getClone() {
			//const box = this.$el.getBoundingClientRect()
			const clone = this.$el.cloneNode(true)
			return clone
		},
		destroy() {
			const fn = () => this.$store.dispatch('bulkParameterPresets/destroy', this.presetKey)
			this.showSmallModal(ConfirmDeleteModal, { items: this.parameterKeys.map(key => `${this.getTitle(key)}: ${this.getValue(key)}`), fn })			
		}
	}
}
</script>

<style lang='scss'>
.bulkParameterPreset {
	position: relative;
	background: $color-neutral-accent;
	
	display: flex;
	
	.sortButton {
		flex: 0;
		padding-right: $size-gutter;
	}

	.content {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		padding-top: 9px;
		padding-bottom: 9px;
		padding-right: $size-gutter;

		.parameter {
			display: flex;
			align-items: center;
			height: $size-control-height * .5;

			> div {
				font-size: $size-font-small;
				white-space: nowrap;
				padding-right: $size-gutter;
				padding-left: $size-gutter;
				border-right: 1px solid $color-neutral-panel;
			}

			&:last-child > div {
				border: none;
			}
		}
	}

	.closeButton {
		position: absolute;
		top: 0;
		right: 0;
	}

	&.canApply {
		cursor: pointer;
		background: $color-white;
	}
/*
	&.distance {
		&:before {
			@include absTopLeft;
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			border: 2px solid $color-focus;			
			pointer-events: none;
			animation: fadeIn 600ms linear;
		}
	}
*/
}

.desktop .bulkParameterPreset {
	.closeButton {
		opacity: 0;
		transition: opacity .2s linear;
	}
	&:hover .closeButton {
		opacity: 1;
	}
}

.bus .bulkParameterPreset {
	margin: 0;
	padding-left: 0;
	background: none;
	.sortHandle { display: none; }
	.content { padding: 0; }
	.closeButton { display: none; }
}
</style>


