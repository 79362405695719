import FileUpload from 'vue-upload-component'
import UploadProgress from '@/components/common/uploads/Progress'
import CancelButton from '@/components/common/uploads/CancelButton'
import { extension } from '@/utilities'
import { resourcesApi } from '@/services/api/modules/clinician/resources'
import { generalApi } from '@/services/api/modules/general'

export const uploadMixin = {
	components: { FileUpload, UploadProgress, CancelButton },
	data: () => ({
		imageExtensions: ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'tif', 'webp', 'svg', 'heic', 'heif'],
		videoExtensions: ['avi', 'flv', 'mov', 'wmv', 'mp4', 'm4v', 'mpg', 'mpeg', 'mkv'],
		files: [],
		polling: false,
		canDrop: false,
		x: 0,
		y: 0
	}),
	methods: {
		catchNativeClick(e) {
			if (this.dropped) {
				e.stopImmediatePropagation()
				e.preventDefault()
			}
		},
		onDragOver(e) { 
			e.preventDefault()
			const rect = this.$el.getBoundingClientRect()
			this.canDrop = true 
			this.x = e.clientX - rect.left
			this.y = e.clientY - rect.top
		},
		onDragLeave() { this.canDrop = false },
		onDrop(e) {
			if (e.dataTransfer.files.length) {
				this.$refs.upload.addDataTransfer(e.dataTransfer)
				this.dropped = true
				setTimeout(() => this.dropped = false, 1000)
			}
		},
		onInputFile(newFile, oldFile) {
			const onError = () => {
				this.$set(newFile, 'result', 'error')
				this.removeFile(newFile)
			}
			if (newFile && oldFile) {
				if (newFile.error !== oldFile.error) {
					onError()
					if (typeof this.onUploadError === 'function') this.onUploadError()
				}
				if (newFile.success !== oldFile.success) {
					this.polling = true
					this.pollUpload(newFile.pendingResource.readyId)
						.then(() => {
							this.$set(newFile, 'result', 'success')
							this.onUploadSuccess(newFile)
							this.removeFile(newFile)
							this.$emit('uploadStop')
						})
						.catch(() => {
							onError()
							if (typeof this.onUploadError === 'function') this.onUploadError()
						})
				} 
			}
			this.startUpload(newFile, oldFile)
		},
		startUpload(newFile, oldFile) {
			if (
				(Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) &&
				!this.$refs.upload.active
			) this.$refs.upload.active = true 
			this.$emit('uploadStart')
		},
		cancelUpload() {
			this.$refs.upload.active = false
			this.$refs.upload.clear()
			this.polling = false
			this.$emit('uploadStop')
		},
		removeFile(file) {
			setTimeout(() => {
				if (this.$refs.upload) this.$refs.upload.remove(file)
			}, 0)
		},
		pollUpload(readyId) {
			const stopTime = Number(new Date()) + (1000 * 120)
			const check = (resolve, reject) => {
				setTimeout(() => {
					generalApi.touch('downloadReady', { id: readyId })
						.then(([err, result]) => {
							if (err) {
								return reject()
							} else if (result.ready) {
								if (this.polling) resolve()
								else reject()
							} else {
								if (Number(new Date()) < stopTime && this.polling) check(resolve, reject)
								else reject()
							}
						})
				}, 1000)
			}
			return new Promise(check)			
		},
		async uploadToS3(file, component) {
			const payload = { resourceWidth: this.resourceWidth }
			if (this.imageExtensions.includes(extension(file.file.name))) payload.type = 'image'
			else if (this.videoExtensions.includes(extension(file.file.name))) payload.type = 'video'
			else if (file.file.type === 'application/pdf') payload.type = 'pdf'
			else if (
				file.file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				(file.file.name.includes('.') && file.file.name.split('.').pop() === 'docx')
			) payload.type = 'docx'
			const [err, result] = await resourcesApi.touch('upload', payload)
			if (err) return component.clear()
			file.putAction = result.uploadUrl
			file.pendingResource = result.pendingResource
			//console.log(result)
			//file.name = uniqid()
			//console.log(file)
			//alert(JSON.stringify(file))
			return await component.uploadPut(file) // component.uploadHtml5(file)
		}
	}
}