<template>
<div class='pdfCover' :class='[$store.state.setPdf.cvr]'>
	<div class='title'>{{$store.state.sets.root[this.setKey].title}}</div>
	<div class='instructions' v-html='$store.state.sets.root[this.setKey].instructions' />
</div>
</template>

<script>
export default {
	name: 'PreviewSummary',
	props: ['setKey'],
	computed: {
		cvr() { return this.$store.state.setPdf.cvr }
	},
	watch: {
		cvr() { this.$nextTick(() => this.$emit('resize')) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfCover {

	width: 1000px;

	.title {
		text-align: center;
		margin: 150px 0 0 0;
	}

	.instructions {
		p, ul, ol, li {
			margin: 0;
			padding: 0;
		}
	}

	&.std {
		.title { font-size: 40px; }
		.instructions {
			margin: 100px 100px 0 100px;
			font-size: 26px;
			line-height: 30px;
			p { margin: 32px 0; }
			ul, ol { margin: 32px; }
		}
	}

	&.lrg {
		.title { font-size: 50px; }
		.instructions {
			margin: 100px 75px 0 75px;
			font-size: 32px;
			line-height: 40px;
			p { margin: 40px 0; }
			ul, ol { margin: 40px; }
		}
	}

	&.max {
		.title { font-size: 60px; }
		.instructions {
			margin: 100px 50px 0 50px;
			font-size: 40px;
			line-height: 45px;
			p { margin: 45px 0; }
			ul, ol { margin: 45px; }
		}
	}
}
</style>
