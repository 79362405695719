<template>
<div class='saveClientSetsHeader' @click.stop>
	<TableColumns 
		:module='module'
		:columns='[{
			label: $t("elements.cols.exerciseSet"),
			selectable: true,
			sort: "savename",
			flex: 4.5
		},{
			label: $t("elements.cols.lastUpdated"),
			selectable: true,
			sort: "id",
			flex: 2
		}]'
	/>
</div>
</template>

<script>
import TableColumns from '@/components/common/TableColumns'

export default {
	name: 'ClientSetsHeader',
	props: ['module'],
	components: { TableColumns }
}
</script>

<style lang='scss'>
.saveClientSetsHeader {
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;

	.tableColumns { padding-right: 0;}

}
</style>