<template>
<div class='bulkParametersContent'>
	<Scroll>
        <div class='presetsWrapper'>
            <Presets />
        </div>        
        <div class='editWrapper'>
            <div 
                class='clearButton iconTextButton neutral' 
                v-tap='clearParameters'
            >
                <svg class='smallIcon'>
                    <use xlink:href='#cancel' />
                </svg>
                <span class='label'>{{$t("elements.buttons.clear")}}</span>
            </div>
            <EditParameters 
                :parameters='parameters' 
                :getList='getParametersList' 
                :setList='setParametersList' 
                @create='createParameter'
                @delete='deleteParameter'
                @setTitle='setParameterTitle'
                @setValue='setParameterValue'
                @keydown.enter='applyParameters'
            />
        </div>
        <EnabledButton 
            v-tap='applyParameters'
            :disabled='!canApply' 
            :svg='"#copy"' 
            :label='$t("elements.buttons.applySelection")' 
            class='applyParameters primary' 
        />
        <EnabledButton 
            v-tap='savePreset'
            :disabled='!fresh' 
            :svg='"#save"' 
            :label='$t("elements.buttons.saveAsPreset")' 
            class='savePreset neutral tagSuccess' 
        />
	</Scroll>
    <transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'
import EnabledButton from '@/components/common/buttons/Enabled'
import EditParameters from '@/components/clinician/designer/common/EditParameters'
import Presets from './Presets'

export default {
    name: 'BulkParametersContent',
    components: {
        Loading,
        Scroll,
        EnabledButton,
        EditParameters,
        Presets
    },
    computed: {
        loading() {
            return this.$store.state.bulkParameterPresets.apiPending
        },
        parameters() {
            return this.$store.getters['bulkParameters/getParameters']
        },
        canApply() {
            return !!this.$store.state.setBuilder.selected.length
        },
        fresh() {
            return this.$store.state.bulkParameters.fresh
        }
    },
    methods: {
        toggleOn() {
           this.$store.dispatch('bulkParameters/toggleOn')
        },
        getParametersList() {
            return this.$store.state.bulkParameters.parametersList
        },
        setParametersList(list) {
            this.$store.dispatch('bulkParameters/setParametersList', list)          
        },
        createParameter() {
            this.$store.dispatch('bulkParameters/createParameter')
        },
        deleteParameter(parameterKey) {
            this.$store.dispatch('bulkParameters/deleteParameter', parameterKey)
        },
        setParameterTitle(payload) {
            this.$store.dispatch('bulkParameters/setParameterTitle', payload)
        }, 
        setParameterValue(payload) {
            this.$store.dispatch('bulkParameters/setParameterValue', payload)
        },
        clearParameters() {
            this.$store.dispatch('bulkParameters/clearParameters')
        },
        applyParameters() {
            if (this.canApply) {
                const exerciseKeys = this.$store.state.setBuilder.selected
                const parameters = this.$store.getters['bulkParameters/getParametersForCopy']
                this.$store.dispatch('sets/applyParameters', { exerciseKeys, parameters })
            }
        },
        savePreset() {
            this.$store.dispatch('bulkParameters/savePreset')
        }
    }
}
</script>
<style lang='scss'>
.bulkParametersContent {
    position: relative;

    .editWrapper {
        margin-top: $size-gutter * 2;
        padding: $size-gutter * 2;
        padding-top: 0;
        overflow: hidden;
    }

    .clearButton {
        border: 1px solid $color-neutral-shadow;
        border-bottom: none;
        height: $size-control-height + 1;        
    }

    .applyParameters, .savePreset {
        margin-left: $size-gutter * 2;
        margin-right: $size-gutter * 2;
        border: 1px solid $color-neutral-shadow;
        height: $size-control-height + 2;
    }

    .savePreset {
        margin-bottom: $size-gutter * 5;
    }

    .applyParameters {
        height: $size-control-height * 2 + 2;
        margin-bottom: $size-gutter * 2;
    }

    .presetsWrapper {
        margin-top: $size-gutter * 2;
    }

    .columns {
        grid-template-columns: 1fr;
        border: 1px solid $color-neutral-shadow;
        border-top: none;
        background: $color-neutral-shadow;
    }

}
</style>