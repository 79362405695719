<template>
<div class='saveAsInput'>

	<div class='label'>
		{{$t('elements.buttons.saveAs')}}:
	</div>

	<TextInput 
		ref='textInput'
		v-model='localValue' 
		:value='value'
		:disabled='disabled'
		:placeholder='$t("elements.labels.untitled")' 
		@submit='$emit("submit")'
		maxlength='100' 
	/>

</div>
</template>

<script>
import TextInput from '@/components/common/TextInput'

export default {
	name: 'SaveAsInput',
	props: ['value', 'disabled'],
	components: { TextInput },
	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		focus() { this.$refs.textInput.focus() }	
	},
	mounted() {
		this.focus()
	}
}
</script>

<style lang='scss'>
.saveAsInput {
	display: flex;
	align-items: center;

	.label {
		flex: 0;
		white-space: nowrap;
		padding-left: $size-gutter * 2;
		padding-right: $size-gutter * 2;
		font-weight: bold;
		font-size: $size-font-standard;
		flex-basis: 114px;
	}

	.textInput {
		flex: 1;
		border: 1px solid $color-neutral-shadow;
		height: $size-control-height + 2;
	}
}
</style>

