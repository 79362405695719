var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saveClientSetsHeader",on:{"click":function($event){$event.stopPropagation();}}},[_c('TableColumns',{attrs:{"module":_vm.module,"columns":[{
			label: _vm.$t("elements.cols.exerciseSet"),
			selectable: true,
			sort: "savename",
			flex: 4.5
		},{
			label: _vm.$t("elements.cols.lastUpdated"),
			selectable: true,
			sort: "id",
			flex: 2
		}]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }