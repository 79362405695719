<template>
<div class='customExerciseHistory'>
	<div class='leftCol'>
		<Scroll>
			<header>Version History</header>
			<div class="points">
				<div v-for="(item, index) in items" :key="index" :class="{ selected: selected===item.id }" @click="selected=item.id">
					<div>Saved on {{dateTime(item.created)}}</div>
					<div v-if="item.savedBy" class="savedBy">by {{item.savedBy}}</div>
				</div>
			</div>
		</Scroll>
	</div>
	<div class='rightCol'>
		<Scroll>
			<div class="exercise">
				<header>{{exercise.title}}</header>
				<div v-if="exercise.thumbnailId" class="thumbnail">
					Thumbnail: 
					<img :src="$store.getters['resources/getResource'](exercise.thumbnailId)" />
				</div>
				<div class='resources'>
					<div
						v-for='(resource, index) in exercise.resources'
						:key='index'
						class='resource'
					>
						<div class='resourceImgContainer'><div><LazyImage v-if='resource.resourceId' :src="$store.getters['resources/getResource'](resource.resourceId)" /></div></div>
						<div v-if='resource.caption' class='caption' v-html='resource.caption' />
					</div>
				</div>
				<div v-if='exercise.instructions' class='instructions' v-html='exercise.instructions' />
			</div>
		</Scroll>
	</div>
	<div class='modalControls footer'>
		<div class='iconTextButton neutral' @click='$emit("close")' >
			<svg class='smallIcon'>
				<use xlink:href='#cancel' />
			</svg>
			<span class='label'>Close</span>
		</div>	
		<div v-if="selected" class='iconTextButton neutral' @click='restore' >
			<svg class='smallIcon'>
				<use xlink:href='#undo' />
			</svg>
			<span class='label'>Restore Version</span>
		</div>	
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script> 
import { pick } from 'lodash'
import { elementsApi } from '@/services/api/modules/clinician/elements'
import { dateTime } from '@/components/common/mixins/dateTime'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import LazyImage from '@/components/common/LazyImage'
import ConfirmModal from '@/components/common/modals/Confirm'

export default {
	name: 'CustomExerciseHistory',
	mixins: [ dateTime, smallModalMixin ],
	components: { Scroll, Loading, LazyImage },
	props: ['exerciseId', 'onRestored'],
	data() { return {
		loading: false,
		items: [],
		exercise: {},
		selected: null
	}},
	computed: {},
	methods: {
		async fetchList() {
			const [err, result] = await elementsApi.touch('fetchHistoryList', { id: this.exerciseId })
			if (!err) {
				this.items = result.items 
			}
		},
		async fetchPoint(historyId) {
			this.loading = true
			const [err, result] = await elementsApi.touch('fetchHistoryPoint', { id: historyId })
			if (!err) {
				const exercise = result.item 
				if (result.resources && result.resources.length) {
					await this.$store.dispatch('resources/parseResourcePreviews', result.resources)
				}
				this.exercise = result.item 
			}
			this.loading = false
		},
		async restore() {
			
			const fn = async () => {
				this.loading=true
				const payload = pick(this.exercise, ["title","instructions","thumbnailId","resources"])
				payload.id = this.exerciseId
				const [err, result] = await elementsApi.touch('update', payload)
				this.$store.commit('elements/setFresh', { key: this.exerciseId, value: false })
				await this.$store.dispatch('elements/parseElementsMerge', result)			
				this.onRestored()
				this.loading=false
				this.$emit("close")
			}

			this.showSmallModal(ConfirmModal, {
				title: "Restore Version",
				message: "You are about to overwrite this exercise.",
				ok: "Continue",
				fn
			})
		}
	},
	watch: {
		async selected(a,b) {
			if (a && a!==b) {
				await this.fetchPoint(a)
			}
		}
	},
	async mounted() {
		this.loading = true
		await this.fetchList()
		if (this.items.length) this.selected = this.items[0].id
		this.loading = false
		// 
	}
}
</script>

<style lang='scss'>
.customExerciseHistory {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	background: $color-neutral-shadow;

	header { text-align: center; margin-top: $size-gutter * 2; margin-bottom: 0;}

	.leftCol {
		position: relative;
		background: $color-neutral-accent;
	}

	.rightCol {
		position: relative;
		background: #fff;
	}

	.footer {
		grid-column: 1 / -1;
	}

	.points {
		margin: $size-gutter * 3;
		border: 1px solid $color-neutral-shadow;
		> div {
			height: $size-control-height;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 $size-gutter * 2;
			font-size: $size-font-standard;
			cursor: pointer;
			&:hover { background: $color-neutral-silver; }
			&.selected { background: $color-focus; }
			.savedBy {
				color: $color-primary-accent;
				margin-left: auto;
			}
		}
	}


	.thumbnail { 
		display: flex;
		align-items: center;
		font-size: $size-font-large;
		gap: $size-gutter * 2;
		margin-top: $size-gutter * 3;
		img { width: 48px; }
	}


	.exercise {
		padding: $size-gutter * 3;
		padding-top: 0;

		&.exercise {
			background: $color-white;
		}

		.title {
			font-size: $size-font-large;
			font-weight: bold;
			text-decoration: underline;
			color: $color-primary;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.resources {
			padding: 1px;
			display: grid;
			grid-gap: $size-gutter * 2;
			grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
			margin-top: $size-gutter * 3;

			.resource {
				background: $color-white;
				box-shadow: 0 0 0 1px $color-neutral-shadow;
			}

			.caption {
				text-align: center;
				background: white;
				padding: $size-gutter * 2;
				font-size: $size-font-standard;
				line-height: $size-lineHeight-standard;
			}

			.resourceImgContainer {
				width: 100%;
				padding-top: 75%;
				position: relative;

				> div {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 3px;
				}
			}
		}

		.instructions {
			padding:  $size-gutter * 3;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
		}
	}

	.loading {
		background: rgba($color-white, 0.3);
	}
}
</style>