<template>
<div 
	class='uploadEducationButton'
	@dragover='onDragOver'
	@dragleave='onDragLeave'
	@drop.prevent='onDrop'
	@mouseleave='onDragLeave'
	v-show='maximum' 
	:style='style'
>
	<label 
		class='uploadButton iconTextButton' 
		ref='uploadButton'
		:class='{ canDrop: canDrop && !files.length }'
	>
		<div class='iconLabelWrapper'>
			<div class='iconLabel'>
				<svg class='smallIcon'>
					<use xlink:href='#upload' />
				</svg>
				<span>{{ $t('elements.buttons.upload') }}</span>
			</div>
			<span class='allowed'>Pdf, Docx, Images &amp; Videos</span> 
		</div>
		<file-upload
			ref='upload'
			accept='image/*,video/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			v-model='files'
			:extensions='["pdf", "docx", ...imageExtensions, ...videoExtensions].join(",")'
			:custom-action='uploadToS3'
			:multiple='true'
			:size='1024 * 1024 * 200'
			:thread='$store.state.main.mobile ? 1 : 8'
			:timeout='1000 * 60 * 5'
			:maximum='maximum'
			@input-file='onStart'
			@click.native='catchNativeClick'
		/>
	</label>
	<CancelButton 
		v-if='files.length' 
		@click='cancelUpload' 
	/>
	<UploadProgress :files='files' />
</div>
</template>

<script>
import { uploadMixin } from '@/components/common/uploads/mixin'
import { resourceDimensions } from '@/configuration/settings'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'

export default {
	name: 'UploadResource',
	mixins: [uploadMixin, smallModalMixin],
	props: ['maximum'],
	computed: {
		resourceWidth() {
			return this.$store.state.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small
		},
		style() {
			return this.canDrop ? `background: radial-gradient(circle at ${this.x}px ${this.y}px, #ffff99 40%, #f8f8f8 40%, #f8f8f8);` : ``
		}
	},
	methods: {
		onUploadSuccess(file) {
			//console.log(file)
			return this.$store.dispatch('resources/parseResourcePreviews', file.pendingResource)
				.then(() => this.$emit('create', { resourceId: file.pendingResource.id, filename: file.file.name }))
		},
		onStart(...options) {
			this.canDrop = false
			this.onInputFile(...options)
		},
		onUploadError() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.uploadError.title'),
				message: this.$t('views.uploadError.p1')
			})
		}
	}
}
</script>

<style lang='scss'>
.uploadEducationButton {
	position: relative;
	min-height: $size-control-height * 2;
	background: $color-neutral-silver;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 0 1px $color-neutral-shadow;

	.uploadButton {
		min-height: $size-control-height * 2;		
		position: relative;
		transition: all .2s;
		/*
		outline-offset: -8px;
		outline: 2px dashed $color-neutral;
		*/
		@include fill;

		font-weight: normal;
		.iconLabelWrapper { text-align: center; }
		.iconLabel {
			display: inline-block;
			align-items: center;
			margin-bottom: 4px;
			svg { margin-right: $size-gutter; }
			> span { font-size: $size-font-standard; }
		}

		.allowed {
			display: block;
			font-size: $size-font-small;
		}
	}

	.cancelUploadButton { margin: $size-gutter * 2 0; }
	.progressBars { padding: 0; padding-bottom: 1px; }

}
</style>