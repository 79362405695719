<template>
<div class='pdfSummary' :class='[$store.state.setPdf.sum]'>
	<div
		v-for='(elementKey, index) in elementKeys'
		:key='index'
		:class='["element", getEl(elementKey).type]'
	>
		<div class='title'>{{getEl(elementKey).title}}</div>
		<template v-if='getEl(elementKey).type === "setExercise"'>
			<div class='thumb' :class='{ flipImages: getEl(elementKey).reflect }'><div>
				<LazyImage 
					v-if='$store.getters["sets/getThumbnailUrl"](elementKey)' 
					:src='$store.getters["sets/getThumbnailUrl"](elementKey)' 
					:contain='true'
				/>
			</div></div>
			<div class='parameters'><div>
				<div 
					v-for='(parameter, index) in getParameters(elementKey)' 
					:key='index'
				>
					<b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' />
				</div>
			</div></div>
		</template>
	</div>
</div>
</template>

<script>
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'PreviewSummary',
	components: { LazyImage },
	props: ['setKey', 'selectedExs'],
	computed: {
		sum() { return this.$store.state.setPdf.sum },
		elementKeys() { 
			const keys = this.$store.state.sets.root[this.setKey].elements 
			return this.selectedExs ? keys.filter(k=>this.selectedExs.includes(k)) : keys
		}
	},
	methods: {
		getEl(elementKey) { return this.$store.state.sets.elements[elementKey] },
		getParameters(elementKey) { 
			return this.$store.getters["sets/getParametersByExercise"](elementKey)
				.filter(parameter => parameter.title && parameter.value) 
		}
	},
	watch: {
		sum() { this.$nextTick(() => this.$emit('resize')) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfSummary {

	width: 1000px;
	display: grid;

	.element {
		background: white;
	}

	.setExercise {
		display: grid;
		border: 1px solid $color-neutral-shadow;

		.thumb { 
			position: relative; 
			padding-top: $size-aspectRatio-thumbnail;

			> div {
				@include absTopLeft;
				@include fill;
			}
		}
		.title { font-weight: bold; }
	}

	.setDivider {
		grid-column: 1 / -1;
		border-bottom: 8px solid $color-primary-accent;
		font-weight: bold;
	}

	&.sml {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 6px;

		.setDivider {
			padding: 10px 0;
			font-size: 24px;
		}

		.setExercise {
			grid-template-columns: 4fr 5fr;
			grid-template-rows: auto 1fr;
			font-size: 16px;

			.title {
				grid-column: 1 / -1;
				text-align: center;
				padding: 10px 16px;
				background: $color-neutral-silver;
			}

			.parameters {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 16px;
			}
		}
	}

	&.std {
		grid-template-columns: 1fr 1fr;
		gap: 16px;

		.setDivider {
			padding: 10px 0;
			font-size: 24px;
		}

		.setExercise {
			grid-template-columns: 4fr 5fr;
			grid-template-rows: auto 1fr;
			font-size: 20px;

			.title {
				grid-column: 1 / -1;
				text-align: center;
				padding: 20px 32px 21px 32px;
				background: $color-neutral-silver;
			}

			.parameters {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 16px;
			}
		}
	}

	&.lrg {
		grid-template-columns: 1fr;
		gap: 16px;

		.setDivider {
			padding: 16px 0;
			font-size: 30px;
		}

		.setExercise {
			grid-template-columns: 2fr 5fr;
			grid-template-rows: auto 1fr;
			grid-template-areas: "a b" "a c";

			.thumb {
				grid-area: a;
			}

			.title {
				grid-area: b;
				padding: 16px 40px;
				font-size: 30px;
			}

			.parameters {
				grid-area: c;
				padding: 16px 40px;
				font-size: 26px;
			}
		}
	}

	&.max {
		grid-template-columns: 1fr;
		gap: 20px;

		.setDivider {
			padding: 20px 0;
			font-size: 40px;
		}

		.setExercise {
			grid-template-columns: 1fr 2fr;
			grid-template-rows: auto 1fr;
			grid-template-areas: "a b" "a c";

			.thumb {
				grid-area: a;
			}

			.title {
				grid-area: b;
				padding: 20px 40px;
				font-size: 40px;
			}

			.parameters {
				grid-area: c;
				padding: 20px 40px;
				font-size: 36px;
			}
		}
	}
}
</style>
