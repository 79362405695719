<template>
<div class='saveTemplateFolders'>
	<transition name='fade'>
		<Scroll v-show='loaded'>
			<DynamicTree 
				:data='userTree' 
				:editable='true' 
				:selected='selected'		
				icon='personal' 
				rootTitle='Personal'
				@select='onSelect'
			/>
			<DynamicTree 
				v-for='(teamTree, index) in teamTrees'
				:key='index'
				:data='parseTeamTreeValue(teamTree)' 
				:editable='true' 
				:selected='selected'		
				icon='team' 
				rootTitle='Team'				
				@select='onSelect'
			/>
			<DynamicTree 
				v-if='$store.state.profile.user.orgTemplates === "readWrite"' 
				:data='orgTree' 
				:editable='true' 		
				:selected='selected'		
				icon='organization' 
				rootTitle='Organization'				
				@select='onSelect'
			/>
		</Scroll>
	</transition>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { replaceKeysDeep, arrToKeyedObj } from '@/utilities'
import { foldersApi } from '@/services/api/modules/clinician/folders'
import DynamicTree from '@/components/common/DynamicTree'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'

export default {
	name: 'SaveTemplateFolders',
	components: { DynamicTree, Loading, Scroll },
	data() { return {
		loading: false,
		loaded: false,
		userTree: {},
		teamTrees: [],
		orgTree: {}
	}},
	computed: {
		flattenedTree() {
			const flatten = tree => {
				const arr = []
				const fn = (node, parentId, index = 0) => {
					arr.push({ id: node.id, parentId, title: node.title, index })
					if (node.children) node.children.forEach((child, index) => fn(child, node.id, index))					
				}
				fn(tree)
				return arr
			}
			const flattened = flatten(this.userTree).map(node=>({ type: 'user', ...node }))
			if (this.orgTree) flattened.push(...flatten(this.orgTree).map(node=>({ type: 'org', ...node })))
			if (this.teamTrees) this.teamTrees.forEach(teamTree=>{
				const [teamId, tree] = Object.entries(teamTree)[0]
				flattened.push(...flatten(tree).map(node=>({ type: 'team', teamId, ...node })))
			})
			return arrToKeyedObj(flattened)
		},
		selected() {
			return this.$store.state.saveTemplates.folderId
		}
	},
	methods: {
		parseTeamTreeValue(teamTree) {
			return Object.values(teamTree)[0]
		},		
		fetch() {
			this.loading = true
			foldersApi.touch('fetchTemplateFolders').then(([err, result]) => {
				if (!err) {
					const { folders } = replaceKeysDeep(result, { subordinates: "children" })
					this.userTree = folders.user 
					this.teamTrees = folders.teams ?
						folders.teams.filter(tree=>{
							const teamId = Object.keys(tree)[0]
							return this.$store.state.profile.teams[teamId].teamTemplates==='readWrite'
						}) : []
					this.orgTree = folders.org
				}
				this.loading = false
				this.loaded = true
				if (!this.$store.state.saveTemplates.folderId) this.$store.dispatch('saveTemplates/setFolderId', this.userTree.id)
			})
		},
		onSelect(id) {
			if (id && id !== this.$store.state.saveTemplates.folderId) {
				this.$store.dispatch('saveTemplates/setFolderId', id)
			}
		}
	},
	watch: {
		flattenedTree(a) {
			this.$emit('update', a)
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.saveTemplateFolders {
	@include fill;
	background: $color-neutral-panel;
	position: relative;
	.scrollContent { padding: $size-gutter * 2; padding-right: 0; }
}
</style>