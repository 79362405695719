<template>
<div v-if='presetKeys.length' class='bulkParameterPresets'>
	<SlickList 
		v-model='list' 
		:axis='"y"' 
		:lockAxis='"y"'
		:helperClass='"sortHelper"' 
		:helperSettlingClass='"settling"' 
		:useDragHandle='true'
		:lockToContainerEdges='true'
		:parent='".scrollContainer"' 
		@sort-end='onSortEnd'
	>
		<SlickItem 
			v-for='(presetKey, index) in presetKeys' 
			:key='presetKey' 
			:index='index' 
		>
			<Preset :presetKey='presetKey' />
		</SlickItem>
	</SlickList>
</div>
</template>

<script>
import { SlickList, SlickItem } from '@/libs/slickSort'
import Preset from './Preset'

export default {
	name: 'BulkParameterPresets',
	components: { Preset, SlickList, SlickItem },
	computed: {
		list: {
			get() {
				return this.$store.state.bulkParameterPresets.list
			},
			set(list) {
				this.$store.dispatch('bulkParameterPresets/setList', list)
			}
		},
		presetKeys() {
			return this.$store.state.bulkParameterPresets.list
		}
	},
	methods: {
		onSortEnd({ oldIndex, newIndex }) {
			if (oldIndex !== newIndex) setTimeout(() => this.$store.dispatch('bulkParameterPresets/move', newIndex), 0)
		},
	}
}
</script>

<style lang='scss'>
.bulkParameterPresets {
	margin: 0 $size-gutter * 2;
	border: 1px solid $color-neutral-shadow;
	background: $color-neutral-shadow;
}
</style>


