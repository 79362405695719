/*
* Heaveily modified from: https://www.npmjs.com/package/merge-images
*/

import { drawImage } from 'canvas-object-fit'

const defaultOptions = {
	format: 'image/png',
	quality: 0.92,
	width: undefined,
	height: undefined,
	Canvas: undefined
}

const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
	options = Object.assign({}, defaultOptions, options)

	// Setup 
	const canvas = options.Canvas ? new options.Canvas() : window.document.createElement('canvas')
	const Image = options.Canvas ? options.Canvas.Image : window.Image
	if (options.Canvas) options.quality *= 100
	
	// Load sources
	const images = sources.map(source => new Promise((resolve, reject) => {
		if (source.constructor.name !== 'Object') source = { src: source }
		const img = new Image()
		img.crossOrigin = 'Anonymous' 
		img.onerror = () => reject(new Error('Couldn\'t load image'))
		img.onload = () => resolve(Object.assign({}, source, { img }))
		img.src = source.src
	}))

	const ctx = canvas.getContext('2d')

	// When sources have loaded
	resolve(Promise.all(images)
		.then(images => {
			const getSize = dim => options[dim] || Math.max(...images.map(image => image.img[dim]))
			canvas.width = getSize('width')
			canvas.height = getSize('height')
			ctx.fillStyle = 'white'
			ctx.fillRect(0, 0, canvas.width, canvas.height)

			images.forEach(image => {
				ctx.globalAlpha = image.opacity ? image.opacity : 1
				if (image.width && image.height) ctx.drawImage(image.img, image.x || 0, image.y || 0, image.width, image.height)
				else if (image.x || image.y) ctx.drawImage(image.img, image.x || 0, image.y || 0)
				else drawImage(ctx, image.img, 0, 0, canvas.width, canvas.height, { objectFit: 'contain' })
				ctx.fillStyle = 'red'
				return ctx.fillRect( 0, 0, 1, 1 )
			})

			if (options.Canvas && options.format === 'image/jpeg') {
				return new Promise(resolve => {
					canvas.toDataURL(options.format, {
						quality: options.quality,
						progressive: false
					}, (err, jpeg) => {
						if (err) {
							throw err
						}
						resolve(jpeg)
					})
				})
			}

			return canvas.toDataURL(options.format, options.quality)
		}))
})

export default mergeImages
