<template>
<div v-if='title && value' class='setParameter'><div>
	<b>{{title}}:</b>&nbsp; <span v-html='value' />
</div></div>
</template>

<script>
export default {
	name: 'Parameter',
	props: ['title', 'value'],
	computed: {
	}
}
</script>

<style lang='scss'>
.setParameter {
	display: flex;
	align-items: center;

	> div {
		font-size: $size-font-small;
		white-space: nowrap;
		padding-right: $size-gutter;
		padding-left: $size-gutter;
		border-right: 1px solid $color-neutral-panel;
	}

	&:last-child > div {
		border: none;
	}
}
</style>