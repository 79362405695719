<template>
<div class='setPdfTemplates'>

	<header>{{$t('views.pdf.titleTemplates')}}</header>

	<div class='templates'>
		<div :class='{ selected: selected.includes("cvr") && !cvrDisabled, disabled: cvrDisabled }'>
			<CheckButton 
				:checked='selected.includes("cvr") && !cvrDisabled' 
				@click='$store.dispatch("setPdf/toggleCover")' 
			/>
			<div class='title'>{{$t('views.pdf.lblCoversheet')}}&nbsp; <span class='value'>({{$t('elements.slides.' + $store.state.setPdf.cvr)}})</span></div>
			<Slider 
				:values='["std","lrg","max"]' 
				:initial='$store.state.setPdf.cvr' 
				:translate='true'
				:marks='true'
				@change='v => $store.dispatch("setPdf/setCover", v)' 
			/>
		</div>
		<div :class='{ selected: selected.includes("edu") && !eduDisabled, disabled: eduDisabled }'>
			<CheckButton 
				:checked='selected.includes("edu") && !eduDisabled' 
				@click='$store.dispatch("setPdf/toggleEducation")' 
			/>
			<div class='title'>{{$t('views.pdf.lblEducation')}}&nbsp; <span class='value'>({{$t('elements.slides.' + $store.state.setPdf.edu)}})</span></div>
			<Slider 
				:values='["std","lrg","max"]' 
				:initial='$store.state.setPdf.edu' 
				:translate='true'
				:marks='true'
				@change='v => $store.dispatch("setPdf/setEducation", v)' 
			/>
		</div>		
		<div :class='{ selected: selected.includes("sum") }'>
			<CheckButton 
				:checked='selected.includes("sum")' 
				@click='$store.dispatch("setPdf/toggleSummary")'
			/>
			<div class='title'>{{$t('views.pdf.lblSummary')}}&nbsp; <span class='value'>({{$t('elements.slides.' + $store.state.setPdf.sum)}})</span></div>
			<Slider 
				:values='["sml","std","lrg","max"]' 
				:initial='$store.state.setPdf.sum' 
				:translate='true'
				:marks='true'
				@change='v => $store.dispatch("setPdf/setSummary", v)'
			/>
		</div>
		<div :class='{ selected: selected.includes("cmp") }'>
			<CheckButton 
				:checked='selected.includes("cmp")' 
				@click='$store.dispatch("setPdf/toggleComprehensive")' 
			/>
			<div class='title'>{{$t('views.pdf.lblComprehensive')}}&nbsp; <span class='value'>({{$t('elements.slides.' + $store.state.setPdf.cmp)}})</span></div>
			<Slider 
				:values='["std","lrg","max"]' 
				:initial='$store.state.setPdf.cmp'
				:translate='true'
				:marks='true'
				@change='v => $store.dispatch("setPdf/setComprehensive", v)'
			/>
		</div>
		<div :class='{ selected: selected.includes("imgs") }'>
			<CheckButton 
				:checked='selected.includes("imgs")' 
				@click='$store.dispatch("setPdf/toggleImages")' 
			/>
			<div class='title'>{{$t('views.pdf.lblImages')}}&nbsp; <span class='value'>({{$t('elements.slides.' + $store.state.setPdf.imgs)}})</span></div>
			<Slider 
				:values='["std","lrg","max"]' 
				:initial='$store.state.setPdf.imgs' 
				:translate='true'
				:marks='true'
				@change='v => $store.dispatch("setPdf/setImages", v)'
			/>
		</div>
		<div :class='{ selected: selected.includes("trck") }'>
			<CheckButton 
				:checked='selected.includes("trck")' 
				@click='$store.dispatch("setPdf/toggleTracking")' 
			/>
			<div class='title'>{{$t('views.pdf.lblTracking')}}&nbsp; <span class='value'>({{$store.state.setPdf.trck}} Days)</span></div>
			<Slider 
				:min='7' 
				:max='28' 
				:interval='1' 
				:initial='$store.state.setPdf.trck' 
				@change='v => $store.dispatch("setPdf/setTracking", v)'
			/>
		</div>
		<div :class='{ selected: selected.includes("txt") }'>
			<CheckButton 
				:checked='selected.includes("txt")' 
				@click='$store.dispatch("setPdf/toggleText")' 
			/>
			<div class='title'>{{$t('views.pdf.lblText')}}</div>
		</div>
	</div>

</div>
</template>

<script>
import CheckButton from '@/components/common/buttons/Check'
import Slider from '@/components/common/Slider'

export default {
	name: 'SetPdfTemplates',
	components: { CheckButton, Slider },
	props: ['setKey'],
	computed: {
		selected() { return this.$store.state.setPdf.selected },
		cvrDisabled() { return !this.$store.state.sets.root[this.setKey].title && !this.$store.state.sets.root[this.setKey].instructions },
		eduDisabled() { return !this.$store.state.sets.root[this.setKey].education || !this.$store.state.sets.root[this.setKey].education.length }
	},
	methods: {}
}
</script>

<style lang='scss'>
.setPdfTemplates {
	padding: 0 $size-gutter * 4;

	.templates { 
		border: 1px solid $color-neutral-shadow;
		background: #fff;

		> div {
			position: relative;
			display: grid;
			grid-template-columns: auto 3fr 5fr;
			background: $color-white;
			padding-top: $size-gutter * 2;
			padding-bottom: $size-gutter * 4;
			&:not(:last-child) { border-bottom: 1px solid $color-neutral-shadow; }

			&.selected {

				&:before {
					@include absTopLeft;
					content: '';
					width: 100%;
					height: 100%;
					z-index: 1;
					border: 2px solid $color-focus;
					background: rgba($color-focus, .2);
					pointer-events: none;
				}

				.slider { 
					pointer-events: auto;
					opacity: 1;
				}
			}

			&.disabled {
				pointer-events: none;
				opacity: .4;
			}

			.checkButton {
				display: flex;
				align-items: center;
				margin-left: $size-gutter * 2;
			}

			.title {
				display: flex;
				align-items: center;
				font-size: $size-font-large;
				padding: 0 $size-gutter * 2;
				color: $color-black;
			}

			.slider {
				position: relative;
				z-index: 2;
				margin: 0 $size-gutter * 6;
				opacity: 0.3;
				pointer-events: none;
			}
		}
	}
}
</style>
