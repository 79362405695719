<template>
<div 
	class='attachEducationListItem' 
	:class='{ selected: selected }'
>
	<div class='title'>
		<CheckButton 
			:checked='selected' 
			@click='$emit("toggleSelect", item.id)'
		/>			
		<span class='overflow'>{{item.title}}</span>
		<span class='path overflow' v-html='path' />
	</div>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'AttachEducationListItem',
	mixins: [dateTime],
	components: { CheckButton },
	props: ['item','path','selected'],
	computed: {
		created() { 
			this.langBackdoor
			return this.dateTime(this.item.created) 
		},
		updated() {
			this.langBackdoor
			return this.dateTime(this.item.created) 			
		}
	}
}
</script>

<style lang='scss'>
.attachEducationListItem {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin: 0 $size-gutter * 2;
	cursor: pointer;

	&:hover { background: $color-neutral-silver; }
	&.selected { background: $color-focus !important; }

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		> div > div { font-size: $size-font-small; color: $color-primary-accent; }
	}

	.updated {
		flex: .4;
	}

	.title {
		flex: 1;
		padding-left: 0;
		.path { color: $color-neutral-shadow; margin-left: $size-gutter * 2; }
		.miniButton { margin-left: $size-gutter * 2; }
		> span { white-space: nowrap; }
	}

	.checkCol {
		flex: 0;
		flex-basis: 45px;
		> div { pointer-events: none; }
	}

	.checkButton { padding: 0 $size-gutter * 2; height: 40px; }


	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }


}
</style>