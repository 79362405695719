<template>
<div class='setExerciseThumbnail' :class='{ flipImages: flip }'>
	<div class='flip'><svg class='smallIcon' :class='{ flipSvg: flip }'>
		<use xlink:href='#flip' />
	</svg></div>
	<img v-if='thumbnailUrl' :src='thumbnailUrl' draggable="false" />
</div>
</template>

<script>
export default {
	name: 'ExerciseThumbnail',
	props: ['elementKey', 'thumbnailUrl', 'flip']
}
</script>

<style lang='scss'>
.setExerciseThumbnail {
	width: 100%;
	height: 60px;
	padding: 7px;
	position: relative;
	.flip {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -17px;
		margin-left: -16px;
		z-index: 10;
		background: #fff;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transition: opacity .2s linear;
		pointer-events: none;

		svg { 
			position: relative; top: -2px; 
			transition: transform .2s linear;
			&.flipSvg { transform: scaleX(-1); }
		}
	}

	&:hover .flip { opacity: 1; }

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;	
		object-position: top center;	
	}
}
</style>


