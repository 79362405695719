<template>
<div class='resourceInspector'>
	<Scroll>
		<div ref='resourcesWrapper'>
			<template v-for='(resource, i) in resourcesData'>
				<component 
					:is='resourceComponent(resource.resourceId)' 
					:resource='resource' 
					:focus='i === index' 
					:key='resource.key' 
					:setResourceId='setResourceId'
					:setResourceCaption='setResourceCaption'
					:disabled='disabled'
					:flip='flip'
					@sketchstart='onsketchstart'
					@sketchstop='onsketchstop'
				/>
			</template>
		</div>
	</Scroll>
		<CloseButton @click='$emit("close")' />

</div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Scroll from '@/components/common/Scroll'
import CloseButton from '@/components/common/buttons/Close'
import ResourceInspectorImage from './Image'
import ResourceInspectorVideo from './Video'

export default {
	name: 'ResourceInspector',
	props: ['resources', 'index', 'setResourceId', 'setResourceCaption', 'disabled', 'flip'],
	components: {
		Scroll,
		ResourceInspectorImage,
		ResourceInspectorVideo,
		CloseButton
	},
	data: function() {
		return {
			resourcesData: cloneDeep(this.resources)
		}
	},
	methods: {
		onsketchstart() {
			document.body.classList.add('sketching')
		},
		onsketchstop() {
			document.body.classList.remove('sketching')			
		},
		onClose() {
			//console.log('oh hai')
		},
		resourceComponent(resourceKey) {
			if (this.$store.getters['resources/isImage'](resourceKey)) return 'ResourceInspectorImage'
			else if (this.$store.getters['resources/isVideo'](resourceKey)) return 'ResourceInspectorVideo'
		},
		scrollToIndex() {
			setTimeout(() => {
				if (this.$refs.resourcesWrapper) {
					const last = this.$refs.resourcesWrapper.childNodes[this.resourcesData.length - 1]
					const h = this.$el.offsetHeight - last.offsetHeight + 3
					this.$refs.resourcesWrapper.style.paddingBottom = h+'px'
					const target = this.$refs.resourcesWrapper.childNodes[this.index]
					if (target) target.scrollIntoView(true)
				}
			}, 0)
		},
		getSpacerHeight() {
			const last = this.$refs.resourcesWrapper.childNodes[this.resourcesData.length - 1]
			const h = this.$el.offsetHeight - last.offsetHeight + 3
			return `height: ${h}px;`
		}
	},
	mounted() {
		this.scrollToIndex()
	},
	beforeDestroy() {
		document.body.classList.remove('sketching')			
	}
}
</script>

<style lang='scss'>
body.sketching * { touch-action: none; }

.resourceInspectorModal {
	background: $color-white;
	box-shadow: $box-shadow-lifted-on;
	top: 0 !important;
}

.resourceInspector {
	width: 100%;
	height: 100%;

	.closeButton {
		position: absolute;
		top: $size-gutter;
		right: $size-gutter;
		z-index: 10;
	}

	.simplebar-content-wrapper {
		scroll-snap-type: y mandatory;
	}

	.resourceInspectorImage, .resourceInspectorVideo {
		scroll-snap-align: start;
	}
}
</style>

