<template>
<ResultInfo 
	:searched='searched'
	:total='total'
	:foundPhrase='$t("elements.results.clientsFound")' 
	class='clientsResultInfo'
/>
</template>

<script>
import ResultInfo from '@/components/common/ResultInfo'

export default {
	name: 'ClientsResultInfo',
	components: { ResultInfo },
	props: ['total', 'module'],
	computed: {
		searched() { return this.$store.state[this.module].meta.search }
	}
}
</script>

<style lang='scss'>
.clientsResultInfo {
}
</style>