<template>
<div class='plainText' :class='{ focussed }'>
	<textarea
		@blur='onBlur'
		@focus='onFocus'
		@keydown.enter.prevent
		@keydown.delete.stop
		@keydown='onKeyDown'
		@input='onInput'
		:placeholder='placeholder'
		:maxlength='maxlength'
		:disabled='disabled'
		v-model='innerValue'
		ref='textarea'
	>
	</textarea>
</div>
</template>

<script>
import { /*throttle,*/ escape, unescape } from 'lodash'

export default {
	name: 'PlainText',
	props: ['value', 'placeholder', 'maxlength', 'htmlEntities', 'disabled'],
	data() { return {
		innerValue: this.htmlEntities ? unescape(this.value) : this.value,
		focussed: false
	}},
	methods: {
		focus() {
			this.$refs.textarea.focus()
			this.onFocus()
		},
		onBlur() {
			this.focussed = false
			if (this.$refs.textarea) this.$refs.textarea.scrollTop = 0;
			this.$emit('blur')
		},
		onFocus() {
			this.focussed = true
			this.$emit('focus')
		},
		onKeyDown(e) {
			if (!this.htmlEntities && ['<', '>', '&', '"', '\''].includes(e.key)) {
				e.preventDefault()
				return false
			}
			this.$emit('keydown', e)
		},
		onInput: function() {
			if (this.active) this.$emit('input', this.htmlEntities ? escape(this.innerValue.trim()) : this.innerValue.trim())
		}
		/*
		onInput: throttle(function() {
			if (this.active) this.$emit('input', this.htmlEntities ? escape(this.innerValue.trim()) : this.innerValue.trim())
		}, 250, { leading: false, trailing: true })
	*/
	},
	watch: {
		value(value) {
			if (!this.focussed) this.innerValue = this.htmlEntities ? unescape(value) : value
		}
	},
	mounted() { this.active = true },
	beforeDestroy() { this.active = false },
}
</script>

<style lang='scss'>
.plainText {
	position: relative;

	> textarea {
		display: block;
		resize: none;
		padding: 13px $size-gutter * 2;
		width: 100%;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		background: white;		
		height: 100%;
		
		&::-webkit-scrollbar { 
			display: none; 
		} 
		scrollbar-width: none;

	}

	&:not(.focussed):after {
		pointer-events: none;
		position: absolute;
		z-index: 1;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 13px;
		background: white;
		content: '';
	}
}
</style>

