<template>
<div class='setPdfFooter modalControls'>
	<template v-if='loading'>
		<div class='loadingWrapper'>
			<Loading />
		</div>
	</template>
	<template v-else>
		<CancelButton @click='$emit("close")' />
		<EnabledButton 
			@click='$emit("printPdf")' 
			:disabled='!$store.state.setPdf.selected.length' 
			:svg='"#print"' 
			:label='$t("elements.buttons.print")' 
			class='success' 
		/>
		<EnabledButton 
			@click='$emit("exportPdf")' 
			:disabled='!$store.state.setPdf.selected.length' 
			:svg='"#export"' 
			:label='$t("elements.buttons.export")' 
			class='success' 
		/>
	</template>
</div>
</template>

<script>
import EnabledButton from '@/components/common/buttons/Enabled'
import CancelButton from '@/components/common/buttons/Cancel'
import Loading from '@/components/common/Loading'

export default {
	name: 'SetPdfFooter',
	components: { EnabledButton, CancelButton, Loading },
	props: ['loading']
}
</script>

<style lang='scss'>
.setPdfFooter {
	.loadingWrapper {
		position: relative;
		background: $button-gradient-success;
		.loading { background: none; }
		.spinner { border: none; }
	}
}
</style>
