<template>
<div class='exerciseFolders'>
	<transition name='fade'>
		<div v-show='loaded'>
			<div class='folders iconTextButton'>
				<svg class='miniIcon'>
					<use xlink:href='#tree' />
				</svg>
				<span class='label'>Folders</span>
			</div>
			<DynamicTree 
				:data='userTree' 
				:editable='$store.state.bus.bussing ? canMoveSelectionToFolder : true' 
				:selected='selected'		
				icon='personal' 
				rootTitle='Personal'								
				@select='onSelect'
				@drop='onDrop'
				v-on='canMoveSelectionToFolder ? { mouseenter: onMouseEnter, mouseleave: onMouseLeave } : {}'				
			/>
			<DynamicTree 
				v-for='(teamTree, index) in teamTrees'
				:key='index'
				:data='parseTeamTreeValue(teamTree)' 
				:editable='$store.state.bus.bussing ? canMoveSelectionToFolder && isTeamTreeEditable(teamTree) : isTeamTreeEditable(teamTree)' 
				:selected='selected'		
				icon='team' 
				rootTitle='Team'				
				@select='onSelect'
				@drop='onDrop'			
				v-on='canMoveSelectionToFolder && isTeamTreeEditable(teamTree) ? { mouseenter: onMouseEnter, mouseleave: onMouseLeave } : {}'								
			/>		
			<DynamicTree 
				v-if='orgTree' 
				:data='orgTree' 
				:editable='$store.state.bus.bussing ? canMoveSelectionToFolder && $store.state.profile.user.orgExercises === "readWrite" : $store.state.profile.user.orgExercises === "readWrite"' 		
				:selected='selected'		
				icon='organization' 
				rootTitle='Organization'
				@select='onSelect'
				@drop='onDrop'	
				v-on='canMoveSelectionToFolder && $store.state.profile.user.orgExercises === "readWrite" ? { mouseenter: onMouseEnter, mouseleave: onMouseLeave } : {}'										
			/>
			<DynamicTree 
				v-for='(linkTree, index) in linkTrees'
				:key='`link-${index}`'
				:data='linkTree' 
				:editable='false' 
				:selected='selected'		
				icon='link' 
				:rootTitle='`Link — ${linkTree.link}`'				
				@select='onSelect'
			/>	
		</div>
	</transition>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { walkTreeData } from 'he-tree-vue'
import { replaceKeysDeep, arrToKeyedObj } from '@/utilities'
import { foldersApi } from '@/services/api/modules/clinician/folders'
import DynamicTree from '@/components/common/DynamicTree'
import Loading from '@/components/common/Loading'

export default {
	name: 'ExerciseFolders',
	components: { DynamicTree, Loading },
	props: ['canMoveSelectionToFolder'],
	data() { return {
		loading: false,
		loaded: false,
		userTree: {},
		teamTrees: [],
		orgTree: {},
		linkTrees: [],
		hovering: false
	}},
	computed: {
		flattenedTree() {
			const flatten = tree => {
				const arr = []
				const fn = (node, parentId, index = 0) => {
					arr.push({ id: node.id, parentId, title: node.title, index })
					if (node.children) node.children.forEach((child, index) => fn(child, node.id, index))					
				}
				fn(tree)
				return arr
			}
			const flattened = flatten(this.userTree).map(node=>({ type: 'user', ...node }))
			if (this.orgTree) flattened.push(...flatten(this.orgTree).map(node=>({ type: 'org', ...node })))
			if (this.teamTrees) this.teamTrees.forEach(teamTree=>{
				const [teamId, tree] = Object.entries(teamTree)[0]
				flattened.push(...flatten(tree).map(node=>({ type: 'team', teamId, ...node })))
			})
			if (this.linkTrees) this.linkTrees.forEach(linkTree=>{
				flattened.push(...flatten(linkTree).map(node=>({ type: 'link', ...node })))
			})
			return { selected: this.selected, ...arrToKeyedObj(flattened) }
		},		
		selected() {
			return this.$store.state.exerciseBrowser.folderId
		},
		dropElements() {
			return this.$store.state.bus.bussing && this.$store.state.bus.contentType === 'browserElements'
		}		
	},
	methods: {
		parseTeamTreeValue(teamTree) {
			return Object.values(teamTree)[0]
		},
		isTeamTreeEditable(teamTree) {
			return this.$store.state.profile.teams[Object.keys(teamTree)[0]]?.teamExercises === "readWrite"
		},		
		fetch() {
			this.loading = true
			foldersApi.touch('fetchExerciseFolders').then(([err, result]) => {
				if (!err) {
					const { folders } = replaceKeysDeep(result, { subordinates: "children" })
					this.userTree = folders.user 
					this.$store.dispatch('exerciseBrowser/setUserRootId', folders.user.id)
					this.teamTrees = folders.teams 
					this.orgTree = folders.org
					this.linkTrees = folders.links
				}
				this.loading = false
				this.loaded = true
			})
		},
		onSelect(id) {
			this.$store.dispatch('exerciseBrowser/setFolderId', +id || null)
		},
		onDrop(value) {
			this.hovering = false
			this.$emit('drop', value)
		},
		onMouseEnter() {
			if (this.dropElements) this.hovering = true
		},
		onMouseLeave() {
			this.hovering = false
		}
	},
	watch: {
		flattenedTree(a) {
			this.$emit('update', a)
		},
		hovering(v) {
			if (v) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.exerciseFolders {
	padding-top: $size-gutter * 2;
	.folders {
		justify-content: left;
		border-top: 2px solid transparent;
		padding-left: $size-gutter + 1;
		/*
		border: 1px solid $color-neutral-shadow;
		border-right: none;
		border-bottom: none;
		border-top-left-radius: 7px;		
		background: linear-gradient(to bottom, $color-neutral-accent, $color-neutral-panel);
		*/
	}
}
.dropElements .exerciseBrowserElementsBus {
	transform: scale(0.5);
	box-shadow: 0 10px 30px -2px rgba(#000000, 0.35);
}
</style>