<template>
<div class='bulkParametersHeader'>
	<div class='title iconTextButton'>
		<svg class='smallIcon'>
			<use xlink:href='#bulkParameters' />
		</svg>
		<span class='label'>{{$t("elements.buttons.parameters")}}</span>
	</div>
	<CloseButton @click='$store.dispatch("bulkParameters/toggleOn")' />	
</div>
</template>

<script>
import CloseButton from '@/components/common/buttons/Close'

export default {
	name: 'BulkParametersHeader',
	components: {
		CloseButton
	}
}
</script>

<style lang='scss'>
.bulkParametersHeader {
	display: flex;
	height: $size-control-height;
	align-items: center;
	height: $size-control-height + 1;
	box-shadow: -1px 0 0 0 $color-neutral-shadow;
	background: linear-gradient(to bottom, $color-neutral-silver, $color-neutral-panel);

	.title {
		flex: 1;
		font-size: $size-font-standard;
		padding-left: $size-gutter * 2;
		justify-content: left;
	}

	.closeButton {
		flex: 0;
	}
}
</style>