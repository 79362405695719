<template>
<div 
	:class='{ 
		detail, 
		selected, 
		selecting,
		bussing
	}' 
	v-touchHold='onPull'	
	v-tap='onClick'		
	v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'
	class='exerciseBrowserElement mainElement exerciseBrowserStack ctrlLasso' 
>
<!-- 	v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'	-->
	<div class='icons'>
		<SortButton v-if='showSortable' axes='xy' />
		<component :is='iconComponent' class='elementType'></component>
		<svg class='miniIcon stackIcon'>
			<use xlink:href='#stack' />
		</svg>
		<Favourite :elementKey='element.key' @mousedown.native.stop />
	</div>
	<div class='aspectRatio'>
		<StackThumbnail :elementKey='element.key' class='thumbnail' />
	</div>
	<div class='title'>
		<span>{{element.title}}</span>
		<svg v-if='!$store.state.main.mobile' class='smallIcon' @click.stop='addElementToSet'>
			<use xlink:href='#plus' />
		</svg>
	</div>
	<Loading v-if='loading' />
</div>
</template>

<script>
/*
<template>
<div 
	v-dropOn='onScreen && bussing && !$store.getters["elements/disabled"](element.key)'
	:class='{ 
		offScreen: !onScreen,
		detail, 
		selected, 
		selecting,
		bussing,
		readWrite,
		bussing: $store.state.bus.bussing
	}' 
	v-touchHold='onPull'	
	v-tap='onClick'		
	v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'		
	@dropOn='copyInto'
	class='exerciseBrowserElement mainElement exerciseBrowserStack ctrlLasso' 
>
	<div class='icons'>
		<SortButton v-if='showSortable' axes='xy' />
		<component :is='iconComponent' class='elementType'></component>
		<svg class='smallIcon stackIcon'>
			<use xlink:href='#stack' />
		</svg>
		<Favourite :elementKey='element.key' @mousedown.native.stop />
	</div>
	<div class='aspectRatio'>
		<StackThumbnail :elementKey='element.key' class='thumbnail' />
	</div>
	<div class='title'>
		<span>{{element.title}}</span>
		<svg v-if='!$store.state.main.mobile' class='smallIcon' @click.stop='addElementToSet'>
			<use xlink:href='#plus' />
		</svg>
	</div>
	<Loading v-if='loading' />
</div>
</template>
*/


import { dropOn } from '@/components/common/directives/dropOn'
import ElementBase from './ElementBase'
import StackThumbnail from '@/components/clinician/designer/exerciseBrowser/StackThumbnail'
import UserStackIcons from './UserStackIcons'
import TeamStackIcons from './TeamStackIcons'
import OrgStackIcons from './OrgStackIcons'
import LinkedStackIcons from './LinkedStackIcons'
import Favourite from './Favourite'

export default {
	name: 'Stack',
	extends: ElementBase,
	components: {
		StackThumbnail,
		UserStackIcons,
		TeamStackIcons,
		OrgStackIcons,
		LinkedStackIcons,
		Favourite
	},
	directives: {
		dropOn
	},
	computed: {
		bussing() { 
			return (
				this.$store.state.bus.bussing &&
				['browserElements', 'setElements', 'exerciseResource'].includes(this.$store.state.bus.contentType)
			)
		},
		showSortable() {
			const folderData = this.$store.state.exerciseBrowser.folderData[this.$store.state.exerciseBrowser.folderId]
			if (this.$store.state.main.mobile) return false
			if (this.$store.state.exerciseBrowser.tab === 'starred') return true
			else if (
				this.$store.state.exerciseBrowser.tab === 'custom' && 
				this.$store.state.exerciseBrowser.folderId && 
				(
					folderData && folderData.type === 'user' || 
					(folderData && folderData.type === 'team' && this.$store.state.profile.teams[folderData.teamId].teamExercises === "readWrite") ||
					(folderData && folderData.type === 'org' && this.$store.state.profile.user.orgExercises === 'readWrite')
				)
			) return true
			else return false
		},
		iconComponent() {
			if (this.element.type === 'userStack') return 'UserStackIcons'
			else if (this.element.type === 'teamStack') return 'TeamStackIcons'
			else if (this.element.type === 'orgStack') return 'OrgStackIcons'
			else if (this.element.type === 'linkedStack') return 'LinkedStackIcons'
			else return false
		}
	},
	methods: {
		async copyInto() {
			if (!this.$store.state.bus.bussing || !this.readWrite) return
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
				) return
				const { exercises } = this.$store.getters['elements/getElementsForCopy'](ids, true)
				this.$store.dispatch('exerciseBrowser/copyToStack', { key: this.element.key, exercises })
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { exercises } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				this.$store.dispatch('exerciseBrowser/copyToStack', { key: this.element.key, exercises })
			}
		},
		toggleSelected() {
			this.$store.dispatch('exerciseBrowser/toggleSelectedMain', this.element.key)
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserStack {

	&.dropOn:before {
		content: '';
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		z-index: 10;
		border: 2px solid $color-focus;
		background: rgba($color-focus, .2);
		pointer-events: none;
	}

	&.dropOn.detail:after { background-color: $color-focus; }
/*
	&.bussing.readWrite {
		cursor: copy;
	}
*/
	.icons {
		.stackIcon { position: relative; top: -1px; }		
	} 

	.spinner { transform: scale(0.8); }
}
</style>