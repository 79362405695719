<template>
	<div class='userStackIcons'>
		<svg class='miniIcon'>
			<use xlink:href='#link' />
		</svg>
	</div>
</template>

<script>
export default {
	name: 'LinkedStackIcons'
}
</script>

<style lang='scss'>
</style>


