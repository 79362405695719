<template>
<div class='clientsPanel'>

	<div class='headerPanel'>

		<SearchInput 
			v-model='search'
			@submit='fetchTop'
			:placeholder='$t("views.assignSet.searchClient")'
		/>
<!--
		<div 
			@click='addClient'
			class='addClientButton iconTextButton neutral'
		>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("views.assignSet.newClient")}}</span>
		</div>
-->
	</div>

	<div class='contentPanel'>
		<ScrollForever
			:itemHeight='40'
			:itemKeys='clientKeys'
			:itemComponent='itemComponent'
			:fetchNext='fetchNext'
			:loading='loading'
		>
			<template slot='header'>
				<div class='controls'>
					<div>
						<EnabledButton 
							@click='addClient'
							:svg='"#plus"' :label='$t("elements.buttons.client")' 
							class='neutral tagPrimary addClientButton' 
						/>	
					</div>
					<div>
						<div class='clientMax'>
							<span>Clients: {{$store.state.saveClients.max}}</span>
							<span v-if="teams.length">
								&nbsp;&nbsp;({{teams.map(t=>t.title).join(", ")}})
							</span>
						</div>
						<!--						
						<div v-if='teams.length' class='teams'>
							<span>Shared:</span>
							<div>
								<div 
									v-for='(team, index) in teams'
									:key='index'
									@click='toggleTeam(team.id)'>
									<CheckButton :checked='$store.state.saveClients.teamId===team.id' />
									<span class='label'>{{team.title}}</span>
								</div>	
							</div>
						</div>
					-->
					</div>
				</div>
			</template>
			<template slot='sticky'>
				<ClientsHeader v-if='total' module='saveClients' />
			</template>
		</ScrollForever>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</div>

</div>
</template>

<script>
import Cookies from 'js-cookie'	
import { UI } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import SearchInput from '@/components/common/SearchInput'
import ScrollForever from '@/components/common/ScrollForever'
import Loading from '@/components/common/Loading'
import CheckButton from '@/components/common/buttons/Check'
import EnabledButton from '@/components/common/buttons/Enabled'

import ClientModal from '@/components/clinician/clients/modals/Client'
import ClientsResultInfo from '@/components/clinician/clients/ClientsResultInfo'
import ClientsHeader from './ClientsHeader'
import ClientRow from './ClientRow'

export default {
	name: 'SaveClientSet',
	props: ['emailRequired'],
	mixins: [smallModalMixin],
	components: {
		SearchInput,
		ScrollForever,
		Loading,
		ClientsHeader,
		ClientsResultInfo,
		EnabledButton,
		CheckButton
	},
	data: () => ({
		itemComponent: ClientRow
	}),
	computed: {
		loading() { return this.$store.state.saveClients.apiPending },
		clientKeys() { return this.$store.state.saveClients.list },
		total() { return this.$store.state.saveClients.meta.total },
		search: {
			get() {
				return this.$store.state.saveClients.search
			},
			set(value) {
				this.$store.dispatch('saveClients/setSearch', value)
			}
		},
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		},
	},
	methods: {
		fetchTop() { this.$store.dispatch('saveClients/fetchTop') },
		fetchNext() { this.$store.dispatch('saveClients/fetchNext') },
		addClient() { this.showSmallModal(ClientModal, { listModule: 'saveClients', emailRequired: this.emailRequired }) },
		toggleTeam(teamId) {
			this.$store.commit("saveClients/toggleTeamId", teamId)
			this.ui.set('saveClientsTeamId', this.$store.state.saveClients.teamId)
			this.fetchTop()
		},
	},
	mounted() {
		this.ui = new UI(this.$store.state.profile.user.id)
		const teamId = this.ui.get('saveClientsTeamId')
		if (this.teams.find(t=>t.id===teamId)) this.$store.commit("saveClients/setTeamId", teamId) 
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.saveAndEmailClientSet .clientsPanel {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;
	
	.headerPanel {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		border: 1px solid $color-neutral-shadow;
		background: $color-neutral-shadow;

		.searchInput {
		}
	}	


	.resultInfoWrapper {
		display: flex;
		align-items: bottom;
	}

	.resultsFilter {
		margin-left: auto;
		display: flex;
		padding-right: $size-gutter * 4;
		padding-top: 11px;
		position: relative;

		> div {
			display: flex;
			align-items: center;
			font-size: $size-font-standard;
			padding-left: $size-gutter * 2;
			cursor: pointer;
			.checkButton { margin-right: $size-gutter; pointer-events: none; }
		}
	}	


	.resultInfo {
		margin-top: $size-gutter * 2 + 1;
	}

	.vue-recycle-scroller__item-wrapper {
		top: -1px;
	}

	.contentPanel {
		position: relative;
	}

		.controls {
			display: flex;
			align-items: flex-start;
			padding: $size-gutter * 2;
			/*
			padding-top: $size-gutter * 3;
			padding-right: $size-gutter * 2;
			padding-left: $size-gutter * 2;
			*/
			min-height: 114px;
			> div:first-child { flex: 0; }
			> div:last-child { flex: 1; }

			.addClientButton {
				height: 24px;
			}

			.clientsFound {
				font-size: $size-font-standard;
				padding-left: $size-gutter * 2 + 1;	
				white-space: nowrap;		
				display: flex;
				align-items: center;
				height: 24px;
			}

			.clientMax {
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: right;
				font-size: $size-font-standard;
				padding-right: $size-gutter * 2;
			}

			.teams {
				display: flex;
				align-items: center;
				justify-content: right;
				> span { font-size: $size-font-standard; font-weight: bold; }
				> div {
					display: flex; 
					column-gap: $size-gutter * 2;
					padding: 0 $size-gutter * 2;
					flex-wrap: wrap;
					justify-content: right;
				}
				> div > div {
					display: flex;
					align-items: center;
					min-height: 24px;				
					font-size: $size-font-standard;
					cursor: pointer;				
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
				}
			}	

		}	
}
</style>
