<template>
<div class='setParameters'>
	<Parameter 
		v-for='parameterKey in parameterKeys' 
		:key='parameterKey' 
		:title='$store.state.sets.parameters[parameterKey].title' 
		:value='$store.state.sets.parameters[parameterKey].value' 
	/>
</div>
</template>

<script>
import Parameter from './Parameter'

export default {
	name: 'Parameters',
	props: ['parameterKeys'],
	components: {
		Parameter
	}
}
</script>

<style lang='scss'>
.setParameters {
	display: flex;
	padding-left: $size-gutter;
	padding-right: $size-gutter;
	padding-top: 5px;

	&:empty {
		display: none;
	}
}
</style>