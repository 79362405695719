<template>
<div 
	class='setElement setDivider ctrlLasso'
	:class='{ newElement, selected, selecting, detail, animateDetail }'
>
	<div>
		<div 
			v-touchHold='onPull'	
			v-tap='toggleDetail'	
			v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'	
		>
			<div class='controlsPanel noSelect' @touchstart.stop><div>
				<CheckButton :checked='selected || selecting' @click='toggleSelected' />
			</div></div>
			<div class='contentPanel'>
				<div>
					<div class='title'>{{title}}</div>
					<div class='instructions' v-html='instructions'></div>
				</div>
			</div>
			<div class='closeWrapper'>
				<CloseButton @click='close' @click.native.stop />	
			</div>
		</div>
	</div>
</div>
</template>

<script>
import ElementBase from './ElementBase'

export default {
	name: 'SetExercise',
	extends: ElementBase,
	computed: {
		instructions() {
			return this.element.instructions
		}
	}
}
</script>

<style lang='scss'>
.setElement.setDivider {

	position: relative;
/*
	&.detail > div > div {
		&:after { background: $color-neutral-accent; }
	}
*/
	> div > div {
		background: $color-neutral-accent;
	}
/*
	&:not(.detail) > div:before {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background: $color-primary-accent;
		content: '';
		z-index: 1;
	}
*/
/*
	.controlsPanel {
		background: $button-gradient-primary;
		div { color: white; }
	}
*/
	.contentPanel {
		position: relative;
		display: flex;
		align-items: center;

		> div { overflow: hidden; margin-right: $size-gutter * 2; }

		.title {
			flex: 1;
			font-size: $size-font-standard;
			padding-left: $size-gutter * 2;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: bold;
		}

		.instructions {
			padding-left: $size-gutter * 2;
			padding-top: 5px;
			margin-right: $size-gutter * 2;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: $size-font-small;

			* {
				margin: 0;
				padding: 0;
				display: inline;
				white-space: nowrap;

				&:after { content: ' ' }
			}

			li::before {
				display: none !important;
			}
		}
	}

	.closeWrapper {
		height: $size-control-height * 1.5;
	}

}

.sortHelper.setDivider > div > div:before {
	display: none;
}
</style>