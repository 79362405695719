<template>
<div class='exerciseBrowserElementDetail' :class='{ animateDetail }'>
	<component
		:is='componentType'
		:elementKey='elementKey'
		@copy='$emit("copy")'
		:path='path'
		:canMoveSelectionToStack='canMoveSelectionToStack'
		:canMoveSelectionOutOfStack='canMoveSelectionOutOfStack'
		@moveElementsIntoStack='v=>$emit("moveElementsIntoStack", v)'						
		@moveElementsOutOfStack='v=>$emit("moveElementsOutOfStack", v)'	
		@showContextMenu='e=>$emit("showContextMenu",e)'												
	/>
</div>
</template>

<script>
import ExerciseDetail from '@/components/clinician/designer/exerciseBrowser/ExerciseDetail'
import StackDetail from '@/components/clinician/designer/exerciseBrowser/StackDetail'

export default {
	name: 'ElementDetail',
	props: ['elementKey','folderData','canMoveSelectionToStack','canMoveSelectionOutOfStack'],
	components: {
		ExerciseDetail,
		StackDetail
	},
	computed: {
		path() {
			const folderId = this.$store.state.elements.root[this.elementKey].folderId
			if (!folderId) return null
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (node.parentId) fn(this.folderData[node.parentId])
			}
			if (this.folderData[folderId]) fn(this.folderData[folderId])
			return "/&nbsp;" + (nodes.join("&nbsp;/&nbsp;"))
		},
		componentType() {
			return this.$store.getters['elements/isStack'](this.elementKey) ? 'StackDetail' : 'ExerciseDetail'
		},
		animateDetail() {
			return this.$store.state.exerciseBrowser.animateDetail === this.elementKey
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserElementDetail {
	position: relative;
	top: -1px;
	left: -1px;
	width: calc(100% + 2px);
	padding-bottom: $size-gutter * 2;
	/*
	background: #fff;
	border-left: 1px solid $color-neutral-shadow;
	border-right: 1px solid $color-neutral-shadow;
	*/


/*
	margin-top: $size-gutter * 2 - 1;
	margin-bottom: $size-gutter * 2 - 1;
*/
/*	background: $background-panel-detail; */
	grid-column: 1/-1;

	&.animateDetail {
		> div > div {
			animation: slideDown 600ms $ease-out-quint;
		}				
	}

	> div {
		overflow: hidden;
		padding-top: $size-gutter * 5;
		margin: 0 $size-gutter * 3;
		margin-bottom: $size-gutter * 3;
	}

	& .exerciseBrowserElementDetail {
		padding-bottom: 0;
	}

	.path { 
		font-size: $size-font-small; 
		color: $color-neutral-dark;
		padding-bottom: $size-gutter;
		padding-right: $size-gutter * 2;
		text-align: right;
		font-weight: bold; 
	}

}
</style>