<template>
<div class='attachEducationPreview'>
	<Scroll>
		<div v-for='(item, i) in items' :key='i' class='preview'>
			<div class='img'>
				<a v-if='isAttachment(item.url)' :href="item.url" target="_blank">
					<img :src='getAttachmentImg(item.url)' class='attachment' />
				</a>
				<img v-else-if='item.url' :src='item.url' />
			</div>
			<div class='title'>{{item.title}}</div>
		</div>	
	</Scroll>
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { educationApi } from '@/services/api/modules/clinician/education'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'

export default {
	name: 'AttachEducationPreview',
	components: { Loading, Scroll },
	props: ['selectedIds'],
	data() { return {
		working: false,
		items: {}
	}},
	computed: {
		diff() {
			const loaded = Object.keys(this.items)
			const id = this.selectedIds.filter(id => !loaded.includes(id+''))[0]
			return id
		}
	},
	methods: {	
		onChange() {
			const loaded = Object.keys(this.items)
			const id = this.selectedIds.filter(id => !loaded.includes(id+''))[0]
			if (id) {
				this.fetch(id)
			}
			loaded.forEach(id => {
				if (!this.selectedIds.includes(+id)) this.$delete(this.items, id) 
			})			
		},
		parseResult({ education, resources }) {
			const url = education.resources.length ? resources.find(r => r.id === education.resources[0].resourceId).url : null
			this.items[education.id] = { url, ...education }
		},
		fetch(id) {
			this.working = true
			educationApi.touch('fetch', { id })
				.then(([err, result]) => {
					if (!err) {
						this.parseResult(result)
					}
				})
				.finally(() => this.working = false)
		},
		isAttachment(url) {
			if (!url) return false
			const ext = url.split('?')[0].split('.').pop()
			return ['docx','pdf'].includes(ext)
		},
		getAttachmentImg(url) {
			const ext = url.split('?')[0].split('.').pop()
			return `/img/attachments/${ext}.jpg`
		},
	},
	watch: {
		selectedIds() {
			this.onChange()
		}
	},
	mounted() {
		this.onChange()
	}
}
</script>

<style lang='scss'>
.attachEducationPreview {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto 1fr;
	background: $color-neutral-accent;

	.scrollContent { padding-top: $size-gutter * 2; }

	.preview {

		border: 1px solid $color-neutral-shadow;
		margin: $size-gutter * 2;
		margin-top: 0;
		margin-left: 0;

		.img {
			position: relative;
			padding-top: 75%;
			background: $color-neutral-silver;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				cursor: pointer;
				&.attachment {
					object-fit: contain;
					background: #fff;
					padding: 28px;
				}				
			}
			.closeButton {
				position: absolute;
				top: -5px;
				right: -$size-gutter;
				z-index: 10;
				transition: opacity .2s linear;
				opacity: 0;	
			}		
			&:hover {
				.closeButton { opacity: 1; }
			}				
		}

		.title {
			background: #fff;
			font-size: $size-font-small;
			line-height: $size-lineHeight-small;
			text-align: center;
			padding: $size-gutter * 1.5;
		}

	}	
}
</style>