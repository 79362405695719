<template>
<div 
	class='exerciseBrowserSelectionControls controlBar' 
	:class='{ selecting }'
>
	<div 
		class='iconTextButton neutral'
		v-tap='unselect'
	>
		<svg class='smallIcon'>
			<use xlink:href='#cancel' />
		</svg>
		<span class='label'>{{$t("elements.buttons.unselect")}}</span>
	</div>
	<div 
		v-if='canCopy' 
		class='iconTextButton neutral' 
		v-tap='copy'
	>
		<svg class='smallIcon'>
			<use xlink:href='#copy' />
		</svg>
		<span class='label'>{{$t("elements.buttons.copy")}}: {{total}}</span>
	</div>
	<div 
		v-if='canMoveSelectionToFolder' 
		class='iconTextButton neutral' 
		v-tap='confirmDelete'
	>
		<svg class='smallIcon'>
			<use xlink:href='#delete' />
		</svg>
		<span class='label'>{{$t("elements.buttons.delete")}}: {{total}}</span>
	</div>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'

export default {
	name: 'SelectionControls',
	mixins: [smallModalMixin],
	props: ['canMoveSelectionToFolder'],
	computed: {
		selecting() {
			return !!this.$store.state.exerciseBrowser.selecting.length
		},
		canCopy() {
			if (!this.keys.length || this.keys.length>10) return false
			else return true
		},
		keys() {
			return this.$store.state.bus.bussing ? this.$store.state.bus.bus : this.$store.state.exerciseBrowser.selected
		},
		total() {
			return this.$store.state.exerciseBrowser.selected.length
		}
	},
	methods: {
		onKeyUp(e) {
			if (e.which === 27) this.unselect()
			else if (
				(e.which === 8 || e.which === 46) && 
				e.target === document.body &&
				this.canMoveSelectionToFolder && 
				(!document.getElementById('modals-container') || !document.getElementById('modals-container').childNodes.length)
			) this.confirmDelete()
		},
		confirmDelete() {
			const keys = this.keys.slice()
			const fn = () => this.$store.dispatch('exerciseBrowser/destroy', keys)
			this.showSmallModal(ConfirmDeleteModal, { items: keys.map(key => this.$store.state.elements.root[key].title), fn })
		},
		async copy() {
			const ids = this.keys
			if (
				!this.$store.getters['elements/complete'](ids) &&
				!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
			) return
			const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy'](ids)
			this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks })
			this.$emit('copy')
		},
		unselect() {
			this.$store.dispatch('exerciseBrowser/clearSelected')
		}
	},
	mounted() {
		window.addEventListener('keyup', this.onKeyUp)
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.onKeyUp)
	}
}
</script>

<style lang='scss'>
.exerciseBrowserSelectionControls {
	border-left: none;
	border-right: none;
	border-bottom: none;
	border: none;

	&.selecting {
		pointer-events: none;
	}

}
</style>


