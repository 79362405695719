<template>
<SlickList 
	axis="xy" 
	v-model="list" 
	class='educationItemResources'
	:helperClass='"sortHelper"' 	
	:useDragHandle='true'	
	:lockToContainerEdges='true'	
>
	<SlickItem v-for='(resource, i) in resources' :key='resource.cid' :index="i" class='educationItemResource'>
		<div class='preview' v-tap='()=>showResourceInspector(resource, i)'>
			<SortButton v-if='!$store.state.main.mobile' axes='xy' />
			<CloseButton @click.stop='pop(i)' />
			<a v-if="$store.getters['resources/isAttachment'](resource.resourceId)" :href="getUrl(resource.resourceId)" target="_blank" @click.stop><img :src='getAttachmentImg(resource.resourceId)' class='attachment' /></a>
			<img v-else draggable='false' :src='getUrl(resource.resourceId)' />
		</div>
		<PlainText v-model='resource.caption' :placeholder='$t("views.educationManager.phCaption")' :maxlength='200' :htmlEntities='true' />
	</SlickItem>
	<Upload 
		:maximum='5-resources.length' 
		@create='onUploadComplete'
	/>
</SlickList>
</template>

<script>
import uniqid from 'uniqid'
import { SlickList, SlickItem } from '@/libs/slickSort'
import { resourceInspectorMixin } from '@/components/clinician/designer/resourceInspector/mixin'
import PlainText from '@/components/common/PlainText'
import SortButton from '@/components/common/buttons/Sort'
import CloseButton from '@/components/common/buttons/Close'
import Upload from './Upload'

export default {
	name: 'EducationItemResources',
	components: { SlickList, SlickItem, PlainText, SortButton, CloseButton, Upload },
	mixins: [resourceInspectorMixin],
	props: ['resources'],
	data() { return {
	}},
	computed: {
		list: {
			get() { return this.resources },
			set(v) { this.$emit('update', v) }
		}
	},
	methods: {
		onUploadComplete({ resourceId, filename }) {
			this.list.push({ cid: uniqid(), resourceId, caption: filename })
		},
		getUrl(resourceId) {
			return this.$store.getters['resources/getResource'](resourceId)
		},
		getAttachmentImg(resourceId) {
			const url = this.getUrl(resourceId)
			const ext = url.split('?')[0].split('.').pop()
			return `/img/attachments/${ext}.jpg`
		},
		pop(index) {
			this.list.splice(index, 1)
		},
		showResourceInspector(resource, index) {
			const setResource = (prop, cid, value) => { // icky but this is how the old way works
				const r = this.resources.find(r => r.cid === cid)
				if (r) r[prop] = value
			}
			this.showResourceInpsectorModal({
				resources: this.resources, 
				index, 
				setResourceId: ({ key, value }) => setResource('resourceId', key, value),
				setResourceCaption: ({ key, value }) => setResource('caption', key, value)
				//disabled: payload.disabled
			})			
		}
	},
	watch: {
	},
	mounted() {
	}
}
</script>

<style lang='scss'>
.educationItemResources {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	grid-gap: 16px;
	padding: 1px; 
	.uploadEducationButton:only-child {
		grid-column: 1/-1;
	}
}

.educationItemResource {
	box-shadow: 0 0 0 1px $color-neutral-shadow;
	.preview {
		position: relative;
		padding-top: 75%;
		background: #fff;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			cursor: pointer;
			padding: $size-gutter;
			&.attachment {
				object-fit: contain;
				background: #fff;
				padding: 28px;
			}
		}
		.sortHandle {
			position: absolute;
			top: $size-gutter * 2;
			left: $size-gutter * 2;
			z-index: 10;
			transition: opacity .2s linear;
			opacity: 0;
			background: $color-white;
			padding: 3px;
			padding-bottom: 0;
			border-radius: 50%;
		}
		.closeButton {
			position: absolute;
			top: 2px;
			right: 0;
			z-index: 10;
			transition: opacity .2s linear;
			opacity: 0;	
		}
		&:hover {
			.sortHandle, .closeButton { opacity: 1; }
		}
	}
	.plainText {
		background: #fff;
		textarea { height: 94px; }
	}

	&.sortHelper {
		z-index: 1000;
	}
}
</style>