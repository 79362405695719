<template>
<div class='resourceInspectorVideo' >
	<div class='videoWrapper'><div v-if='onScreen'>
		<VideoPlayer
			:options='playerOptions'
		/>
	</div></div>
	<Caption 
		v-if='!disabled'
		:resource='resource'
		:key='`caption-${resource.key}`'
		:setResourceCaption='setResourceCaption'
	/>
</div>
</template>

<script>
import 'video.js/dist/video-js.css'
import { onScreenMixin } from '@/components/common/mixins/onScreen'
import VideoPlayer from '@/components/common/VideoPlayer'
import Caption from './Caption'

export default {
	name: 'ResourceInspectorVideo',
	props: ['resource', 'setResourceCaption', 'disabled'],
	mixins: [onScreenMixin],
	components: {
		VideoPlayer,
		Caption
	},
	data: () => ({}),
	computed: {
		url() {
			return this.$store.getters['resources/getResource'](this.resource.resourceId, false)
		},
		playerOptions() {
			return {
				preload: 'auto',
				controls: true,
				sources: [{
					type: 'video/mp4',
					src: this.url
				}],
				aspectRatio: '16:9',
				poster: this.$store.getters['resources/getResource'](this.resource.resourceId, 'poster'),
				controlBar: { pictureInPictureToggle: false }
			}
		}
	},
	methods: {
		onScreenEnter() {
			this.destroyObserver()
		}
	}
}
</script>

<style lang='scss'>
.resourceInspectorVideo {
	width: 100%;

	.videoWrapper {
		position: relative;
		width: 100%;
		padding-top: 56.25%;

		> div {
			@include absTopLeft;
			@include fill;
		}
	}

	.vjs-poster {
		background-size: cover;
	}

	margin-bottom: -3px;
}
</style>

