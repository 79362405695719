<template>
	<div 
		class='createExerciseBrowserElement' 
		:class='{ bussing }' 
		v-dropOn='bussing' 
		@dropOn='() => canMoveSelectionToStack ? $emit("move") : $emit("copy")'
	>
		<div 
			class='buttonWrapper'
			v-on='bussing ? { mouseenter: ()=>hovering = true, mouseleave: ()=>hovering=false } : {}'		
			@click='createExercise'
		> 
			<div class='roundButton'>
				<div class='border'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
				</div>
				<span v-if='bussing && canMoveSelectionToStack' class='label'>Move in...</span>
				<span v-else class='label'>{{ $t('elements.buttons.exercise') }}</span>
			</div>
		</div>
		<div 
			v-if='showCreateStack && !bussing' 
			class='buttonWrapper' 
			@click='createStack'
		>
			<div class='roundButton'>
				<div class='border'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
				</div>
				<span class='label'>{{ $t('elements.buttons.stack') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { dropOn } from '@/components/common/directives/dropOn'

export default {
	name: 'CreateButton',
	directives: {
		dropOn
	},	
	props: ['showCreateStack','canMoveSelectionToStack'],
	data() { return { hovering: false }},
	computed: {
		bussing() { 
			return (
				(
					this.$store.state.bus.bussing &&
					['setElements'].includes(this.$store.state.bus.contentType) &&
					this.$store.state.bus.bus.length <= 10
				) || (
					this.canMoveSelectionToStack && this.$store.state.bus.bussing && !this.showCreateStack
				)
			)
		}
	},
	methods: {
		createExercise() {
			this.$emit('create', 'exercise')
		},
		createStack() {
			this.$emit('create', 'stack')
		}
	},
	watch: {
		hovering(v) {
			if (v) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}
		},
		bussing(v) {
			if (!v) this.hovering = false
		}
	}
}
</script>

<style lang='scss'>
.desktop .createExerciseBrowserElement .buttonWrapper:hover {
	.roundButton .border {
		color: $color-white;
		background: $button-gradient-primary;
		border: none;
	}
}

.createExerciseBrowserElement {
	box-sizing: content-box;
	position: relative;
	transform: translateZ(10px);
	margin-bottom: 1px; 

	&:only-child { 
		outline: 1px solid $color-neutral-shadow;
		background: #fff;
	}
/*
	&:hover {
		outline: 1px solid $color-neutral-shadow;
		background: #fff;		
	}
*/
	.buttonWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:only-child {
			height: calc(100% + 1px);
		}

		& + .buttonWrapper {
			top: 50%;
			margin-top: 1px;
			height: calc(50%);
		}

		.roundButton {
			position: relative;
			z-index: 10;
		}
	}

	&:only-child {
		padding-top: $size-aspectRatio-thumbnail;
		height: $size-control-height + 50;
	}

	transition: background 200ms linear, box-shadow 200ms linear;

	&.bussing {
		z-index: 10 !important;
		outline: 1px solid saturate(lighten($color-primary, 20%), 20%);
		background: #fff;
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08);
/*
		.buttonWrapper {
			cursor: copy;
			outline: 2px dashed rgba($color-black, 0.2);
			outline-offset: -$size-gutter * 2;

			* { pointer-events: none; }
		}

		&.dropOn .buttonWrapper {
			&:before {
				content: '';
				position: absolute;
				top: $size-gutter * 2;
				left: $size-gutter * 2;
				width: calc(100% - #{$size-gutter * 4});
				height: calc(100% - #{$size-gutter * 4} + 1px);
				z-index: 0;
				background: rgba($color-focus, .2);
				pointer-events: none;		
			}		
		}
*/
	}
}
</style>