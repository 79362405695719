<template>
<div 
	class='exerciseBrowserStackDetail'
>
	<div v-if='canMoveSelectionOutOfStack && $store.state.bus.bussing' @mouseenter='hoveringOut=true' @mouseleave='hoveringOut=false' @mouseup='moveOutOfStack' class='atTop'>
		<div class='iconTextButton'>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>Move out...</span>
		</div>		
	</div>
	<div v-if='canMoveSelectionOutOfStack && $store.state.bus.bussing' @mouseenter='hoveringOut=true' @mouseleave='hoveringOut=false' @mouseup='moveOutOfStack' class='atBottom'>
		<div class='iconTextButton'>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>Move out...</span>
		</div>	
	</div>
	<!--
	<div v-if='canMoveSelectionToStack && $store.state.bus.bussing && !$store.getters["elements/disabled"](elementKey)'  @mouseenter='hovering=true' @mouseleave='hovering=false' @mouseup='moveIntoStack' class='atIn'>
		<div class='iconTextButton'>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>Move here...</span>
		</div>	
	</div>	
-->
	<Lasso 
		:selectableKeys='stackExerciseKeys'
		:getSelected='getSelected'
		:setSelected='setSelected'
		:setSelecting='setSelecting'
		:max='50'		
		selectableClass='subElement'
	>
		<div class='path' v-html='path' @click="$store.dispatch('exerciseBrowser/setFolderId', stack.folderId)"  />
		<StackControls :stackKey='stack.key' @copy='$emit("copy")'  />
		<StackExercises 
			:stackKey='stack.key' 
			:elementKeys='stackExerciseKeys' 
			:canMoveSelectionToStack='canMoveSelectionToStack'
			@move='moveIntoStack'
			@copy='$emit("copy")' @showContextMenu='e=>$emit("showContextMenu",e)' 
		/>
	</Lasso>
</div>
</template>

<script>
import StackControls from '@/components/clinician/designer/exerciseBrowser/StackControls'

export default {
	name: 'StackDetail',
	props: ['elementKey','path','canMoveSelectionToStack','canMoveSelectionOutOfStack'],
	components: {
		StackControls,
		Lasso: () => import('@/components/common/Lasso'),
		StackExercises: () => import('@/components/clinician/designer/exerciseBrowser/StackExercises')
	},
	data() { return {
		hovering: false,
		hoveringOut: false
	}},
	computed: {
		stack() {
			return this.$store.state.elements.root[this.elementKey]
		},
		stackExerciseKeys() {
			return this.stack.exercises
		}
	},
	methods: {
		moveIntoStack() {
			this.$emit("moveElementsIntoStack", this.stack)
			this.hovering=false
		},
		moveOutOfStack() {
			this.$emit("moveElementsOutOfStack", this.stack)
			this.hovering=false
		},
		getSelected() {
			return this.$store.state.exerciseBrowser.selected
		},
		setSelected(keys) {
			this.$store.dispatch('exerciseBrowser/setSelectedSub', keys)
		},
		setSelecting(keys) {
			this.$store.dispatch('exerciseBrowser/setSelecting', keys)
		}
	},
	watch: {
		hovering(v) {
			if (v) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}
		},
		hoveringOut(v) {
			if (v) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}			
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserStackDetail {
	.atTop {
		position: absolute;
		top: -199px;
		left: 0;
		width: 100%;
		height: 200px;
		content: '';
		background: #fff;
		z-index: 10;
		z-index: 20;
		opacity: 0;
		transition: opacity 200ms;
		&:hover {
			opacity: 0.99;
		}
		display: flex;
		align-items: center;
		justify-content: center;		
		border: 1px solid saturate(lighten($color-primary, 20%), 20%);
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08),
					0 -30px 30px 30px $color-neutral-silver,
					0 20px 0 0 $color-neutral-silver;
	}

	.atBottom {
		position: absolute;
		bottom: -201px;
		left: 0;
		width: 100%;
		height: 200px;
		content: '';
		background: #fff;
		z-index: 10;
		opacity: 0;
		transition: opacity 200ms;
		&:hover {
			opacity: 0.99;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid saturate(lighten($color-primary, 20%), 20%);
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08),
					0 30px 30px 30px $color-neutral-silver;

	}	

	.atIn {
		position: absolute;
		top: $size-gutter * 8 + $size-control-height;
		left: 21px;
		width: calc(100% - 42px);
		height: calc(100% - 130px);
		content: '';
		background: #fff;
		z-index: 10;
		opacity: 0;
		transition: opacity 200ms;
		&:hover {
			opacity: 0.99;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid saturate(lighten($color-primary, 20%), 20%);
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08);

	}		

	.path:hover { color: $color-primary-accent; text-decoration: underline; cursor: pointer; }


	.lassoContainer {
		/*
		padding: $size-gutter * 2;
		background: $color-white;
		border: 1px solid $color-neutral-shadow;
		*/
		overflow: visible;
	}
	&.hovering .exerciseBrowserElements {
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			background: #fff;
			opacity: 0.99;
			z-index: 10;

			border: 1px solid saturate(lighten($color-primary, 20%), 20%);
			box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08),
					0 30px 30px 30px $color-neutral-panel;
			animation: fadeIn 200ms;		
		}	
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: 'Move here...';		
			z-index: 11;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $size-font-standard;
		}
	}
}
</style>


