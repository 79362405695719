<template>
<div class='setPdfHeaderFooters'>

	<header>{{$t('views.pdf.titleHeaderFooters')}}</header>

	<div class='headerFooters'>
		<HeaderFooterList />
	</div>

</div>
</template>

<script>
import Cookies from 'js-cookie'
import HeaderFooterList from '@/components/clinician/settings/headerFooters/List'

export default {
	name: 'SetPdfHeaderFooters',
	components: { HeaderFooterList },
	mounted() {
		const headerFooterId = +Cookies.get(`${this.$store.state.profile.user.id}-headerFooterId`) || null
		if (headerFooterId) this.$store.dispatch('headerFooters/setSelected', headerFooterId)
		//console.log(this.$store.state.headerFooters)
	}
}
</script>

<style lang='scss'>
.setPdfHeaderFooters {
	padding: 0 $size-gutter * 4;

	.headerFooters { 
	}
}
</style>
