<template>
<div 
	class='uploadResource'
	@dragover='onDragOver'
	@dragleave='onDragLeave'
	@drop.prevent='onDrop'
	@mouseleave='onDragLeave'
>
	<div 
		v-show='maximum' 
		class='controls' 
		:style='style'
	>
		<label 
			class='uploadButton iconTextButton' 
			ref='uploadButton'
			:class='{ canDrop: canDrop && !files.length }'
		>
			<svg class='smallIcon'>
				<use xlink:href='#upload' />
			</svg>
			<span class='label'>{{ $t('elements.buttons.upload') }}</span>
			<file-upload
				ref='upload'
				accept='image/*,video/*'
				v-model='files'
				:extensions='imageExtensions.concat(videoExtensions).join(",")'
				:custom-action='uploadToS3'
				:multiple='true'
				:size='1024 * 1024 * 200'
				:thread='$store.state.main.mobile ? 1 : 8'
				:timeout='1000 * 60 * 5'
				:maximum='maximum'
				@input-file='onStart'
				@click.native='catchNativeClick'
			/>
		</label>
		<div 
			@click='onClickSketch' 
			class='sketchButton iconTextButton'
		>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{$t("elements.buttons.sketch")}}</span>
		</div>
	</div>
	<CancelButton 
		v-if='files.length' 
		@click='cancelUpload' 
	/>
	<UploadProgress :files='files' />
</div>
</template>

<script>
import { uploadMixin } from '@/components/common/uploads/mixin'
import { resourceDimensions } from '@/configuration/settings'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'

export default {
	name: 'UploadResource',
	mixins: [uploadMixin, smallModalMixin],
	props: ['maximum'],
	computed: {
		resourceWidth() {
			return this.$store.state.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small
		},
		style() {
			return this.canDrop ? `background: radial-gradient(circle at ${this.x}px ${this.y}px, #ffff99 40%, #f8f8f8 40%, #f8f8f8);` : ``
		}
	},
	methods: {
		onClickSketch() {
			if (!this.dropped) this.$emit('sketch')
		},
		onUploadSuccess(file) {
			return this.$store.dispatch('resources/parseResourcePreviews', file.pendingResource)
				.then(() => this.$emit('create', { resourceId: file.pendingResource.id }))
		},
		onStart(...options) {
			//console.log('here', options)
			this.canDrop = false
			this.onInputFile(...options)
		},
		onUploadError() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.uploadError.title'),
				message: this.$t('views.uploadError.p1')
			})
		}
	}
}
</script>

<style lang='scss'>
.uploadResource {

	position: relative;

	.controls {
		display: flex;
		background-color: $color-neutral-accent;

		.uploadButton {
			flex: 1;
			position: relative;
			transition: all .2s;
			height: $size-control-height * 2;
/*
			&.canDrop:before {
				content: '';
				position: absolute;
				top: $size-gutter * 2;
				left: $size-gutter * 2;
				width: calc(100% - #{$size-gutter * 4});
				height: calc(100% - #{$size-gutter * 4});
				z-index: 0;
				background: $color-focus;
				opacity: 0.5;
			}
*/
		}

		.sketchButton {
			height: $size-control-height * 2;
			flex: 1;
		}
	}
}
</style>