<template>
<div class='bulkParametersWrapper'>
	<div class='bulkParameters'>
		<Header />
		<Content />
	</div>
</div>
</template>

<script>
import Header from './Header'
import Content from './Content'

export default {
	name: 'BulkParameters',
	components: {
		Header,
		Content
	},
	computed: {
		lang() { return this.$store.state.profile.user.lang }
	},
	methods: {
		resetParameters() {
			const parameters = this.$t('parameters').split(',').map(title => ({ title, value: '' }))
			this.$store.dispatch('bulkParameters/resetParameters', parameters)			
		}
	},
	watch: {
		lang() {
			this.resetParameters()
		}
	},
	mounted() {
		this.unsubscribe = this.$store.subscribe(({ type }) => {
			if (type === 'bulkParameters/toggleOn' && this.$store.state.bulkParameters.on) this.resetParameters()
		})
		this.resetParameters()
		this.$store.dispatch('bulkParameterPresets/fetch')
	},
	beforeDestroy() {
		this.unsubscribe()
	}
}
</script>

<style lang='scss'>
.bulkParametersWrapper {
	height: 100%;
	position: relative;
	z-index: 10;
	overflow: hidden;

	.bulkParameters {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		position: relative;
		width: calc(100%);
		height: 100%;
		margin-left: 1px;
		background: $color-neutral-panel;
	}

}
</style>


