<template>
<div 
	class='setElement setExercise ctrlLasso'
	:class='{ newElement, selected, selecting, detail, animateDetail }'
>
	<div>
		<div 
			v-touchHold='onPull'	
			@click='toggleDetail'		
			v-on='!$store.state.main.mobile ? { mousedown: onMouseDown } : {}'	
		>
			<div class='controlsPanel noSelect' @touchstart.stop><div>
				<CheckButton :checked='selected || selecting' @click='toggleSelected' />
			</div></div>
			<div 
				class='thumbnailPanel noSelect'
			><div><div v-if='thumbnailUrl' @click.stop='flipImages'>
				<ExerciseThumbnail 
					:thumbnailUrl='thumbnailUrl'
					:flip='element.reflect'
				/>
			</div></div></div>
			<div class='contentPanel'>
				<div>
					<div class='title'>
						<!--
						<svg
							v-if='thumbnailUrl && !$store.state.main.mobile'
							class='miniIcon'
							:class='{ flip: element.reflect }'
							@click.stop='flipImages'
						>
							<use xlink:href='#flip' />
						</svg>
					-->
					<!--
						<span style='color: red' v-if='element.orig && (element.stockId || element.customId)'>REF</span>&nbsp;
						<span style='color: blue'>{{element.id}} / {{element.draftId}} / {{element.key}}</span>&nbsp;
						<span v-if='no'>{{no}}.  &nbsp;</span>
					-->
					{{title}}
					</div>
					<Parameters :parameterKeys='element.parameters' />
				</div>
			</div>
			<CloseButton @click='close' @click.native.stop />
		</div>
	</div>
</div>
</template>

<script>
import ElementBase from './ElementBase'
import ExerciseThumbnail from './ExerciseThumbnail'
import Parameters from './Parameters'

export default {
	name: 'SetExercise',
	extends: ElementBase,
	components: {
		ExerciseThumbnail,
		Parameters
	},
	props: ['thumbnailUrl','no'],
	methods: {
		flipImages() {
			this.$store.dispatch('sets/toggleReflect', this.element.key)
		}
	}
}
</script>
<style lang='scss'>

.setElement.setExercise {
	position: relative;

	>div>div {
		background: $color-white;
		&:after { background: $color-white; }
	}

	.thumbnailPanel {
		position: relative;
		flex: 0;
		> div {
			width: $size-control-height * 1.5 * 400 / 370;
			height: $size-control-height * 1.5;
			overflow: hidden;
		}

		> div > div { cursor: pointer; }
	}

	.contentPanel {
		display: flex;
		align-items: center;

		>div {

			overflow: hidden; 
			margin-right: $size-gutter * 2;

			.title {
				flex: 1;
				font-size: $size-font-standard;
				padding-left: $size-gutter * 2;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				svg { 
					margin-right: $size-gutter;
					cursor: pointer;
					transition: transform .2s linear;

					&.flip { transform: scaleX(-1); }
				}
			}
		}
	}
/*
	&.dropParameters:hover>div>div:before {
		@include absTopLeft;
		content: '';
		width: 100%;
		height: 100%;
		z-index: 10;
		border: 2px solid $color-focus;
		background: rgba($color-focus, .2);
		
		pointer-events: none;
	}
*/
}
</style>