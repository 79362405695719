<template>
<div class='editParameter'>
	<TextInput 
		v-model='title' 
		ref='title'
		:placeholder='$t("views.designer.phParameterTitle")' 
		:disabled='disabled'
		class='editParameterTitle' 
		:class='{ focussed: focusTitle }'		
		@focus='() => { focusTitle = true; $emit("focus") }'
		@blur='() => { focusTitle = false; $emit("blur") }'
		@keydown='e => $emit("keydown", e)'
		@keydown.down='e => down(e, 0)'
		@keydown.up='e => up(e, 0)'
		@keydown.left='e => left(e, 0)'
		@keydown.right='e => right(e, 0)'
		maxlength='30' 
	/>
	<TextInput 
		v-model='value' 
		ref='value'
		:placeholder='$t("views.designer.phParameterValue")' 
		:disabled='disabled'
		:htmlEntities='true'
		class='editParameterValue' 
		maxlength='50' 
		:class='{ focussed: focusValue }'
		@focus='() => { focusValue = true; $emit("focus") }'
		@blur='() => { focusValue = false; $emit("blur") }'
		@keydown='e => $emit("keydown", e)'
		@keydown.tab='e => down(e, 1)'
		@keydown.down='e => down(e, 1)'
		@keydown.up='e => up(e, 1)'
		@keydown.left='e => left(e, 1)'
		@keydown.right='e => right(e, 1)'
	/>
	<template v-if='!disabled'>
		<SortButton v-if='!$store.state.main.mobile && !focusTitle' class='sortButton blockButton' axes='xy' />
		<div v-handle v-else-if='!editing' class='mobileSortHandle'>
			<div class='focusTitle' v-tap='() => { $refs.title.focus() }' />
			<div class='focusValue' v-tap='() => { $refs.value.focus() }' />
		</div>
		<CloseButton v-if='!focusValue && (!$store.state.main.mobile || !editing)' @click='deleteParameter' v-tap='deleteParameter' />
	</template>
</div>
</template>

<script>
import { HandleDirective } from '@/libs/slickSort'	
import CloseButton from '@/components/common/buttons/Close'
import SortButton from '@/components/common/buttons/Sort'
import TextInput from '@/components/common/TextInput'

export default {
	name: 'EditParameter',
	props: ['parameter', 'disabled', 'editing'],
	directives: {
		handle: HandleDirective
	},	
	components: {
		SortButton,
		CloseButton,
		TextInput
	},
	data: () => ({
		focusValue: false,
		focusTitle: false
	}),
	computed: {
		title: {
			get() {
				return this.parameter.title
			},
			set(value) {
				this.$emit('setTitle', { key: this.parameter.key, value })
			}
		},
		value: {
			get() {
				return this.parameter.value
			},
			set(value) {
				this.$emit('setValue', { key: this.parameter.key, value })
			}
		}
	},
	methods: {
		down(e, index) {
			const nextSibling = this.$el.parentElement.nextElementSibling
			if (nextSibling && nextSibling.childNodes[0].childNodes[index]) {
				e.preventDefault()
				nextSibling.childNodes[0].childNodes[index].focus()
			}
		},
		up(e, index) {
			const prevSibling = this.$el.parentElement.previousElementSibling
			if (prevSibling) {
				e.preventDefault()
				prevSibling.childNodes[0].childNodes[index].focus()
			}
		},
		left(e, index) {
			if (e.target.selectionStart === 0 && e.target.selectionStart === e.target.selectionEnd) {
				if (index) this.$refs.title.focus()
				else this.up(e, 1)
			} 
		},
		right(e, index) {
			if (e.target.value.length === e.target.selectionStart) {
				if (!index) this.$refs.value.focus()
				else this.down(e, 0)
			} 
		},
		deleteParameter() {
			this.$emit('delete', this.parameter.key)
		}
	}
}
</script>

<style lang='scss'>
.editParameter {
	position: relative;
	display: flex;
	overflow: hidden;


	> .sortHandle {
		@include absTopLeft;
		transition: opacity .2s linear;
		opacity: 0;
	}

	> .editParameterTitle {
		text-align: right;
		background: #fff;
		flex: 1;
		padding-left: $size-gutter * 2;
	}

	> .editParameterValue {
		flex: 1;
		background: $color-white;
		padding-right: $size-gutter * 2;
	}

	.focussed { background: $color-focus !important; }

	> .closeButton {
		@include absTopRight;
		transition: opacity .2s linear;
		opacity: 0;
		background: $color-white;
	}

	> .sortButton {
		background: #fff;
	}

	&:hover {
		&:not(.focussed) .closeButton {
			opacity: 1;
		}
		&:not(.focussed) .sortHandle {
			opacity: 1;
		}
	}
}

.mobile .editParameter {

	.mobileSortHandle {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.focusTitle {
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
		}

		.focusValue {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;			
		}
	}

/*
	.closeButton { opacity: 1; }
*/

}
</style>

