<template>
	<div>
		<svg class='miniIcon'>
			<use xlink:href='#personal' />
		</svg>
	</div>
</template>

<script>
export default {
	name: 'UserExerciseIcons'
}
</script>

<style lang='scss'>
</style>


