<template> 
<div class='pagination'> 
	<div v-if='page > 1' @click='()=>$emit("click", page-1)' class='prev'>
		<svg class='smallIcon'>
			<use xlink:href='#caretLeft' />
		</svg>
	</div>
	<template v-for='(p, index) in pages'>
		<div v-if='index && p-pages[index-1]>1' :key='`${p}-ellipsis`' class='ellipsis'>...</div>
		<div v-tap='()=>$emit("click", p)' class='page' :key='p' :class='{ selected: p===page }'>{{p}}</div>
	</template>
	<div v-if='page < totalPages' @click='()=>$emit("click", page+1)' class='next'>
		<svg>
			<use xlink:href='#caretRight' />
		</svg>
	</div>	
</div> 
</template> 
 
<script>
export default {
	name: 'Pagination',
	props: ['totalPages', 'page'],
	components: {},
	data() { return {
		gutter: 3,
		margin: 1
	}},
	computed: {
		pages() {
			const pages = []
			for (let page = 1; page <= this.totalPages; page++) {
				if (
					page <= this.gutter || 
					Math.abs(this.page - page) <= this.margin ||
					page > this.totalPages - this.gutter
				) pages.push(page) 
			}
			return pages
		}
	},
	methods: {
	}
}
</script>

<style lang='scss'>
.pagination {
	display: flex;
	align-items: center;
	font-size: $size-font-standard;
	.page {
		cursor: pointer;
		padding: 0 $size-gutter;
		display: flex;
		align-items: center;
		justify-content: center;
		&.selected {
			cursor: auto;
			border-radius: 12px;
			font-weight: bold;
			color: $color-white;
			background: $button-gradient-primary;
		}
	}
	.ellipsis {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.prev, .next {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;	
		color: $color-black;
		svg { height: 15px; width: 15px; }
	}
	.prev {}
	.next {}
}
</style>