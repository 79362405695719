<template>
<transition-group name='progressBars' tag='div' class='progressBars'>
	<ProgressBar v-for='file in files' :key='file.id' :progress='file.progress' :result='file.result' class='progressBars-transition' />
</transition-group>
</template>

<script>
import ProgressBar from '@/components/common/uploads/ProgressBar'

export default {
	name: 'UploadProgress',
	components: { ProgressBar },
	props: ['files']
}
</script>

<style lang='scss'>
.progressBars {
	padding: $size-gutter * 2;

	&:empty {
		padding: 0;
	}

	.progressBar:not(:only-child) {
		margin-bottom: 1px;
	}
}

.progressBars-transition {
	transition: transform 600ms, opacity 600ms;
	position: relative;
}
.progressBars-enter, .progressBars-leave-to {
	opacity: 0;
}
.progressBars-enter {
	transform: scale(0.5);
}
.progressBars-leave-to {
	transform: scale(0.5);
}
</style>