import { generalApi } from '@/services/api/modules/general'

export const pollDownload = { 
    methods: {
        poll(readyId) {
            let tries = 0
            this.polling = true
            const check = (resolve, reject) => {
                generalApi.touch('downloadReady', { id: readyId })
                    .then(([err, result]) => {
                        if (err) {
                            return reject()
                        } else if (result.ready) {
                            return resolve()
                        } else {
                            tries++
                            if (tries > 120 || !this.polling) return reject()
                            else setTimeout(() => check(resolve, reject), 1000)
                        }
                    })
            }
            return new Promise(check)
        }
    }
} 