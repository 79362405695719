<template>
<div class='editResources'>
	<SlickList 
		v-model='list' 
		:axis='"xy"' 
		:helperClass='"sortHelper"' 
		:helperSettlingClass='"settling"' 
		:useDragHandle='true'
		:lockToContainerEdges='true'
		:pressDelay='$store.state.main.mobile ? 400 : 0'		
		@sort-start='blurAll'		
		ref='columns'
		class='columns'
	>
		<SlickItem 
			v-for='(resource, index) in resources' 
			:key='resource.key' 
			:index='index' 
		>
			<EditResource 
				:resource='resource' 
				:disabled='disabled' 
				@focus='editing=true' 
				@blur='editing=false' 
				:editing='editing'
				v-on='$listeners' 
				:class='{ flipImages: flip }'
			/>
		</SlickItem>
		<div v-if='bussing' class='addResourceButton dropResource' @mouseup='onMouseUp'  :style='buttonStyle'>
			<div class='roundButton'>
				<div class='border'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
				</div>
			</div>
		</div>
		<UploadResource 
			class='addResourceButton'
			v-else-if='!disabled' 
			v-on='$listeners' 
			:maximum='10-resources.length' 
			:style='buttonStyle'
		/>
	</SlickList>
</div>
</template>

<script>
import { SlickList, SlickItem } from '@/libs/slickSort'
import { onResizeMixin } from '@/components/common/mixins/onResize'
import EditResource from './EditResource'
import UploadResource from './UploadResource'

export default {
	name: 'EditResources',
	props: ['flip', 'resources', 'getList', 'setList', 'disabled'],
	mixins: [onResizeMixin],	
	components: {
		SlickList,
		SlickItem,
		EditResource,
		UploadResource
	},
	data: () => ({ startCol: 0, editing: false }),	
	computed: {
		list: {
			get() {
				return this.getList()
			},
			set(list) {
				this.setList(list)
			}
		},
		bussing() { 
			return (
				this.$store.state.bus.bussing && !this.disabled && 
				this.$store.state.bus.contentType === 'exerciseResource' &&
				!this.resources.filter(resource => resource.key === this.$store.state.bus.bus.key).length
			)
		},
		buttonStyle() {
			return `grid-column: ${this.startCol} / -1;` 
		}		
		/*
		buttonStyle() {
			const startCol = this.resources.length % (this.wide ? 3 : 2) + 1
			return `grid-column: ${startCol} / -1;` 
		}
		*/
	},
	methods: {
		blurAll() {
			if ("activeElement" in document)
				document.activeElement.blur()
		},		
		onMouseUp() {
			if (this.$store.state.bus.bussing) {
				this.$emit('create', {
					resourceId: this.$store.state.bus.bus.resourceId,
					caption: this.$store.state.bus.bus.caption
				})
			}
		},
		onResize() {
			const count = Math.floor(this.$refs.columns.$el.clientWidth / 250)
			this.startCol = count ? this.list.length % count + 1 : 1
		}		
	},
	watch: {
		list() { this.onResize() }
	}	
}
</script>

<style lang='scss'>
.editResources {
	overflow: hidden;
	background: $color-neutral-accent;

	.dropResource {
		display: flex;
		align-items: center;
		justify-content: center;
		height: $size-control-height * 2;
		background: $color-neutral-silver;

		&:hover {
			.roundButton .border {
				color: $color-white;
				background: $button-gradient-primary;
			}
		}
	}

	.columns {
		display: grid;
		/* grid-template-columns: 1fr 1fr; */
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));		
	}
/*
	&.wide .columns {
		grid-template-columns: 1fr 1fr 1fr;
	}
*/
}
</style>

