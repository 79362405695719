<template>
<div class='resourceInspectorImage'>
	<div class='imagePanel'>
		<div 
			v-if='!disabled' 
			class='tools' 
			:class='{ drawing }'
		>
			<div 
				class='blockButton' 
				:class='{ primary: tool === "brush", neutral: tool !== "brush" }' 
				@click='() => setTool("brush")'
			>
				<svg class='smallIcon'>
					<use xlink:href='#sketchBrush' />
				</svg>
			</div>
			<div 
				class='blockButton' 
				:class='{ primary: tool === "line", neutral: tool !== "line" }' 
				@click='() => setTool("line")'
			>
				<svg class='smallIcon'>
					<use xlink:href='#sketchLine' />
				</svg>
			</div>
			<div 
				class='blockButton' 
				:class='{ primary: tool === "arrow", neutral: tool !== "arrow" }' 
				@click='() => setTool("arrow")'
			>
				<svg class='smallIcon'>
					<use xlink:href='#sketchArrow' />
				</svg>
			</div>
			<Swatches @setSwatch='setSwatch' @setRadius='setRadius' />
		</div>
		<LazyImage :src='url' :class='{ flipImages: flip }' :contain='true'>
			<Loading />
		</LazyImage>
		<Sketch
			v-if='!disabled'
			ref='sketch' 
			:onBegin='onBegin'
			:onEnd='onEnd'
			:class='{ saving: files.length }'
			:disabled='!tool'
		/>
		<file-upload
			ref='upload'
			accept='image/jpeg'
			v-model='files'
			:extensions='imageExtensions.join(",")'
			:multiple='false'
			:size='1024 * 1024 * 5'
			:drop='false'			
			:thread='1'
			:timeout='1000 * 60'
			:maximum='1'
			:custom-action='uploadToS3'
			@input-file='onInputFile'
		/>
		<transition name='fade'>
			<div 
				v-if='fresh && !files.length' 
				class='modalControls' 
				:class='{ drawing }'
			>
				<CancelButton @click='cancel' />
				<UndoButton @click='undo' :disabled='!$refs.sketch.undo' />
				<div class='saveButton iconTextButton primary' @click='save'>
					<svg class='smallIcon'>
						<use xlink:href='#save' />
					</svg>
					<span class='label'>{{$t('elements.buttons.save')}}</span>
				</div>
			</div>
		</transition>
		<UploadProgress :files='files' />
	</div>
	<div class='captionPanel'>
		<Caption 
			v-if='!disabled'
			:resource='resource'
			:key='`caption-${resource.key}`'
			:setResourceCaption='setResourceCaption'
			@focus='onCaptionFocus'
		/>
	</div>
</div>
</template>

<script>
import { dataURItoBlob } from '@/utilities'
import { eventBus } from '@/utilities/eventBus'
import { uploadMixin } from '@/components/common/uploads/mixin'
import LazyImage from '@/components/common/LazyImage'
import Loading from '@/components/common/Loading'
import CancelButton from '@/components/common/buttons/Cancel'
import UndoButton from '@/components/common/buttons/Undo'
import Sketch from './Sketch'
import Caption from './Caption'
import Swatches from './Swatches'
import mergeImages from './mergeImages'
import flipImage from './flipImage'

export default {
	name: 'ResourceInspectorImage',
	props: ['resource', 'setResourceId', 'setResourceCaption', 'disabled', 'flip'],
	mixins: [uploadMixin],
	components: {
		Loading,
		LazyImage,
		CancelButton,
		UndoButton,
		Swatches,
		Caption,
		Sketch
	},
	data: () => ({
		resourceWidth: 1000,
		imageExtensions: ['jpg'],
		fresh: false,
		penColor: '#000000',
		tool: false,
		drawing: false
	}),
	computed: {
		url() {
			return this.$store.getters['resources/getResource'](this.resource.resourceId, false)
		}	
	},
	methods: {
		onBegin() {
			this.fresh = true
			this.drawing = true
		},
		onEnd() {
			this.fresh = true
			this.drawing = false
		},
		onUploadSuccess(file) {
			return this.$store.dispatch('resources/parseResources', file.pendingResource)
				.then(() => {
					this.setResourceId({ key: this.resource.key || this.resource.cid, value: file.pendingResource.id })
					this.fresh = false
				})
		},
		onCaptionFocus() {
			eventBus.$emit("sketchstop")
		},
		onUploadError() {
			this.cancel()
		},
		stopHandler() {
			this.tool = false
		},
		async save() {
			this.tool = false
			const data = this.flip ? await flipImage(this.$refs.sketch.save()) : this.$refs.sketch.save()
			mergeImages([this.url, { src: data, width: 1000, height: 750 }], { width: 1000, height: 750, format: 'image/jpeg', quality: .95 })
				.then(b64 => {
					const file = new File([dataURItoBlob(b64)], 'canvasImage.jpg', { type: 'image/png' })
					this.$refs.upload.add(file)
				})
		},
		cancel() {
			this.$refs.sketch.clear()
			this.tool = false
			this.fresh = false
		},
		undo() {
			this.$refs.sketch.undoLast()
		},
		setSwatch(swatch) {
			this.$refs.sketch.setColour(swatch)
			if (!this.tool) this.setTool('brush')
		},
		setRadius(radius) {
			this.$refs.sketch.setRadius(radius)
		},
		setTool(tool) {
			if (!this.$refs.sketch) return
			this.tool = tool === this.tool ? false : tool
			this.$refs.sketch.setTool(tool)
		}
	},
	mounted() {
		eventBus.$on('sketchstop', this.stopHandler)
		if (!this.$store.state.main.mobile) this.setTool('brush')
	},
	beforeDestroy() {
		eventBus.$off('stopHandler', this.stopHandler)
	},
	watch: {
		tool(a, b) {
			if (a && !b) {
				this.$emit('sketchstart')
			} else if (!a && b) {
				this.$emit('sketchstop')
				eventBus.$emit('sketchstop')
			}
		}
	}
}
</script>

<style lang='scss'>
.resourceInspectorImage {

	margin: 0 !important; 

	.drawing {
		pointer-events: none;
	}

	.imagePanel {
		position: relative;
		height: 600px;
	}

	.sketch {
		@include absTopLeft;
		width: 100%;
		height: 100%;
		cursor: crosshair;

		&.saving {
			pointer-events: none;
		}
	}

	.tools {
		@include absTopLeft;
		z-index: 2;
		padding: $size-gutter;

		> div {
			margin-bottom: $size-gutter;
		}

	}

	.modalControls {
		@include absBottomLeft;
		width: 100%;
		z-index: 2;

		.saveButton {
			flex: 2;
		}
	}

	.progressBars {
		@include absBottomLeft;
		width: 100%;
		padding-top: 0;
	}

	margin-bottom: -3px;
}
</style>

