<template>
<SlickItem
	v-if='index !== undefined'
	class='setTab' 
	v-tap='onClick'
	:class='{ selected }'
	:index='index' 
><div v-if='selected' v-handle>
	<div 
		@click='togglePin'
		@touchstart.stop
		class='pinButton blockButton'
	>
		<svg class='smallIcon'>
			<use xlink:href='#pin' />
		</svg>
	</div>
	<div class='label' :key='2' @click='rename'>
		<span v-if='selected'>
			<span class='filename'>{{filename}}</span>
			<span v-if='restored' class='restored'>&nbsp;({{$t('views.designer.msgDraftRestored')}})</span>
			<span v-if='client' class='client' @click.stop='editClient'>{{client.firstname}} {{client.lastname}}</span>
		</span>			
	</div>
	<CloseButton @click='close' />
</div><div v-else>
	<div class='label'><span>
		<span class='filename'>{{filename}}</span>
		<span v-if='client' class='client' @click.stop='editClient'>{{client.firstname}} {{client.lastname}}</span>
	</span></div>
</div></SlickItem>
<div v-else
	class='setTab singleTab'
	:class='{ selected }' 
><div>
	<transition name='fade' mode='out-in'>
		<!-- <div v-if='saved' class='label' :key='1'>{{$t('views.designer.msgDraftSaved')}}</div> -->
		<div class='label' :key='2'
			v-tap='rename'
		>
			<span class='filename'>{{filename}}</span>
			<span v-if='client' class='client'>{{client.firstname}} {{client.lastname}}</span>
				<CloseButton 
					v-tap='close'
				/>	
		</div>
	</transition>
	<div 
		v-tap='togglePin'
		class='pinButton blockButton'
	>
		<svg class='smallIcon'>
			<use xlink:href='#pin' />
		</svg>
	</div>
</div></div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import { HandleDirective } from '@/libs/slickSort'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { SlickItem } from '@/libs/slickSort'
import { setsApi } from '@/services/api/modules/clinician/sets'
import CloseButton from '@/components/common/buttons/Close'
import TextInputModal from '@/components/common/modals/TextInput'
import ClientModal from '@/components/clinician/clients/modals/Client'

export default {
	name: 'SetTab',
	mixins: [smallModalMixin],
	props: ['setKey', 'index', 'columns'],
	directives: {
		handle: HandleDirective
	},	
	components: {
		CloseButton,
		SlickItem
	},
//	data: () => ({
//		saved: false
//	}),
	computed: {
		filename() {
			return (
				this.$store.state.sets.root[this.setKey].savename || 
				//this.$store.state.sets.root[this.setKey].title || 
				this.$t('elements.labels.untitled')
			)
		},
//		draftStatus() {
//			return this.$store.state.sets.root[this.setKey].draftStatus
//		},
		restored() {
			return this.$store.state.sets.root[this.setKey].restored
		},
		selected() {
			return this.$store.state.setBuilder.focus === this.setKey
			/*
			let selected
			if (
				this.$store.state.setBuilder.columns &&
				this.$store.state.sets.root[this.$store.state.setBuilder.focus]
			) selected = this.$store.state.setBuilder.list.indexOf(this.$store.state.setBuilder.focus) === index
			else selected = this.tabIndex === index
			if (selected && this.$refs.tabScrollContent) this.$refs.tabScrollContent.children[index].scrollIntoView(true)
			return selected
		*/
		},
		client() {
			const clientKey = this.$store.state.sets.root[this.setKey].clientId
			return clientKey ? this.$store.state.clients[clientKey] : false
		}
	},
	methods: {
		editClient() { 
			this.showSmallModal(ClientModal, { client: this.client }) 
		},		
		onClick() {
			this.$store.dispatch('setBuilder/setFocus', this.setKey)
			this.$store.dispatch('setBuilder/setTabIndex', this.index)
			this.$store.dispatch('setBuilder/clearSelected')
			eventBus.$emit('tabSelected', this.setKey)
			this.$emit('tabSelected', this.index)
		},
		close() {
			this.$store.dispatch('setBuilder/closeSet', this.setKey)
			this.$emit('tabClosed', this.index)
		},
		togglePin() {
			this.$store.dispatch("setBuilder/togglePin", this.setKey)
			this.onClick()
			this.$emit('tabClosed', this.index)
		},
		rename() {
			const fn = async savename => {
				const id = this.$store.state.sets.root[this.setKey].id 
				this.$emit('showSpinner')
				try {
					if (id) await setsApi.touch('updateSet', { id, savename })
					this.$store.dispatch('sets/setSavename', { key: this.setKey, value: savename })
				} finally {
					this.$emit('hideSpinner')
				}
			}
			if (this.selected) this.showSmallModal(TextInputModal, {
				title: this.$t('views.editSetTitle.title'),
				placeholder: this.$t('views.editSetTitle.phSetTitle'),
				value: this.filename,
				maxlength: 100,
				fn
			})	
		}
	},
	watch: {
		selected(v) {
			if (v) {
				const inline = this.columns ? 'start' : 'center'
				this.$el.scrollIntoView({ inline })
			}
		},
		columns() {
			if (this.selected) {
				const inline = this.columns ? 'start' : 'center'
				this.$el.scrollIntoView({ inline })				
			}
		}
	},
	mounted() {
		setTimeout(() => {
			const inline = this.columns ? 'start' : 'center'
			if (this.selected) this.$el.scrollIntoView({ inline })
		})
	}
//	watch: {
//		draftStatus: function(status) {
//			if (status === 'success') {
//				this.saved = true
//				setTimeout(() => this.saved = false, 1200)
//			}
//		}
//	}
}
</script>

<style lang='scss'>
.setTab {

	> div {
		padding-right: $size-gutter * 2;
		padding-left: $size-gutter * 2;
		display: flex;
		align-items: center;
		position: relative;
		font-size: $size-font-standard;
		white-space: nowrap;
		cursor: pointer;
		height: $size-control-height;
		width: 100%;

	}

	.label { display: flex; align-items: center; position: relative; z-index: 1; margin: 0 auto; }

	&:not(.selected)+.setTab:not(.selected) > div::after {
		content: '';
		position: absolute;
		left: 0;
		top: 25%;
		width: 0;
		height: 50%;
		border-left: 1px solid;
		opacity: 0.2;
		border-color: $color-black;
	}

	&.selected {


		> div {
			background: linear-gradient(to bottom, $color-neutral-accent, $color-neutral-panel);
			background: $color-neutral-silver;
			left: 2px;

			box-shadow: 0 1px 0 0 $color-neutral-silver, 1px 0 0 0 $color-neutral-shadow, -1px 0 0 0 $color-neutral-shadow;
			margin: 1px 0;

			.pinButton { padding-left: $size-gutter; }

			.closeButton > svg {
				background: $color-neutral-dark;
			}

			.label {
				color: $color-primary-accent;			
			}

			.label .filename {
				font-weight: bold;
				&:hover { text-decoration: underline; }
			}

			.pinButton { color: $color-primary-accent; }
		}
	}

	.label .client {
		display: block;
		font-size: $size-font-small;
		font-weight: normal;
		color: $color-black;
		&:hover { text-decoration: underline; }
	}	

	.closeButton {
		padding: 0;
		margin-left: $size-gutter * 2;
	}

	.sortHandle {
		position: relative;
		top: 1px;
	}

	&.singleTab {
		position: relative;
		height: $size-control-height + 1;
		> div { border-right: none !important; }
			background: linear-gradient(to bottom, $color-neutral-accent, $color-neutral-panel);
			background: $color-neutral-silver;
		.filename { padding-left: 16px + $size-gutter * 2; }
		.client {
			display: block;
			font-size: $size-font-standard;
			font-weight: normal;
			color: $color-black;
			margin-left: $size-gutter;
		}	
		.pinButton {
			position: absolute;
			top: 0;
			right: 0;
			color: $color-primary-accent; 
		}
	}
}

.sortHelper.setTab {
	border: none;
	&:before { display: none; }
	&:after { display: none; }
}
</style>