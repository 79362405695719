<template>
	<div>
		<video ref='videoPlayer' class='video-js vjs-default-skin vjs-big-play-centered'></video>
	</div>
</template>

<script>
import videojs from 'video.js'

export default {
	name: 'VideoPlayer',
	props: {
		options: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			player: null
		}
	},
	mounted() {
		this.player = videojs(this.$refs.videoPlayer, { ...this.options, muted: true }, function onPlayerReady() {

		})
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose()
		}
	}
}
</script>

<style lang='scss'>
.video-js * {
	outline: none;
}
</style>