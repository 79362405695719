<template>
<div 
	v-tap='() => $emit("click")'
	class='flipImagesButton iconTextButton neutral' :class='{ flip }'
>
	<svg class='smallIcon'>
		<use xlink:href='#flip' />
	</svg>
	<span class='label'>{{$t("elements.buttons.flip")}}</span>
</div>
</template>

<script>
export default {
	name: 'FlipImagesButton',
	props: ['flip']
}
</script>

<style lang='scss'>
.flipImagesButton {
	svg {
		transition: transform .2s linear;
	}
	&.flip svg {
		transform: scaleX(-1);
	}
}
</style>
