<template>
<div>
	<svg v-if='$store.state.exerciseBrowser.tab !== "stock"' class='miniIcon'>
		<use xlink:href='#stock' />
	</svg>
</div>
</template>

<script>
export default {
	name: 'StockExerciseIcons',
	//props: ['elementKey'],
	computed: {
		/*
		hasVideo() {
			const resources = this.$store.getters['elements/getResourcesByExercise'](this.elementKey)
			return !resources.every(resource => this.$store.getters['resources/isImage'](resource.resourceId))
		}
		*/
	}
}
</script>

<style lang='scss'>
</style>


