<script>
import ElementsBase from './ElementsBase'

export default {
	extends: ElementsBase,
	name: 'Elements',
	data: () => ({
		showCreateStackButton: true
	}),
	computed: {
		list: {
			get() {
				return this.$store.state.exerciseBrowser.list
			},
			set(list) {
				this.$store.dispatch('exerciseBrowser/setList', list)
			}
		},
		showCreate() {
			return !!this.$store.state.exerciseBrowser.meta.showCreate
		}
	},
	methods: {
		onSortEnd({ oldIndex, newIndex }) {
			if (oldIndex !== newIndex) this.$nextTick().then(() => this.$store.dispatch('exerciseBrowser/moveElement', newIndex))
		},
		async onCopy() {
			/*
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
				) return
				const { exercises, stacks } = this.$store.getters['elements/getElementsForCopy'](ids)
				this.$store.dispatch('exerciseBrowser/copy', { exercises, stacks })
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { exercises } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				this.$store.dispatch('exerciseBrowser/copy', { exercises })
			}
			*/
			this.$emit('copy')
		},
		onCreate(element) {
			if (element === 'exercise') {
				this.$store.dispatch('exerciseBrowser/createExercise')
					.then(([exerciseKey]) => {
						this.$store.dispatch('exerciseBrowser/toggleDetail', exerciseKey)
							.then(() => this.$store.dispatch('exerciseBrowser/setSelectedMain', [exerciseKey]))
					}) 
			} else if (element === 'stack') {
				this.$store.dispatch('exerciseBrowser/createStack')	
					.then(([stackKey]) => {
						this.$store.dispatch('exerciseBrowser/toggleDetail', stackKey)
							.then(() => this.$store.dispatch('exerciseBrowser/setSelectedMain', [stackKey]))
					}) 				
			}
		}
	}
}
</script>
