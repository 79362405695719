<template>
	<div 
		class='copyToClipboard iconTextButton neutral' 
		v-tap='copyToClipboard'
	>
		<svg class='smallIcon'>
			<use xlink:href='#clipboard' />
		</svg>
		<span class='label'><slot /></span>
		<div v-if='copied' class='highlight' :class='{ fadeOut }' />
	</div>	
</template>

<script>
const copy = require('copy-to-clipboard')

const copyIt = async (html, txt) => {
	if (window.ClipboardItem) {
		await navigator.clipboard.write([
			new window.ClipboardItem({
				'text/plain': new Blob([txt], { type: 'text/plain', }),
				'text/html': new Blob([html], { type: 'text/html' })
			})
		])
	} else {
		copy(txt)
	}
}

const formatPlain = (data, id) => {
	let txt = ''
	if (data.title) txt += `${data.title}\r\n\r\n`
	data.elements.forEach(element => {
		if (element.type === 'setExercise') {
			txt += `- ${element.title}`
			const parameters = element.parameters.filter(parameter => parameter.title && parameter.value)
			if (parameters.length) {
				txt += ` -- `
				parameters.forEach((parameter, i) => txt += `${parameter.title}: ${parameter.value}${i + 1 < parameters.length ? '; ' : ''}`)
			}
			txt += `\r\n`
		} else if (element.type === 'setDivider') {
			txt += `\r\n${element.title}\r\n\r\n`
		}
	})
	if (id) txt+=`\r\n${window.location.protocol}//${window.location.host}/#/clinician/designer/exerciseBrowser/all/setBuilder/${id}`
	return txt
}

const formatHtml = (data, id) => {
	let txt = ''
	if (data.title) txt += `<b>${data.title}</b><br /><br />`
	data.elements.forEach(element => {
		if (element.type === 'setExercise') {
			txt += `- ${element.title}`
			const parameters = element.parameters.filter(parameter => parameter.title && parameter.value)
			if (parameters.length) {
				txt += ` -- `
				parameters.forEach((parameter, i) => txt += `${parameter.title}: ${parameter.value}${i + 1 < parameters.length ? '; ' : ''}`)
			}
			txt += `<br />`
		} else if (element.type === 'setDivider') {
			txt += `<br /><b><i>${element.title}</i></b><br /><br />`
		}
	})
	if (id) txt+=`<br><a href='${window.location.protocol}//${window.location.host}/#/clinician/designer/exerciseBrowser/all/setBuilder/${id}'>${window.location.protocol}//${window.location.host}/#/clinician/designer/exerciseBrowser/all/setBuilder/${id}</a>`
	return txt
}
/*
const htmlDecode = input => {
	const doc = new DOMParser().parseFromString(input, "text/html")
	return doc.documentElement.textContent
}
*/
export default {
	name: 'CopyToClipboard',
	props: ['setKey'],
	data: () => ({ copied: false, fadeOut: false }),
	methods: {
		onKeyDown(e) {
			//console.log(e.target.closest(".setElements"))
			if (this.$store.state.setBuilder.focus !== this.setKey) return
//			if (e.keyCode === 67 && (e.ctrlKey || e.metaKey) && e.target === document.body) {
			if (
				e.keyCode === 67 && 
				(e.ctrlKey || e.metaKey) && 
				!e.target.closest("input") &&
				!e.target.closest("textarea") &&
				!e.target.closest("[contenteditable]")
			) { // in case copying in an input
				e.preventDefault()
				e.stopImmediatePropagation()
				this.copyToClipboard()
			}
		},
		async copyToClipboard() {
			const select = window.getSelection()
			select.removeAllRanges()
			const id = this.$store.state.sets.root[this.setKey].id
			const txt = formatPlain(this.$store.getters['sets/getSetForExport'](this.setKey), id)
			const html = formatHtml(this.$store.getters['sets/getSetForExport'](this.setKey), id)
			await copyIt(html, txt)
			this.copied = true
			setTimeout(() => {
				this.copied = false
				this.fadeOut = false
			}, 1200)
			setTimeout(() => {
				this.fadeOut = true
			}, 0)
			this.$store.dispatch('flash/showAction', 'copyToClipboard')
		}
	},
	mounted() {
		document.addEventListener('keydown', this.onKeyDown)
		
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.onKeyDown)
	}
}
</script>
<style lang='scss'>
.copyToClipboard {
	.highlight {
		@include absTopLeft;
		@include fill;
		z-index: 200;
		background: $color-focus;
		pointer-events: none;
		transition: opacity 1200ms;

		&.fadeOut { opacity: 0; }
	}
}
</style>