<script>
import { uniq } from 'lodash'
import { Distance } from '@/utilities'
//import { onScreenMixin } from '@/components/common/mixins/onScreen'
import SortButton from '@/components/common/buttons/Sort'
import Loading from '@/components/common/Loading'

let animateTimer

export default {
	name: 'Element',
	props: ['element', 'selected', 'selecting', 'detail'],
//	mixins: [onScreenMixin],
	components: {
		SortButton,
		Loading
	},
	data: () => ({
		distance: false 
	}),
	computed: {
		loading() { return this.$store.state.exerciseBrowser.fetchingById.includes(this.element.key) }
	},
	methods: {
		onMouseDown(e) {
			if (e.target.closest('.sortHandle') || this.loading) return
			else if (e.ctrlKey || e.metaKey) this.toggleSelected()
			else if (e.which & e.which === 3) {
				if (!this.selected) {
					this.$store.dispatch('exerciseBrowser/clearSelected').then(()=>this.toggleSelected())
				}
				this.$emit('showContextMenu', e)
				// this.addElementToSet()
			} else new Distance({
				onPull: () => this.onPull(e),
				onStart: () => this.distance = true,
				onStop: () => this.distance = false,
				e
			})
		},
		onClick(e) {
			e.stopPropagation()
			e.preventDefault()
			//setTimeout(() => {
			if (!e.ctrlKey && !e.metaKey && !this.$store.state.bus.bussing && !this.loading) this.toggleDetail()
			//}, 0)
		},
		onPull(e) {
			const pageX = e.pageX ? e.pageX : e.touches[0].pageX
			const pageY = e.pageY ? e.pageY : e.touches[0].pageY
			const rect = this.$el.getBoundingClientRect()
			const offset = this.$el.getClientRects()[0]
			if (!this.selected) this.$store.dispatch('exerciseBrowser/clearSelected')
			const keys = uniq(this.$store.state.exerciseBrowser.selected.concat([this.element.key]))
			const options = Object.assign({
				data: keys,
				contentType: 'browserElements',
				el: this.getClone()
			}, keys.length === 1 ? {
				startX: rect.x,
				startY: rect.y,
				screenX: pageX,
				screenY: pageY,
				originX: (e.clientX - offset.left) / rect.width * 100,
				originY: (e.clientY - offset.top) / rect.height * 100				
			} : null)
			this.$store.dispatch('bus/loadBus', options)
		},
		toggleDetail() {
			if (this.doubleClick) return
			this.doubleClick = true
			setTimeout(() => this.doubleClick = false, 500)
			this.$store.dispatch('exerciseBrowser/toggleDetail', this.element.key)
				.then(() => {
					if (this.$store.getters['exerciseBrowser/detail'](this.element.key)) {
						this.$el.dispatchEvent(new Event('toggleDetail', { bubbles: true }))
						clearTimeout(animateTimer)
						this.$store.dispatch('exerciseBrowser/setAnimateDetail', this.element.key)
							.then(() => {
								animateTimer = setTimeout(() => this.$store.dispatch('exerciseBrowser/setAnimateDetail', false), 1000)
								setTimeout(() => this.$el.scrollIntoView(), 0)
							})
					}
				})	
		},
		getClone() {
			const box = this.$el.getBoundingClientRect()
			const clone = this.$el.cloneNode(true)
			clone.classList.remove('detail', 'selected', 'selecting', 'distance')
			clone.style.width = box.width + 'px'
			return clone
		},
		async addElementToSet() {
			const keys = uniq(this.$store.state.exerciseBrowser.selected.concat([this.element.key]))
			if (
				!this.$store.getters['elements/complete'](keys) &&
				!await this.$store.dispatch('exerciseBrowser/fetchByIds', keys)
			) return
			const { exercises } = this.$store.getters['elements/getElementsForCopy'](keys, true)
			const copyElements = () => this.$store.dispatch('sets/copyElements', { exercises, setKey: this.$store.state.setBuilder.focus })
			if (!this.$store.state.setBuilder.list.length) {
				this.$store.dispatch('setBuilder/createSet') 
					.then(() => this.$store.dispatch('setBuilder/setTabIndex', 0))
					.then(copyElements)
			} else {
				copyElements()
			}
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserElement {
	position: relative;
	padding-bottom: 50px;
	background: $color-white;
	outline: 1px solid $color-neutral-shadow;
	overflow: hidden;

	&.offScreen {
		.thumbnail,
		.title,
		.icons * {
			display: none;
		}
	}

	&.detail {
		overflow: visible;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -$size-gutter * 3 + 1;
			width: 100%;
			height: $size-gutter * 3;
			background: $color-white;
			box-shadow: 0 0 3px 0 $color-white;
			clip-path: polygon(50% 100%, 0 1px, 0 -1px, 100% -1px, 100% 1px);
		}
		
		filter: drop-shadow(0 1px 0 $color-neutral-shadow);
		outline: none;
		box-shadow: -1px 0 0 $color-neutral-shadow, 1px 0 0 $color-neutral-shadow, 0 -1px 0 $color-neutral-shadow;
	}

	&.selected,
	&.selecting {
		&:before {
			@include absTopLeft;
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			border: 2px solid $color-focus;
			background: rgba($color-focus, .2);
			
			pointer-events: none;
		}

		&:after {
			background-color: $color-focus;
			transition: none;
		}
	}

	&:after { background: $color-white; }
	/*
	&.distance {
		&:before {
			@include absTopLeft;
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			border: 2px solid $color-focus;			
			pointer-events: none;
			animation: fadeIn 600ms linear;
		}

		&:after {
			transition: background 600ms linear;
			background: $color-focus;
		}
	}
	*/
	.aspectRatio {
		position: relative;
		padding-top: $size-aspectRatio-thumbnail;

		&:first-child {
			overflow: hidden;
		}
	}

	.title {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		text-align: center;
		padding-left: $size-gutter;
		padding-right: $size-gutter;
		font-size: $size-font-small;
		line-height: $size-lineHeight-small;
		
		transition: background-color .2s linear;

		span {
			flex: 1;
			overflow: hidden;
			max-height: 30px;
		}

		svg {
			position: absolute;
			top: 50%;
			right: 10px;
			margin-top: -8px;
			background: $button-gradient-primary;
			color: $color-white;
			width: 16px;
			height: 16px;
			padding: 3px;
			border-radius: 50%;
			cursor: pointer;
			box-shadow: 0 0 3px 7px $color-white;
			opacity: 0;
			transition: opacity 200ms linear;
		}
	}

	>.icons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		height: $size-control-height;
		padding: 10px;
		color: $color-neutral-dark;
		
		transition: background-color .2s linear;

		.sortHandle {
			position: absolute; top: $size-gutter; left: $size-gutter;
			z-index: 1;
			transition: opacity 200ms linear;
			opacity: 0;
			background: $color-white;
			padding: 3px;
			padding-bottom: 0;
			border-radius: 50%;

			&:after {
				content: '';
				position: absolute;
				top: -50%;
				left: -50%;
				width: 200%;
				height: 200%;
			}

			&:only-child {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.elementType {
			transition: opacity 200ms linear;
			margin-right: auto;
		}

		.favourite { margin-left: auto; }
	}

	&:hover {
		> .icons {
			.sortHandle { opacity: 1; }
			.sortHandle + .elementType { opacity: 0; }
		}
		&:not(.bussing) { 
			.title svg { opacity: 1; }
		}
	}

	.loading {
		background: none !important;
	}
}

.bus .exerciseBrowserElement {
	box-shadow: none;
}
</style>