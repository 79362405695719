<template>
<div class='cancelUploadButton iconTextButton alert' @click='$emit("click")'>
	<svg class='smallIcon'>
		<use xlink:href='#cancel' />
	</svg>
	<span class='label'>{{ $t('elements.buttons.cancel') }}</span>
</div>
</template>

<script>
export default {
	name: 'CancelUpload'
}
</script>

<style lang='scss'>
.cancelUploadButton {
	margin: $size-gutter * 2;
	margin-bottom: 0;
}
</style>