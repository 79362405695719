<template>
<div class='sketch'>
	<canvas 
		class='mainCanvas' 
		ref='mainCanvas' 
	/>
	<canvas 
		class='tmpCanvas' 
		ref='tmpCanvas' 
		:class='{ disabled }'
		@pointermove='onMouseMove' 
		@pointerup='onMouseUp' 
		@pointerdown='onMouseDown' 
	/>
</div>
</template>

<script>
export default {
	name: 'Sketch',
	components: {},
	props: ['onBegin','onEnd','disabled'],
	data: () => ({
		ppts: [],
		undo: null,
		width: 1600,
		height: 1200,
		mouse: { x: 0, y: 0 },
		startMouse: { x: 0, y: 0},
		mainCanvas: null,
		mainCtx: null,
		tmpCanvas: null,
		tmpCtx: null,
		tool: null,
		painting: false
	}),
	computed: {
	},
	methods: {
		initialize() {
			this.mainCanvas = this.$refs.mainCanvas
			this.tmpCanvas = this.$refs.tmpCanvas
			this.mainCanvas.width = this.width
			this.mainCanvas.height = this.height
			this.tmpCanvas.width = this.width
			this.tmpCanvas.height = this.height
			this.tmpCanvas.getContext('2d').scale(2,2)
			this.mainCtx = this.mainCanvas.getContext('2d')
			this.tmpCtx = this.tmpCanvas.getContext('2d')
			this.tmpCtx.lineJoin = 'round'
			this.tmpCtx.lineCap = 'round'
			this.setColour('#000000')
			this.setRadius(6)
			this.setTool('brush')
		},
		onMouseMove(e) {
			this.mouse.x = typeof e.offsetX !== 'undefined' ? e.offsetX : e.layerX
			this.mouse.y = typeof e.offsetY !== 'undefined' ? e.offsetY : e.layerY
		},
		onMouseDown(e) {
			if (typeof this.onBegin === 'function') this.onBegin()
			this.tmpCanvas.addEventListener('pointermove', this.tool, false)
			this.mouse.x = typeof e.offsetX !== 'undefined' ? e.offsetX : e.layerX
			this.mouse.y = typeof e.offsetY !== 'undefined' ? e.offsetY : e.layerY
			this.startMouse.x = this.mouse.x
			this.startMouse.y = this.mouse.y
			this.ppts.push({ x: this.mouse.x, y: this.mouse.y })
			this.tool()
		},
		onMouseUp() {
			if (typeof this.onEnd === 'function') this.onEnd()
			this.undo = this.mainCanvas.toDataURL()
			this.tmpCanvas.removeEventListener('pointermove', this.tool, false)
			this.mainCtx.drawImage(this.tmpCanvas, 0, 0)
			this.tmpCtx.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height)
			this.ppts = []
		},
		onPaint() {
			this.ppts.push({ x: this.mouse.x, y: this.mouse.y })
			if (this.ppts.length < 3) {
				const b = this.ppts[0]
				this.tmpCtx.beginPath()
				this.tmpCtx.arc(b.x, b.y, this.tmpCtx.lineWidth / 2, 0, Math.PI * 2, !0)
				this.tmpCtx.fill()
				this.tmpCtx.closePath()
				return
			}
			this.tmpCtx.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height)
			this.tmpCtx.beginPath()
			this.tmpCtx.moveTo(this.ppts[0].x, this.ppts[0].y)
			for (var i = 1; i < this.ppts.length - 2; i++) {
				const c = (this.ppts[i].x + this.ppts[i + 1].x) / 2
				const d = (this.ppts[i].y + this.ppts[i + 1].y) / 2
				this.tmpCtx.quadraticCurveTo(this.ppts[i].x, this.ppts[i].y, c, d)
			}
			this.tmpCtx.quadraticCurveTo(
				this.ppts[i].x,
				this.ppts[i].y,
				this.ppts[i + 1].x,
				this.ppts[i + 1].y
			);
			this.tmpCtx.stroke()
		},
		onLine() {
			this.tmpCtx.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height)
			this.tmpCtx.beginPath()
			this.tmpCtx.moveTo(this.startMouse.x, this.startMouse.y)
			this.tmpCtx.lineTo(this.mouse.x, this.mouse.y)
			this.tmpCtx.stroke()
			this.tmpCtx.closePath()
		},
		onArrow() {
			const tox = this.mouse.x
			const toy = this.mouse.y
			const dx = tox - this.startMouse.x
			const dy = toy - this.startMouse.y
			const angle = Math.atan2(dy, dx)
			this.tmpCtx.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height)
			this.tmpCtx.beginPath()
			this.tmpCtx.moveTo(this.startMouse.x, this.startMouse.y)
			this.tmpCtx.lineTo(tox, toy)
			if (dx || dy) {
				const dxy = Math.sqrt(dx*dx + dy*dy)
				const dh = dxy > 200 ? this.arrowHead : Math.min(this.arrowHead * dxy / 200, this.arrowHead)

				this.tmpCtx.lineTo(tox - dh * Math.cos(angle - Math.PI / 6), toy - dh * Math.sin(angle - Math.PI / 6))
				this.tmpCtx.moveTo(tox, toy)
				this.tmpCtx.lineTo(tox - dh * Math.cos(angle + Math.PI / 6), toy - dh * Math.sin(angle + Math.PI / 6))
			}
			this.tmpCtx.stroke()
			this.tmpCtx.closePath()
		},
		setColour(colour) {
			this.tmpCtx.strokeStyle = colour
			this.tmpCtx.fillStyle = colour
		},
		setRadius(radius) {
			this.arrowHead = Math.sqrt(radius * 1000)
			this.tmpCtx.lineWidth = radius * 2
		},
		setTool(tool) {
			if (tool === 'line') this.tool = this.onLine
			else if (tool === 'arrow') this.tool = this.onArrow
			else this.tool = this.onPaint
		},
		clear() {
			this.mainCtx.clearRect(0, 0, this.tmpCanvas.width, this.tmpCanvas.height)			
		},
		save() {
			return this.mainCanvas.toDataURL()
		},
		undoLast() {
			if (this.undo) {
				this.clear()
				const img = new Image
				img.onload = () => this.mainCtx.drawImage(img, 0, 0)
				img.src = this.undo
				this.undo = null
			}
		}
	},
	mounted() {
		this.initialize()
	}
}
</script>

<style lang='scss'>

.sketch {
	position: relative;

	canvas {
		width: 100%;
		height: 100%;
		background: transparent;

		&.disabled { pointer-events: none; }
	}

	.tmpCanvas {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		cursor: crosshair;
	}
}
</style>

