<template>
<div class='editParameters'>
	<SlickList 
		v-model='list' 
		:axis='"xy"' 
		:helperClass='"sortHelper"' 
		:helperSettlingClass='"settling"' 
		:useDragHandle='true'
		:lockToContainerEdges='true'
		:pressDelay='$store.state.main.mobile ? 400 : 0'		
		@sort-start='blurAll'
		ref='columns'
		class='columns'
	>
		<SlickItem 
			v-for='(parameter, index) in parameters' 
			:key='parameter.key' 
			:index='index' 
			class='parameterWrapper'
		>
			<EditParameter :parameter='parameter' :disabled='disabled' v-on='$listeners' @focus='editing=true' @blur='editing=false' :editing='editing' />
		</SlickItem>
		<div 
			v-if='!disabled && parameters.length <= 15' 
			v-tap='() => $emit("create")'
			class='iconTextButton neutral' 
			:style='buttonStyle'
		>
			<svg class='smallIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>{{ $t('elements.buttons.parameter') }}</span>
		</div>
	</SlickList>
</div>
</template>

<script>
import { SlickList, SlickItem } from '@/libs/slickSort'
import { onResizeMixin } from '@/components/common/mixins/onResize'
import EditParameter from './EditParameter'

export default {
	name: 'EditParameters',
	props: ['parameters', 'getList', 'setList', 'disabled'],
	mixins: [onResizeMixin],
	components: {
		SlickList,
		SlickItem,
		EditParameter
	},
	data: () => ({ startCol: 0, editing: false }),
	computed: {
		list: {
			get() {
				return this.getList()
			},
			set(list) {
				this.setList(list)
			}
		},
		buttonStyle() {
			return `grid-column: ${this.startCol} / -1;` 
		}
	},
	methods: {
		blurAll() {
			if ("activeElement" in document)
				document.activeElement.blur()
		},
		onResize() {
			const count = Math.floor(this.$refs.columns.$el.clientWidth / 200)
			this.startCol = count ? this.list.length % count + 1 : 1
			//console.log(gridComputedStyle.getPropertyValue("grid-template-columns"))
			//console.log(this.startCol)
		}
	},
	watch: {
		list() { this.onResize() }
	}
}
</script>

<style lang='scss'>
.editParameters {

	background: $color-neutral-accent;

	.columns {
		display: grid;
		/* grid-template-columns: 1fr 1fr; */
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
/*
	&.wide .columns {
		grid-template-columns: 1fr 1fr 1fr;
	}
*/
	overflow: hidden;
}
</style>

