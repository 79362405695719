<template>
<div class='exerciseBrowserElementsHeader' :class='{ bussing: $store.state.bus.bussing }'>
	<div class='path'>
		<template v-if='path'>
			<div class='content'>
				<span 
					v-for='(node, index) in path' 
					:key='index'
					@click='$store.state.exerciseBrowser.tab === "custom" ? $store.dispatch("exerciseBrowser/setFolderId", node.id) : $store.dispatch("categories/setSelected", node.id)'
				>/&nbsp;&nbsp;<span>{{node.title}}</span>&nbsp;&nbsp;
				</span>
			</div>
			<CloseButton @click='closePath' />	
		</template>
		<div v-else-if='$store.state.exerciseBrowser.tab==="custom"' class='content'>Results from all folders...</div>
		<div v-else-if='$store.state.exerciseBrowser.tab==="stock"' class='content'>Results from all categories...</div>
	</div>

	<ResultInfo 
		:total='total'
		:foundPhrase='$t("elements.results.found")' 
	/>		

	<div class='paginationWrapper'>
		<Pagination 
			v-if='$store.state.exerciseBrowser.meta.total > 100'
			:totalPages='$store.getters["exerciseBrowser/totalPages"]' 
			:page='$store.state.exerciseBrowser.page'
			@click='page => $store.dispatch("exerciseBrowser/setPage", page)' 
		/>	
	</div>

	<div v-if='subfolders' class='subfolders'>
		<div 
			v-for='(folder, index) in subfolders' 
			:key='index'
			@click='$store.dispatch("exerciseBrowser/setFolderId", folder.id)'
			class='folder'
		>
			<svg class='smallIcon'>
				<use xlink:href='#folders' />
			</svg>		
			<span class='label'>{{folder.title}}</span>
		</div>
	</div>

	<div v-if='categories' class='categories'>
		<div 
			v-for='(category, index) in categories' 
			:key='index'
			v-tap='() => selectCategory(category.categoryId)'
			class='category textButton tagPrimary'
		>			
			<span class='label'>{{getCategoryTitle(category.categoryId)}} <b>({{category.total}})</b></span>
		</div>
	</div>
	<!--
	<div v-else-if='children.length' class='categories'>
		<div 
			v-for='(folder, index) in children' 
			:key='index'
			class='category textButton tagPrimary'
		>
			<span class='label'>{{folder.title}}</span>
		</div>
	</div>
-->
</div>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import CloseButton from '@/components/common/buttons/Close'
import CheckButton from '@/components/common/buttons/Check'
import ResultInfo from '@/components/common/ResultInfo'

export default {
	name: 'ElementsHeader',
	props: ['path','children','folders','showSubfolders'],
	components: {
		CloseButton,
		ResultInfo,
		CheckButton,
		Pagination
	},
	computed: {
		total() {
			return this.$store.state.exerciseBrowser.meta.total
		},
		searched() {
			return this.$store.state.exerciseBrowser.meta.search
		},
		categories() {
			const categories = this.$store.state.exerciseBrowser.meta.categories
			return categories && categories.length > 1 ? categories : false
		},
		showResultsFilter() {
			return this.$store.state.exerciseBrowser.tab === 'custom'
		},
		subfolders() {
			if (!this.showSubfolders) return null
			if (this.$store.state.exerciseBrowser.meta.folderId) {
				return Object.values(this.$store.state.exerciseBrowser.folderData).filter(f=>f && f.parentId === this.$store.state.exerciseBrowser.meta.folderId).sort((a,b)=>a.index-b.index)
			} else if (this.$store.state.exerciseBrowser.tab==='custom' && !this.$store.state.exerciseBrowser.meta.search) {
				return [
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='user'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='team'),
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='org'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='link')
				].filter(v=>v)
			} else {
				return null
			}
		}
	},
	methods: {
		getCategoryTitle(categoryId) {
			return this.$store.state.categories.tree.find(category => category.id === categoryId).title
		},
		selectCategory(categoryId) {
			this.$store.dispatch('categories/setSelected', categoryId)
		},
		closePath() {
			if (this.$store.state.exerciseBrowser.tab==='stock') this.$store.dispatch("categories/setSelected", null)
			else if (this.$store.state.exerciseBrowser.tab==='custom') this.$store.dispatch("exerciseBrowser/setFolderId", null)
		}
	}
}
</script>

<style lang='scss'>
.exerciseBrowserElementsHeader {

	&.bussing { pointer-events: none; }

	padding-bottom: $size-gutter * 2;
	padding-top: $size-control-height + $size-gutter * 2 + 1;
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	flex-wrap: wrap;
	min-height: $size-control-height * 3 + 31;
	margin-right: $size-gutter * 2;
	.path {
		height: $size-control-height;
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: $size-font-standard;
		color: $color-primary-accent;
		border: none;
		white-space: nowrap;
		margin-right: auto;
		margin-left: $size-gutter * 2;
		.content > span { cursor: pointer; }
		.content > span:hover > span { text-decoration: underline; }
	}

	.resultInfo {
		height: $size-control-height;
		padding: 0;
		font-weight: normal;
		margin-bottom: 0;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 2;
		> div, .total { margin: 0; padding: 0; }
		> div > div { height: $size-control-height;   }
	}	

	.paginationWrapper {
		height: $size-control-height;
		display: flex;
		align-items: center;
		margin-left: $size-gutter * 2;
		margin-right: $size-gutter * 2;
		&:empty { display: none; }
	}

	.categories {
		margin-right: $size-gutter * 2;
		margin-bottom: $size-gutter;
		margin-top: $size-gutter;
		padding: 1px;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.category {
			margin-right: 1px;
			margin-bottom: 1px;
			padding: 0; 
			height: auto;
		}
	}

	.subfolders {
		flex-basis: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		min-height: $size-control-height;
		padding-bottom: $size-gutter;
		padding-top: $size-gutter;
		/* background: #fff; */
		border: 1px solid transparent;

		.folder {
			margin: 3.5px $size-gutter;
			padding: 0; 
			font-size: $size-font-small;
			align-items: center;
			display: flex;
			padding-left: $size-gutter;
			cursor: pointer;
			padding-bottom: 1px;
			color: $color-primary-accent;
			font-weight: bold;
			svg { margin-right: $size-gutter * 1.5; color: $color-neutral-dark; transform: scale(0.9);}
			&:hover { 
				text-decoration: underline;
				svg { color: $color-primary-accent; }
			}
		}		
	}
}
</style>