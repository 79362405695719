<template>
<div class='categoriesTree'>
	<div class='header'>
		<div class='title iconTextButton'>
			<svg class='miniIcon'>
				<use xlink:href='#tree' />
			</svg>
			<span class='label'>{{$t("elements.buttons.categories")}}</span>
		</div>
	</div>
	<div class='content'>
		<ul class='tree'>
			<Category 
				v-for='node in $store.state.categories.tree'
				:key='node.id'
				:node='node' 
			/>
		</ul>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>				
</div>
</template>

<script>
import Loading from '@/components/common/Loading'
import Category from './Category'

export default {
	name: 'Categories',
	components: {
		Category,
		Loading
	},
	data() { return { working: false }},
	async mounted() {
		if (!this.$store.state.categories.tree.length) {
			this.working = true
			await this.$store.dispatch('categories/fetch')
			this.working = false
		}
		if (this.$route.params.folderId && this.$store.state.exerciseBrowser.tab==='stock') this.$store.dispatch('categories/setSelected', +this.$route.params.folderId)
	}
}
</script>

<style lang='scss'>
.categoriesTree {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	padding-top: $size-gutter * 2;
	padding-bottom: $size-gutter * 2;
	min-height: 100%;

	.header {
		display: flex;
		height: $size-control-height;
		padding-left: 0;
		/*
		background: linear-gradient(to bottom, rgba(0,0,0,.05), rgba(0,0,0,0));

			background: linear-gradient(to bottom, $color-neutral-accent, $color-neutral-panel);
			*/
		border: 1px solid transparent;
		border-top-left-radius: 7px;
		border-bottom: transparent;
		border-right: none; 
		padding-left: $size-gutter;

		.title {
			color: $color-black;
			padding-left: 0;
			padding-right: 0;
		}


		.closeButton {
			flex: 0;
			margin-left: auto;
		}
	}

	.content {
		position: relative;
			padding-left: $size-gutter + 1;

	}

	.tree {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		color: $color-black;
		list-style: none;
		padding: 0;
		padding-right: $size-gutter * 2;
		padding-left: $size-gutter * 2.5 - 1;
		margin: 0;
		margin-top: 9px;
	}
}
</style>


