<template>
	<ConfirmModal 
		@close='$emit("close")'
		:title='$t("views.updateClientSet.title")'
		:message='message'
		:ok='`${$t("views.updateClientSet.ok")} ${user.firstname} ${user.lastname}`'
		:fn='fn'
		:doDefaultOnEnter='false'
	/> 
</template>

<script>
import { fill } from '@/utilities'
import ConfirmModal from '@/components/common/modals/Confirm'

export default {
	name: 'SignClientSet',
	components: {
		ConfirmModal
	},
	props: ['fn', 'client', 'clientSet'],
	computed: {
		user() { return this.$store.state.profile.user },
		message() {
			const client = this.client.firstname +' '+ this.client.lastname 
			let msg = fill(this.$t('views.updateClientSet.p1'), {
				user: this.user.firstname +' '+ this.user.lastname,
				client
			})
			if (this.clientSet.enabled) msg += `<br /><br />${fill(this.$t('views.updateClientSet.p2'), { client })}`
			return msg
		}
	}
}
</script>

