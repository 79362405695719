<template>
<div class='saveAndEmailClientSet'>
	<ClientsPanel :emailRequired='emailRequired' />
	<ClientPanel 
		v-if='showClientPanel'  
		:savename='savename' 
		@close='$emit("close")' 
		@save='save' 
	/>
	<div v-else class='cancelFooter modalControls'>
		<CancelButton @click='$emit("close")' />
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import CancelButton from '@/components/common/buttons/Cancel'
import ClientModal from '@/components/clinician/clients/modals/Client'
import ClientsPanel from './ClientsPanel'
import ClientPanel from './ClientPanel'

export default {
	name: 'SaveAndEmailClientSet',
	mixins: [smallModalMixin],
	components: {
		Loading,
		ClientsPanel,
		ClientPanel,
		CancelButton
	},
	props: ['setKey', 'emailRequired', 'onSave', 'cb'],
	data: () => ({
		showClientPanel: false,
		loading: false
	}),
	computed: {
		client() { return this.$store.getters['saveClients/detail'] },
		savename() {
			return (
				this.$store.state.sets.root[this.setKey].savename //|| 
				//this.$store.state.sets.root[this.setKey].title
			)
		},
		err() {
			return this.$store.state.saveClients.apiError || this.$store.state.saveClientSets.apiError
		}
	},
	methods: {
		save({ savename, email }) {
			const fn = async () => {
				if (this.$store.state.profile.user.clinicianType === 'demo') {
					this.$emit('close')
					return this.$store.dispatch('flash/showAlert', 'notAvailableInDemo')
				}				
				this.loading = true
				await this.$store.dispatch('sets/saveNewClientSet', {
					setKey: this.setKey,
					clientId: this.$store.state.saveClients.detail,
					savename,
					enabled: !!this.emailRequired
				})
				//console.log(result)
				await this.$store.dispatch('sets/email', {
					setKey: this.setKey,
					email
				})			
				this.cb()	
				this.$emit('close')
			//	if (typeof this.onSave === 'function') this.onSave()
			}
			if (this.emailRequired && (!this.client.email || !this.client.portalKey || this.client.emailErr)) {
				this.showSmallModal(ClientModal, {
					client: this.client,
					emailRequired: true,
					onSave: fn //() => this.showSmallModal(SignClientSet, { fn, client: this.client })
				})
			} else {
				fn()
				//this.showSmallModal(SignClientSet, { fn, client: this.client })
			}
		}
	},
	watch: {
		client(value) {
			if (value) this.showClientPanel = true
		},
		err(err) {
			if (err && err.code === 'ERRPERMISSION') this.$emit('close')
		}
	},
	created() {
		this.$store.dispatch('saveClients/setSelected', null)
	},
	destroyed() {
		if (this.$store.state.auth.authenticated) this.$store.dispatch('saveClientSets/clearList')
	}
}
</script>

<style lang='scss'>
.saveAndEmailClientSet {
	@include fill;
	display: grid;
	grid-template-columns: 5fr 4fr;
	background: $color-neutral-panel;

	.cancelFooter {
		display: flex;

		> div {
			flex: 1;
			margin-top: auto;
		}
	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}
}
</style>
