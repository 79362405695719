<template>
<div class='saveTemplateListItem' >
	<div class='title'>				
		<span class='overflow'>{{item.savename || item.title || $t("elements.labels.untitled")}}</span>
		<!--<span class='overflow path' v-html='path' />-->
	</div>
	<div class='updatedCol'><div>
		<div class='updated'>{{updated}}</div>
		<div v-if='item.savedBy' class='savedBy'>{{$t('elements.parts.by')}} {{item.savedBy}}</div>
	</div></div>	
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'

export default {
	name: 'SaveTemplateListItem',
	mixins: [dateTime],
	props: ['item','path'],
	computed: {
		updated() {
			this.langBackdoor
			return this.dateTime(this.item.updated) 			
		}
	},
	methods: {}
}
</script>

<style lang='scss'>
.saveTemplateListItem {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin: 0 $size-gutter * 2;

	&:hover { background: $color-neutral-silver; }

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		> div > div { font-size: $size-font-small; color: $color-neutral-dark; }
	}


	.title {
		flex: 1;
		padding-right: 0;
		.path { color: $color-neutral-shadow; margin-left: $size-gutter * 2; }
		> span { white-space: nowrap; }	
	}

	.updatedCol {
		flex: 0;
		flex-basis: 180px;
		.updated { color: $color-black; font-size: $size-font-small; line-height: $size-lineHeight-standard; }
	}

	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }


}
</style>