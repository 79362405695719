import { eventBus } from '@/utilities/eventBus'

const wm1 = new WeakMap()
const wm2 = new WeakMap()
const wm3 = new WeakMap()

const onEnter = el => el.classList.add('dropOn')

const onLeave = el => el.classList.remove('dropOn')

const onBussing = (elFromPt, el) => {
	const contains = el.contains(elFromPt)
	if (contains && !wm3.get(el)) {
		wm3.set(el, true)
		onEnter(el)
	} else if (!contains && wm3.get(el)) {
		wm3.delete(el)
		onLeave(el)
	}
}

const onUnloadBus = el => {
	if (el.closest('.dropOn')) el.closest('.dropOn').dispatchEvent(new CustomEvent('dropOn'))
	onLeave(el)
}

const bind = el => {
	unbind(el)
	const fn1 = elFromPt => onBussing(elFromPt, el)
	const fn2 = () => onUnloadBus(el)
	if (!wm1.has(el)) wm1.set(el, fn1)
	if (!wm2.has(el)) wm2.set(el, fn2)
	eventBus.$on('bussing', fn1)
	eventBus.$on('unloadBus', fn2)
}

const unbind = el => {
	el.classList.remove('dropOn')
	const fn1 = wm1.get(el)
	if (fn1) {
		eventBus.$off('bussing', fn1)
		wm1.delete(el)
	}
	const fn2 = wm2.get(el)
	if (fn2) {
		eventBus.$off('unloadBus', fn2)
		wm2.delete(el)
	}
}

export const dropOn = {
	update: function(el, binding) {
		if (binding.value) bind(el)
		else unbind(el)
	},
	unbind: function(el) {
		unbind(el)
	}
}
