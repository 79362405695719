<template>
	<div class='progressBar'>
		<div class='progress' :class='{ uploaded, success, error }' :style='{width: progress + "%"}'>
			<span v-if='success'>{{$t('elements.uploads.completed')}}</span>
			<span v-else-if='error'>{{$t('elements.uploads.failed')}}</span>
			<span v-else-if='uploaded'>{{$t('elements.uploads.processing')}}</span>
			<span v-else>{{Math.ceil(progress)}}%</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProgressBar',
	props: ['progress', 'result'],
	computed: {
		uploaded() {
			return parseInt(this.progress) == 100
		},
		success() {
			return this.result === 'success'
		},
		error() {
			return this.result === 'error'
		}
	}
}
</script>

<style lang='scss'>
.progressBar {
	position: relative;
	width: 100%;
	height: $size-control-height / 2;
	overflow: hidden;
	background: linear-gradient(to bottom, rgba($color-black, 0.5), rgba($color-black, 0.3));
	border-radius: $size-control-height / 2;

	.progress {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		min-width: 35px;
		border-radius: $size-control-height / 2;
		overflow: hidden;
		transition: width .6s $ease-out-quint;
		background: $color-primary-accent;
		background-size: 30px 30px;
		background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent);
		animation: barberpole 0.5s linear infinite;

		&.uploaded, &.success, &.error {
			width: 100% !important;

			span {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.success {
			background: $color-success;
		}

		&.error {
			background: $color-alert;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba($color-white, .3), rgba($color-white, 0))
		}

		span {
			color: $color-white;
			font-weight: bold;
			font-size: $size-font-small;
			margin-left: auto;
			margin-right: $size-gutter;		
		}
	}
}

@keyframes barberpole {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 60px 30px;
	}
}
</style>