<template>
<div class='saveTemplate'>
	<Folders @update='onFolderUpdate' />
	<List :folders='folders' @close='close' @save='save' :savename='savename' />
</div>
</template>

<script> 
import Folders from './Folders'
import List from './List'

export default {
	name: 'SaveTemplate',
	components: { Folders, List },
	props: ['setKey'],	
	data() { return {
		folders: {}
	}},
	computed: {
		savename() {
			return (
				this.$store.state.sets.root[this.setKey].savename || 
				//this.$store.state.sets.root[this.setKey].title ||
				null
			)
		},		
	},
	methods: {
		onFolderUpdate(val) {
			this.folders = val
		},
		save(savename) {
			this.$store.dispatch('sets/saveNewFolderSet', {
				setKey: this.setKey,
				folderId: this.$store.state.saveTemplates.folderId,
				savename
			})
			this.$emit('close', true)
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.saveTemplate {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: minmax(270px, 20%) 1fr;
}
</style>