<template>
<div 
	class='set' 
	:class='{ focus: $store.state.setBuilder.focus === setKey, wideMenu: width > 800 }'
	@pointerdown='setFocus' 
	@toggleDetail='onToggleDetail'
>
	<transition name='scroll'>
		<div 
			v-show='!$store.state.bus.bussing'
			ref='menu'
			class='scrollMenu' 
			:style='`transform: translateY(${menuTop}px)`'
		>
			<SetControls :setKey='setKey' @scrollToTop='scrollToTop' @scrollToBottom='scrollToBottom' @addCover='addCover' @duplicated='$emit("duplicated")' />
		</div>
	</transition>
	<transition name='scroll'>
		<div v-show='!$store.state.main.mobile && showMenuTab && !$store.state.bus.bussing' class='blockButton neutral menuTrigger' @click='onClickMenuTab'>
			<svg class='smallIcon'>
				<use xlink:href='#caretDown' />
			</svg>
		</div>
	</transition>	
	<SetContent 
		:setKey='setKey' 
		ref='setContent' 
		@showSpinner='showSpinner = true' 
		@hideSpinner='showSpinner = false' 
		@diff='onScrollDiff'
		@resize='onResize'
		:mousein='mousein' 
	/>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { eventBus } from '@/utilities/eventBus'
import SetControls from './SetControls'
import SetContent from './SetContent'
import Loading from '@/components/common/Loading'

export default {
	name: 'Set',
	components: {
		SetControls,
		SetContent,
		Loading
	},
	props: ['setKey'],
	data: () => ({ 
		showSpinner: false, 
		mousein: false, 
		menuTop: 0,
		showMenuTab: false,
		ignoreScrollEvents: false,
		scrollTop: 0,
		width: 0
	}),
	computed: {
		loading() {
			return this.$store.state.sets.root[this.setKey].apiPending || this.showSpinner
		}
	},
	methods: {
		onResize() {
			this.width = this.$el.offsetWidth
		},
		onScrollDiff({ diff, el }) {
			this.scrollTop = el.scrollTop
			if (this.ignoreScrollEvents) return
			// bounce
			if (el.scrollTop < 0) return this.menuTop = 0
			if (el.scrollTop > el.scrollHeight - el.offsetHeight) return //this.menuTop = -h
			// cap limits
			const h = this.$refs.menu.offsetHeight + 14
			if (this.menuTop > 0) return this.menuTop = 0
			else if (this.menuTop < -h) return this.menuTop = -h
			// calc tab
			if (!this.showMenuTab && this.menuTop === -h) this.showMenuTab = true
			else if (this.showMenuTab && this.menuTop > -h) this.showMenuTab = false
			// calc pos
			if (diff < 0 && Math.abs(this.menuTop) < h) {
				this.menuTop+=diff
			} else if (diff > 0 && this.menuTop < 0) {
				this.menuTop = Math.min(0, this.menuTop + diff)
			}
		},
		onClickMenuTab() {
			this.showMenuTab=false
			this.menuTop = 0
			this.$refs.setContent.$refs.scroll.setScrollOffset(-this.$refs.menu.offsetHeight)
		},
		onToggleDetail() {
			this.ignoreScrollEvents = true
			setTimeout(() => this.ignoreScrollEvents = false, 500)
			setTimeout(() => this.hideMenu(), 0)
		},
		onTabSelected(key) {
			if (key === this.setKey) {
				this.showMenu()
				this.$refs.setContent.$refs.scroll.scrollToTop()
			}
		},
		hideMenu() {
			const h = this.$refs.menu.offsetHeight	
			this.menuTop = -h - 14
			this.showMenuTab = true		
		},	
		showMenu() {
			this.menuTop = 0
			this.showMenuTab = false	
		},
		onBussing(el) {
			if (this.$el.contains(el)) this.mousein = true
			else this.mousein = false
		},
		onUnloadBus() {
			this.mousein = false
		},
		setFocus() {
			//if (this.$store.state.setBuilder.pin !== this.setKey) this.$store.dispatch('setBuilder/setFocus', this.setKey)
			this.$store.dispatch('setBuilder/setFocus', this.setKey)
		},
		scrollToBottom(selector) {
			this.$refs.setContent.$refs.scroll.scrollToBottom(selector)
		},
		scrollToTop(selector) {
			this.$refs.setContent.$refs.scroll.scrollToTop(selector)
		},		
		addCover() {
			this.$refs.setContent.addCover()
			this.$refs.setContent.$refs.scroll.scrollToTop
		}
	},
	mounted() {
		eventBus.$on('bussing', this.onBussing)
		eventBus.$on('unloadBus', this.onUnloadBus)
		eventBus.$on('tabSelected', this.onTabSelected)
	},
	beforeDestroy() {
		eventBus.$off('bussing', this.onBussing)
		eventBus.$off('unloadBus', this.onUnloadBus)	
		eventBus.$off('tabSelected', this.onTabSelected)			
	},
	watch: {
		scrollTop(v) {
			if (v === 0) this.showMenu()
			//if (!this.menuShadow && v > 108) this.menuShadow = true
			//else if (this.menuShadow && v <= 108) this.menuShadow = false
		}
	}
}
</script>

<style lang='scss'>
.set {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	height: 100%;
	width: 100%;
background: linear-gradient(to top, $color-neutral-panel, $color-neutral-silver);

/*
	transition: opacity 0.2s linear;

	&:not(.focus) { opacity: 0.7; }
*/
	.loading {
		background: rgba($color-neutral-panel, 0.3);
		pointer-events: none;
	}

	.scrollMenu {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 550;
		width: calc(100% - #{$size-gutter * 2});
		padding-left: $size-gutter * 2;
		padding-top: $size-gutter * 2;
		background: $color-neutral-silver;

		> div { transition: box-shadow .5s; }

		&.menuShadow > div {
			box-shadow: 0 3px 5px rgba(0,0,0,0.2);			
		}
	}	

	.menuTrigger {
		position: absolute;
		top: 0;
		z-index: 550;
		left: $size-gutter * 2;
		border-top: none;
		border-bottom-right-radius: 7px;
		border: 1px solid $color-neutral-shadow;
		border-top: none;
		box-shadow: 0 4px 6px  rgba(0,0,0,.08);
	}
}

.sorting {
	.set {
		opacity: 1;
	}
	.setWrapper:after {
		content: '';
		position: absolute;
		top: $size-gutter * 2;
		left: calc(100% + #{$size-gutter * 2});
		width: calc(100% - #{$size-gutter * 4});
		height: calc(100% - #{$size-gutter * 4});
		
		background: $color-primary-shadow;	
	}
	.setWrapper:first-child:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: $size-gutter * 2;
		left: calc(-100% + #{$size-gutter * 2});
		width: calc(100% - #{$size-gutter * 4});
		height: calc(100% - #{$size-gutter * 4});
		
		background: $color-primary-shadow;	
	}
}

.sortHelper .set .setContent .sortHandle {
	color: $color-neutral-shadow;
}
.sortHelper .set .editParameters .sortHandle, 
.sortHelper .set .editResources .sortHandle {
	display: none;
}
</style>