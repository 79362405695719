<template>
<div class='pdfText'>
	<div
		v-for='(element, index) in set.elements'
		:key='index'
		:class='["element", element.type]'
	>
		<div class='title'>{{element.title}}</div>
		<div v-if='element.type === "setExercise"' class='parameters'>
			<div 
				v-for='(parameter, index) in element.parameters' 
				:key='index'
			>
				{{parameter.title}}:&nbsp; <span v-html='parameter.value' />
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'PreviewText',
	props: ['setKey', 'selectedExs'],
	computed: {
		set() { return this.$store.getters['sets/getSetForExport'](this.setKey, this.selectedExs) }
	},
	mounted() {
		this.$emit('resize')
	}
}
</script>

<style lang='scss'>
.pdfText {
	width: 1000px;

	.title {
		font-size: 20px;
	}

	.setDivider {
		margin-bottom: 16px;
		.title {
			font-weight: bold;
			border-bottom: 8px solid $color-primary-accent;	
			padding: 10px 0;
		}
	}

	.setExercise {
		padding-bottom: 16px;
		padding-left: 10px;
		margin-bottom: 16px;

		&:not(:last-child) {
			border-bottom: 1px dashed $color-neutral-shadow;
		}
	}

	.parameters {
		display: flex;
		flex-wrap: wrap;
		font-size: 18px;
		padding-top: 4px;

		> div {
			white-space: nowrap;
			border-right: 1px solid $color-neutral-shadow;
			padding-right: 16px;
			margin-right: 16px;

			&:last-child {
				border-right: none !important;
				padding-right: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}
</style>
