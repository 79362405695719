<template>
<div 
	class='uploadLogo' 
	@dragover='onDragOver' 
	@dragleave='onDragLeave' 
	@drop.prevent='onDrop'
	@mouseleave='onDragLeave'
>
	<CancelButton 
		v-if='files.length' 
		@click='() => {
			cancelUpload()
			$emit("unsetLogo")
		}' 
	/>
	<label 
		v-show='!files.length' 
		class='uploadButton iconTextButton silver' 
		:class='{ canDrop: canDrop && !files.length }' 
		:style='style'
	>
		<svg class='smallIcon'>
			<use xlink:href='#upload' />
		</svg>
		<span class='label'>{{ $t('elements.buttons.uploadLogo') }}</span>
		<file-upload
			ref='upload'
			accept='image/*'
			v-model='files'
			:extensions='imageExtensions'
			:custom-action='uploadToS3'
			:multiple='false'
			:size='1024 * 1024 * 3'
			:thread='1'
			:timeout='1000 * 30'
			:maximum='1'
			@input-file='onStart'
			@click.native='catchNativeClick'
		/>
	</label>
	<UploadProgress :files='files' />
</div>
</template>

<script>
import { uploadMixin } from '@/components/common/uploads/mixin'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'

export default {
	name: 'UploadLogo',
	mixins: [uploadMixin, smallModalMixin],
	props: ['getUploadUrl'],
	computed: {
		style() {
			return this.canDrop ? `background: radial-gradient(circle at ${this.x}px ${this.y}px, #ffff99 40%, #f8f8f8 40%, #f8f8f8);` : ``
		}
	},
	methods: {
		onUploadSuccess(file) {
			this.$emit('uploadSuccess', file.pendingResource.url)
		},
		onStart(...options) {
			this.canDrop = false
			this.onInputFile(...options)
		},
		async uploadToS3(file, component) {
			const [err, result] = await this.getUploadUrl()
			if (err) return component.clear()
			file.putAction = result.uploadUrl
			file.pendingResource = result.pendingResource
			return await component.uploadPut(file)	
		},
		onUploadError() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.uploadError.title'),
				message: this.$t('views.uploadError.p1')
			})
		}
	}
}
</script>

<style lang='scss'>
.uploadLogo {
	position: relative;
	height: 100%;

	.progressBars {
		background: $color-white;
	}

	.uploadButton {
		height: 100%;
		/*
		outline: 2px dashed rgba($color-black, 0.2);
		outline-offset: -$size-gutter * 2;
		*/
		border: 1px solid $color-neutral-shadow;
	}
}
</style>