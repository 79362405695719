<template>
<div class='setElementDetail setDividerDetail ctrlLasso' @pointerdown='onMouseDown'><div><div>

	<TextInput 
		ref='titleInput'
		v-model='title' 
		:placeholder='$t("views.designer.phDividerTitle")' 
		maxlength='100' 
	/>

	<RichText 
		v-model='instructions' 
		:placeholder='$t("views.designer.phDividerInstructions")' 
		:maxlength='5000'
	/>

</div></div></div>
</template>

<script>
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'

export default {
	name: 'DividerDetail',
	components: {
		TextInput,
		RichText
	},
	props: ['elementKey'],
	computed: {
		divider() {
			return this.$store.state.sets.elements[this.elementKey]
		},
		title: {
			get() {
				return this.divider.title
			},
			set(value) {
				this.$store.dispatch('sets/setElementTitle', {
					key: this.divider.key,
					value
				})
			}
		},
		instructions: {
			get() {
				return this.divider.instructions
			},
			set(value) {
				this.$store.dispatch('sets/setElementInstructions', {
					key: this.divider.key,
					value
				})
			}
		}
	},
	methods: {
		onMouseDown() {
			this.$store.dispatch('setBuilder/setFocus', this.divider.setKey)
		}
	},
	mounted() {
		if (!this.title) this.$refs.titleInput.focus()		
	}
}
</script>

<style lang='scss'>
.setDividerDetail {
	position: relative;
	top: 14px;
	left: 7px;
	z-index: 10;
	transform: translate3d(0,0,10px); /* safari */
	width: calc(100% - 14px);
	padding-bottom: 28px;
	/*
	padding-top: $size-gutter * 5 - 1;
	padding-bottom: $size-gutter * 4;
	background: $color-neutral-panel;
	border-top: 1px solid $color-neutral-shadow;
	border-bottom: 1px solid $color-neutral-shadow;
	margin-bottom: -2px;
	*/
	z-index: 10;
	transform: translate3d(0,0,10px); /* safari */

	> div {
		background: $color-neutral-shadow;
		border: 1px solid $color-neutral-shadow;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);	
	}

	.textInput {
		margin-bottom: 1px;
	}
}
</style>