<template>
<SlickList 
	v-model='list' 
	:axis='"y"' 
	:lockAxis='"y"' 
	:helperClass='"sortHelper"' 
	:helperSettlingClass='"settling"' 
	:parent='".scrollContainer"' 
	:useDragHandle='true' 
	:shouldCancelStart='cancelSort' 
	:lockToContainerEdges='true' 
	:distance='10'
	:yOffset='60'
	@before-sort-start='onBeforeSortStart'
	@sort-end='onSortEnd'
>
	<div 
		v-if='elementKeys.length' 
		class='setElements' 
		:class='{ sorting, dropElements, dropParameters, dropPending }' 
		ref='setElements'
	>
		<div class='hoverTop' :class='{ hovering: hovering === "top" }' />
		<template v-for='(elementKey, index) in elementKeys'>
			<SlickItem 
				v-if='$store.state.sets.elements[elementKey].type === "setExercise"' 
				:index='index' 
				:key='elementKey' 
				:data-index='index'
				class='setElementWrapper'
				:class='{ hovering: hovering === index, fixed: fixed === index }'
			>	
				<SortButton 
					v-show='!sorting || !isSortingSelected() || !$store.state.setBuilder.selected.includes(elementKey) || $store.state.setBuilder.selected.length === 1' 
					axes='y' 
					@pointerdown.native='() => closeDetailForSort(elementKey)' 
					class='ctrlLasso' 
				/>
				<template v-if='sorting && sortIndex===index && $store.state.setBuilder.selected.includes(elementKey)'>
					<template v-for='selectedKey in $store.state.setBuilder.selected'>
						<Exercise 
							v-if='$store.state.sets.elements[selectedKey].type === "setExercise"' 
							:key='selectedKey'
							:element='$store.state.sets.elements[selectedKey]'
							:thumbnailUrl='$store.getters["sets/getThumbnailUrl"](selectedKey)'
							:selected='$store.state.setBuilder.selected.includes(selectedKey)'
							class='setElement' 
						/>
						<Divider
							v-else-if='$store.state.sets.elements[selectedKey].type === "setDivider"' 
							:key='selectedKey'							
							:element='$store.state.sets.elements[selectedKey]'
							:selected='$store.state.setBuilder.selected.includes(selectedKey)'
							class='setElement' 
						/>	
					</template>	
				</template>	
				<template v-else-if='!sorting || !isSortingSelected() || !$store.state.setBuilder.selected.includes(elementKey)'>
					<Exercise 
						:element='$store.state.sets.elements[elementKey]'
						:thumbnailUrl='$store.getters["sets/getThumbnailUrl"](elementKey)'
						:selected='$store.state.setBuilder.selected.includes(elementKey)'
						:selecting='$store.state.setBuilder.selecting.includes(elementKey)'
						:detail='detail(elementKey)'
						:no='getNo(elementKey)'
						@showContextMenu='showContextMenu'
						class='setElement' 
					/>
				</template>
				<ExerciseDetail 
					v-if='prevDetailed[elementKey] || detail(elementKey)'
					v-show='detail(elementKey)'
					:elementKey='elementKey' 
					class='setElementDetail'
				/>
			</SlickItem>
			<SlickItem 
				v-if='$store.state.sets.elements[elementKey].type === "setDivider"'
				:index='index' 
				:key='elementKey' 
				:data-index='index'				
				class='setElementWrapper'
				:class='{ hovering: hovering === index, fixed: fixed === index }'
			>
				<SortButton axes='y' @pointerdown.native='() => closeDetailForSort(elementKey)' class='ctrlLasso' />
				<template v-if='sorting && sortIndex===index && $store.state.setBuilder.selected.includes(elementKey)'>
					<template v-for='selectedKey in $store.state.setBuilder.selected'>
						<Exercise 
							v-if='$store.state.sets.elements[selectedKey].type === "setExercise"' 
							:key='selectedKey'
							:element='$store.state.sets.elements[selectedKey]'
							:thumbnailUrl='$store.getters["sets/getThumbnailUrl"](selectedKey)'
							:selected='$store.state.setBuilder.selected.includes(selectedKey)'
							class='setElement' 
						/>
						<Divider
							v-else-if='$store.state.sets.elements[selectedKey].type === "setDivider"' 
							:key='selectedKey'							
							:element='$store.state.sets.elements[selectedKey]'
							:selected='$store.state.setBuilder.selected.includes(selectedKey)'
							class='setElement' 
						/>	
					</template>	
				</template>	
				<template v-else-if='!sorting || !isSortingSelected() || !$store.state.setBuilder.selected.includes(elementKey)'>
					<Divider
						:element='$store.state.sets.elements[elementKey]'
						:selected='$store.state.setBuilder.selected.includes(elementKey)'
						:selecting='$store.state.setBuilder.selecting.includes(elementKey)'
						:detail='detail(elementKey)'
						@showContextMenu='showContextMenu'
						class='setElement' 
					/>				
				</template>
				<DividerDetail 
					v-if='prevDetailed[elementKey] || detail(elementKey)'				
					v-show='detail(elementKey)'
					:elementKey='elementKey' 
					class='setElementDetail'
				/>		
			</SlickItem>
		</template>
	</div>
	<div 
		v-if='list.length'
		class='setFooterPadding' 
		:style='`height: ${padding}px`' 
		v-tap='() => $store.dispatch("setBuilder/clearSelected")'
	/>
		<ContextMenu 
			v-if='contextMenu' 
			:e='contextMenu'
			@close='contextMenu=false'
		>
				<div 
					class='iconTextButton neutral'
					@click='$store.dispatch("setBuilder/clearSelected")'
				>
					<svg class='smallIcon'>
						<use xlink:href='#cancel' />
					</svg>
					<span class='label'>{{$t("elements.buttons.unselect")}}</span>
				</div>
				<div 
					class='iconTextButton neutral' 
					@click='()=>{ $store.commit("setBuilder/setSelected", []); contextMenu.target.click() }'
					:class='{ disabled: selected.length>1 }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#edit' />
					</svg>
					<span class='label'>Open / Close</span>
				</div>						
				<div 
					class='iconTextButton neutral' 
					@click='duplicate'
					:class='{ disabled: selected.length > 10 }'
				>
					<svg class='smallIcon'>
						<use xlink:href='#copy' />
					</svg>
					<span class='label'>{{$t("elements.buttons.copy")}}: {{selected.length}}</span>
				</div>	
				<div 
					class='iconTextButton neutral' 
					@click='convertToStack'
					:class='{ disabled: selected.length<2 || selected.length>10 }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#stack' />
					</svg>
					<span class='label'>Convert to Stack: {{selected.length}}</span>
				</div>
				<div 
					class='iconTextButton neutral' 
					@click='copyToCustom'
					:class='{ disabled: selected.length<1 || selected.length>10 }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#personal' />
					</svg>
					<span class='label'>Copy to Custom: {{selected.length}}</span>
				</div>	
				<div 
					class='iconTextButton neutral' 
					@click='printSelected'
					:class='{ disabled: selected.length<1 || selected.length>10 }'					
				>
					<svg class='smallIcon'>
						<use xlink:href='#print' />
					</svg>
					<span class='label'>Print Selected: {{selected.length}}</span>
				</div>															
				<div 
					class='iconTextButton neutral' 
					@click='removeElements'
				>
					<svg class='smallIcon'>
						<use xlink:href='#delete' />
					</svg>
					<span class='label'>{{$t("elements.buttons.delete")}}: {{selected.length}}</span>
				</div>	
		</ContextMenu>
</SlickList>
</template>

<script>
import { largeModalMixin } from '@/components/common/mixins/modal'
import SetPdf from './modals/setPdf/SetPdf'
import AlertModal from '@/components/common/modals/Alert'

import { without } from 'lodash'
import { eventBus } from '@/utilities/eventBus'
import { SlickList, SlickItem } from '@/libs/slickSort'
import ContextMenu from '@/components/common/ContextMenu'
import SortButton from '@/components/common/buttons/Sort'
import Exercise from './Exercise'
import Divider from './Divider'
import ExerciseDetail from './ExerciseDetail'
import DividerDetail from './DividerDetail'


export default {
	name: 'SetElements',
	props: ['setKey', 'padding'],
	mixins: [largeModalMixin],
	components: {
		Exercise,
		Divider,
		ExerciseDetail,
		DividerDetail,
		ContextMenu,
		SlickList, SlickItem, SortButton
	},
	data() { return {
		sorting: false,
		sortIndex: null,
		hovering: false,
		dropPending: false,
		fixed: false,
		contextMenu: false,
		prevDetailed: {}
	}},
	computed: {
		list: {
			get() {
				return this.$store.state.sets.root[this.setKey].elements
			},
			set(list) {
				this.$store.commit('sets/setElementsList', {
					key: this.setKey,
					value: list
				})
			}
		},
		selected() {
			return this.$store.state.setBuilder.selected
		},
		elementKeys() {
			return this.$store.state.sets.root[this.setKey].elements
		},
		dropParameters() {
			return (
				this.$store.state.bus.bussing &&
				this.$store.state.bus.contentType === 'bulkParameters'
			)
		},
		dropElements() {
			return (
				this.$store.state.bus.bussing && (
					this.$store.state.bus.contentType === 'browserElements' || 
					(this.$store.state.bus.contentType === 'setElements' && this.$store.state.setBuilder.focus !== this.setKey)
				) ||
				this.dropPending
			)
		}
	},
	methods: {
		onBussing(el) {
			if (!this.$el.contains(el)) return this.hovering = false
			let element
			element = el.closest('.setElementWrapper')
			if (element) return this.hovering = +element.dataset.index
			element = el.closest('.hoverTop')
			if (element) return this.hovering = 0
			element = el.closest('.initialDrop')
			if (element) return this.hovering = 0
			this.hovering = this.list.length 
		},
		onUnloadBus(el) {
			if (!this.$el.contains(el)) return
			if (this.dropElements) this.copy(this.hovering)
			else if (this.dropParameters) this.onDropParameters(this.hovering)
			this.hovering = false
		},
		onBeforeSortStart({ index }) {
			this.sorting=true
			this.sortIndex=index
			const selectedKey = this.$store.state.sets.root[this.setKey].elements[this.sortIndex]
			if (this.$store.state.setBuilder.selected.includes(selectedKey) && this.$store.state.setBuilder.selected.length > 1) {
				this.$store.dispatch('sets/setDetail', { key: this.setKey, value: null })
			}
		},
		closeDetailForSort(elementKey) {
			if (this.detail(elementKey)) {
				const setKey = this.$store.state.sets.elements[elementKey].setKey
				this.$store.dispatch('sets/setDetail', { key: setKey, value: null })
			}
		},	
		onSortEnd() {
			if (this.isSortingSelected()) {
				const selectedKey = this.$store.state.sets.root[this.setKey].elements[this.sortIndex]
				const selected = [...this.$store.state.setBuilder.selected]
				const a = without(selected, selectedKey)
				this.$nextTick(() => {
					const b = without(this.$store.state.sets.root[this.setKey].elements, ...a)
					const i = b.indexOf(selectedKey)
					const sorted = without(b, selectedKey)
					sorted.splice(i, 0, ...selected)
					this.$store.commit('sets/setElementsList', { key: this.setKey, value: sorted })					
				})
			}
			setTimeout(() => {
				this.sorting = false
				this.sortIndex = null
			}, 0)	
		},
		isSortingSelected() {
			return this.$store.state.setBuilder.selected.includes(this.$store.state.sets.root[this.setKey].elements[this.sortIndex])
		},
		onDropParameters(index) {
			const elementKey = this.$store.state.sets.root[this.setKey].elements[index]
			if (elementKey) {
				this.$store.dispatch('sets/applyParameters', {
					exerciseKeys: [elementKey],
					parameters: this.$store.state.bus.bus
				})
			}
		},
		showContextMenu(e) {
			this.contextMenu=e
		},	
		async copy(index) {
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (!ids) return
				if (!this.$store.getters['elements/complete'](ids)) {
					this.fixed = index
					this.dropPending = true
					if (! await this.$store.dispatch('exerciseBrowser/fetchByIds', ids.join(','))) {
						this.fixed = false
						this.dropPending = false
						return 
					}
					this.fixed = false
					this.dropPending = false
				}
				const { exercises } = this.$store.getters['elements/getElementsForCopyToSet'](ids)
				this.$store.dispatch('sets/copyElements', { exercises, setKey: this.setKey, index })
					.then(elementKeys => {
						if (elementKeys.length === 1) this.$store.dispatch('setBuilder/setSelected', elementKeys)
					})
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { elements } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				this.$store.dispatch('sets/copyElements', { elements, setKey: this.setKey, index })
					.then(elementKeys => {
						//if (elementKeys.length === 1) 
						this.$store.dispatch('setBuilder/setSelected', elementKeys)
					})
			}
			setTimeout(() => this.$store.dispatch('setBuilder/setFocus', this.setKey), 0)
		},
		duplicate() {
			const el = this.contextMenu.target.closest('.setElementWrapper')
			//const index = [...el.parentElement.children].indexOf(el)
			const index = this.elementKeys.length
			const { elements } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.setBuilder.selected)			
			this.$store.dispatch('sets/copyElements', { elements, setKey: this.setKey, index })
				.then(elementKeys => {
					this.$store.dispatch('setBuilder/setSelected', elementKeys)
					const el = this.$el.querySelector('.setElementWrapper:last-child') 
					el.scrollIntoView({ block: 'center' })
				})			
		},
		async convertToStack() {
			const o = {}
			const { exercises } = this.$store.getters['sets/getElementsForCopyToBrowser'](this.$store.state.setBuilder.selected)			
			const keys = await this.$store.dispatch('exerciseBrowser/createStack', exercises)
			if (!this.$store.getters['exerciseBrowser/showCreate']) {
				if (this.$store.state.exerciseBrowser.tab!=="custom") {
					this.$router.replace({ params: { tab: 'custom' }})
					o.tab = 'custom'
				}
				if (!this.$store.getters['exerciseBrowser/showCreate']) {
					o.folderId = this.$store.state.exerciseBrowser.userRootId
				}
				await this.$store.dispatch('exerciseBrowser/fetchWorkaround', o)	
				await this.$store.dispatch('exerciseBrowser/setSelectedMain', keys)				
			}
			//	.then(()=>this.$store.dispatch('flash/showAction', 'converted'))			
		},
		async copyToCustom() {
			const o = {}
			const { exercises } = this.$store.getters['sets/getElementsForCopyToBrowser'](this.$store.state.setBuilder.selected)			
			const keys = await this.$store.dispatch('exerciseBrowser/copy', { exercises })
			if (!this.$store.getters['exerciseBrowser/showCreate']) {
				if (this.$store.state.exerciseBrowser.tab!=="custom") {
					this.$router.replace({ params: { tab: 'custom' }})
					o.tab = 'custom'
				}
				if (!this.$store.getters['exerciseBrowser/showCreate']) {
					o.folderId = this.$store.state.exerciseBrowser.userRootId
				}				
				await this.$store.dispatch('exerciseBrowser/fetchWorkaround', o)			
				await this.$store.dispatch('exerciseBrowser/setSelectedMain', keys)				
			}						

			//	.then(()=>this.$store.dispatch('flash/showAction','copied'))
		},		
		printSelected() {
			if (this.selected.length > 20) {
				this.showSmallModal(AlertModal, {
					title: this.$t('views.warnCannotExport.title'),
					message: this.$t('views.warnCannotExport.p1')
				})
			} else {
				this.showMediumModal(SetPdf, { setKey: this.setKey, selectedExs: this.selected }) 

			}
		},
		removeElements() {
            this.$store.dispatch('setBuilder/removeElements', { elementKeys: this.selected })			
		},
		cancelSort(e) {
			return e.ctrlKey || e.metaKey || e.shiftKey
		},
		detail(elementKey) {
			const setKey = this.$store.state.sets.elements[elementKey].setKey
			if (this.$store.state.sets.root[setKey].detail === elementKey) {
				this.prevDetailed[elementKey] = true
				return true
			} else { 
				return false
			}
		},
		getNo(elementKey) {
			const setKey = this.$store.state.sets.elements[elementKey].setKey
			let no = 1
			for (const i of this.$store.state.sets.root[setKey].elements) {
				if (i === elementKey) break
				else if (this.$store.state.sets.elements[i].type === 'setDivider') no = 1 
				else no++
			}
			return no
		}
	},
	mounted() {
		eventBus.$on('bussing', this.onBussing)
		eventBus.$on('unloadBus', this.onUnloadBus)
	},
	beforeDestroy() {
		eventBus.$off('bussing', this.onBussing)
		eventBus.$off('unloadBus', this.onUnloadBus)		
	},
	watch: {
		hovering(v) {
			if (v!==false && this.dropElements) {
				document.body.classList.add('dropElements')
			} else {
				document.body.classList.remove('dropElements')
			}
		}		
	}
}
</script>
<style lang='scss'>
$hoverOffset: $size-control-height * 1.5;

/*
@mixin setElementDropZone {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: $size-control-height * 1.5;
	background: $color-neutral-accent;
}
*/ 

@mixin loadingElements {
	/*
	position: absolute;
	left: calc(50% - 16px);
	background-color: #fff;
	border: 1px solid $color-neutral-shadow;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
	width: 38px;
	height: 38px;
	content: '';
	*/
}

@mixin setElementButton {
	pointer-events: none;
	position: absolute;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	left: calc(50% - 16px);
	content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' width='16px' height='16px'>\ <path fill='rgb(255, 255, 255)' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'/>\ </svg>");
	background: $button-gradient-primary;
	color: $color-white;
}

.setFooterPadding {  position: relative; z-index: 20; }

.setElements {
	outline: 1px solid $color-neutral-shadow;
	outline-offset: -1px;
	border: $size-gutter+1 solid #fff;
	margin: 0 $size-gutter * 2;
	background: #fff;

	>div {
		position: relative;
	}
/*
	.setElement {
		border-left: 1px solid $color-neutral-shadow;
		border-right: 1px solid $color-neutral-shadow;		
	}
*/
	.setElementWrapper + .setElementWrapper {
	}

	.padding:after, .padding:before {
		display: none !important;
	}

	>div:last-child .setElementDetail { border: none; }
}

.initialDrop {
	margin-left: $size-gutter * 2;
	margin-right: $size-gutter * 2;
	cursor: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 151px;
	left: 0;
	width: calc(100% - 58px);
	height: calc(100% - 166px);
	box-sizing: content-box;
	border: 1px solid transparent;
	margin-bottom: 0 !important;
	transition: all 200ms linear;
	border: 1px solid $color-neutral-shadow;
	background: #fff;

	&.bussing:hover {
		border: 1px solid saturate(lighten($color-primary, 20%), 20%);
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 4px 6px -2px rgba(0,0,0,.08);
	}

	&.dropPending {
		outline: none;
		background: none;
		border: none;

		&:after {
			@include loadingElements;
		}
	}

	&.initialDropHover:not(.dropPending).hovering {
		* {
			display: none;
		}

		&:after {
			@include setElementButton;
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


	.dropExercisesButton {
		color: $color-black;
		font-size: $size-font-large;
		text-align: center;
		max-width: 96px;
		width: 100%;
		padding: 1px;
			.label { white-space: nowrap; font-weight: normal; }

		.border {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 96px;
			width: 100%;
			padding-top: 100%;
			border-radius: 50%;
			margin-bottom: $size-gutter * 2;
			background: $button-gradient-primary;
			color: $color-white;

			svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: scale(0.5);
			}
		}
	}

}

/* Hover Behaviour */

.setElements {
	position: relative;

	.setElementWrapper {
		position: relative;
		> .sortHandle {
			position: absolute; 
			top: 0; 
			left: 0; 
			z-index: 507;
			padding: $size-gutter*3 $size-gutter $size-gutter*3 $size-gutter*2;
		}
	}
	
	.hoverTop { display: none; }

	&.dropElements {

		&:not(.dropPending) {
			&:after {
				position: absolute;
				bottom: -$size-control-height * 1.5 - 1;
				left: 0;
				width: 100%;
				height: $size-control-height * 1.5;
				content: '';
				z-index: 10;
			}

			.hoverTop {
				display: block;
				position: absolute;
				top: -207px;
				left: 0;
				width: 100%;
				height: 200px;
				background: $color-neutral-silver;
				z-index: 200;	
				border-bottom: 1px solid $color-neutral-shadow;
				&:before, &:after { display: none; }
				
				&.hovering ~ div {
					transform: translateY($hoverOffset); 
				}			
			}
		}

		padding-bottom: $size-control-height * 1.5;

		.setElementWrapper {
			transition: transform 600ms $ease-out-quart;

			&:before {
				pointer-events: auto;
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}

			.setElement:after {
				opacity: 1;
			}

			&:nth-child(2)>div:before {
				opacity: 1;
			}

			&.hovering, &.fixed  {
				transform: translateY($hoverOffset);

				&~div {
					transform: translateY($hoverOffset);
					&:last-child:after { display: none; }
				}
				&:last-child:after { display: none; }
			}

			.newElement {
				/* prevents the stutter that sometimes happens */
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	&.dropParameters .setElementWrapper.hovering {
		.setExercise >div>div:before {
			@include absTopLeft;
			content: '';
			width: 100%;
			height: 100%;
			z-index: 10;
			border: 2px solid $color-focus;
			background: rgba($color-focus, .2);
			
			pointer-events: none;
		}
		>div > div > div:after {
			background: $color-focus !important;
		}		
	}

	.newElement {
		&:after {
			display: none;
		}

		&:before {
			display: none;
		}

		>div {
			animation: setElementIn 300ms linear;

			&:after {
				display: none;
			}
		}

		&:first-child>div:before {
			display: none;
		}

		&:nth-last-child(2)>div {
			animation: setElementInLast 300ms linear;
		}
	}

	&.dropPending {
		overflow: hidden;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			z-index: 10;
		}


		.setElementWrapper {
			&:after {
				@include loadingElements;
				bottom: -$size-control-height - 10px;
			}
			&:nth-child(2)>div:not(.sortHandle):before {
				@include loadingElements;
				top: -$size-control-height - 10px;
			}
		}
	}

	&:not(.dropPending){
		.setElementWrapper {
			&:after {
				@include setElementButton;
				bottom: -$size-control-height - 6px;
			}
			&:nth-child(2)>div:not(.sortHandle):not(.setElementDetail):before {
				@include setElementButton;
				top: -$size-control-height - 6px;
			}
		}
	}

	.setElementWrapper {
		&:before {
			pointer-events: none;
			content: '';
			position: absolute;
			top: -($size-control-height * 1.5);
			left: 0;
			width: 100%;
			height: $size-control-height * 1.5 + 2px;
			opacity: 0;
		}
/*
		&:first-child:before {
			@include setElementDropZone;
			z-index: 1;
			top: -($size-control-height * 1.5 + 1px);
		}
*/
		&:nth-child(2)>div {
			&:before {
				opacity: 0;
				z-index: 2;
			}

			&.newElement:before {
				display: none;
			}
		}

		&:after {
			opacity: 0;
		}
/*
		.setElement:not(.detail):after {
			@include setElementDropZone;
			pointer-events: none;
			top: $size-control-height * 1.5 + 1;
			opacity: 0;
		}
*/
		&.hovering~div:nth-last-child(2):after,
		&.hovering~div:nth-last-child(2)>div:after,
		&.hovering:nth-last-child(2):after,
		&.hovering:nth-last-child(2)>div:after {
			z-index: -1;
			opacity: 0;
		}
	}
}

.sortHelper.setElementWrapper {
	padding: 0;
	margin: 0;
	> div { 
		padding: 0; margin: 0;
	}
	/*
	border-left: 1px solid $color-neutral-shadow;
	border-right: 1px solid $color-neutral-shadow;
	*/
	.sortHandle {
		position: absolute; 
		top: 0; 
		left: 0; 
		z-index: 120;
		padding: $size-gutter*3 $size-gutter $size-gutter*3 $size-gutter*2;
	}	
}


</style>